import React, { Component } from 'react';
import { Button, Modal, Row, Col, Layout, Input, message } from 'antd';
import { createBeautifullsCampaignAPI, updateBeautifullsCampaignAPI, deleteBeautifullsCampaignAPI} from "../apis/beautifulls";
import {UploadButton} from '../components/uploadButton';
import { DeleteOutlined } from '@ant-design/icons'

const { Content } = Layout;

export class LogoManagementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo_placer_api_running: false,
      logo_upload_loading: false,
      new_campaign_name: "",
      campaigns: props.campaigns,
    };
  }

    crudCampaign = async (actionType, payload) => {
        this.setState({
            logo_placer_api_running: true,
        })
        message.loading("Pasting logo...", 0)
            let apiFunction;
            switch(actionType) {
              case 'create':
                apiFunction = createBeautifullsCampaignAPI;
                break;
              case 'update':
                apiFunction = updateBeautifullsCampaignAPI;
                break;
              case 'delete':
                apiFunction = deleteBeautifullsCampaignAPI;
                break;
            default:
                throw new Error("Invalid action type");
            }        
            var status_code = 200;
            try {
                console.log("id token", this.props.idToken)
                apiFunction(payload, this.props.idToken).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Campaigns Updated!")
                        this.setState({
                          logo_placer_api_running: false,
                          campaigns: response.message.campaigns,
                      })  
                    }
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to paste logo. Try again :)")
                }
                else {
                    message.error("Failed to paste logo!")
                }
                this.setState({
                    logo_placer_api_running: false,
                })
            }
    }

    handleLogoClick = (logo_url) => {
        this.props.onLogoSelect(logo_url); // Trigger a callback provided by parent
        this.props.onOk(); // Close the modal
    }
    componentDidUpdate(prevProps) {
      if (this.props.campaigns !== prevProps.campaigns) {
        this.setState({ campaigns: this.props.campaigns });
      }
    }
    
  render() {
    const { campaigns } = this.state;
    console.log("campaings inside logo management modal", campaigns)
    return (
      <Modal
        title="Logo Management"
        centered
        open={this.props.isOpen}
        onOk={this.props.onOk}
        onCancel={this.props.onOk}
        closable={true}
        width={1200}
    >
        <Content>
        <Row>
            <h3>Manage Campaigns</h3>
        </Row>
        <Row>
          <Col span={15}>
            <Input
              placeholder="New Campaign Name!"
              value={this.state.new_campaign_name}
              onChange={e => this.setState({ new_campaign_name: e.target.value })}
            />
            </Col>
            <Col span={2}>
            <Button onClick={() => {
              console.log("new campaign name", this.state.new_campaign_name)
                const payload = { "name": this.state.new_campaign_name };
                this.crudCampaign("create", payload);
            }}>+ Add Campaign</Button>
            </Col>
          </Row>
          <br></br>
          {campaigns.map((campaign) => (
            <div key={campaign.id} style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px' }}>
            <Row key={campaign.id}>
              <Col span={2}>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const payload = {
                      id: campaign.id,
                      active: false,
                    }
                    this.crudCampaign("delete", payload)
                }}/>
              </Col>
              <Col span={18}>{campaign.name}</Col>
              <Col span={2}>
                <UploadButton
                  signin_name={this.props.username}
                  upload_loading={this.props.logo_upload_loading}
                  upload_endpoint={this.props.upload_endpoint}
                  id_token={this.props.idToken}
                  onImUploadChange={(e) => {
                      if (e.file.status === 'done') {
                          message.success('Logo Uploaded!');
                          var temp_campaign = campaign;
                          temp_campaign.logos.push({
                              "url": e.file.response.image_url,
                          })
                          this.crudCampaign("update", temp_campaign);
                      }
                  }}
                />
              </Col>
              <Row>
                {campaign.logos.map((logo) => (
                  <Col key={logo.id} span={3}>
                    <img
                        style={{ 
                          width: '100%', 
                          cursor: 'pointer',
                          backgroundColor: 'grey'  // Add this line
                        }} 
                      src={logo.url}
                      onClick={() => this.handleLogoClick(logo.url)} 
                    />
                    <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      // remove logo from campaign.logos
                      var temp_campaign = campaign;
                      temp_campaign.logos = temp_campaign.logos.filter((l) => l.url !== logo.url);
                    this.crudCampaign("update", temp_campaign)
                    }}/>
                  </Col>
                ))}
              </Row>
            </Row>
            </div>
          ))}
        </Content>
      </Modal>
    );
  }
}
