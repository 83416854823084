const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;

export async function GetDocubotProjectsAPI(token, skip=0, limit=30){
    let request={
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    }

    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/docubot/latest?skip="+skip+"&limit="+limit, request);
    if(rawResponse.ok){
        const content = await rawResponse.json();  
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    let content=null
    try {
      content = await rawResponse.json()
    } catch (error) {
      content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  };


export async function DocubotChatAPI(payload, token=null){
    let request={
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    if(token)
        request.headers['Authorization'] = `Bearer ${token}`;
        const rawResponse = await fetch(API_BASE_URL + "/api/v1/docubot/chat", request);
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}


export async function CreateDocubotProjectAPI(payload, token=null){
  let request={
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
      },
      body: JSON.stringify(payload)
  }
  if(token)
      request.headers['Authorization'] = `Bearer ${token}`;
      const rawResponse = await fetch(API_BASE_URL + "/api/v1/docubot/create", request);
  if(rawResponse.ok){
      const content = await rawResponse.json();
      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
      content = await rawResponse.json()
  } catch (error) {
      content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function AddToIndexAPI(payload, token=null){
    let request={
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    if(token)
        request.headers['Authorization'] = `Bearer ${token}`;
        const rawResponse = await fetch(API_BASE_URL + "/api/v1/docubot/update", request);
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  }
  
  