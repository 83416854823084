import React from "react";
import { Button, Modal, Row, Col, Layout, Input, Empty, Checkbox } from "antd";
const { Content } = Layout;

const { Search } = Input;
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const GenerativeImageModal = (props) => {

  return (
    <Modal
      title="Generate Images"
      centered
      open={props.isOpen}
      onOk={props.onOk}
      onCancel={props.onOk}
      closable={false}
      width={1200}
      footer={[
        <Button key="done" type="primary" onClick={props.onOk}>
          Done
        </Button>,
      ]}
    >
      <Content>
        <Row>
          <Col span={4}>
            <img
              style={{
                width: "100%",
                padding: "5px",
              }}
              src={props.selectedImage}
            />
          </Col>
          <Col span={20}>
            <Row>
              <Col span={20}>
                <Search
                  placeholder="Get Images from Stable Diffusion!"
                  allowClear
                  loading={props.image_generation_api_running}
                  enterButton="Get Images"
                  defaultValue={props.default_value}
                  onSearch={async (e) => {
                    console.log("inside gen on search e", e)
                    console.log("marking status:", props.image_generation_api_running)
                    await props.imageGeneration(e);
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{
                maxHeight: "70vh",
                overflow: "scroll",
              }}
            >
              {props.image_generation_api_response == null ? (
                <Empty
                  style={{
                    paddingTop: "5px",
                    width: "100%",
                  }}
                />
              ) : (
                <Checkbox.Group
                    mode="multiple"
                    onChange={props.imageOnSelect}
                    style={{
                        paddingTop: "5px",
                    }}
                    optionType="button"
                >
                {
                    props.image_generation_api_response.message.images.map((image) => {
                        return (
                            image["urls"].map((image_url) => {
                                return (
                                    <Checkbox
                                        value={image_url}
                                        style={{
                                        height: "150px",
                                        padding: "5px",
                                        marginRight: "5px",
                                        marginLeft: "5px",
                                        marginTop: "5px",
                                        marginBottom: "5px",
                                        }}
                                    >
                                        <img
                                        style={{
                                            height: "150px",
                                        }}
                                        alt=""
                                        src={image_url}
                                        />
                                    </Checkbox>
                                    )
                            })
                        )
                    })
                }
                </Checkbox.Group>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
    </Modal>
  );
};
