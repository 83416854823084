const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;

export async function CreateHeadlinesAPI(payload, token=null){
    let request={
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    if(token)
        request.headers['Authorization'] = `Bearer ${token}`;
        const rawResponse = await fetch(API_BASE_URL + "/api/v1/contentfactory/headlines", request);
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}


export async function CreateDummyArticleAPI(payload, token=null, timeoutMs=30000){
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeoutMs);
    try {
        let request = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
          body: JSON.stringify(payload),
          signal: controller.signal  // Add this line
        };
    
        if (token) {
          request.headers['Authorization'] = `Bearer ${token}`;
        }
    
        const rawResponse = await fetch(API_BASE_URL + "/api/v1/contentfactory/dummyarticle", request);
    
        clearTimeout(timeoutId);  // Clear the timeout if the request completes
    
        if (rawResponse.ok) {
          const content = await rawResponse.json();
          return {
            status: true,
            message: content
          };
        }
    
        // Error handling
        let content = null;
        try {
          content = await rawResponse.json();
        } catch (error) {
          content = await rawResponse.text();
        }
        return {
          status: false,
          message: content
        };
    
      } catch (error) {
        clearTimeout(timeoutId);  // Clear the timeout in case of error
    
        if (error.name === 'AbortError') {
          return {
            status: false,
            message: 'Request timed out'
          };
        }
    
        return {
          status: false,
          message: error.message
        };
      }
    }
    
export async function UpdateContentFactoryAPI(payload, token=null){
    let request={
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    if(token)
        request.headers['Authorization'] = `Bearer ${token}`;
        const rawResponse = await fetch(API_BASE_URL + "/api/v1/contentfactory/update", request);
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function ScheduleArticlesAPI(payload, token=null){
    let request={
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    if(token)
        request.headers['Authorization'] = `Bearer ${token}`;
        const rawResponse = await fetch(API_BASE_URL + "/api/v1/contentfactory/create", request);
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function SearchContentFactoryAPI(payload, token,){
    let request={
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify(payload)
    }

    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/contentfactory/search", request);
    if(rawResponse.ok){
        const content = await rawResponse.json();  
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
      content = await rawResponse.json()
    } catch (error) {
      content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  };