import { FontSizeOutlined, FileImageOutlined, SketchOutlined } from '@ant-design/icons';
import {
    Button, Col, Card, Input, Layout, message, Row, 
    Select
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import {TextCompletionAPI} from "../apis/vcs";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";


const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;

export default class TextCompletion extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,

            
            lang_options: [
                "es",
                "ja",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr"
            ],

            model_options: [
                "gemini",
                "gpt4o",
                "gpt4omini",
                "gpt3516k",
                "gemini",
                "gpt4",
            ],
            model: "gpt4o",
            target_language: "en",
            prompt: "",
            text_complete_api_running: false,
            text_complete_api_response: null,
            compeleted_text:"",
            translated_text:""

           
        };
        this.textCompletion = this.textCompletion.bind(this);
      }

    textCompletion = async () => {
        this.setState({
            text_complete_api_running: true,
            text_complete_api_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                prompt: this.state.prompt,
                target_language: this.state.target_language,
                model: this.state.model,
            }
            try {
                TextCompletionAPI(payload, token).then((response) => {
                    if(response.status==false){
                        message.destroy()
                        message.error("Text Completion API failed!")
                    }
                    else {
                        message.destroy()
                        message.success("Text Completed!")
                        this.setState({
                            compeleted_text: response.message.text
                        })
                        if(this.state.target_language!='en')
                        {
                            this.setState({
                                translated_text: response.message.translated_text
                            })
                        }
                    }
                    this.setState({
                        text_complete_api_running: false,
                        text_complete_api_response: response
                    })
                })
            } catch (error) {
                message.destroy()
                message.error("Failed to complete texts!")
                this.setState({
                    text_complete_api_running: false,
                })
            }
        })
    }



    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleTransitionOverlayModal = () => {
        this.setState({
            transition_overlay_modal_open: !this.state.transition_overlay_modal_open
        })
    }

    toggleVideoEffectModal = () => {
        this.setState({
            video_effect_modal_open: !this.state.video_effect_modal_open
        })
    }

    render() {
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <br/>
            <br/>
            <h3> Ask AI to Complete the text </h3>
            <Row>
            <Col span={22}>
            <TextArea rows={6} style={{ width: 1200 }} placeholder="Enter the text" onChange={(e)=>{
                        this.setState({
                            prompt: e.target.value
                        })
                    }} />
            <br/>
            <br/>
            </Col>
            </Row>
            <Row>
            <Col span={4}>
                <Select
                    defaultValue={this.state.model}
                    placeholder="Models"
                    style={{
                        width: '100%',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}
                    onChange={(e)=>{
                        this.setState({
                            model: e
                        })
                    }}
                    >
                    {this.state.model_options.map((e) => {
                        return <Option 
                        value={e}>{e}</Option>
                    })}
                    </Select>
                </Col>
            <Col span={4}>
                <Select
                    defaultValue="en"
                    placeholder="Languages"
                    style={{
                        width: '100%',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}
                    onChange={(e)=>{
                        this.setState({
                            target_language: e
                        })
                    }}
                    >
                    {this.state.lang_options.map((e) => {
                        return <Option 
                        value={e}>{e}</Option>
                    })}
                    </Select>
                </Col>
                <Col span={4}>
            <Button type="primary" block 
                onClick={this.textCompletion} loading={this.state.text_complete_api_running}
            >
             Complete the text 
            </Button>
            </Col>
            </Row>
            <br/>
            <br/>
            <h3> Output </h3>
            <Row>
            <Col span={0}></Col>
  
            <Col span={22}>
              <TextArea rows={12}
                style={{
                    fontSize: '13px',
                    minHeight: '75px'
                }}
                value={this.state.compeleted_text}
                />

            </Col>
  
            <Col span={4}></Col>
  
            </Row>
            <br/>
            <br/>
    
            <h3> Translated Output </h3>
            <Row>
            <Col span={0}></Col>
            <Col span={22}>
              <TextArea rows={12}
                style={{
                    fontSize: '13px',
                    minHeight: '75px'
                }}
                value={this.state.translated_text}
                />
            </Col>
            </Row>
            <br/>
            <br/>
            </Content>
          </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2021 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
