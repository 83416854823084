import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConf";
import {Button, } from 'antd';
import {WindowsOutlined} from '@ant-design/icons';
import { useEffect, useState } from "react";

const MINUTE_MS = 3000000;

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButtonMSFT = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const [open, setOpen] = useState(false);
  const { instance } = useMsal();
  const [username, setUsername] = useState("Sign In!");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const loggedInAcc = instance.getActiveAccount();
    if (loggedInAcc) {
      setUsername(loggedInAcc.name);
      setEmail(loggedInAcc.idTokenClaims.email);
    }

    const interval = setInterval(() => {
      var sess_store = window.sessionStorage;
      var username = null;
      for (const [key, value] of Object.entries(sess_store)) {
        // console.log(key, value)
        if (key.includes("active-account")) {
          // we skip this token since its not a json
          continue;
        }
        var val_json = JSON.parse(value);

        if ("name" in val_json) {
          username = val_json["username"];
          break;
        }
      }

      var currentAccount = instance.getAccountByUsername(username);
      var accessTokenAcquireRequest = {
        scopes: ["user.read"], // optional Array<string>
        account: currentAccount,
      };
      
      instance
        .acquireTokenSilent(accessTokenAcquireRequest)
        .then((tokenResponse) => {
          props.authRefresh(tokenResponse);
        })
        .catch(async (error) => {
          // message.error("Session Expired! Please login again!");
          setOpen(true);
          props.signInFunc(instance);
          
        })
        .catch((error) => {
          // console.log(error)
          // message.error("Error Signing in! Please reload and sign in again!");
          setOpen(true);
        });
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [instance]);

  return (
    <div sx={{
        backgroundColor: 'black'
    }}>
        
      <Button
        style={{
          width: '100%'
        }}
            type="primary"
            onClick={() => props.signInFunc(instance)}
        >
        <WindowsOutlined style={{ fontSize: '16px', color: 'white' }}/> 
        {"Sign In with Microsoft"}
      </Button>
    </div>
  );
};