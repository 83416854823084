import React from "react";
import { Modal, Layout } from "antd";
const { Content } = Layout;


/**
 * Renders a button which, when selected, will open a popup for login
 */
export const ArticlePreviewModal = (props) => {
    return (
        <Modal
          title="Article Preview"
          centered
          open={props.isOpen}
          onOk={props.onOk}
          onCancel={props.onOk}
          closable={false}
          width={1200}
          footer={[
          ]}
        >
          <Content>
            <center>
            <iframe
              style={{
                  width: '360px',
                  height: '640px'
              }}
              srcdoc={props.article_html}/>
          </center>
          </Content>
        </Modal>
      );
    };
    
