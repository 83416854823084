import { UpCircleOutlined } from '@ant-design/icons';
import {
    Button, Col, Input, Layout, message, Select, Divider, Card, Breadcrumb, List, Menu, Row, Form, Tooltip
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import { withRouter } from 'react-router-dom';
import {GetDocubotProjectsAPI, AddToIndexAPI, CreateDocubotProjectAPI, DocubotChatAPI} from "../apis/docubot";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
import { DocubotProjectFilesModal } from '../components/docubotProjectFiles';


const { Search } = Input;
const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

class Docubot extends React.Component{

    constructor(props) {
        super(props);
        this.lastMessageRef = React.createRef();

        // Don't call this.setState() here!
        this.state = {
            project_id: "create",
            project_name: null,
            project: null,
            get_docubot_projects_api_running: false,
            create_project_api_running: false,
            add_to_index_api_running: false,
            docubot_projects_response: null,
            docubot_project_files_modal_open: false,
            messages: [],
            inputValue: '',
            loading: false,
            loadingText: 'Thinking',
            question: null,
            answer: null,
            history: [],
            new_project_document_urls: [],
            new_project_description: null,
            new_project_name: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
        };

        this.myMessageBubbleStyle = {
            position: 'relative',
            borderRadius: '12px',
            backgroundColor: '#ccd8ff',
            padding: '8px',
            marginBottom: '8px',
            maxWidth: '80%',
        };
            
        this.theirMessageBubbleStyle = {
            position: 'relative',
            borderRadius: '12px',
            backgroundColor: '#d6f5d6',
            padding: '8px',
            marginBottom: '8px',
            maxWidth: '80%',
          };
         
        this.myMessageContentStyle = {
            display: 'inline-block',
            color: 'black',
            whiteSpace: 'pre-wrap',
            textAlign: 'right',
            fontFamily: 'sans-serif', // Add this
          };
          
        this.theirMessageContentStyle = {
            display: 'inline-block',
            color: 'black',
            whiteSpace: 'pre-wrap',
            textAlign: 'left',
            fontFamily: 'sans-serif', // Add this
          };

        this.preElementStyle = {
            margin: 0,
            whiteSpace: 'pre-wrap',
            fontFamily: 'inherit',
            fontSize: 'inherit',
        };
        this.addToIndex = this.addToIndex.bind(this);
        this.toggleDocubotProjectFilesModal = this.toggleDocubotProjectFilesModal.bind(this);
        this.getDocubotProjects = this.getDocubotProjects.bind(this);
        this.updateLoadingText = this.updateLoadingText.bind(this);
        this.createDocubotProject = this.createDocubotProject.bind(this);
    }

    toggleDocubotProjectFilesModal = () => {
        this.setState({
            docubot_project_files_modal_open: !this.state.docubot_project_files_modal_open
        })
    }

    scrollToBottom() {
        if (this.lastMessageRef.current) {
          this.lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    updateLoadingText = async () => {
        const { loadingText } = this.state;
        const nextLoadingText = loadingText.length < 11 ? loadingText + '.' : 'Thinking';
        this.setState({ loadingText: nextLoadingText });
      };
      startLoadingAnimation = async () => {
        this.loadingAnimationInterval = setInterval(this.updateLoadingText, 500);
      };
      
      stopLoadingAnimation = async () => {
        clearInterval(this.loadingAnimationInterval);
      };

      createDocubotProject = async () => {
        this.setState({
            create_project_api_running: true,
        })
        this.props.refreshIdToken().then((token) => {
            try {
                const payload = {
                    name: this.state.new_project_name,
                    description: this.state.new_project_description,
                }
                CreateDocubotProjectAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Project creation failed")
                    } else {
                        message.destroy()
                        message.success("Project created successfully")
                        this.getDocubotProjects(0, 25, null).then(() => {
                            console.log("Projects fetched, ", this.state.docubot_projects_response)
                        })
                        this.setState({
                            create_project_api_running: false,
                            project_id: response.message.id,
                            project_name: response.message.name,
                            project: response.message,
                        })
                        this.props.history.push("/docubot/" + response.message.id)
                    }
                    this.setState({
                        create_project_api_running: false,
                    })
                })
            } catch {
                message.error("Project creation failed")
            }
        })
      };

      addToIndex = async () => {
        this.setState({
            add_to_index_api_running: true,
        })
        message.loading("Ingesting in index... 🕥", 0)
        this.props.refreshIdToken().then((token) => {
            try {
                const payload = {
                    id: this.state.project.id,
                    document_urls: this.state.new_project_document_urls,
                }
                console.log("AddToIndexAPI payload: ", payload)
                AddToIndexAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Ingesting in index failed")
                    } else {
                        message.destroy()
                        message.success("Ingesting in index successfully")
                        this.setState({
                            add_to_index_api_running: false,
                            project: response.message,
                        })
                        this.props.history.push("/docubot/" + response.message.id)
                    }
                    this.setState({
                        add_to_index_api_running: false,
                    })
                })
            } catch {
                message.error("Ingesting in index failed")
            }
        })
        this.setState({
            add_to_index_api_running: false,
        })
      };

    getDocubotProjects = async (skip=0, limit=25, project_id=null) => {
        this.setState({
            get_docubot_projects_api_running: true,
        })

        try {
            this.props.refreshIdToken().then((token) => {
                GetDocubotProjectsAPI(token, skip, limit).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Failed to fetch Docubot Projects!")
                    } else {
                        message.destroy()
                        message.success("Docubot Projects Fetched!")
                    }

                    if (project_id != null) {
                        const listOfProjects = response.message;
                        const project = listOfProjects.find(project => project.id === project_id);
                        if (project) {
                        console.log('Found project:', project);
                        } else {
                        console.log('No project with id', project_id, 'was found.');
                        }
                        this.setState({
                            project_id: project["id"],
                            project_name: project["name"],
                            project: project,
                        })
                    }
        

                    // console.log(response)
                    this.setState({
                        get_docubot_projects_api_running: false,
                        docubot_projects_response: response
                    })
                })
            })

        } catch (error) {
            message.destroy()
            message.error("Failed to fetch Docubot Projects!")
            this.setState({
                get_docubot_projects_api_running: false,
            })
        }

    }

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
      };
    
      handleSendClick = async () => {
        try {
            this.props.refreshIdToken().then((token) => {

                this.setState((prevState) => ({
                    messages: [
                        ...prevState.messages,
                        { text: prevState.inputValue, isMine: true },
                        ],
                    inputValue: '',
                }));
                this.setState({loading: true})
                this.startLoadingAnimation();
                const payload = {
                    index_id: this.state.project_id,
                    question: this.state.inputValue,
                    history: this.state.history,
                    answer: this.state.answer,
                }
                try {
                    DocubotChatAPI(payload, token).then((response) => {
                        // Append the answer to the messages array
                        if (response && response.status) {
                            this.setState((prevState) => ({
                            messages: [
                                ...prevState.messages,
                                { text: response.message.answer, isMine: false },
                            ],
                            }));
                            this.setState({
                                question: this.state.inputValue,
                                answer: response.message.answer,
                                history: response.message.history,
                                loading: false,
                            })
                            this.stopLoadingAnimation();
                        } else {
                            // Handle the case when the response doesn't have the expected format
                            console.error('Invalid response format:', response);
                        }
                        this.setState({loading: false})
                        console.log("loading via .. loading ", this.state.loading)
                        this.stopLoadingAnimation();
                    })
                } catch (error) {
            // Handle the error while making the API call
            console.error('Error while sending the message:', error);
            this.setState({loading: false})
            console.log("loading via .. loading ", this.state.loading)
            this.stopLoadingAnimation();
                }
            })
        }
        catch (error) {
            // Handle the error while making the API call
            console.error('Error while sending the message:', error);
          }
          this.scrollToBottom();
        }; 

      async componentDidMount() {
        const { projectId } = this.props.match.params;
        console.log('Project ID:', projectId);
        this.getDocubotProjects(0, 25, projectId).then(() => {
            console.log("this.state.docubot_projects_response", this.state.docubot_projects_response)
        })
      }

      componentDidUpdate(prevProps, prevState) {
        if (prevState.messages.length !== this.state.messages.length) {
          this.scrollToBottom();
        }
      }

    
    render() {
        const { messages, inputValue } = this.state;
        const createForm = (

            <div>
                <Layout style={{
                    minHeight: '90vh',
                }}>

            <DocubotProjectFilesModal
                isOpen={this.state.docubot_project_files_modal_open}
                onOk={this.toggleDocubotProjectFilesModal}
                signin_name={this.props.username}
                upload_loading={this.state.image_upload_loading}
                upload_endpoint={this.state.upload_endpoint}
                id_token={this.props.idToken}
                project={this.state.project}
                addToIndex={this.addToIndex}
                new_project_document_urls={this.state.new_project_document_urls}
                add_to_index_api_running={this.state.add_to_index_api_running}
                onImUploadChange={(info)=>{
                    const { fileList } = info;
                    console.log("fileList", fileList)
                    // Filter the successfully uploaded files
                    const doneFiles = fileList.filter(file => file.status === 'done');

                    // Extract the URLs from the successfully uploaded files
                    const fileUrls = doneFiles.map(file => {
                      // Assuming your server responds with the file URL in the 'url' key
                      return file.response.image_url;
                    });
                    this.setState({
                        new_project_document_urls: fileUrls,
                    },  ()=> {
                        console.log("new_project_document_urls", this.state.new_project_document_urls, fileUrls)
                    })
                    message.success('Files Uploaded!')
                    }
                }
            />

                    {
                        this.state.docubot_projects_response == null
                        ? (
                            <h3>You should login perhaps</h3>
                        )
                        : 
                        <>
                            <Sider
                                width={400}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                }}
                                >
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    selectedKeys={[this.state.project_id.toString()]}
                                    style={{
                                        height: '100%',
                                        borderRight: 0,
                                        borderTop: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Menu.Item key="create"
                                    title="Create"
                                        onClick={(e)=> {
                                        this.setState({
                                            project_id: "create",
                                            project_name: "Create",
                                            new_project_document_urls: [],
                                            project: null,
                                            history: [],
                                            question: null,
                                            answer: null,
                                            messages: [],
                                            loading: false,
                                            loadingText: 'Thinking',
                                        })
                                    }}>
                                        <b>➕ CREATE NEW</b>
                                    </Menu.Item>

                                    <Divider orientation="left"
                                        orientationMargin={0}
                                        style={{
                                            margin: '5px'
                                        }}
                                    />

                                    {
                                        (this.state.docubot_projects_response.message)
                                        ? (
                                            this.state.docubot_projects_response.message.map((project) => {
                                                return (
                                                    <>
                                                        <Menu.Item key={project.id}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start',
                                                            width: '100%',
                                                            height: 'auto',
                                                        }}
                                                        onClick={(e)=> {
                                                            this.setState({
                                                                project_id: project.id,
                                                                project_name: project.name,
                                                                new_project_document_urls: [],
                                                                project: project,
                                                                history: [],
                                                                question: null,
                                                                answer: null,
                                                                messages: [],
                                                                loading: false,
                                                                loadingText: 'Thinking',
                                                            })
                                                            this.props.history.push(`/docubot/${project.id}`);
                                                        }}>
                                                                <h3>{project.name}</h3>
                                                                <h5>{project.description}</h5>
                                                        </Menu.Item>
                                                        <Divider orientation="left"
                                                            orientationMargin={0}
                                                            style={{
                                                                margin: '1px'
                                                            }}
                                                        />
                                                    </>
                                                )
                                            })
                                        )
                                        : null
                                    }
                                </Menu>
                            </Sider>
                            {
                                    (this.state.project_id == "create")
                                    ? (
                                        <>
                                        <div
                                            style={{
                                                padding: '0 24px 24px',
                                                paddingTop: 5,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                                }}
                                        >

                            <Form name="dynamic_form_nest_item" onFinish={this.createDocubotProject} autoComplete="off"
                                style={{
                                    width: '80%'
                                }}
                            >
                                <Form.Item
                                    label="Name of project"
                                    name="name1"
                                >
                                <Tooltip trigger={['focus']} title={"What will be the name for your project?"} placement="topLeft">
                                    <Input placeholder='Add name for your project' onChange={(e)=>{
                                            this.setState({
                                                new_project_name: e.target.value
                                            })
                                        }}/>
                                </Tooltip>
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                >
                                <Tooltip trigger={['focus']} title={"Add description"} placement="topLeft">
                                    <Input placeholder='Add description for your project' onChange={(e)=>{
                                            this.setState({
                                                new_project_description: e.target.value
                                            })
                                        }}/>
                                </Tooltip>
                                </Form.Item>
                                <Form.Item>
                                    <br></br>
                                    <center>
                                    <Button type="primary"
                                        onClick={() => {
                                            console.log("Creating project")
                                        }}
                                        loading={this.state.create_project_api_running}
                                        htmlType="submit">
                                        Create Project!
                                    </Button>
                                    </center>
                                </Form.Item>
                            </Form>



                                        </div>
                                        </>
                                    )
                                    :
                                    <>
                            <Layout
                                style={{
                                    padding: '0 24px 24px',
                                    paddingTop: 5,
                                }}
                            >
                                <Row>
                                <Breadcrumb
                                    style={{
                                    margin: '16px 0'
                                    }}
                                >
                                    <Breadcrumb.Item>{this.state.project_name}</Breadcrumb.Item>
                                </Breadcrumb>
                                </Row>
                                <Row>
                                    <Button
                                        onClick={e => {
                                            this.toggleDocubotProjectFilesModal()
                                        }}
                                        >
                                            Manage Files
                                    </Button>
                                </Row>
                                <Content
                                    style={{
                                        height: '70vh', // 50% of the viewport height
                                        overflowY: 'auto', // Enable vertical scrolling when the content overflows
                                    }}
                                >
                                    <List
                                        dataSource={messages}
                                        renderItem={(message) => 
                                            <List.Item>
                                                <div
                                                    style={{
                                                    display: 'flex',
                                                    justifyContent: message.isMine ? 'flex-end' : 'flex-start',
                                                    width: '100%',
                                                    padding: '4px 0',
                                                    }}
                                                >
                                                <div 
                                                    style={
                                                        message.isMine ? this.myMessageBubbleStyle : this.theirMessageBubbleStyle
                                                    }
                                                >
                                                <pre
                                                style={
                                                    message.isMine
                                                    ? { ...this.myMessageContentStyle, ...this.preElementStyle }
                                                    : { ...this.theirMessageContentStyle, ...this.preElementStyle }
                                                }
                                                >{message.text}</pre>
                                                </div>
                                                </div>                                                
                                            </List.Item>
                                            }
                                    />
                                    <List.Item ref={this.lastMessageRef}>
                                        {(this.state.loading)?(
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: '4px 0' }}>
                                            <div style={this.theirMessageBubbleStyle}>
                                            <span style={this.theirMessageContentStyle}><i>{this.state.loadingText}</i></span>
                                            </div>
                                        </div>
                                        ):null}
                                    </List.Item>
                                </Content>
                                <Footer>
                                    <Row>
                                    <Col span={22}>
                                    <Input
                                        value={inputValue}
                                        onChange={this.handleInputChange}
                                        onPressEnter={
                                            !this.state.loading ? this.handleSendClick : undefined
                                          }
                                    />
                                    </Col>
                                    <Col span={2}>
                                    <Button type="primary" disabled={this.state.loading} onClick={this.handleSendClick}>
                                        Send
                                    </Button>
                                    </Col>
                                    </Row>
                                </Footer>

                            </Layout>
                            </>}
                        </>
                    }
                </Layout>
            </div>
        )

        
        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 0px', marginTop: 50 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth/>
                </UnauthenticatedTemplate>
            </>
        )
    }
}

export default withRouter(Docubot);