const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;

export async function LogoPlacerAPI(payload,token){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL+"/api/v1/helpers/logo/placer",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}
