import {
    Button, Col, Pagination, Input, Layout, message, Row, Tooltip, Card, Select, Checkbox, Form, Empty, Breadcrumb, Menu, Collapse, Tag, Typography, Popconfirm
} from 'antd';
import React from 'react';
import { CheckCircleTwoTone, DeleteFilled, EditTwoTone } from '@ant-design/icons';
import NavBar from '../components/header';
import { GenerativeImageModal } from '../components/generativeImage';
import {IMSImageSearchModal} from "../components/imsImageSearchModal";
import {ArticlePreviewModal} from "../components/articlePreviewModal";
import { AIPublisherIdDropDown } from '../components/aipublisherIdDropdown';
import { CategoryDropDown } from "../components/categoryDropDown"
import { ArticleHTML} from "../apis/articlePreview";
import { IMSV2ImageSelectionAPI, ImageGenerationAPI, ImageSelectionAPI} from "../apis/vcs";
import {GetArticlePublishers, CreateArticleAPI, GetRSS, SearchArticleAPI, UpdateArticleAPI, MultigenerateArticleAPI, MultipublishArticlesAPI, MultiupdateArticlesAPI, TranslateArticleAPI, DuplicateArticleAPI } from "../apis/publisher"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
import Cropper from 'react-easy-crop';
import {getCroppedImg} from '../apis/cropImage';
const { Title, Text } = Typography;
const { Search, TextArea } = Input;
const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;
const {SubMenu} = Menu;
const { Meta } = Card;
const { Panel } = Collapse;


export default class AIPublisher extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,

            crop: { x: 0, y: 28.24 },
            zoom: 1,
            aspect: 7 / 4,
            croppedImageUrl: null,
            croppedAreaPixels: null,
            embed: null,
            // api call button
            api_called: false,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            forceUpdate: false, // Add this line
            temp_contents: [],
            current_content_index: 0,
            current_article_index: 0,

            image_searched_on_index: null,
            image_id_to_source_map: {},
            image_generation_api_running: false,
            translate_article_api_running: false,
            duplicate_article_api_running: false,
            image_generation_api_response: null,
            text_generation_api_running: false,
            text_generation_api_response: null,
            article_preview_modal_open: false,
            generative_image_modal_open: false,
            generative_text_modal_open: false,
            strict_image_search: true,
            generate_catchy_headline: false,
            generate_sub_headings: false,
            generate_kickr: false,
            image_search_modal_open: false,
            temp_article_html: null,
            temp_articles: null,
            temp_publisher: null,
            country: null,
            category: "sports",
            filterStatus: 'all', 
            publisher_id: null,
            translate_to_publisher_ids: [],
            image_ids: [],
            selected_image_id: null,
            banner_image_url: null,
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",
            search_text: null,
            article_index: 0,
            countries: ["in", "id", "us", "br", "co", "mx", "vn", "th", "jp", "ph", "my"],
            categories: ["travel", "fiction", "lifestyle"],
            country_to_display_name_map: {
                "id": "🇮🇩 Indonesia",
                "in": "🇮🇳 India",
                "mx": "🇲🇽 Mexico",
                "co": "🇨🇴 Colombia",
                "us": "🇺🇸 US",
                "br": "🇧🇷 Brazil",
                "th": "🇹🇭 Thailand",
                "vn": "🇻🇳 Vietnam",
                "jp": "🇯🇵 Japan",
                "ph": "🇵🇭 Philippines",
                "my": "🇲🇾 Malaysia",
            },

            // https://developers.google.com/admin-sdk/directory/v1/languages
            country_to_publisher_id_map: {
                "in": ["en_newsflash", "en_misc", "en_now", "en_1wpulse", "en_specials", "bn_misc", "hi_misc", "ta_misc", "te_misc", "mr_misc", "kn_misc", "en_pti", "en_wiki"],
                "id": ["id_ba_goodtimes", "id_ba_misc", "id_en_misc", "ba_travel"],
                "mx": ["mx_es_misc", "mx_en_misc"],
                "co": ["co_es_misc", "co_en_misc"],
                "br": ["br_pt_misc", "br_en_misc", "br_pt_wiki"],
                "us": ["us_en_misc", "us_es_misc", "us_en_wiki", "us_en_trial"],
                "th": ["th_th_misc", "th_en_misc"],
                "vn": ["vn_vi_misc", "vn_en_misc"],
                "jp": ["jp_ja_misc", "jp_ja_wod", "jp_en_misc"],
                "ph": ["ph_fil_misc", "ph_en_misc"],
                "my": ["my_ms_misc", "my_en_misc"],
            },

            elaborating_method: "untouch",
            theme: "",
            title: null,
            prompt: null,
            context: null,
            facts: null,
            facts_text: null,
            image_search_mode: "header",
            generate_images: false,
            generate_images_from_cardpress: true,
            lang_options: [
                "en",
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "ba",
                "mr",
                "fil",
                "ja",
                "th",
                "vi",
                "ms",
            ],
            language_to_display_name_map: {
                "en": "English",
                "es": "Spanish",
                "pt": "Portuguese",
                "bn": "Bengali",
                "kn": "Kannada",
                "ta": "Tamil",
                "te": "Telugu",
                "hi": "Hindi",
                "ba": "Bahasa",
                "mr": "Marathi",
                "fil": "Filipino",
                "ja": "Japanese",
                "th": "Thai",
                "vi": "Vietnamese",
                "ms": "Malay",
            },
            content_type: null,
            publisher_ids: ["en_misc"],
            available_publishers: [],
            article_categories: [
                'daily_digest',
                'cricket',
                'sports',
                'entertainment',
                'technology',
                'travel_and_lifestyle',
                'automotive',
                'food_and_health',
                'national_v_two',
                'international',
                'business',
                'fashion',
                'music',
                'fun_facts',
                'games',
                'nature',
                'talent',
                'comedy',
                'tamil_news',
                'telugu_news',
                'kannada_news',
                'marathi_news',
                'bengali_news',
                'home_n_living',
                'celebrity',
                'education',
                'live_game_streams',
                'religion',
                'content_for_apps',
                'ipl',
            ],

            // filter criteria
            search_query: null,
            search_category_dropdown: "all",
            category_dropdown_options: [
                "all",
                "daily_digest",
                "cricket",
                "sports",
                "entertainment",
                "technology",
                "travel_and_lifestyle",
                "automotive",
                "food_and_health",
                "national_v_two",
                "international",
                "business",
                "fashion",
                "fun_facts",
                "games",
                "nature",
            ],
            search_content_type: "all",
            content_type_options: [
                "all",
                "timeless",
                "timebound",
            ],
            search_flow: "all",
            flow_options: [
                "all",
                "lex",
            ],



            page_size: 30,
            page_number: 1,
        };
        for (const publishers of Object.values(this.state.country_to_publisher_id_map)) {
            for (const publisher of publishers) {
                this.state.available_publishers.push(publisher);
            }
        }

        this.toggleArticlePreviewModal = this.toggleArticlePreviewModal.bind(this);
        this.toggleGenerativeImageModal = this.toggleGenerativeImageModal.bind(this);
        this.imageGeneration = this.imageGeneration.bind(this);
        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.imageSelection = this.imageSelection.bind(this);
        this.getPublisherArticles = this.getPublisherArticles.bind(this);
        this.searchPublisherArticles = this.searchPublisherArticles.bind(this);
        this.updateArticle = this.updateArticle.bind(this);
        this.createArticle = this.createArticle.bind(this);
        this.createEmbedArticle = this.createEmbedArticle.bind(this);
        this.publishArticle = this.publishArticle.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.formatPublisherId = this.formatPublisherId.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.translateArticle = this.translateArticle.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
      }

      onCropChange(crop) {
        this.setState({ crop });
      }

      onZoomChange(zoom) {
        this.setState({ zoom });
      }

      onCropComplete(croppedArea, croppedAreaPixels) {
        getCroppedImg(this.state.banner_image_url, croppedAreaPixels).then((croppedImage) => {
            console.log("Cropped area", croppedArea)
            console.log("Cropped image", croppedImage)
            console.log("Cropped area pixels", croppedAreaPixels)
            this.setState({
                croppedAreaPixels: croppedAreaPixels,
            })
        });
      }

    handleFilterChange = (value) => {
        this.setState({ filterStatus: value });
    };

    
    formatPublisherId = (publisherId) => {
        const components = publisherId.split("_");
        let countryCode, languageCode, languageName, countryName, misc;
        if (components.length === 2) {
          [languageCode, misc] = components;
          languageName = this.state.language_to_display_name_map[languageCode] || languageCode;
          return `${languageName} ${misc}`;
        } else if (components.length === 3) {
          [countryCode, languageCode, misc] = components;
          languageName = this.state.language_to_display_name_map[languageCode] || languageCode;
          countryName = this.state.country_to_display_name_map[countryCode] || countryCode;
          return `${countryName}-${languageName} ${misc}`;
        } else {
          return publisherId; // Fallback to displaying the raw publisher ID if format is not recognized
        }
      };

    formatTimestamp = (timestamp) => {
        // Assuming that article.created_at is the timestamp string
        let date = new Date(timestamp);
        let formattedDate = `${date.getFullYear()}-${("0" + (date.getMonth()+1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
        return formattedDate;
    }

    toggleArticlePreviewModal = () => {
        this.setState({
            article_preview_modal_open: !this.state.article_preview_modal_open
        })
    }

    toggleGenerativeImageModal = () => {
        this.setState({
            generative_image_modal_open: !this.state.generative_image_modal_open
        })
    }

    imageSelection = async (search_string) => {
        // TODO: Add image-selection API call
        // this.setState({
        //     clip_generate_api_running: true
        // })
        if(search_string == ""){
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": search_string,
                    "description": "",
                    "max_count": 30,
                    "duplicate_removal": true,
                    "blacklisted_removal": true,
                }
            }
            IMSV2ImageSelectionAPI(payload, token).then((response) => {
                if(response.status==false){
                    message.destroy()
                    message.error("Image Selection API failed!")
                }else{
                    message.destroy()
                    message.success("Fetched Some Fresh Images!")
                }
                if(response.status == true){
                    console.log("Image Selection API Response: ", response.message)
                    const image_ids = response.message.images.map(item => item.id);
                    const image_id_to_source_map = response.message.images.reduce((acc, item) => {
                        acc[item.id] = item.source_name;
                        return acc;
                    }, {});
                    this.setState({
                        image_ids: image_ids,
                        selected_image_id: image_ids[0],
                        image_id_to_source_map: image_id_to_source_map,
                    })
                    console.log("Image Ids: ", this.state.image_ids)
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch images!")
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to fetch images!")
        })
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    handlePanelChange = (activeKey) => {
        console.log("Active key: ", activeKey)
        this.setState({
            banner_image_url: null,
            croppedAreaPixels: null,
        })
      };

    imageGeneration = async (prompt) => {
        this.setState({
            image_generation_api_running: true,
            image_generation_api_response: null,
        })
        this.props.refreshIdToken().then((token) => {
            message.loading("Generating Images...", this.state.image_generation_api_running)
            const payload = {
                prompt: prompt,
                mode: "Square"
            }
            var status_code = 200;
            ImageGenerationAPI(payload, token).then((response) => {
                status_code = response.status_code
                if(response.status==false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab 😅")
                }else{
                    message.destroy()
                    message.success("Image Generation Completed!")
                }
                this.setState({
                    image_generation_api_running: false,
                    image_generation_api_response: response,
                    improved_prompt: response.message.spec.improved_prompt
                })
                console.log("state setting complete")
            }).catch((error) => {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to generate images! Most likely due to high load. Try again 😅")
                }
                this.setState({
                    image_generation_api_running: false,
                })
            })
        }).catch((error) => {
            console.log(error)
            message.error("Failed to generate images! Most likely due to high load. Try again 😅")
            this.setState({
                image_generation_api_running: false,
            })
        })
    }

    getPublisherArticles = async (publisher_id, skip = 0, limit=30) => {
        this.props.refreshIdToken().then((token) => {
            message.loading("Fetching articles...", publisher_id)
            var status_code = 200;
            GetArticlePublishers(publisher_id, token, skip, limit).then((response) => {
                status_code = response.status_code
                if (status_code == 500){
                    message.error("Failed to fetch articles! Most likely due to high load. Try again")
                }
                else if (status_code == 401){
                    message.error("You are not logged in. We'll fix this, just refresh the page or open in new tab 😅")
                }
                else{
                    message.destroy()
                    message.success("Fetched Articles!")

                    console.log("temp_articles: ", response)
                    this.setState({
                        temp_articles: response.message.articles,
                        temp_publisher: response.message.publisher
                    })
                    console.log("temp contents are now -> ", this.state.temp_contents)
                }
            }).catch((error) => {
                if (status_code == 500){
                    message.error("Failed to fetch articles! Most likely due to high load. Try again")
                }
                else if (status_code == 401){
                    message.error("You are not logged in. We'll fix this, just refresh the page or open in new tab 😅")
                }
                else {
                    message.error("Failed to get articles!")
                }
            })
            console.log("Articles Fetched!", publisher_id)
        })
    }

    searchPublisherArticles = async (search_query, skip = 0, limit=100) => {
        this.props.refreshIdToken().then((token) => {
            message.loading("Fetching articles...", 0)
            var status_code = 200;
            this.setState({ search_query: search_query });
            const payload = {
                query: search_query,
                publisher_id: this.state.publisher_id,
                content_type: this.state.search_content_type,
                flow: this.state.search_flow,
                category: this.state.search_category_dropdown,
                skip: skip,
                limit: limit
            }
            SearchArticleAPI(payload, token).then((response) => {
                status_code = response.status_code
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to fetch articles! Most likely due to high load. Try again")
                }
                else if (status_code == 401){
                    message.error("You are not logged in. We'll fix this, just refresh the page or open in new tab 😅")
                }
                else{
                    message.success("Fetched Articles!")

                    console.log("temp_articles: ", response)
                    this.setState({
                        temp_articles: response.message,
                    })
                    console.log("temp contents are now -> ", this.state.temp_contents)
                }
            }).catch((error) => {
                if (status_code == 500){
                    message.error("Failed to fetch articles! Most likely due to high load. Try again")
                }
                else if (status_code == 401){
                    message.error("You are not logged in. We'll fix this, just refresh the page or open in new tab 😅")
                }
                else {
                    message.error("Failed to get articles!")
                }
            })
            console.log("Articles Fetched!", this.state.publisher_id)
        })
    }

    updateArticle = async (article, article_index, mode="single") => {
        this.setState({
            update_article_api_called: true,
        })
        this.props.refreshIdToken().then((token) => {
            message.loading("Saving Article...", this.state.update_article_api_called)
            if ((article.banner_image_url == null) || (article.banner_image_url == "") || (article.banner_image_url == undefined)){
                // if article does not have banner image
                console.log("article did not have banner image ..")
                if (this.state.banner_image_url == null){
                    // if no banner image is selected
                    this.setState({
                        update_article_api_called: false
                    })
                    message.destroy()
                    message.error("Please select a banner image before saving!")
                    return
                }
                else {
                    // if banner image is selected
                    if (this.state.image_searched_on_index == article_index) {
                        // if banner image is selected and for this article
                        console.log("banner_image_url added: ", article.banner_image_url, this.state.banner_image_url)
                        article.banner_image_url = this.state.banner_image_url
                    }
                    else {
                        // banner image selected but not for this
                        console.log("banner_image_url not added")
                        message.error("Please select a banner image before saving!")
                        this.setState({
                            update_article_api_called: false
                        })
                        return
                    }
                }
            }
            else {
                // article has banner image
                if ((this.state.image_searched_on_index == article_index) && (article.banner_image_url!=this.state.banner_image_url)){
                    // if banner image is selected and for this article
                    console.log("banner_image_url changed: ", article.banner_image_url, this.state.banner_image_url)
                    article.banner_image_url = this.state.banner_image_url
                }
                else {
                    // nothing chnages
                    console.log("banner_image_url did not change")
                }
            }
            console.log("article_index: ", article_index, "this.state.image_searched_on_index: ", this.state.image_searched_on_index)
                const apiFunction = mode === 'single' ? UpdateArticleAPI : MultiupdateArticlesAPI;
                if (!article.spec) {
                    article.spec = {};
                }
                console.log("article: ", article)
                article["spec"]["banner_image_cropped_area_pixels"] = this.state.croppedAreaPixels;
                apiFunction(article, token).then((response) => {
                    if(response.status==false){
                        message.destroy()
                        message.error("Couldn't save!")
                    }else{
                        message.destroy()
                        message.success("Saved!")
                    }
                    console.log(response)
                    const temp_articles = [...this.state.temp_articles];
                    temp_articles[article_index] = response.message;
                    this.setState({
                        temp_articles: temp_articles,
                        update_article_api_called: false
                    })
                }).catch((error) => {
                    message.destroy()
                        message.error("Failed to update")
                        this.setState({
                            update_article_api_called: false
                        })
                    })
        }).catch((error) => {
            message.destroy()
                message.error("Failed to update")
                this.setState({
                    update_article_api_called: false
                })
            })
    }

    createEmbedArticle = async() => {
        this.setState({
            generate_article_api_called: true,
        })
        const key = 'updatable';
        this.props.refreshIdToken().then((token) => {
            // message.loading("Generating Article...", 0)
            message.loading({ content: "Generating Article...", key, duration: 0 });
            let modifiedHtml = this.state.embed.replace(/\n/g, ' '); // replace new lines with space
            modifiedHtml = '<center>' + modifiedHtml + '</center>'; // add <center> tags
            const contents = {
                legos: [
                    {
                        text: "",
                        elaborate_text: "",
                        html: modifiedHtml,
                        images: [],
                    }
                ]
            }
            const payload = {
                publisher_id : this.state.publisher_ids[0],
                title: this.state.title,
                author: "embed.admin",
                text: modifiedHtml,
                categories: [this.state.category],
                banner_image_url: this.state.banner_image_url,
                banner_image_cropped_area_pixels: this.state.croppedAreaPixels,
                contents: contents,
                spec: {
                    is_embed: true,
                    manual: true,
                },
                prompt_spec: {
                    prompt: this.state.prompt,
                },
                flow: "manual",
                content_type: this.state.content_type,
            }
            console.log("payload: ", payload)
            CreateArticleAPI(payload, token).then((response) => {
                if(response.status==false){
                    // message.destroy()
                    message.error("Couldn't generate!")
                }else{
                   // message.destroy()
                   // message.success("Generated!")
                    message.success({ content: "Generated!", key });
                    console.log("temp_articles: ", response)
                    this.setState({
                        temp_articles: [response.message],
                    })
                }
                this.setState({
                    generate_article_api_called: false
                })

            }).catch((error) => {
               // message.destroy()
                 message.error({ content: "Failed to generate", key });
                this.setState({
                    generate_article_api_called: false
                })
            })
        }).catch((error) => {
            // message.destroy()
            message.error({ content: "Failed to generate", key });
            this.setState({
                generate_article_api_called: false
            })
        })
    }

    createArticle = async () => {
        this.setState({
            generate_article_api_called: true,
        })
        const key = 'updatable';
        this.props.refreshIdToken().then((token) => {
            message.loading({ content: "Generating Article...", key, duration: 0 });
            //message.loading("Generating Article...", 0)
            const payload = {
                title: this.state.title,
                prompt: this.state.prompt,
                context: this.state.context,
                facts: this.state.facts,
                facts_text: this.state.facts_text,
                country: "in",
                spec: {},
                categories: [this.state.category],
                generate_images: this.state.generate_images,
                generate_images_from_cardpress: this.state.generate_images_from_cardpress,
                banner_image_url: this.state.banner_image_url,
                banner_image_cropped_area_pixels: this.state.croppedAreaPixels,
                flow: "manual",
                content_type: this.state.content_type,
                generate_catchy_headline: this.state.generate_catchy_headline,
                generate_kickr: this.state.generate_kickr,
                generate_sub_headings: this.state.generate_sub_headings,
            }
            if (this.state.publisher_ids != null){
                payload["publisher_ids"] = this.state.publisher_ids
            }
            if (this.state.strict_image_search){
                payload["ims_score_threshold"] = 0.33
            }
            console.log("payload: ", payload)
            MultigenerateArticleAPI(payload, token).then((response) => {
                if(response.status==false){
                    //message.destroy()
                    message.error("Couldn't generate!")
                }else{
                    //message.destroy()
                    message.success({ content: "Generated!", key });
                    //message.success("Generated!")
                    console.log("temp_articles: ", response)
                    this.setState({
                        temp_articles: response.message,
                    })
                }
                // console.log(response)
                this.setState({
                    generate_article_api_called: false
                })
            }).catch((error) => {
                // message.destroy()
                message.error({ content: "Failed to generate", key });
                this.setState({
                    generate_article_api_called: false
                })
            })
        })
        .catch((error) => {
           //  message.destroy()
            message.error({ content: "Failed to generate", key });
                this.setState({
                    generate_article_api_called: false
                })
            })
    }

    publishArticle = async (article) => {
        this.setState({
            update_article_api_called: true,
        })
        message.loading("Publishing Article...", 0)
        this.props.refreshIdToken().then((token) => {
            UpdateArticleAPI(article, token).then((response) => {
                if(response.status==false){
                    message.destroy()
                    message.error("Couldn't publish!")
                }else{
                    message.destroy()
                    message.success("Published!")
                }
                // console.log(response)
                this.setState({
                    update_article_api_called: false
                })
            }).catch((error) => {
            message.destroy()
                message.error("Failed to publish")
                this.setState({
                    update_article_api_called: false
                })
            })    
        }).catch((error) => {
            message.destroy()
                message.error("Failed to publish")
                this.setState({
                    update_article_api_called: false
                })
            })
    }

    multiPublishArticles = async (article) => {
        this.setState({
            update_article_api_called: true,
        })
        message.loading("Publishing Articles...", 0)
        this.props.refreshIdToken().then((token) => {
        MultipublishArticlesAPI(article, token).then((response) => {
            if(response.status==false){
                message.destroy()
                message.error("Couldn't publish!")
            }else{
                message.destroy()
                message.success("Published all!")
            }
            this.setState({
                update_article_api_called: false
            })
        }).catch((error) => {
            message.destroy()
                message.error("Failed to publish")
                this.setState({
                    update_article_api_called: false
                })
            })
        }).catch((error) => {
            message.destroy()
                message.error("Failed to publish")
                this.setState({
                    update_article_api_called: false
                })
            })
    }

    translateArticle = async (article, article_index) => {
        this.setState({
            translate_article_api_running: true,
        })
        message.loading("Translating Article...", 0)
        this.props.refreshIdToken().then((token) => {
            const payload = {
                article: article,
                publisher_ids: this.state.translate_to_publisher_ids,
            }
            TranslateArticleAPI(payload, token).then((response) => {
                if(response.status==false){
                    message.destroy()
                    message.error("Couldn't translate!")
                }else{
                    message.destroy()
                    message.success("Translated!")
                    console.log("temp_articles: ", response)
                }
                this.setState({
                    translate_article_api_running: false
                })
            }).catch((error) => {
                message.destroy()
                message.error("Failed to translate")
                this.setState({
                    translate_article_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to translate")
            this.setState({
                translate_article_api_running: false
            })
        })
    }

    duplicateArticle = async (article, article_index) => {
        this.setState({
            duplicate_article_api_running: true,
        })
        message.loading("Cloning Article...", 0)
        this.props.refreshIdToken().then((token) => {
            DuplicateArticleAPI(article, token).then((response) => {
                if(response.status==false){
                    message.destroy()
                    message.error("Couldn't duplicate!")
                }else{
                    message.destroy()
                    message.success("Duplicated!")
                    console.log("temp_articles: ", response)
                }
                this.setState({
                    duplicate_article_api_running: false
                })
            }).catch((error) => {
                message.destroy()
                message.error("Failed to duplicate")
                this.setState({
                    duplicate_article_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to duplicate")
            this.setState({
                duplicate_article_api_running: false
            })
        })
    }

    onFinish = async values => {
        // Handle the form submission here
        // Construct response
        this.setState({
            api_called: true,
            current_project_status: null,
            current_project_id: null
        })
        console.log(values)
    };
    
    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {

        return (
            <>
                <AuthenticatedTemplate>
            <Layout style={{'min-height':'100vh'}}>
                <GenerativeImageModal
                    isOpen={this.state.generative_image_modal_open}
                    onOk={(a)=>{
                        this.toggleGenerativeImageModal()
                        console.log("temp_contents", this.state.temp_contents)
                    }}
                    imageGeneration={this.imageGeneration}
                    image_generation_api_running={this.state.image_generation_api_running}
                    image_generation_api_response={this.state.image_generation_api_response}
                    imageOnSelect={(checkedValues) => {
                        console.log('checked = ', checkedValues);
                        const urlCaptionList = checkedValues.map(url => {
                          return {
                            url: url,
                            caption: "Lex AI"
                          };
                        });

                        console.log("Working on article: ", this.state.current_article_index, "with content index: ", this.state.current_content_index)
                        var temp_articles = this.state.temp_articles;
                        if (temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] == null){
                            temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] = []
                        }
                        // TODO: This is a hack, fix it
                        const new_images_state = new Set(temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"].concat(urlCaptionList))
                        var new_images = Array.from(new_images_state)
                        temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] = new_images
                        this.setState({
                            temp_articles: temp_articles
                        })
                    }}
                    signin_name={this.props.username}
                    id_token={this.props.idToken}
                />
                <ArticlePreviewModal
                    isOpen={this.state.article_preview_modal_open}
                    onOk={this.toggleArticlePreviewModal}
                    article_html={this.state.temp_article_html}
                />

            <IMSImageSearchModal
                isOpen={this.state.image_search_modal_open}
                onOk={this.toggleImageSearchModal}
                imageSelection={this.imageSelection}
                default_value={this.state.search_text}
                image_list={this.state.image_ids}
                image_cdn_prefix={this.state.image_cdn_prefix}
                imageOnSelect={(e)=>{
                    if (this.state.image_search_mode === "header") {
                        var current_article_index = this.state.current_article_index;
                        console.log("Header Mode, ", current_article_index)
                        this.setState({
                            image_searched_on_index: current_article_index,
                            selected_image_id: e.target.value,
                            banner_image_url: this.state.image_cdn_prefix + e.target.value + ".jpg",
                        }, () => {console.log("image_searched_on_index: ", this.state.image_searched_on_index)}
                        )
                    }
                    else {
                        var image_packet_checked = {
                            url: this.state.image_cdn_prefix + e.target.value + ".jpg",
                            caption: this.state.image_id_to_source_map[e.target.value]?? ""
                        }
                        var checkedValues = [image_packet_checked];
                        console.log('checked = ', checkedValues);
                        console.log("Working on article: ", this.state.current_article_index, "with content index: ", this.state.current_content_index)
                        var temp_articles = this.state.temp_articles;
                        if (temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] == null){
                            temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] = []
                        }
                        // TODO: This is a hack, fix it
                        const new_images_state = new Set(temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"].concat(checkedValues))
                        var new_images = Array.from(new_images_state)
                        temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] = new_images
                        this.setState({
                            temp_articles: temp_articles,
                            image_search_modal_open: false
                        })
                    }
                }}
                selectedImage={this.state.banner_image_url}
                signin_name={this.props.username}
                upload_loading={this.state.image_upload_loading}
                upload_endpoint={this.state.upload_endpoint}
                id_token={this.props.idToken}
                onImUploadChange={(e)=>{
                    if(e.file.status == 'done'){
                        message.success('Image Uploaded!')
                        if (this.state.image_search_mode === "header") {
                            this.setState({
                                banner_image_url: e.file.response.image_url,
                            })
                        }
                        else {
                            var image_packet_checked = {
                                url: e.file.response.image_url,
                                caption: ""
                            }
                            const checkedValues = [image_packet_checked]
                            console.log('checked = ', checkedValues);
                            console.log("Working on article: ", this.state.current_article_index, "with content index: ", this.state.current_content_index)
                            var temp_articles = this.state.temp_articles;
                            if (temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] == null){
                                temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] = []
                            }
                            // TODO: This is a hack, fix it
                            const new_images_state = new Set(temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"].concat(checkedValues))
                            var new_images = Array.from(new_images_state)
                            console.log("new_images", new_images)
                            temp_articles[this.state.current_article_index].contents["legos"][this.state.current_content_index]["images"] = new_images
                            this.setState({
                                temp_articles: temp_articles,
                                image_search_modal_open: false
                            })
                        }
                    }
                }}
            />

                <NavBar
                    authHandler={async (instance) => {await this.authHandler(instance)}}
                    authRefresh={this.authRefresh}
                    signin_name={this.props.username}
                />

                <Layout>

                <Sider
                    width={200}
                    style={{
                    paddingTop: 60,
                    }}
                    >
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        style={{
                        height: '100%',
                        borderRight: 0,
                        borderTop: 0,
                        }}
                    >
                                    <Menu.Item key="create" title="Create"
                                     onClick={(e)=> {
                                        this.setState({
                                            publisher_id: "create",
                                            temp_articles: null,
                                            temp_publisher: null,
                                        })
                                    }}>
                                        <b>➕ CREATE NEW</b>
                                    </Menu.Item>

                        {
                            this.state.countries.map((country) => {
                                return (
                                    <SubMenu key={country} title={this.state.country_to_display_name_map[country]}>
                                        {
                                            this.state.country_to_publisher_id_map[country].map((publisher_id) => {
                                                return (
                                                    <Menu.Item key={publisher_id} onClick={(e)=> {
                                                        this.setState({
                                                            country: country,
                                                            publisher_id: publisher_id,
                                                            temp_articles: null,
                                                            temp_publisher: null,
                                                        })
                                                        this.getPublisherArticles(publisher_id)
                                                    }}>{publisher_id}</Menu.Item>
                                                )
                                            })
                                        }

                                    </SubMenu>
                                )
                            })
                        }
                    </Menu>
                </Sider>

                <Layout
                    style={{
                        padding: '0 24px 24px',
                        paddingTop: 60,
                    }}
                >
                    <Breadcrumb
                        style={{
                        margin: '16px 0',
                        }}
                    >
                        <Breadcrumb.Item>{this.state.country}</Breadcrumb.Item>
                        <Breadcrumb.Item>{this.state.category}</Breadcrumb.Item>
                        <Breadcrumb.Item>{this.state.publisher_id}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div>
                    {
                        (this.state.publisher_id == null)
                        ? (
                            <p></p>
                        )
                        : (
                            <Row style={{width:'100%'}}>
                            <Col span={3}>
                                <Button
                                    onClick={(e)=>{
                                        const final_link = GetRSS(this.state.publisher_id, this.state.publisher)
                                        console.log("final_link: ", final_link)
                                        window.open(final_link)
                                    }}
                                >Get RSS</Button>
                            </Col>
                            <Col span={3}>
                                <Text>
                                    Category
                                </Text>
                                <CategoryDropDown
                                    category={this.state.search_category_dropdown}
                                    category_options={this.state.category_dropdown_options}
                                    onCategorySelect={(e) => {
                                        console.log("onCategorySelect - " + e)
                                        this.setState({
                                            search_category_dropdown: e
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={3}>
                                <Text>
                                    Content Type
                                </Text>
                                <Select
                                    placeholder="any"
                                    style={{
                                        width: '100%',
                                        paddingLeft: '5px',
                                        paddingRight: '5px'
                                    }}
                                    value={this.state.search_content_type}
                                    allowClear={false}
                                    onChange={(e)=>{
                                        this.setState({
                                            search_content_type: e
                                        })
                                    }}
                                    >
                                    {this.state.content_type_options.map((e) => {
                                        return <Option
                                        value={e}>{e}</Option>
                                    })}
                                </Select>
                            </Col>
                            <Col span={3}>
                                <Text>
                                    Flow
                                </Text>
                                <Select
                                    placeholder="any"
                                    style={{
                                        width: '100%',
                                        paddingLeft: '5px',
                                        paddingRight: '5px'
                                    }}
                                    value={this.state.search_flow}
                                    allowClear={false}
                                    onChange={(e)=>{
                                        this.setState({
                                            search_flow: e
                                        })
                                    }}
                                    >
                                    {this.state.flow_options.map((e) => {
                                        return <Option
                                        value={e}>{e}</Option>
                                    })}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Text>
                                    Query
                                </Text>
                                <Search
                                    placeholder="Search Articles!"
                                    allowClear
                                    enterButton="search"
                                    defaultValue={this.state.search_query}
                                    onSearch={async (search_query) => {
                                        console.log("Page change",search_query)
                                        this.searchPublisherArticles(search_query)
                                    }}
                                />
                            </Col>
                            </Row>
                        )
                    }
                    </div>
                    <br></br>
                    <br></br>
                    <Text>Status</Text>
                    <Select
                        defaultValue="all"
                        style={{ width: 120, marginBottom: 20 }}
                        onChange={this.handleFilterChange}
                    >
                        <Select.Option value="all">All</Select.Option>
                        <Select.Option value="published">Published</Select.Option>
                        <Select.Option value="draft">Draft</Select.Option>
                    </Select>
                    {
                        (this.state.publisher_id == "create")
                        ?(
                            <div>
                            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 0 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 1000 }}>

                            <center>


                            <Form name="dynamic_form_nest_item" onFinish={this.onFinish} autoComplete="off"
                                style={{
                                    width: '100%'
                                }}
                            >
                        <Form.Item
                                label="Title of your page"
                                name="title"
                                required
                            >
                                    <Input required placeholder='Add title for your page' onChange={(e)=>{
                                            this.setState({
                                                title: e.target.value
                                            })
                                        }}/>
                        </Form.Item>
                        <Form.Item
                                label="Prompt (Optional)"
                                name="prompt"
                            >

                                    <TextArea rows={4}
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '75px',
                                        }}
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 10,
                                        }}
                                        placeholder="Add prompt for your page (optional)."
                                        defaultValue={this.state.prompt}
                                        onChange={(e) => {
                                            this.setState({
                                                prompt: e.target.value
                                            })
                                        }}
                                    />

                        </Form.Item>
                        <Form.Item
                                label="Create from Embed (Optional)"
                                name="embed"
                        >
                            <TextArea rows={4}
                                style={{
                                    fontSize: '13px',
                                    minHeight: '75px',
                                }}
                                autoSize={{
                                    minRows: 2,
                                    maxRows: 3,
                                }}
                                placeholder="Add embed, if you want."
                                defaultValue={this.state.embed}
                                onChange={(e) => {
                                    this.setState({
                                        embed: e.target.value
                                    })
                                }}
                            />
                        </Form.Item>
                        <Text>
                            <h4>OR</h4>
                        </Text>
                        <Tooltip trigger={['focus']} title={"Context takes extra info from the content you provide and it into your final article. Final article is relevant to the context."} placement="topLeft">
                        <Form.Item
                                label="Context (Optional)"
                                name="context"
                            >
                                    <TextArea rows={4}
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '75px',
                                        }}
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 10,
                                        }}
                                        placeholder="Add context for your page (optional). 1 Article link or text. context is simply to be used as a segway - that bro this article I am writing is because this thing happened. Add this tiny info in this."
                                        defaultValue={this.state.context}
                                        onChange={(e) => {
                                            this.setState({
                                                context: e.target.value
                                            })
                                        }}
                                    />
                        </Form.Item>
                        </Tooltip>
                        <Tooltip trigger={['focus']} title={"source facts from is that bro replicate this article URL but do not plagarize. Either 1 URL works or any quantity of text works for both of these"} placement="topLeft">

                        <Form.Item
                                label="Source Facts From URL (Optional)"
                                name="facts"
                            >
                                    <TextArea rows={4}
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '75px',
                                        }}
                                        autoSize={{
                                            minRows: 2,
                                            maxRows: 15,
                                        }}
                                        placeholder="source facts from is that bro replicate this article URL but do not plagarize. Either 1 URL works or any quantity of text works for both of these"
                                        defaultValue={this.state.facts}
                                        onChange={(e) => {
                                            this.setState({
                                                facts: e.target.value
                                            })
                                        }}
                                    />
                        </Form.Item>
                        </Tooltip>
                        <Tooltip trigger={['focus']} title={"source facts from is that bro replicate this text but do not plagarize. Either 1 URL works or any quantity of text works for both of these"} placement="topLeft">

                        <Form.Item
                                label="Source Facts From Text (Optional)"
                                name="facts_text"
                            >
                                    <TextArea rows={4}
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '75px',
                                        }}
                                        autoSize={{
                                            minRows: 2,
                                            maxRows: 15,
                                        }}
                                        placeholder="source facts from is that bro replicate this text but do not plagarize. any quantity of text works for both of these"
                                        defaultValue={this.state.facts_text}
                                        onChange={(e) => {
                                            this.setState({
                                                facts_text: e.target.value
                                            })
                                        }}
                                    />
                        </Form.Item>

                        </Tooltip>
                        <Form.Item
                                label="Header Image"
                                name="header_image"
                                required
                            >

                                <Button
                                onClick={e => {
                                    this.setState({
                                        "image_search_mode": "header"
                                    })
                                    this.toggleImageSearchModal()
                                }}
                                style={{
                                    width:'100%',
                                    overflow:'clip'
                                }}
                                    >
                                    {this.state.banner_image_url==undefined?
                                        "Select Image":
                                        this.state.banner_image_url
                                    }
                                    </Button>

                                    <div
                                    style={{
                                        width: '100%',
                                        height: this.state.banner_image_url?'400px':'1px', // Set the desired height here
                                        position: 'relative',
                                        overflow: 'hidden',
                                      }}
                                    >
                                    <Cropper
                                        image={this.state.banner_image_url}
                                        crop={this.state.crop}
                                        zoom={this.state.zoom}
                                        aspect={this.state.aspect}
                                        onCropChange={this.onCropChange}
                                        onZoomChange={this.onZoomChange}
                                        onCropComplete={this.onCropComplete}
                                    />
                                </div>
                        </Form.Item>
                                <Form.Item required>
                                <Row>
                                <Col span={3}>
                                    </Col>
                                    <Form.Item
                                label = ""
                                name = "Publishers:"
                                required
                                >
                                    </Form.Item>

                                    <Col span={4}>
                                    <Tooltip trigger={['focus']} title={"Any specific publisher?"} placement="topLeft">
                                    <Text>
                                    Publishers
                                </Text>
                                    <AIPublisherIdDropDown
                                        style={{
                                            width: '100%',
                                            paddingLeft: '5px',
                                            paddingRight: '5px'
                                        }}
                                        publisher_ids={this.state.publisher_ids}
                                        category_options={this.state.category_options}
                                        onPublisherSelect={(e)=>{
                                            this.setState({
                                                publisher_ids: e
                                            })
                                        }}
                                        formatPublisherId={this.formatPublisherId}
                                        available_publishers={this.state.available_publishers}
                                    />
                                    </Tooltip>
                                    </Col>

                                    <Col span={3}>
                                    <Tooltip trigger={['focus']} title={"Add a category "} placement="topLeft">
                                    <Text>
                                    Category
                                </Text>
                                    <Select
                                        placeholder="Categories"
                                        style={{
                                            width: '100%',
                                            paddingLeft: '5px',
                                            paddingRight: '5px'
                                        }}
                                        value={this.state.category}
                                        allowClear={false}
                                        onChange={(e)=>{
                                            this.setState({
                                                category: e
                                            })
                                        }}
                                        >
                                        {this.state.article_categories.map((e) => {
                                            return <Option
                                            value={e}>{e}</Option>
                                        })}
                                        </Select>
                                    </Tooltip>
                                    </Col>

                                    <Col span={3}>
                                <Text>
                                    Content Type
                                </Text>
                                <Select
                                    placeholder="any"
                                    style={{
                                        width: '100%',
                                        paddingLeft: '5px',
                                        paddingRight: '5px'
                                    }}
                                    value={this.state.content_type}
                                    allowClear={false}
                                    onChange={(e)=>{
                                        this.setState({
                                            content_type: e
                                        })
                                    }}
                                    >
                                    {this.state.content_type_options.map((e) => {
                                    if (e !== "any") {
                                        return <Option value={e}>{e}</Option>;
                                    }
                                    return null; // This will effectively skip rendering for the value "any"
                                    })}
                                </Select>
                            </Col>
                                    <Col span={4}>
                                    <Row>
                                    <Checkbox checked={this.state.generate_images_from_cardpress} onChange={(e)=>{
                                        this.setState({
                                            generate_images_from_cardpress: e.target.checked
                                        })
                                    }}>Generate Images From CP</Checkbox>

                                    </Row>

                                    <Row>
                                    <Checkbox checked={this.state.generate_kickr} onChange={(e)=>{
                                        this.setState({
                                            generate_kickr: e.target.checked
                                        })
                                    }}>Generate Kickr</Checkbox>
                                    </Row>
                                    <Row>
                                    <Checkbox checked={this.state.generate_catchy_headline} onChange={(e)=>{
                                        this.setState({
                                            generate_catchy_headline: e.target.checked
                                        })
                                    }}>Generate Catchy Headline</Checkbox>

                                    </Row>

                                    <Row>
                                    <Checkbox checked={this.state.generate_sub_headings} onChange={(e)=>{
                                        this.setState({
                                            generate_sub_headings: e.target.checked
                                        })
                                    }}>Generate Sub Headings</Checkbox>

                                    </Row>
                                    <Row>
                                    <Checkbox checked={this.state.strict_image_search} onChange={(e)=>{
                                        this.setState({
                                            strict_image_search: e.target.checked
                                        })
                                    }}>Stricter Image Search</Checkbox>

                                    </Row>

                                    </Col>
                                    <Button type="primary"
                                        onClick={() => {
                                            if (this.state.embed !== null && this.state.embed !== "") {
                                                console.log('Embed Article Created:');
                                                this.createEmbedArticle();
                                            }
                                            else {
                                                this.createArticle()
                                            }
                                        }}
                                        loading={this.state.generate_article_api_called}
                                        htmlType="submit">
                                        Generate Article!
                                    </Button>
                                </Row>

                                {
                                    (this.state.temp_articles != null && this.state.temp_articles.length > 0)
                                    ? (
                                        <p> Go find your article in the left section please</p>
                                    )
                                    : (
                                        <p></p>
                                    )
                                }
                                </Form.Item>
                            </Form>
                            </center>
                            </div>
                            </Content>
                            </div>
                        )
                        :(<Collapse accordion onChange={this.handlePanelChange}>
                    {
                        ((this.state.temp_articles == null) || (this.state.temp_articles.length===0))
                        ? (
                            <Empty
                            style={{
                                paddingTop: "5px",
                                width: "100%",
                            }}
                            />
                        )
                        : 
                        (
                            this.state.temp_articles
                                .map((article, article_index) => {
                                    return (
                                        <Panel
                                            hidden={!((this.state.filterStatus === 'all') || (this.state.filterStatus === 'published' ? article.is_published : !article.is_published))}
                                            header={
                                                <div>
                                                    <h4>{article.title}</h4>
                                                    <p style={{ color: 'grey', fontSize: '0.8rem' }}>
                                                        By {article.author} on {this.formatTimestamp(article.created_at)}
                                                    </p>
                                                </div>
                                            }
                                            key={article.id}
                                            extra={
                                                (article.is_published == true)
                                                ? <><Tag color="green">PUBLISHED</Tag></>
                                                : <><Tag color="gold">DRAFT</Tag></>
    }>

                                        <div key={`article-${article_index}-${this.state.forceUpdate}`}>
                                            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 0 }}>
                                            <div className="site-layout-background" style={{ padding: 24, minHeight: 1000 }}>

                                            {
                                                (article.is_published)
                                                ? <Tag color="green">PUBLISHED</Tag>
                                                : <Tag color="gold">DRAFT</Tag>
                                            }
                                            <Button
                                                type="primary"
                                                onClick={e => {
                                                    this.setState({
                                                        temp_article_html: ArticleHTML(article),
                                                    })
                                                    this.toggleArticlePreviewModal()
                                                }}
                                                style={{
                                                    width:'15%',
                                                }}
                                                    >
                                                    Preview
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() =>  {
                                                    const currentUrl = window.location.href; // Get the current URL
                                                    const extraBit = "/aiarticle/"+article.id;
                                                    const fullUrl = currentUrl + extraBit; // Concatenate them
                                                    navigator.clipboard.writeText(fullUrl); // Copy to clipboard
                                                }}
                                                style={{
                                                    width:'15%',
                                                }}
                                            >
                                                Copy Preview Link
                                            </Button>
                                            <br/><br/>
                                            <center>
                                            <Form name="dynamic_form_nest_item" onFinish={this.onFinish} autoComplete="off"
                                                style={{
                                                    width: '100%'
                                                }}
                                            >
                                                <Form.Item
                                                    label="Title of Article"
                                                    name="title"
                                                    rules={[
                                                        { required: true, message: 'Please input a title for your article!' },
                                                    ]}
                                                >
                                                    <Input defaultValue={article.title}
                                                        onChange={(e)=>{
                                                            var temp_articles = this.state.temp_articles;
                                                            temp_articles[article_index]["title"]=e.target.value;
                                                            this.setState({
                                                                temp_articles: temp_articles,
                                                            })
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Tooltip trigger={['focus']} title={"Prompt, if empty we used default."} placement="topLeft">
                                                <Form.Item
                                                    label="Prompt"
                                                    name="prompt"
                                                    rules={[
                                                        { required: false, message: 'Please input a prompt if needed!' },
                                                    ]}
                                                >
                                                    <TextArea rows={4}
                                                        style={{
                                                            width: '100%',
                                                            fontSize: '13px',
                                                            minHeight: '75px',
                                                        }}
                                                        autoSize={{
                                                            minRows: 2,
                                                            maxRows: 15,
                                                        }}
                                                        placeholder="Prompt, if empty, we used default."
                                                        defaultValue={article.prompt_spec?.prompt ?? ""}
                                                        disabled
                                                    />
                                                    </Form.Item>
                                                </Tooltip>

                                                <Tooltip trigger={['focus']} title={"Context takes extra info from the content you provide and it into your final article. Final article is relevant to the context."} placement="topLeft">
                                                <Form.Item
                                                    label="Context"
                                                    name="context"
                                                    rules={[
                                                        { required: false, message: 'Please input a context if needed!' },
                                                    ]}
                                                >
                                                    <TextArea rows={4}
                                                        style={{
                                                            width: '100%',
                                                            fontSize: '13px',
                                                            minHeight: '75px',
                                                        }}
                                                        autoSize={{
                                                            minRows: 2,
                                                            maxRows: 15,
                                                        }}
                                                        placeholder="Add context for your page (optional). Article link or text. This helps you link an article you create with some news event that might be happening. Context takes extra info from the content you provide and it into your final article. Final article is relevant to the context."
                                                        defaultValue={article.prompt_spec?.context ?? ""}
                                                        disabled
                                                    />
                                                    </Form.Item>
                                                </Tooltip>
                                                <Tooltip trigger={['focus']} title={"Source Facts from gets inspired from the points of the content you provide and adds it into your final article. Final article might have ideas from the info you provide."} placement="topLeft">
                                                <Form.Item
                                                    label="Source Facts From"
                                                    name="facts"
                                                    rules={[
                                                        { required: false, message: 'Please input a source for facts if needed!' },
                                                    ]}
                                                >
                                                    <TextArea rows={4}
                                                        style={{
                                                            width: '100%',
                                                            fontSize: '13px',
                                                            minHeight: '75px',
                                                        }}
                                                        autoSize={{
                                                            minRows: 2,
                                                            maxRows: 15,
                                                        }}
                                                        defaultValue={article.prompt_spec?.facts ?? ""}
                                                        disabled
                                                    />
                                                    </Form.Item>
                                                </Tooltip>
                                                <Tooltip trigger={['focus']} title={"Source Facts Text from gets inspired from the points of the content you provide and adds it into your final article. Final article might have ideas from the info you provide."} placement="topLeft">
                                                <Form.Item
                                                    label="Source Facts From"
                                                    name="facts_text"
                                                    rules={[
                                                        { required: false, message: 'Please input a source for facts if needed!' },
                                                    ]}
                                                >
                                                    <TextArea rows={4}
                                                        style={{
                                                            width: '100%',
                                                            fontSize: '13px',
                                                            minHeight: '75px',
                                                        }}
                                                        autoSize={{
                                                            minRows: 2,
                                                            maxRows: 15,
                                                        }}
                                                        defaultValue={article.prompt_spec?.facts_text ?? ""}
                                                        disabled
                                                    />
                                                    </Form.Item>
                                                </Tooltip>
                                                <Form.Item
                                                    label = "Header Image"
                                                    name = "Header Image"
                                                    >
                                                    {
                                                        (article.banner_image_url)
                                                        ? (
                                                            <img alt="" width={this.state.banner_image_url ? "200" : "400"} src={article.banner_image_url ? article.banner_image_url : this.state.banner_image_url} />
                                                        )
                                                        : <></>
                                                    }
                                                    <br/>
                                                    <Button
                                                    disabled={this.state.temp_articles[article_index].is_published}
                                                    onClick={e => {
                                                        console.log("article_index: ", article_index)
                                                        this.setState({
                                                            "image_search_mode": "header",
                                                            "current_article_index": article_index
                                                        })
                                                        this.toggleImageSearchModal()
                                                    }}
                                                    style={{
                                                        width:'100%',
                                                        overflow:'clip'
                                                    }}
                                                        >
                                                        {this.state.temp_articles[article_index]["banner_image_url"]==undefined?
                                                            "Select Image":
                                                            this.state.temp_articles[article_index]["banner_image_url"]
                                                        }
                                                        </Button>
                                                    </Form.Item>
                                                    {
                                                        (this.state.banner_image_url)
                                                        ? (
                                                            <>
                                                    <Button
                                                        disabled={this.state.temp_articles[article_index].is_published}
                                                        onClick={e => {
                                                            this.setState({
                                                                banner_image_url: null,
                                                                croppedAreaPixels: null,
                                                            })
                                                        }}
                                                        type="text"
                                                    >
                                                        <DeleteFilled />
                                                    </Button>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: this.state.banner_image_url?'400px':'1px', // Set the desired height here
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                        }}
                                                        >
                                                        <Cropper
                                                            image={this.state.banner_image_url}
                                                            crop={this.state.crop}
                                                            zoom={this.state.zoom}
                                                            aspect={this.state.aspect}
                                                            onCropChange={this.onCropChange}
                                                            onZoomChange={this.onZoomChange}
                                                            onCropComplete={this.onCropComplete}
                                                        />
                                                    </div>

                                                            </>

                                                        )
                                                        : <p></p>
                                                    }

                                                <h4>Scratch Space (just to note down things etc, does not reflect in article at all)</h4>
                                                <Form.Item>
                                                <TextArea rows={4}
                                                    style={{
                                                        fontSize: '13px',
                                                        minHeight: '75px',
                                                    }}
                                                    autoSize={{
                                                        minRows: 2,
                                                        maxRows: 10,
                                                    }}
                                                    defaultValue={article.raw_text}
                                                    onChange={(e) => {
                                                        console.log("Working on article", article_index, "for summary")
                                                        var temp_articles = this.state.temp_articles;
                                                        temp_articles[article_index]["raw_text"]=e.target.value;
                                                        this.setState({
                                                            temp_articles: temp_articles,
                                                        })
                                                    }}
                                                />
                                                <br></br>
                                                <h4> Article Starts From Here</h4>
                                                </Form.Item>

                                                {
                                                    article.contents["legos"].map((content, idx) => {
                                                        return (
                                                            <div
                                                                key={`content-block-${idx}`}
                                                                style={{
                                                                    border: '1px solid #d0d0d0', // Adjust the border style as needed
                                                                    padding: '10px', // Add some padding to separate the border from the content
                                                                    margin: '10px', // Add horizontal margin between the div elements
                                                                }}
                                                            >
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}>
                                                                    <Popconfirm
                                                                        title="Do you really want to remove this content block (heading, paragraph, images) ?"
                                                                        disabled={this.state.temp_articles[article_index].is_published}
                                                                        onConfirm={() => {
                                                                            console.log("Deleting article content lego block", content)
                                                                            var temp_articles = this.state.temp_articles;
                                                                            temp_articles[article_index].contents["legos"].splice(idx, 1)
                                                                            this.setState({ temp_articles: temp_articles, forceUpdate: !this.state.forceUpdate }, () => {
                                                                                console.log('Article updated');
                                                                            });
                                                                        }}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                    <Button
                                                                        disabled={this.state.temp_articles[article_index].is_published}
                                                                        type="text"
                                                                    >
                                                                        <DeleteFilled />
                                                                    </Button>
                                                                    </Popconfirm>
                                                                </div>
                                                                <Row>
                                                                    <Text>
                                                                        <h6> Sub Heading</h6>
                                                                    </Text>
                                                                    <TextArea rows={4}
                                                                        key={`subheading-textarea-${idx}`}
                                                                        style={{
                                                                            fontSize: '13px',
                                                                            minHeight: '75px',
                                                                        }}
                                                                        autoSize={{
                                                                            minRows: 1,
                                                                            maxRows: 10,
                                                                        }}
                                                                        defaultValue={content.text}
                                                                        onChange={(e) => {
                                                                            console.log("Working on article: ", article_index, "with content index: ", idx)
                                                                            var temp_articles = this.state.temp_articles;
                                                                            temp_articles[article_index].contents["legos"][idx]["text"]= e.target.value
                                                                            this.setState({
                                                                                temp_articles: temp_articles
                                                                            })
                                                                        }}
                                                                    />

                                                                    <Text>
                                                                        <h6> Paragraph</h6>
                                                                    </Text>
                                                                    <TextArea rows={4}
                                                                        key={`paragraph-textarea-${idx}`}
                                                                        style={{
                                                                            fontSize: '13px',
                                                                            minHeight: '75px',
                                                                            marginBottom: '10px',
                                                                        }}
                                                                        autoSize={{
                                                                            minRows: 2,
                                                                            maxRows: 10,
                                                                        }}
                                                                        defaultValue={content.elaborate_text}
                                                                        onChange={(e) => {
                                                                            console.log("Working on article: ", article_index, "with content index: ", idx)
                                                                            var temp_articles = this.state.temp_articles;
                                                                            temp_articles[article_index].contents["legos"][idx]["elaborate_text"]= e.target.value
                                                                            this.setState({
                                                                                temp_articles: temp_articles
                                                                            })
                                                                        }}
                                                                    />
                                                                    <Text>
                                                                        <h6> Optional (Raw HTML) </h6>
                                                                    </Text>
                                                                    <TextArea rows={4}
                                                                        key={`subheading-textarea-${idx}`}
                                                                        style={{
                                                                            fontSize: '13px',
                                                                            minHeight: '75px',
                                                                        }}
                                                                        autoSize={{
                                                                            minRows: 1,
                                                                            maxRows: 10,
                                                                        }}
                                                                        defaultValue={content?.html}
                                                                        onChange={(e) => {
                                                                            console.log("Working on article: ", article_index, "with content index: ", idx)
                                                                            var temp_articles = this.state.temp_articles;
                                                                            temp_articles[article_index].contents["legos"][idx]["html"]= e.target.value
                                                                            this.setState({
                                                                                temp_articles: temp_articles
                                                                            })
                                                                        }}
                                                                    />

                                                                    <Button
                                                                        type="primary"
                                                                        disabled={this.state.temp_articles[article_index].is_published}
                                                                        onClick={()=>{
                                                                            this.setState({
                                                                                current_article_index: article_index,
                                                                                current_content_index: idx,
                                                                            })
                                                                            this.toggleGenerativeImageModal()
                                                                        }}
                                                                        style={{
                                                                            width:'15%',
                                                                            left: '50%',
                                                                            transform: 'translateX(-50%)'
                                                                        }}
                                                                    >
                                                                        Add Images
                                                                    </Button>
                                                                    <Button
                                                                        type="primary"
                                                                        disabled={this.state.temp_articles[article_index].is_published}
                                                                        onClick={e => {
                                                                            this.setState({
                                                                                image_search_mode: "article",
                                                                                current_article_index: article_index,
                                                                                current_content_index: idx
                                                                            })
                                                                            this.toggleImageSearchModal()
                                                                        }}
                                                                        style={{
                                                                            width:'15%',
                                                                            left: '50%',
                                                                            leftPadding: '20px',
                                                                            transform: 'translateX(-50%)'
                                                                        }}
                                                                            >
                                                                            Add CP Images
                                                                    </Button>
                                                                </Row>
                                                                <Row style={{overflow: "scroll"}}>
                                                                    {
                                                                        (content["images"] == null)
                                                                        ? (
                                                                            <p>No images</p>
                                                                        )
                                                                        :
                                                                        (
                                                                            content["images"].map((image_packet) => {
                                                                                return (
                                                                                    <Col>
                                                                                        <Button
                                                                                            disabled={this.state.temp_articles[article_index].is_published}
                                                                                            type="text"
                                                                                            onClick={e => {
                                                                                                console.log("Deleting image", image_packet)
                                                                                                var temp_articles = this.state.temp_articles;
                                                                                                const image_url = image_packet.url;
                                                                                                const image_index = temp_articles[article_index].contents["legos"][idx]["images"].findIndex(image => image.url === image_url);
                                                                                                temp_articles[article_index].contents["legos"][idx]["images"].splice(image_index, 1)
                                                                                                this.setState({
                                                                                                    temp_articles: temp_articles
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <DeleteFilled />
                                                                                        </Button>
                                                                                        {
                                                                                            (image_packet && image_packet.url && image_packet.url.endsWith(".mp4"))
                                                                                            ? (
                                                                                                <video width="200" controls src={image_packet.url}/>
                                                                                            )
                                                                                            : (
                                                                                                <Card
                                                                                                hoverable
                                                                                                style={{
                                                                                                width: 200
                                                                                                }}
                                                                                                cover={<img alt="" src={image_packet.url} />}
                                                                                            >
                                                                                                <Input.TextArea
                                                                                                    placeholder="Enter Caption / Image Source Attribution"
                                                                                                    onChange={(e)=>{
                                                                                                        const value = e.target.value
                                                                                                        var temp_articles = this.state.temp_articles;
                                                                                                        const image_url = image_packet.url;
                                                                                                        const image_index = temp_articles[article_index].contents["legos"][idx]["images"].findIndex(image => image.url === image_url);
                                                                                                        //image_packet["caption"] = value
                                                                                                        temp_articles[article_index].contents["legos"][idx]["images"][image_index]["caption"] = value
                                                                                                        this.setState({
                                                                                                            temp_articles: temp_articles
                                                                                                        })
                                                                                                    }}
                                                                                                    value={image_packet?.caption}
                                                                                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                                                                                />
                                                                                            </Card>
                                                                                            )
                                                                                        }
                                                                                    </Col>
                                                                                )
                                                                            })
                                                                        )
                                                                    }
                                                                </Row>
                                                                <br></br>
                                                            </div>
                                                        )
                                                    })                                                    
                                                }

                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        disabled={this.state.temp_articles[article_index].is_published}
                                                        onClick={() => {
                                                            console.log("Saving article..", article_index)
                                                            this.updateArticle(this.state.temp_articles[article_index], article_index)
                                                        }}
                                                        loading={this.state.update_article_api_called}
                                                        htmlType="submit">
                                                        Save progress!
                                                    </Button>
                                                    {
                                                        (this.state.temp_articles[article_index].is_published)
                                                        ? (<></>)
                                                        : (
                                                            <Button
                                                                style={{
                                                                    marginLeft: '10px'
                                                                }}
                                                                type="primary"
                                                                disabled={this.state.temp_articles[article_index].is_published}
                                                                onClick={() => {
                                                                    console.log("Saving article..", article_index)
                                                                    this.updateArticle(this.state.temp_articles[article_index], article_index, "all")
                                                                }}
                                                                loading={this.state.update_article_api_called}
                                                                htmlType="submit">
                                                                Save progress for all cards across geo!
                                                            </Button>
                                                        )
                                                    }

                                                </Form.Item>
                                            </Form>

                                            </center>



                                            </div>
                                            </Content>
                                        </div>
                                        <Row>
                                        <Col span={2}>
                                                <Button
                                                    type="primary"
                                                    disabled={this.state.temp_articles[article_index].is_published}
                                                    onClick={() => {
                                                        console.log("Saving article..", article_index)
                                                        var _temp_articles = this.state.temp_articles
                                                        _temp_articles[article_index]["is_published"] = true
                                                        this.setState({
                                                            temp_articles: _temp_articles
                                                        })
                                                        this.publishArticle(_temp_articles[article_index])
                                                    }}
                                                >{
                                                    (this.state.temp_articles[article_index].is_published) ? "Published" : "Publish"
                                                }</Button>
                                        </Col>
                                        <Col span={3}>
                                                {
                                                    (this.state.temp_articles[article_index].is_published)
                                                    ? (<></>)
                                                    :
                                                    <Button
                                                        type="primary"
                                                        disabled={this.state.temp_articles[article_index].is_published}
                                                        style={{
                                                            marginLeft: '10px'
                                                        }}
                                                        onClick={() => {
                                                            console.log("Saving article..", article_index)
                                                            var _temp_articles = this.state.temp_articles
                                                            _temp_articles[article_index]["is_published"] = true
                                                            this.setState({
                                                                temp_articles: _temp_articles
                                                            })
                                                            this.multiPublishArticles(_temp_articles[article_index])
                                                        }}
                                                    >{
                                                        "Publish For All Cards Across Geos"
                                                    }</Button>
                                                }
                                        </Col>
                                    <Col span={10}></Col>
                                    <Col span={2}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                console.log("Duplicating article..", article_index)
                                                this.duplicateArticle(this.state.temp_articles[article_index], article_index)
                                            }}
                                        >{
                                            "Duplicate Article"
                                        }</Button>
                                    </Col>

                                    <Col span={4}>
                                    <AIPublisherIdDropDown
                                        style={{
                                            width: '100%',
                                            paddingLeft: '5px',
                                            paddingRight: '5px'
                                        }}
                                        publisher_ids={this.state.translate_to_publisher_ids}
                                        category_options={this.state.category_options}
                                        onPublisherSelect={(e)=>{
                                            this.setState({
                                                translate_to_publisher_ids: e
                                            })
                                        }}
                                        formatPublisherId={this.formatPublisherId}
                                        available_publishers={this.state.available_publishers}
                                    />
                                    </Col>
                                    <Col span={2}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                console.log("Translating article..", article_index)
                                                this.translateArticle(this.state.temp_articles[article_index], article_index)
                                            }}
                                        >{
                                            "Translate & Send Article"
                                        }</Button>
                                </Col>

                                    </Row>

                                        </Panel>
                                    )
                            })
                        )
                    }
                            </Collapse>
                        )
                    }
                    <center style={{
                        marginTop: '20px'
                    }}>
                        <Pagination
                            defaultCurrent={1}
                            total={50000} // Consider dynamically adjusting based on search results
                            onChange={(page_number, pageSize) => {
                                const { search_query, page_size } = this.state;
                                console.log("Page changed", page_number, pageSize, "with query", this.state.search_query);
                                // Check if there's an active search query before deciding which method to call
                                if (this.state.search_query) {
                                    this.searchPublisherArticles(this.state.search_query, (page_number - 1) * pageSize, pageSize);
                                } else {
                                    // If no search query, call a method to fetch articles without search criteria
                                    // Adjust this part according to your application's logic
                                    this.getPublisherArticles(this.state.publisher_id, (page_number - 1) * pageSize, pageSize);
                                }
                            }}
                        />
                    </center>
                </Layout>
            </Layout>

            <Footer style={{ textAlign: 'center'}}>
                <img
                    src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                    alt=""
                    style={{
                        height:'20px',
                        display:'inline',
                        paddingRight:'10px'
                    }}/>
                    ©2021 Created by TAG AI Team
            </Footer>
        </Layout>
        </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuth />
            </UnauthenticatedTemplate>
        </>
        )
    }
}

