const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;

export async function WikipediaListAPI(payload, token=null) {
    return await postRequestWikipediaSHPAPI("wikipedialist", payload, token);
}


export async function WikipediaSearchAPI(payload, token=null) {
    return await postRequestWikipediaSHPAPI("wikipediasearch", payload, token);
}


export async function WikipediaScheduleCollectionAPI(payload, token=null) {
    return await postRequestWikipediaSHPAPI("collection/schedule", payload, token);
}


export async function WikipediaGetCreationStatusAPI(payload, token=null) {
    return await postRequestWikipediaSHPAPI("shp/getwork", payload, token);
}


export async function postRequestWikipediaSHPAPI(api_function, payload, token=null, method="POST"){
    let request={
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    console.log(payload)
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/wikishp/" + api_function, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}
