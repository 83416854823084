import React from "react";
import { Select } from "antd";
const { Option } = Select;
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const FontDropDown = (props) => {
  return (
    <>
      <Select
        defaultValue={"ba"}
        style={{
          width: "30%",
        }}
        value={props.language}
        onChange={props.onLanguageSelect}
      >
        {props.language_options.map((lang) => (
          <Option key={lang}>{lang}</Option>
        ))}
      </Select>
      <Select
        style={{
          width: "70%",
        }}
        value={props.second_font}
        onChange={props.secondFontChange}
      >
        {console.log(props.fonts[props.language])}
        {props.fonts[props.language].map((font) => (
          <Option key={font}>
            {font in props.fonts_to_images ? (
              <img
                style={{
                  height: "13px",
                  // padding: '5px'
                }}
                src={props.font_assets_cdn_prefix + props.fonts_to_images[font]}
              />
            ) : (
              font
            )}
          </Option>
        ))}
      </Select>
      <br></br>
      <Select
       defaultValue={50}
        style={{
          width: "30%",
        }}
        value={props.font_size}
        onChange={props.onfontsizeChange}
      >
        {props.font_size_options.map((size) => (
          <Option key={size}>{size}</Option>
        ))}
      </Select>
      <Select
        style={{
          width: "70%",
        }}
        value={props.color}
        onChange={props.oncolorChange}
      >
        {props.color_options.map((color) => (
          <Option key={color}>{color}</Option>
        ))}
      </Select>
    </>
  );
};


export const FontDropDownLite = (props) => {
  return (
    <>
      <Select
        defaultValue={"ba"}
        style={{
          width: "30%",
        }}
        value={props.language}
        onChange={props.onLanguageSelect}
      >
        {props.language_options.map((lang) => (
          <Option key={lang}>{lang}</Option>
        ))}
      </Select>
      <Select
        style={{
          width: "70%",
        }}
        value={props.second_font}
        onChange={props.secondFontChange}
      >
        {console.log(props.fonts[props.language])}
        {props.fonts[props.language].map((font) => (
          <Option key={font}>
            {font in props.fonts_to_images ? (
              <img
                style={{
                  height: "13px",
                  // padding: '5px'
                }}
                src={props.font_assets_cdn_prefix + props.fonts_to_images[font]}
              />
            ) : (
              font
            )}
          </Option>
        ))}
      </Select>
    </>
  );
};
