import { Button, Layout, Menu, Typography,Dropdown,Space  } from "antd";

import React from "react";
import { SignInButton } from "./signInButton";
import { SignInButtonMSFT } from "./signInButtonMSFT";
const { Header, Content, Footer } = Layout;


export default class NavBar extends React.Component {
  render() {
    return (
      <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys="none">
          <Menu.Item
            key="logo"
            onClick={() => (window.location = window.location.origin)}
          >
            <div className="logo" style={{}}>
              <Typography.Title
                level={3}
                style={{
                  color: "white",
                  paddingTop: "12px",
                  color: "#FC054D",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/lex.png"}
                  style={{
                    height: "40px",
                  }}
                ></img>{" "}
              </Typography.Title>
            </div>
          </Menu.Item>
          <Menu.Item key="login">
            <Button>
              {this.props.signin_name ? this.props.signin_name : "?!"}
            </Button>
          </Menu.Item>
          <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              href={window.location.origin + "/create"}
            >
              🚀 Create
            </a>
          </Menu.Item>
          <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              href={window.location.origin + "/clipmaker"}
            >
              🎥 Clip Maker
            </a>
          </Menu.Item>
          <Menu.SubMenu key="sublp" title="💵 AIGC LP">
          <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              href={window.location.origin + "/aipublisher"}
            >
            💵 AI Publisher
            </a>
          </Menu.Item>
          <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              href={window.location.origin + "/contentfactory"}
            >
            🏧 Content Factory
            </a>
          </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="sub1" title="🎨 GLIDE">
          <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              target="_blank"
              rel="noreferrer"
              href={window.location.origin + "/imagegeneration"}
            >
              🏞 Image Generation
            </a>
          </Menu.Item>
           {/* <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              target="_blank"
              rel="noreferrer"
              href={window.location.origin + "/lstransition"}
            >
              📲 LockScreen Transition
            </a>  
            </Menu.Item>
            <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              target="_blank"
              rel="noreferrer"
              href={window.location.origin + "/imageSuperResolution"}
            >
              🖼️ Image Super Resolution 
            </a>  
            </Menu.Item>*/}
            <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              target="_blank"
              rel="noreferrer"
              href={window.location.origin + "/glideEditor"}
            >
              🖼️ Glide Editor 
            </a>  
            </Menu.Item>
           {/* <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              target="_blank"
              rel="noreferrer"
              href={window.location.origin + "/imageAutoCorrection"}
            >
              🎯 Image Auto Correction
            </a>  
            </Menu.Item>*/}
            <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              target="_blank"
              rel="noreferrer"
              href={window.location.origin + "/lstransition"}
            >
              💾 Saved Searches
            </a>  
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              href={window.location.origin + "/ampstorycreate"}
            >
              🌀 AMPer
            </a>
          </Menu.Item>
          <Menu.SubMenu key="sub2" title="🎛️ IMS">
          <Menu.Item key="home">
              <a
                style={{
                  color: "white",
                }}
                href={window.location.origin + "/imsAdd"}
              >
              📁 Upload
              </a>
            </Menu.Item>
            <Menu.Item key="home">
              <a
                style={{
                  color: "white",
                }}
                href={window.location.origin + "/ims"}
              >
              🔍 Search
              </a>
            </Menu.Item>
            <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              href={window.location.origin + "/imsAdmin"}
            >
              ⚙️ Admin
            </a>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="home">
              <a
                  style={{
                      color: 'white'
                  }}
                  href={window.location.origin+"/textcompletion"}
              >
                💬 Text Summarizer</a>
          </Menu.Item>

          <Menu.Item key="home">
              <a
                  style={{
                      color: 'white'
                  }}
                  href={window.location.origin+"/moderation"}
              >
              🏎️ HL Moderation</a>
          </Menu.Item>
          {/*
          <Menu.Item key="home">
              <a
                  style={{
                      color: 'white'
                  }}
                  href={window.location.origin+"/personabot/1"}
              >
                💬 ChatGPT</a>
          </Menu.Item>
                  */ }
          <Menu.Item key="home">
            <a
              style={{
                color: "white",
              }}
              href={window.location.origin + "/help"}
            >
              🚑 Help
            </a>
          </Menu.Item>
        </Menu>
      </Header>
    );
  }
}
