import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import React from 'react';
import NavBar from './components/header';
import { Button, Image, Result } from 'antd';
import FrontPage from './activities/frontPage';
import HelpPage from './activities/help';
import CreateLex from './activities/create';
import VideoPortal from './activities/videoPortal';
import ClipMaker from './activities/clipMaker';
import ContentFactory from './activities/contentFactory';
import TextCompletion from './activities/textCompletion';
import ImageGeneration from './activities/imageGeneration';
import ImageAutoCorrection from './activities/ImageAutoCorrection';
import IMSSearch from './activities/ImageManagementService';
import OneClickPreview from './activities/1clickPreview';
import ContentReview from './activities/contentReview';
import HLModeration from './activities/HLModeration';
import IMSAdd from './activities/imsAdd';
import MemeCreate from './activities/memeCreate'
import IMSAdmin from './activities/imsAdmin';
import AIPublisher from './activities/aiPublisher';
import LSTransition from './activities/lsTransition';
import ImageSuperResolution from './activities/imageSuperResolution';
import GlideEditor from './activities/glideEditor';
import LogoPlacer from './activities/logoPlacer';
import Blank from './activities/blank';
import Docubot from './activities/docubot';
import Personabot from './activities/personabot';
import LipSync from './activities/lipSync';
import Transcription from './activities/transcription';
import AmpStoryCreate from './activities/ampStoryCreate';
import AmpStoryPortal from './activities/ampStoryPortal';
import NewsStoryCreate from './activities/newsStoryCreate';
import NewsStoryPortal from './activities/newsStoryPortal';
import NicQuizCreate from './activities/nicQuizCreate';
import NicQuizPortal from './activities/nicQuizPortal';
import NicQuizPortalV2 from './activities/nicQuizPortalv2';
import WikiTools from './activities/wikiTools';
import WikiCollections from './activities/wikiCollections';
import WikiCollectionsStatus from './activities/wikiCollectionsStatus';
import AIArticleView from './activities/aiArticleView';
import ZappFactory from './activities/zappFactory';
import PTMSearch from './activities/productCatalogue';
import { useEffect, useState, useCallback } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import VoxCaeli from './activities/voxcaeli';

function App() {

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState(null);
  const [username, setUsername] = useState("?");
  const [email, setEmail] = useState("");
  const refreshIdToken = async () => {
    const loggedInAcc = instance.getActiveAccount();
    const request = {
      scopes: ["openid"],
      account: loggedInAcc,
      forceRefresh: true
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      setIdToken(response.idToken);
      return response.idToken;
    } catch (error) {
      const response = instance.acquireTokenPopup(request);
      setIdToken(response.idToken);
      return response.idToken;
    }
  }

  useEffect(() => {
    const loggedInAcc = instance.getActiveAccount();
    if (accounts.length > 0) {
      refreshIdToken().then((token)=>{
        setUsername(loggedInAcc.name);
        setEmail(loggedInAcc.username);
      });
    }
    if (loggedInAcc) {
      setUsername(loggedInAcc.name);
      setEmail(loggedInAcc.username);
    }


  }, [instance]);

  return (
    <Router>
      <Switch>
        <Route exact path="/create">
          <CreateLex
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/videoportal">
          <VideoPortal
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/clipmaker">
          <ClipMaker
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/imagegeneration">
          <ImageGeneration
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/aipublisher">
          <AIPublisher
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/lstransition">
          <LSTransition
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/imageSuperResolution">
          <ImageSuperResolution
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/glideeditor">
          <GlideEditor
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/logoplacer">
          <LogoPlacer
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/imageAutoCorrection">
          <ImageAutoCorrection
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/textcompletion">
          <TextCompletion
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/ims">
          <IMSSearch
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/imsAdd">
          <IMSAdd
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/imsAdmin">
          <IMSAdmin
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/">
          <FrontPage
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/help">
          <HelpPage
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/lipsync">
          <LipSync
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/1clickPreview">
          <OneClickPreview
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/contentReview">
          <ContentReview
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/moderation">
          <HLModeration
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/Transcription">
          <Transcription
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/memecreate">
          <MemeCreate
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/docubot/:projectId?">
          <Docubot
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/aipublisher/aiarticle/:articleId?">
          <AIArticleView
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/personabot/:botId?">
          <Personabot
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        <Route exact path="/voxcaeli/:botId?">
          <VoxCaeli
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/ampstorycreate">
          <AmpStoryCreate
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/ampstoryportal">
          <AmpStoryPortal
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/newsstorycreate">
          <NewsStoryCreate
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/newsstoryportal">
          <NewsStoryPortal
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/nicquizcreate">
          <NicQuizCreate
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/nicquizportal">
          <NicQuizPortal
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/zappfactory">
          <ZappFactory
            username={username} 
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/contentFactory">
          <ContentFactory
            username={username} 
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/nicquizportalv2">
          <NicQuizPortalV2
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/wikitools">
          <WikiTools
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/wikicollections">
          <WikiCollections
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/wikicollectionsstatus">
          <WikiCollectionsStatus
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/ptmsearch">
          <PTMSearch
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
            email={email}
          />
        </Route>
        <Route exact path="/blank">
          <Blank
            username={username}
            idToken={idToken}
            refreshIdToken={refreshIdToken}
          />
        </Route>
        {/* <Route exact path="/deepfake/project/:id/request/:requestid/video/:which(input|output)"
          render={(props) => (
            <MediaViewer
              projectId={props.match.params.id}
              requestId={props.match.params.requestid}
              which={props.match.params.which}
            />
          )} /> */}
        <Route>
          <>
            <NavBar />
            <Result
              style={{
                paddingTop: '100px'
              }}
              // status="404"
              title="404"
              icon={<Image style={{ height: '40vh' }} preview={false} src={process.env.PUBLIC_URL + '/raven_confused.png'}
              />}
              subTitle="Sorry, the page you visited does not exist."
              extra={<Button type="primary"
                onClick={() => {
                  window.location = window.origin + "/"
                }}
              >
                Check Home Instead!</Button>}
            /></>

        </Route>
      </Switch>
    </Router>
  );
}

export default App;
