import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'antd/dist/antd.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventType} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./authConf";
import { message } from 'antd';

const msalInstance = new PublicClientApplication(msalConfig)

// const accounts = msalInstance.getAllAccounts();
// if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
// }

msalInstance.addEventCallback((event) => {
  // set active account after redirect
  // console.log(event)
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    // console.log(event)
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    // Reload window for fresh cookies to take effect!
    localStorage.clear(); // make sure localStorage doesn't store anything

    // passing "true" to reload will force-reload the page ONLY ON FIREFOX
    // For Chrome and other browsers, the "true" argument will be ignored
    // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#location.reload_has_no_parameter
//    window.location.reload(true);
    // In case of Chrome browsers, we are disabling browser caching 
    // from public/index.html with meta tags
    
    // Adding a query param at the end of the URL ensures that 
    // we dont read from the cache. This is an alt method!
    // const url = new URL(window.location.href);
    // url.searchParams.set('rt', Date.now().toString());
    // window.location.href = url.toString();

  }

}, error=>{
  console.log('error', error);
});

// console.log('get active account', msalInstance.getActiveAccount());


// msalInstance.handleRedirectPromise().then(authResult=>{
//   // Check if user signed in 
//   // const account = msalInstance.getActiveAccount();
//   // // console.log(account)
//   // if(!account){
//   //   // redirect anonymous user to login page 
//   //   msalInstance.loginRedirect();
//   // }
// }).catch(err=>{
//   // TODO: Handle errors
//   console.log(err);
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App/>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
