import { UpCircleOutlined } from '@ant-design/icons';
import {
    Button, Col, Input, Layout, message, Select, Divider, Card, Breadcrumb, List, Menu, Row
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import { withRouter } from 'react-router-dom';
import { VCChatSetupAPI, VCChatAPI } from '../apis/voxcaeli';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";


const { Search } = Input;
const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

class VoxCaeli extends React.Component{

    constructor(props) {
        super(props);
        this.lastMessageRef = React.createRef();
        // Don't call this.setState() here!

        this.state = {
            aibot_id: "bot1-ritesh-pallod",
            aibot: null,
            user: null,
            conversation: null,
            user_id: null,
            loading: false,
            system_message: null,
            messages: [],
            inputValue: '',
            loadingText: '',
            question: null,
            answer: null,
            history: [],
            customSystemMessage: "",
            isCustomSystemMessageFrozen: false,

            // setup chat
            vc_setupchat_running: false,
        };

        this.myMessageBubbleStyle = {
            position: 'relative',
            borderRadius: '12px',
            backgroundColor: '#ccd8ff',
            padding: '8px',
            marginBottom: '8px',
            maxWidth: '80%',
        };
            
        this.theirMessageBubbleStyle = {
            position: 'relative',
            borderRadius: '12px',
            backgroundColor: '#d6f5d6',
            padding: '8px',
            marginBottom: '8px',
            maxWidth: '80%',
          };
         
          this.myMessageContentStyle = {
            display: 'inline-block',
            color: 'black',
            whiteSpace: 'pre-wrap',
            textAlign: 'right',
            fontFamily: 'sans-serif', // Add this
          };
          
        this.theirMessageContentStyle = {
            display: 'inline-block',
            color: 'black',
            whiteSpace: 'pre-wrap',
            textAlign: 'left',
            fontFamily: 'sans-serif', // Add this
          };

        this.preElementStyle = {
            margin: 0,
            whiteSpace: 'pre-wrap',
            fontFamily: 'inherit',
            fontSize: 'inherit',
        };

        this.updateLoadingText = this.updateLoadingText.bind(this);
    }

    scrollToBottom() {
        if (this.lastMessageRef.current) {
          this.lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    updateLoadingText = async () => {
        const { loadingText } = this.state;
        const nextLoadingText = loadingText.length < 11 ? loadingText + '.' : 'Thinking';
        this.setState({ loadingText: nextLoadingText });
      };
      startLoadingAnimation = async () => {
        this.loadingAnimationInterval = setInterval(this.updateLoadingText, 500);
      };
      
      stopLoadingAnimation = async () => {
        clearInterval(this.loadingAnimationInterval);
      };

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
      };
    
    handleSendClick = async () => {
        try {
            this.props.refreshIdToken().then((token) => {
            var question = this.state.inputValue;
            this.setState((prevState) => ({
                messages: [
                    ...prevState.messages,
                    { text: prevState.inputValue, isMine: true },
                    ],
                inputValue: '',
            }));
            this.setState({loading: true})
            console.log("loading via .. loading ", this.state.loading)

            this.startLoadingAnimation();
                const payload = {
                    user_id: this.state.user_id,
                    aibot_id: this.state.aibot_id,
                    conversation_id: this.state.conversation.id,
                    system_message: this.state.system_message,
                    question: question,
                    history: this.state.history,
                    answer: this.state.answer,
                }
                console.log(payload)
                try {
                    console.log("Bot option selected:",this.state.bot_option)
                    VCChatAPI(payload, token).then((response) => {
                        // Append the answer to the messages array
                        if (response && response.status) {
                            this.setState((prevState) => ({
                            messages: [
                                ...prevState.messages,
                                { text: response.message.answer, isMine: false },
                            ],
                            }));
                            // TODO: MAKE SURE THIS ALWAYS HAPPENS
                            this.setState({
                                question: question,
                                answer: response.message.answer,
                                history: response.message.history,
                                loading: false,
                            })
                        } else {
                            // Handle the case when the response doesn't have the expected format
                            console.error('Invalid response format:', response);
                        } 
                    }) 
                } catch (error) {
            // Handle the error while making the API call
            console.error('Error while sending the message:', error);
                }
            })
            this.setState({loading: false})
            console.log("loading via .. loading ", this.state.loading)
            this.stopLoadingAnimation();
        }
        catch (error) {
            // Handle the error while making the API call
            console.error('Error while sending the message:', error);  
            this.setState({loading: false})
            console.log("loading via .. loading ", this.state.loading)
            this.stopLoadingAnimation();

        }
        this.scrollToBottom();
    }; 

    setupVCChat = async (user_id, aibot_id) => {
        console.log("user_id:", user_id, "aibot_id:", aibot_id)
        this.props.refreshIdToken().then((token) => {
            this.setState({vc_setupchat_running: true})
            message.loading("Setting up chat ...", 0)
            const payload = {
                user_id: user_id,
                aibot_id: aibot_id,
            }
            VCChatSetupAPI(payload, token).then((response) => {
                console.log("VCChatSetupAPI response:", response)
                message.destroy()
                if (response && response.status) {
                    message.success("Chat setup complete")
                    this.setState({
                        user: response.message.user,
                        aibot: response.message.aibot,
                        conversation: response.message.conversation,
                        system_message: response.message.system_message,
                        vc_setupchat_running: false,
                    })
                } else {
                    // Handle the case when the response doesn't have the expected format
                    console.error('Invalid response format:', response);
                    message.error("Chat setup failed")
                    this.setState({vc_setupchat_running: false})
                }
            }).catch((error) => {
                message.destroy()
                console.error('Error while making the call, error:', error);
                this.setState({vc_setupchat_running: false})
            })
        }).catch((error) => {
            message.destroy()
            console.error('Error while retrieving the access token:', error);
        })
    };
    async componentDidMount() {
        const { botId } = this.props.match.params;
        console.log('Bot ID:', botId);
        this.setState({
            //aibot_id: botId,
        })
        // Wait for the email prop to be available
        if (this.props.email) {
            const userId = this.props.email.split('@')[0];
            this.setState({
                user_id: userId,
            });
            await this.setupVCChat(userId, this.state.aibot_id);
        }
      }

    async componentDidUpdate(prevProps, prevState) {
        console.log("componentDidUpdate")
        if (prevState.messages.length !== this.state.messages.length) {
          this.scrollToBottom();
        }
        if (this.props.email) {
            const userId = this.props.email.split('@')[0];
            this.setState({
                user_id: userId,
            });
        }
      }

    render() {
        const { messages, inputValue } = this.state;
        const createForm = (
            <div>
                <Button type="primary" disabled={this.state.vc_setupchat_running} onClick={() => {
                    this.setupVCChat(this.state.user_id, this.state.aibot_id)
                }}>
                    Fetch Chat Profile
                </Button>
                <br></br>
                <Layout style={{ minHeight: '90vh', overflow: 'scroll' }}>
                    <div style={{ display: 'flex', height: '87%' }}>
                        <div style={{ flex: '0 0 30%', marginRight: '10px' }}>
                            <TextArea
                                autoSize={{ minRows: 40, maxRows: 40 }}
                                value={this.state.system_message}
                                onChange={e => this.setState({ system_message: e.target.value })}
                                style={{ height: '87%' }}
                            />
                        </div>
                        <div style={{ flex: '1' }}>
                            {
                                (this.state.aibot_id == "null")
                                ? (
                                    <>
                                    <div style={{ height: '87%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <h2> Please select bot</h2>
                                    </div>
                                    </>
                                )
                                :(
                                        <Layout style={{ minHeight: '90vh', overflow: 'scroll' }}>
                                        <Content style={{ height: 'calc(100% - 50px)' , overflowY: 'auto'}}>
                                            <div style={{ maxHeight: 'calc(100% - 50px)', overflowY: 'auto' }}>
                                        <List
                                            dataSource={messages}
                                            renderItem={(message) => 
                                                <List.Item>
                                                    <div
                                                        style={{
                                                        display: 'flex',
                                                        justifyContent: message.isMine ? 'flex-end' : 'flex-start',
                                                        width: '100%',
                                                        padding: '4px 0',
                                                        }}
                                                    >
                                                    <div 
                                                        style={
                                                            message.isMine ? this.myMessageBubbleStyle : this.theirMessageBubbleStyle
                                                        }
                                                    >
                                                    <pre
                                                    style={
                                                        message.isMine
                                                        ? { ...this.myMessageContentStyle, ...this.preElementStyle }
                                                        : { ...this.theirMessageContentStyle, ...this.preElementStyle }
                                                    }
                                                    >{message.text}</pre>
                                                    </div>
                                                    </div>                                                
                                                </List.Item>
                                                }
                                        />
                                        </div>
                                                <List.Item ref={this.lastMessageRef}>
                                                {(this.state.loading)?(
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: '4px 0' }}>
                                                    <div style={this.theirMessageBubbleStyle}>
                                                    <span style={this.theirMessageContentStyle}><i>{this.state.loadingText}</i></span>
                                                    </div>
                                                </div>
                                                ):null}
                                                </List.Item>
                                    </Content>
                                    <Footer style={{ height: '50px' }}>
                                        <Row>
                                        <Col span={22}>
                                        <Input
                                            value={this.state.inputValue}
                                            onChange={this.handleInputChange}
                                            onPressEnter={
                                                !this.state.loading ? this.handleSendClick : undefined
                                                }
                                        />
                                        </Col>
                                        <Col span={2}>
                                        <Button type="primary" disabled={this.state.loading} onClick={this.handleSendClick}>
                                            Send
                                        </Button>
                                        </Col>
                                        </Row>
                                    </Footer>
                                </Layout>
                                )
                            }
                        </div>
                    </div>
                </Layout>
            </div>
        )

        
        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 0px', marginTop: 50 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth/>
                </UnauthenticatedTemplate>
            </>
        )
    }
}

export default withRouter(VoxCaeli);