import React from "react";
import { Button, Col, Layout, Row, Typography, Tooltip, Select, Radio, Modal } from "antd";
import { EyeFilled } from '@ant-design/icons';
import { MultiSelectDropDown } from "../components/multiSelectDropDown"

const { Content } = Layout;
const { Text } = Typography;

export const NicQuizNewModal = (props) => {
    return (
        <Modal
            title="Select Questions for News in Clues Quiz"
            open={props.isOpen}
            onOk={props.onOk}
            onCancel={props.onCancel}
            onViewUrl={props.onViewUrl}
            getGlobalProperty={props.onGetGlobalProperty}
            setGlobalProperty={props.onSetGlobalProperty}
            onQuestionSelection={props.onQuestionSelection}
            onGenerateNicQuiz={props.onGenerateNicQuiz}
            closable={true}
            width={1200}
            footer={[
                <Button
                    type="primary"
                    onClick={async () => {
                        await props.onGenerateNicQuiz()
                        props.onOk()
                    }}
                    loading={props.getGlobalProperty("nic_quiz_generate_api_running")}
                    style={{
                        width: '100%',
                        marginTop: '15px',
                        display: 'block'
                    }}
                    disabled={
                        props.getGlobalProperty("selected_questions") === null
                        || props.getGlobalProperty("selected_questions").length == 0
                    }
                >
                    Generate Quiz
                </Button>,
            ]}
        >
            <Content>
                <Row>
                    <Col span={3}>
                        <Text style={{
                            fontWeight: "bold",
                        }}>
                            # Questions:
                        </Text>
                        <br />
                        <Select
                            defaultValue={props.getGlobalProperty("max_num_questions")}
                            onChange={(value) => {
                                props.setGlobalProperty("max_num_questions", value)
                            }}
                        >
                            <Option value={5}>{5}</Option>
                            <Option value={15}>{15}</Option>
                            <Option value={20}>{20}</Option>
                            <Option value={25}>{25}</Option>
                            <Option value={30}>{30}</Option>
                        </Select>
                    </Col>
                    <Col span={2}>
                        <Text style={{
                            fontWeight: "bold",
                            marginLeft: '-20px'
                        }}>
                            Language:
                        </Text>
                        <br />
                        <Select
                            style={{
                                marginLeft: '-20px'
                            }}
                            defaultValue={props.getGlobalProperty("target_language")}
                            onChange={(value) => {
                                props.setGlobalProperty("target_language", value)
                            }}
                        >
                            {props.getGlobalProperty("lang_options").map((lang) => {
                                return (
                                    <Option value={lang}>{lang}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                    <Col span={11}>
                        <Text style={{
                            fontWeight: "bold"
                        }}>
                            Question Categories to Fetch:
                        </Text>
                        <br />
                        <MultiSelectDropDown
                            options={props.getGlobalProperty("category_options")}
                            onChange={(e) => {
                                props.setGlobalProperty("selected_categories", e)
                            }}
                        />
                    </Col>
                    <Col span={1}></Col>
                    <Col span={7}>
                        <Text style={{
                            fontWeight: "bold"
                        }}>
                            Publishers to Include:
                        </Text>
                        <br />
                        <MultiSelectDropDown
                            options={props.getGlobalProperty("publisher_options")}
                            onChange={(e) => {
                                props.setGlobalProperty("selected_publishers", e)
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={5}>
                        <Tooltip title="Search for and manually select questions for the quiz">
                            <Button
                                type="primary"
                                onClick={(e) => {   
                                    props.onQuestionSelection("manual")
                                }}
                                style={{
                                    marginTop: '15px',
                                    display: 'block'
                                }}
                                disabled={
                                    props.getGlobalProperty("nic_quiz_generate_api_running")
                                    || props.getGlobalProperty("nic_quiz_automatic_question_search_api_running")
                                }
                                loading={props.getGlobalProperty("nic_quiz_manual_question_search_api_running")}
                            >
                                Select Questions Manually
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col span={6}>
                        <Tooltip title={'Use a random selection of ' + props.getGlobalProperty("max_num_questions") + ' recent questions from all categories and publishers'}>
                            <Button
                                type="primary"
                                onClick={(e) => {   
                                    props.onQuestionSelection("automatic")
                                }}
                                style={{
                                    marginTop: '15px',
                                    display: 'block'
                                }}
                                disabled={
                                    props.getGlobalProperty("nic_quiz_generate_api_running")
                                    || props.getGlobalProperty("nic_quiz_manual_question_search_api_running")
                                }
                                loading={props.getGlobalProperty("nic_quiz_automatic_question_search_api_running")}
                            >
                                Select Questions Automatically
                            </Button>
                        </Tooltip>
                        <br />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Text style={{ paddingBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Selected questions for the quiz</span> ({props.getGlobalProperty("num_questions")} selected out of maximum of {props.getGlobalProperty("max_num_questions")}):
                        </Text>
                        <br />
                        <Text>
                            <br />
                            {
                                props.getGlobalProperty("selected_questions").length > 0 ? (
                                    <Content>
                                        <table style={{ borderSpacing: "1px", borderCollapse: "separate" }}>
                                            <tr style={{ paddingBottom: "4px" }}>
                                                <th style={{ width: "50%", borderBottom: "1px solid black", padding: "3px" }}>Question and Answer</th>
                                                <th style={{ width: "35%", borderBottom: "1px solid black", padding: "3px" }}>Article</th>
                                                <th style={{ width: "12%", borderBottom: "1px solid black", padding: "3px" }}>Source</th>
                                                <th style={{ width: "3%", borderBottom: "1px solid black", padding: "3px", textAlign: "center" }}><EyeFilled /></th>
                                            </tr>
                                            {props.getGlobalProperty("selected_questions").map((question, index) => {
                                                return (
                                                    <tr style={{
                                                        backgroundColor: index % 2 == 0 ? "#eee" : "white"
                                                    }}>
                                                        <td style={{ width: "50%", verticalAlign: "top", padding: "3px" }}>
                                                            <b>Q: </b>{question.question_text}
                                                            <br/>
                                                            <b>A: </b>{question.answers[question.correct_idx].answer_text}
                                                        </td>
                                                        <td style={{ width: "35%", verticalAlign: "top", padding: "3px" }}>
                                                            <Text style={{
                                                                fontStyle: "italic"
                                                            }}>
                                                                {question.article.title}
                                                            </Text>
                                                        </td>
                                                        <td style={{ width: "12%", verticalAlign: "top", padding: "3px" }}>
                                                            <Text style={{
                                                                fontStyle: "italic"
                                                            }}>
                                                                {question.article.source_name}
                                                            </Text>
                                                        </td>
                                                        <td style={{ width: "3%", verticalAlign: "top", padding: "3px" }}>
                                                            <Button
                                                                type="text"
                                                                onClick={() => {
                                                                    props.onViewUrl(question.url);
                                                                }}
                                                            >
                                                                <EyeFilled />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </Content>
                                ) : (
                                    <Text style={{ fontStyle: 'italic' }}>No questions selected</Text>
                                )
                            }
                        </Text>
                    </Col>
                </Row>
                <br/>
                <br/>
                <h3 style={{borderBottom: '1px solid #ddd'}}>
                    Quiz Generation
                </h3>
                <Row>
                    <Col span={8}>
                        <Radio.Group
                            value={props.getGlobalProperty("random_order")}
                            onChange={(e) => {
                                props.setGlobalProperty("random_order", e.target.value)
                            }}
                            style={{
                                marginTop: '15px'
                            }}
                        >
                            <Radio value={true}>Randomize question ordering</Radio>
                            <br />
                            <Radio value={false}>Group questions by category</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </Content>
        </Modal>
    )
}