import {
    Button, Col, Input, Layout, message, Row, Card, Select,Checkbox, Empty,Upload,Image
} from 'antd';
import React from 'react';
import UpCircleOutlined from '@ant-design/icons';
import NavBar from '../components/header';
import {LSTransitionAPI, ImageSuperResolutionAPI} from "../apis/vcs";
import {UploadButton} from '../components/uploadButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;


export default class LSTransition extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            input_image: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
            default_prompt: "Fill in the Empty pixels with clean background and no texts so that it can be used as a wallpaper 4k",
            prompt: null,
            positions: ["center", "bottom"],
            outpaint: false,
            smart_crop: false,
            position: "bottom",
            ls_transition_api_running: false,
            ls_transition_api_response: null,
            image_super_res_api_running: false,
        };
        this.lsTransition = this.lsTransition.bind(this);
        this.imageSuperRes = this.imageSuperRes.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);

      }

    lsTransition = async () => {
        this.setState({
            ls_transition_api_running: true,
            ls_transition_api_response: null,
        })
        this.props.refreshIdToken().then((token) => {
            var prompt = this.state.prompt
            if ((prompt==null) || (prompt === "")) {
                console.log("this.state.prompt is ", this.state.prompt, " so using default prompt: ", this.state.default_prompt)
                prompt = this.state.default_prompt
            }
            const payload = {
                image_url:this.state.input_image,
                prompt: prompt,
                outpaint: this.state.outpaint,
                smart_crop: this.state.smart_crop,
                position: this.state.position,
            }
            var status_code = 200;
            try {
                LSTransitionAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("LS Transition Completed!")
                    }
                    this.setState({
                        ls_transition_api_running: false,
                        ls_transition_api_response: response,
                    })   
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to convert images! Most likely due to high load. Try again :)")
                }
                this.setState({
                    ls_transition_api_running: false,
                })
            }
        })        
    }
    onClickHandler = async () =>  {
        if(this.state.smart_crop==false && this.state.outpaint==false){
            message.error("Please select at least one option!")
        } 
        else {
            this.lsTransition()
        }
    
    }


    imageSuperRes = async (image_url) => {
        const payload = {
            image_url: image_url,
            parameters: {}
        }
        message.loading("Create High Res Images...", 0)
        this.props.refreshIdToken().then((token) => {
            var status_code = 200;
            try {
                ImageSuperResolutionAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Image Super Res Completed!")
                    }
                    this.setState({
                        image_super_res_api_running: false,
                        image_super_res_api_response: response,
                    })
                    window.open(response.message.image_url)
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to res images! Most likely due to high load. Please do not use webp. Try again :)")
                }
                else {
                    message.error("Failed to res images!")
                }
                this.setState({
                    image_super_res_api_running: false,
                })
            }
        })
    }


    
    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <br/>
            <br/>
            <h3> Ask AI to Generate LS Images </h3>
            <Row>
            <Col span={4}>

                    <UploadButton
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.props.idToken}
                    onImUploadChange={(e)=>{
                        if(e.file.status == 'done'){
                            message.success(' Uploaded!')
                            var temp_video_upload_url = e.file.response.image_url
                            this.setState({
                                input_image: temp_video_upload_url
                            }) 
                            //console.log(this.state.input_image)
                        }}}
                />
            </Col>
            <Col span={13}>
            <TextArea rows={2} style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px'}} placeholder="Override default promt..." onChange={(e)=>{
                this.setState({
                    prompt: e.target.value
                })
            }} />
            </Col>
            <Col span={2} >
            <Row>
                <Checkbox onChange={(e)=>{
                    this.setState({
                            smart_crop: e.target.checked
                                })
                    }}>Smart Crop</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={(e)=>{
                    this.setState({
                        outpaint    : e.target.checked
                                })
                    }}>Outpaint</Checkbox>
            </Row>   
            </Col>
                 

            <Col span={2}>
                <Select
                    placeholder="position"
                    style={{
                        width: '100%',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}
                    value={this.state.position}
                    allowClear={true}
                    onChange={(e)=>{
                        this.setState({
                            position: e
                        })
                    }}
                    >
                    {this.state.positions.map((e) => {
                        return <Option
                        value={e}>{e}</Option>
                    })}
                    </Select>
                </Col>


            <Col span={3}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
                onClick={this.onClickHandler}
                 loading={this.state.ls_transition_api_running}
            >
             Submit
            </Button>
            </Col>
            </Row>
            <br>
            </br>
            </Content>
            <br></br>
            <br></br>
            <Content>
                
                <Row style={{overflow: "scroll",justifyContent: 'center',
                                                alignItems: 'center',}}>
                {
                    this.state.ls_transition_api_response == null
                    ? (
                        <Empty
                        style={{
                            paddingTop: "5px",
                            width: "100%",
                        }}
                        />
                    )
                    : 
                    (   
                        
                        //console.log(this.state.ls_transition_api_response.message.images),
                        this.state.ls_transition_api_response.message.images.map((image) => {
                            return (
                                        <Col span={6}>
                                            <Card
                                                hoverable
                                                style={{
                                                width: 240
                                                }}
                                                cover={
                                                    <a
                                                        href={image["image_url"]}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img style={{width: 240}} alt="" src={image["image_url"]} />
                                                    </a>
                                                }
                                            >
                                                <Meta title={image["image_title"]} />
                                                <br></br>
                                                <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block
                                                    onClick={() => {
                                                            console.log("Running super resolution on image..", image["image_url"])
                                                            this.imageSuperRes(image["image_url"])
                                                        }
                                                    }
                                                    loading={this.state.image_super_res_api_running}
                                                    icon={<UpCircleOutlined />}
                                                >
                                                    Super Res!
                                                </Button>
                                            </Card>
                                        </Col>
                                       
                                    
                            )
                                })
                            )
                        }
                </Row>
            </Content>
          </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                    <NavBar
                        authHandler={async (instance) => {await this.authHandler(instance)}}
                        authRefresh={this.authRefresh}
                        signin_name={this.props.username}
                    />
                    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                            {createForm}
                        </div>
                        </Content>
                    <Footer style={{ textAlign: 'center' }}>
                    <img
                        src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                        alt="logo"
                        style={{
                            height:'20px',
                            display:'inline',
                            paddingRight:'10px'
                        }}/>
                        ©2021 Created by TAG AI Team
                    </Footer>
                </Layout>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuth />
            </UnauthenticatedTemplate>
            </>
        )
    }
}
