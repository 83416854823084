import React from "react";
import {
    Button, Col, Divider, Layout, Row, Modal,
    Select, Typography, Empty, InputNumber, Tooltip
} from 'antd';
import 'antd/dist/antd.css';
import TextArea from 'antd/lib/input/TextArea';
import { FontDropDownLite } from "./fontDropDown"

const { Text } = Typography;
const { Content } = Layout;
const { Option } = Select;


export const VideoGeneratorModal = (props) => {

    return (
        <Modal
            title="Speak to AI. Ask it to summarize an article, get you content, or simply submit a URL"
            open={props.isOpen}
            onOk={props.onOk}
            onChangeVideoSetting={props.onChangeVideoSetting}
            getVideoSetting={props.onGetVideoSetting}
            getGlobalProperty={props.onGetGlobalProperty}
            toggleTextOverlayModalParent={props.toggleTextOverlayModalParent}
            videoGenerate={props.videoGenerate}
            updateVideoResponse={props.updateVideoResponse}
            videoUpdate={props.videoUpdate}
            closable={false}
            style={{top: '50px'}}
            width={'98%'}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={props.onOk}
                    disabled={
                        props.getGlobalProperty("video_generate_api_running")
                        | props.getGlobalProperty("video_update_api_running")
                        | props.getGlobalProperty("video_generate_api_response") == null
                    }
                >
                    Review and Edit &gt;&gt;
                </Button>,
            ]}
        >
            <Content>
                <Row style={{ width: '100%' }}>
                    <Col span={15}>
                        <Text
                            style={{
                                marginLeft: '10px',
                                marginRight: '10px'
                            }}
                        > Prompt </Text>
                        <Tooltip trigger={['focus']} title={"Submit a URL or an Article. Speak to AI"} placement="topLeft">
                            <TextArea
                                style={{
                                    fontSize: '13px',
                                    minHeight: '75px'
                                }}
                                placeholder="Submit a link to an article or just the article text :) Speak to AI. "
                                onChange={(e) => {
                                    props.onChangeVideoSetting("url", e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Text
                            style={{
                                marginLeft: '10px',
                            }}
                        > Clips # </Text>
                        <Tooltip trigger={['focus']} title={"How many clips do you want?"} placement="topLeft">
                            <InputNumber
                                style={{
                                    width: '100%',
                                    marginLeft: '5px',
                                    marginRight: '5px'
                                }}
                                min={1} max={5} defaultValue={3}
                                onChange={(e) => {
                                    props.onChangeVideoSetting("num_points", e)
                                }} />
                        </Tooltip>
                    </Col>

                    <Col span={4}>
                        <Row>
                            <Text
                                style={{
                                    marginLeft: '56px',
                                }}
                            > Language & Font
                            </Text>
                        </Row>
                        <FontDropDownLite
                            language={props.getGlobalProperty("language")}
                            language_options={props.getGlobalProperty("lang_options")}
                            fonts={props.getGlobalProperty("language_to_fonts")}
                            second_font={(props.getVideoSetting("font") == null)
                                ? "Archivo-Regular.ttf"
                                : props.getVideoSetting("font")
                            }
                            fonts_to_images={props.getGlobalProperty("fonts_to_images")}
                            font_assets_cdn_prefix={props.getGlobalProperty("font_assets_cdn_prefix")}
                            onLanguageSelect={(e) => {
                                console.log("onLanguageSelect - " + e)
                                var temp_font = props.getGlobalProperty("language_to_fonts")[e][0];
                                props.onChangeVideoSetting("language", e)
                                props.onChangeVideoSetting("font", temp_font)
                            }}
                            secondFontChange={(e) => {
                                console.log("secondFontChange - " + e)
                                props.onChangeVideoSetting("font", e)
                            }}
                        />

                    </Col>

                    <Col span={3}>
                        <Text
                            style={{
                                marginLeft: '45px',
                                marginRight: '5px'
                            }}
                        > Select Theme </Text>
                        <Tooltip trigger={['focus']} title={"Overlay Theme?"} placement="topLeft">
                            <Button
                                onClick={props.toggleTextOverlayModalParent}
                                style={{
                                    width: '100%',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                }}
                            >
                                {props.text_overlay ? props.text_overlay : "Text Overlay"}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>

                <Divider orientation="left"
                    orientationMargin={0}
                    style={{
                        margin: '5px',
                        marginTop: '15px',
                        marginBottom: '15px',
                    }}
                >Select Audio Over Options</Divider>
                <Row>
                    <Col span={6}>
                    </Col>
                    <Col span={4}>
                        <Text
                            style={{
                                marginLeft: '15px',
                                marginRight: '5px'
                            }}
                        > Select Audio / Voiceover: </Text>

                        <Tooltip trigger={['focus']} title={"Type of voiceover for final video?"} placement="topLeft">
                            <Select
                                defaultValue="speech"
                                placeholder="Voice Over"
                                style={{
                                    width: '100%',
                                    paddingLeft: '5px',
                                    paddingRight: '5px'
                                }}
                                onChange={(e) => {
                                    props.onChangeVideoSetting("voice_over", e)
                                }}
                            >
                                {props.getVideoSetting("voice_over_options").map((e) => {
                                    return <Option
                                        value={e}>{e}</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </Col>
                    <Col span={3}>
                        <Text
                            style={{
                                marginLeft: '15px',
                                marginRight: '5px'
                            }}
                        > Gender of the voice </Text>

                        <Tooltip trigger={['focus']} title={"Gender of voiceover for final video?"} placement="topLeft">
                            <Select
                                placeholder="Gender"
                                style={{
                                    width: '100%',
                                    paddingLeft: '5px',
                                    paddingRight: '5px'
                                }}
                                value={props.gender}
                                allowClear={true}
                                onChange={(e) => {
                                    props.onChangeVideoSetting("gender", e)
                                }}
                            >
                                {props.getVideoSetting("genders").map((e) => {
                                    return <Option
                                        value={e}>{e}</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </Col>
                    <Col span={3}>
                        <Button style={{ width: '100%', marginLeft: '5px', marginTop: '21px' }}
                            type="primary"
                            loading={props.getGlobalProperty("video_generate_api_running")}
                            disabled={props.getGlobalProperty("video_update_api_running")}
                            onClick={props.videoGenerate}
                        >Generate</Button>
                    </Col>
                </Row>
                <Divider orientation="left"
                    orientationMargin={0}
                    style={{
                        margin: '5px',
                        marginTop: '15px',
                    }}
                >Change Voice Over Text (if needed) </Divider>

                <Row style={{
                    width: '100%',
                    height: '100%',
                }}>
                    {props.getGlobalProperty("video_generate_api_response") == null ?
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{
                                width: '100%',
                                height: '100%',
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}
                        /> :
                        <>
                            <Col span={22}>
                                <Tooltip trigger={['focus']} title={"Edit voice over text"} placement="topLeft">
                                    <TextArea
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '100px'
                                        }}
                                        placeholder="Edit voice over text"
                                        value={props.getGlobalProperty("video_generate_api_response").message.spec["audio_text"]}
                                        onChange={(e) => {
                                            props.updateVideoResponse(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col span={2}>
                                <Button style={{ width: '100%', marginLeft: '5px' }}
                                    type="primary"
                                    loading={props.getGlobalProperty("video_update_api_running")}
                                    disabled={props.getGlobalProperty("video_generate_api_running")}
                                    onClick={props.videoUpdate}
                                >Update</Button>
                            </Col>
                        </>
                    }
                </Row>

                <Divider orientation="left"
                    orientationMargin={0}
                    style={{
                        margin: '5px',
                        marginTop: '15px',
                    }}
                >What did AI write back? </Divider>
                <Row style={{
                    width: '100%',
                    height: '100%',
                }}>
                    {props.getGlobalProperty("video_generate_api_response") == null ?
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{
                                width: '100%',
                                height: '100%',
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}
                        /> :
                        <>
                            <Col span={22}>
                                <Tooltip trigger={['focus']} title={"What points did the AI summarize?"} placement="topLeft">
                                    <TextArea
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '100px'
                                        }}
                                        placeholder="What points did the AI summarize?"
                                        value={props.getGlobalProperty("video_generate_api_response").message.points}
                                        disabled={true}
                                    />
                                </Tooltip>
                            </Col>
                        </>
                    }
                </Row>
            </Content>
        </Modal>
    )
}
