import React, { useState } from "react";
import { Button, Modal, Row, Col, Layout, Typography, Empty, Checkbox, Popconfirm } from "antd";
import { EyeFilled, MinusCircleOutlined } from '@ant-design/icons';
const { Content } = Layout;
const { Text } = Typography;


export const QuestionSelectionModal = (props) => {
    const [sel_count, setSelCount] = useState(0);
    
    return (
        <Modal
            title={
                <div>
                    Select Questions ({props.fetchedQuestions.length} available,&nbsp;
                    <span style={{ color: sel_count >= props.maxSelectionCount ? 'red' : 'black'}}>{sel_count} selected out of maximum of {props.maxSelectionCount}</span>):
                </div>
            }
            centered
            open={props.isOpen}
            onOk={props.onOk}
            onCancel={props.onOk}
            onViewUrl={props.onViewUrl}
            onViewArticle={props.onViewArticle}
            fetchedQuestions={props.fetchedQuestions}
            questionsOnSelect={props.questionsOnSelect}
            selectedQuestions={props.selectedQuestions}
            rejectedQuestions={props.rejectedQuestions}
            maxSelectionCount={props.maxSelectionCount}
            api_running={props.api_running}
            closable={false}
            width={1200}
            footer={[
                <Button key="done" type="primary" onClick={props.onOk}>
                    Done
                </Button>,
            ]}
        >
            <Content>
                <Row>
                    <Col span={24}>
                        {props.fetchedQuestions.length == 0 ? (
                            <Empty
                                style={{
                                    paddingTop: "5px",
                                    width: "100%",
                                }}
                            />
                        ) : (
                            <div>
                                <div
                                    style={{
                                        height: "500px",
                                        overflow: "auto"
                                    }}
                                >
                                    <Checkbox.Group
                                        onChange={(e) => {
                                            setSelCount(e.filter(q => !props.rejectedQuestions.indexOf(q) == -1).length)
                                            props.questionsOnSelect(e)
                                            console.log(e)
                                        }}
                                        style={{
                                            paddingTop: "5px",
                                        }}
                                        optionType="button"
                                    >
                                        <table style={{ borderSpacing: "1px", borderCollapse: "separate" }}>
                                            <tr style={{ paddingBottom: "4px" }}>
                                                <th style={{ width: "50%", borderBottom: "1px solid black", padding: "3px" }}>Question and Answer</th>
                                                <th style={{ width: "2%", borderBottom: "1px solid black", padding: "3px", textAlign: "center" }}><MinusCircleOutlined /></th>
                                                <th style={{ width: "34%", borderBottom: "1px solid black", padding: "3px" }}>Article</th>
                                                <th style={{ width: "12%", borderBottom: "1px solid black", padding: "3px" }}>Source</th>
                                                <th style={{ width: "2%", borderBottom: "1px solid black", padding: "3px", textAlign: "center" }}><EyeFilled /></th>
                                            </tr>
                                            {props.fetchedQuestions.map((question, index) => {
                                                return (
                                                    <tr style={{
                                                        backgroundColor: index % 2 == 0 ? "#eee" : "white",
                                                        display: props.rejectedQuestions.indexOf(question) == -1 ? "table-row" : "none"
                                                    }}>
                                                        <td style={{ width: "50%", verticalAlign: "top", padding: "3px" }}>
                                                            <Checkbox
                                                                value={question}
                                                                style={{
                                                                    paddingBottom: "4px",
                                                                }}
                                                                // disable this checkbox if it has not been checked already and the max selection count has been reached
                                                                disabled={props.selectedQuestions && !props.selectedQuestions.includes(question) && sel_count >= props.maxSelectionCount}
                                                            >
                                                                <b>Q: </b>{question.question_text}
                                                                <br/>
                                                                <b>A: </b>{question.answers[question.correct_idx].answer_text}
                                                            </Checkbox>
                                                        </td>
                                                        <td style={{ width: "2%", verticalAlign: "top", padding: "3px" }}>
                                                            <Popconfirm
                                                                title="Are you sure you want to reject this question?"
                                                                onConfirm={() => {
                                                                    props.onRejectQuestion(question);
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button type="text">
                                                                    <MinusCircleOutlined />
                                                                </Button>
                                                            </Popconfirm>
                                                        </td>
                                                        <td style={{ width: "34%", verticalAlign: "top", padding: "3px" }}>
                                                            <Text style={{
                                                                fontStyle: "italic"
                                                            }}>
                                                                {question.article.title}
                                                            </Text>
                                                        </td>
                                                        <td style={{ width: "12%", verticalAlign: "top", padding: "3px" }}>
                                                            <Text style={{
                                                                fontStyle: "italic"
                                                            }}>
                                                                {question.article.source_name}
                                                            </Text>
                                                        </td>
                                                        <td style={{ width: "2%", verticalAlign: "top", padding: "3px" }}>
                                                            <Button
                                                                type="text"
                                                                onClick={() => {
                                                                    props.onViewUrl(question.url);
                                                                }}
                                                            >
                                                                <EyeFilled />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </Checkbox.Group>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Content>
        </Modal>
    );
};
