import { Button, Col, Input, Layout, message, Row, Checkbox } from 'antd';
import React from 'react';
import NavBar from '../components/header';
import { WikipediaListAPI, WikipediaSearchAPI } from "../apis/wikiSHPs";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Content, Footer } = Layout;
const { TextArea } = Input;

export default class WikiTools extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            id_token: null,

            list_page_url: "",
            depth: "1",
            output_urls: "",
            output_urls_count: 0,

            list_page_url: "",
            wikipedia_list_api_running: false,

            search_query: "",
            search_method: "",
            minimum_score: 0.85,
            search_results_max_count: 20,
            wikipedia_search_api_running: false,
        };

        this.getWikipediaListURLs = this.getWikipediaListURLs.bind(this);
        this.getWikipediaSearchResults = this.getWikipediaSearchResults.bind(this);
    }

    getWikipediaListURLs = async () => {
        message.loading("Getting Wikipedia list URLs...", 0)
        this.setState({
            output_urls: "",
            output_urls_count: 0,
            wikipedia_list_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            const payload = {
                list_page_url: this.state.list_page_url,
                depth: this.state.depth,
            }
            WikipediaListAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("WikipediaListAPI API failed!")
                    this.setState({
                        wikipedia_list_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("WikipediaListAPI API success!")
                    this.setState({
                        output_urls: response.message.urls.join("\n"),
                        output_urls_count: response.message.urls.length,
                        wikipedia_list_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to get Wikipedia list!")
                this.setState({
                    wikipedia_list_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to get Wikipedia list!", error)
            this.setState({
                wikipedia_list_api_running: false
            })
        })
    }


    getWikipediaSearchResults = async (search_method) => {
        message.loading("Getting Wikipedia search results...", 0)
        this.setState({
            output_urls: "",
            output_urls_count: 0,
            search_method: search_method,
            wikipedia_search_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            const payload = {
                query: this.state.search_query,
                search_method: search_method,
                minimum_score: this.state.minimum_score,
                limit: this.state.search_results_max_count,
            }
            WikipediaSearchAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("WikipediaSearchAPI API failed!")
                    this.setState({
                        wikipedia_search_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("WikipediaSearchAPI API success!")
                    let search_results_summary = ""
                    if (response.message.results.length > 0) {
                        for (let i = 0; i < response.message.results.length; i++) {
                            search_results_summary += response.message.results[i].score.toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 }) + ": " + response.message.results[i].name + "\n"
                        }
                    }
                    this.setState({
                        output_urls: search_results_summary,
                        output_urls_count: response.message.results.length,
                        wikipedia_search_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to get Wikipedia search results!")
                this.setState({
                    wikipedia_search_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to get Wikipedia search results!", error)
            this.setState({
                wikipedia_search_api_running: false
            })
        })
    }

    
    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }
    
    render() {
        console.log("nic_quiz: ", this.state.nic_quiz)
        const createForm = (
            <div>          
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Input Wikipedia List Page URL / Search Query
                        </h3>
                        <br />
                        <Row>
                            <Col span={11}>
                                <Input
                                    placeholder="Paste Wikipedia list page URL"
                                    value={this.state.list_page_url}
                                    onChange={(e) => {
                                        this.setState({
                                            list_page_url: e.target.value
                                        })
                                    }}
                                    disabled={this.state.wikipedia_list_api_running}
                                />
                            </Col>
                            {/* <Col span={12}>
                                <Input
                                    placeholder="Depth to search"
                                    value={this.state.depth}
                                    onChange={(e) => {
                                        this.setState({
                                            depth: e.target.value
                                        })
                                    }}
                                    disabled={this.state.wikipedia_list_api_running}
                                />
                            </Col> */}
                            <Col span={2}>
                            </Col>
                            <Col span={11}>
                                <Input
                                    placeholder="Search Query"
                                    value={this.state.search_query}
                                    onChange={(e) => {
                                        this.setState({
                                            search_query: e.target.value
                                        })
                                    }}
                                    disabled={this.state.wikipedia_search_api_running}
                                />
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={13}>
                            </Col>
                            <Col span={2}>
                                Max Results:<br />
                                <Input
                                    placeholder="Result Count"
                                    value={this.state.search_results_max_count}
                                    onChange={(e) => {
                                        this.setState({
                                            search_results_max_count: Math.min(1000, e.target.value)
                                        })
                                    }}
                                    disabled={this.state.wikipedia_search_api_running}
                                    style={{width: '100px'}}
                                />
                            </Col>
                            <Col span={1}>
                            </Col>
                            <Col span={8}>
                                Minimum score:<br />
                                <Input
                                    placeholder="Minimum Score"
                                    value={this.state.minimum_score}
                                    onChange={(e) => {
                                        this.setState({
                                            minimum_score: e.target.value
                                        })
                                    }}
                                    disabled={this.state.wikipedia_search_api_running}
                                    style={{width: '100px'}}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={11}>
                                <Button
                                    type="primary"
                                    onClick={this.getWikipediaListURLs}
                                    loading={this.state.wikipedia_list_api_running}
                                    disabled={this.state.list_page_url==""}
                                >
                                    Wikipedia List URLs
                                </Button>
                            </Col>
                            <Col span={2}>
                            </Col>
                            <Col span={3}>
                                <Button
                                    type="primary"
                                    onClick={
                                        (e) => {
                                            this.getWikipediaSearchResults("plaintext")
                                        }
                                    }
                                    loading={this.state.wikipedia_search_api_running && this.state.search_method == "plaintext"}
                                    disabled={
                                        (this.state.search_query=="") ||
                                        (this.state.wikipedia_search_api_running && this.state.search_method != "plaintext")
                                    }
                                >
                                    Wikipedia Search
                                </Button>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={3}>
                                <Button
                                    type="primary"
                                    onClick={
                                        (e) => {
                                            this.getWikipediaSearchResults("theme")
                                        }
                                    }
                                    loading={this.state.wikipedia_search_api_running && this.state.search_method == "theme"}
                                    disabled={
                                        (this.state.search_query=="") ||
                                        (this.state.wikipedia_search_api_running && this.state.search_method != "theme")
                                    }
                                >
                                    Theme Search
                                </Button>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={3}>
                                <Button
                                    type="primary"
                                    onClick={
                                        (e) => {
                                            this.getWikipediaSearchResults("article")
                                        }
                                    }
                                    loading={this.state.wikipedia_search_api_running && this.state.search_method == "article"}
                                    disabled={
                                        (this.state.search_query=="") ||
                                        (this.state.wikipedia_search_api_running && this.state.search_method != "article")
                                    }
                                >
                                    RAG Search
                                </Button>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Output (Total {this.state.output_urls_count} results found)
                        </h3>
                        <br />
                        <Row>
                            <Col span={24}>
                                <TextArea 
                                    rows={30}
                                    style={{ width: 1200 }}
                                    placeholder="Fill in the form above and click the button"
                                    value={this.state.output_urls}
                                />
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.state.signin_name}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
