const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;


export async function AmpStoryGenerateAPI(payload, token=null) {
    return await postRequestAmpStoryAPI("generate", payload, token);
}


export async function AmpStoryViewAPI(payload, token=null) {
    return await postRequestAmpStoryAPI("view", payload, token);
}


export async function AmpStoryCreateAPI(payload, token=null) {
    return await postRequestAmpStoryAPI("create", payload, token);
}


export async function AmpStoryUpdateAPI(payload, token=null) {
    return await postRequestAmpStoryAPI("update", payload, token);
}

export async function AmpStorySearchAPI(payload, token=null) {
    return await postRequestAmpStoryAPI("search", payload, token);
}


export async function AmpStoryPublishAPI(amp_story_id, token=null) {
    return await getRequestAmpStoryAPI("publish", amp_story_id, token);
}


export async function AmpStoryDeleteAPI(payload, token=null) {
    var id = payload.story.id;
    return await getRequestAmpStoryAPI("delete", id, token);
}


export async function AmpStoryPostPublishAPI(payload, token=null) {
    return await postRequestAmpStoryAPI("publishstory", payload, token);
}


export async function AmpStoryVideoGenerateAPI(payload, token=null) {
    return await postRequestAmpStoryAPI("videogenerate", payload, token);
}


export async function postRequestAmpStoryAPI(api_function, payload, token=null, method="POST"){
    let request={
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    console.log(payload)
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/ampstory/" + api_function, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}


export async function getRequestAmpStoryAPI(api_function, id, token=null){
    let request={
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
    }
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/ampstory/" + api_function + "/" + id, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}
