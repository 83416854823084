export function ArticleHTML(article) {
    const article_headline = article.title;
    // replace all \n with <br>
    const article_text = article.text.replace(/\n/g, "<br>");
    const article_publisher_image = "https://aicontentsa.azureedge.net/prod/assets/publishers/logo/LP-logo-24x24.jpg"
    const article_banner_image_url = article.banner_image_url;
    const html_preview = `
        <style>
        html,
        body {
        padding: 0;
        margin: 0;
        }
        * {
        font-family: "Roboto", sans-serif;
        }
        .main {
        padding-bottom: 60px;
        }
        .container {
        padding: 16px;
        box-sizing: border-box;
        }
        .center {
        text-align: center;
        }
        .author-info {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.6);
        }
        .dot {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.6);
        margin: 0 3px;
        }
        .container h1 {
        font-size: 18px;
        font-weight: 700;
        line-height: 22.4px;
        }
        .container .description {
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: rgba(41, 41, 41, 0.5);
        }
        .container .banner-image {
        width: 100%;
        height: auto;
        margin: 16px 0;
        }
        .article p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #292929;
        }
        .article img {
        max-width: 100% !important;
        height: auto !important;
        }
        .article figure {
        width: auto !important;
        }
        .redirect-button {
        background: #226dff;
        box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        border: none;
        padding: 10px 16px;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        margin-top: 14px;
        }
        .ad-slot-300x250 {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        min-height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        }
        .ad-slot-320x50 {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.05);
        min-height: 50px;
        }
        .ad-slot-floating {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 320px;
        }

        .related-container h2 {
        padding: 20px 16px;
        font-size: 20px;
        margin: 0;
        font-weight: 700;
        }
        .related-box {
        margin-bottom: 16px;
        padding: 20px 16px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .related-box a {
        text-decoration: none;
        }
        .related-box h3 {
        font-size: 16px;
        margin: 12px 0;
        color: initial;
        }
        .related-box .related-img {
        width: 100%;
        height: auto;
        border-radius: 18px;
        }
        .related-box .related-img-cover {
        width: 100%;
        aspect-ratio: 16/9;
        background-repeat: no-repeat;
        background-position: center 38%;
        }
        .article-author-info {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;
        margin: 12px 0;
        color: #0009;
        }
        .article-author-info img {
        width: 16px;
        margin-right: 8px;
        }
        </style>

        <!DOCTYPE html>
        <!-- saved from url=(0091)https://trends.glance.com/food/IN/en/feedpost/lentil-variety-for-the-week.html?utm_medium=r -->
        <html lang="en"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta name="description" content="${article_headline}">
            <title>${article_headline}</title>
        <body>
            <div class="main">
            <div class="container">
                <h1>${article_headline}</h1>
                <div class="author-info">
                Article by&nbsp;&nbsp;
                <img src="${article_publisher_image}" alt="publisher_logo">
                <b>&nbsp;&nbsp;FeedPost</b>
                <div class="dot"></div>
                3 min read
                </div>
                <p class="description"></p>
                <img src="${article_banner_image_url}" alt="banner-image" class="banner-image">
                <div class="article">
                ${article_text}
                </div>
            </div>
            </div>
            </body>
        </html>
    `;
    console.log(html_preview)
    return html_preview;
}
