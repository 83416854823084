import React from "react";
import { Button, Col, Input, Layout, Row, Typography, Popconfirm, Radio, Modal, message, Select } from "antd";
import { DeleteFilled, MinusCircleOutlined, UpSquareTwoTone, DownSquareTwoTone } from '@ant-design/icons';

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

export const NicQuizEditorModal = (props) => {
    const validateQuestions = () => {
        let valid = true;
        var msg;
        props.editing_questions.map((question, question_index) => {
            if (question.question_text === undefined || question.question_text === "") {
                msg = "Question text is required for question " + (question_index+1);
                valid = false;
            }
            if (question.title === undefined || question.title === "") {
                msg = "Title is required for question " + (question_index+1);
                valid = false;
            }
            if (question.fact === undefined || question.fact === "") {
                msg = "Fact is required for question " + (question_index+1);
                valid = false;
            }
            if (question.article.source_name === undefined || question.article.source_name === "") {
                msg = "Source name is required for question " + (question_index+1);
                valid = false;
            }
            if (question.article.category === undefined || question.article.category === null) {
                msg = "Category is required for question " + (question_index+1);
                valid = false;
            }
            question.answers.map((answer, answer_index) => {
                if (answer.answer_text === undefined || answer.answer_text === "") {
                    msg = "Answer text is required for question " + (question_index+1) + ", answer " + (answer_index+1);
                    valid = false;
                }
            })
            if (question.question_image_url === undefined || question.question_image_url === "") {
                msg = "Question image is required for question " + (question_index+1);
                valid = false;
            }
        })
        if (!valid) {
            message.destroy();
            message.error(msg);
        }
        return valid;
    }

    return (
        <Modal
            title="Edit Quiz"
            open={props.isOpen}
            onOk={props.onOk}
            onCancel={props.onCancel}
            onDeleteQuestion={props.onDeleteQuestion}
            onChangeQuestion={props.onChange}
            onChangeQuestionArticle={props.onChangeQuestionArticle}
            onChangeAnswer={props.onChangeAnswer}
            onReplaceImage={props.onReplaceImage}
            toggleImageSearchModal={props.toggleImageSearchModal}
            onCropImage={props.onCropImage}
            toggleImageCroppingModal={props.toggleImageCroppingModal}
            onAddBlankQuestion={props.onAddBlankQuestion}
            onGenerateQuestion={props.onGenerateQuestion}
            onMoveQuestion={props.onMovePage}
            category_options={props.category_options}
            editing_quiz={props.editing_quiz}
            editing_questions={props.editing_questions}
            image_cdn_prefix={props.image_cdn_prefix}
            closable={false}
            width={1200}
            footer={[
                <Button
                    type="primary"
                    style={{float: "left"}}
                    onClick={async () => {
                        await props.onAddBlankQuestion()
                    }}
                    disabled={props.nic_quiz_generate_question_api_running}
                >
                    Add a Blank Question
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => {
                        if (validateQuestions()) {
                            props.onOk()
                        }
                    }}
                    disabled={props.nic_quiz_generate_question_api_running}
                >
                    Done
                </Button>,
            ]}
        >
            <Content>
                {props.editing_quiz ? props.editing_questions.map((question, question_index) => {
                    return (
                        <div>
                            <Row>
                                <Col span={24}>
                                    <Text>
                                        <b>Question {question_index+1}</b><br />
                                        {question.article.title == "" ? (
                                            <div>
                                                <Text>
                                                    <b>Article URL:</b>
                                                </Text>
                                                <TextArea
                                                    rows={1}
                                                    value={question.article.url}
                                                    placeholder="Optional article URL to link to from the quiz (required to use the question generation button)"
                                                    onChange={(e) => {
                                                        props.onChangeQuestionArticle("url", question_index, e.target.value)
                                                    }}
                                                    style={{marginBottom: '10px'}}
                                                />
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        marginTop: '2px',
                                                        marginBottom: '10px'
                                                    }}
                                                    onClick={() => {
                                                        if (question.article.url == "") {
                                                            message.destroy();
                                                            message.error("Article URL is required to use the question generation button");
                                                        } else {
                                                           props.onGenerateQuestion(question_index)
                                                        }
                                                    }}
                                                    loading={props.nic_quiz_generate_question_api_running}
                                                >
                                                    Generate Question from Article URL
                                                </Button>
                                            </div>
                                        ) : (
                                            <>
                                            From article: <a href={question.article.url} target="_blank">{question.article.title}</a>
                                            </>
                                        )}
                                        
                                    </Text>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '10px'}}>
                                <Col span={1}>
                                    <Popconfirm
                                        title="Do you really want to remove this question from the quiz?"
                                        onConfirm={(e) => {
                                            props.onDeleteQuestion(question_index)
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            type="text"
                                        >
                                            <DeleteFilled />
                                        </Button>
                                    </Popconfirm>
                                    <br />
                                    <Popconfirm
                                        title="Are you sure you want to reject this question?"
                                        onConfirm={() => {
                                            props.onRejectQuestion(question);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="text">
                                            <MinusCircleOutlined />
                                        </Button>
                                    </Popconfirm>
                                    <br />
                                    <br />
                                    <br />
                                    <Button
                                        type="text"
                                        disabled={question_index === 0}
                                        onClick={() => props.onMoveQuestion('up', question_index)}
                                    >
                                        <UpSquareTwoTone />
                                    </Button>
                                    <br />
                                    <Button
                                        type="text"
                                        disabled={question_index === props.editing_questions.length - 1}
                                        onClick={() => props.onMoveQuestion('down', question_index)}
                                    >
                                        <DownSquareTwoTone />
                                    </Button>
                                </Col>
                                <Col span={8}>
                                    <Text style={{paddingTop: '30px'}}>
                                        <b>Question</b>
                                    </Text>
                                    <TextArea
                                        rows={2}
                                        value={question.question_text}
                                        onChange={(e) => {
                                            props.onChangeQuestion("question_text", question_index, e.target.value)
                                        }}
                                        style={{marginBottom: '10px'}}
                                    />
                                    <Text style={{marginTop: '10px'}}>
                                        <b>Title</b>
                                    </Text>
                                    <TextArea
                                        rows={1}
                                        value={question.title}
                                        onChange={(e) => {
                                            props.onChangeQuestion("title", question_index, e.target.value)
                                        }}
                                        style={{marginBottom: '10px'}}
                                    />
                                    <Text style={{marginTop: '10px'}}>
                                        <b>Fact</b>
                                    </Text>
                                    <TextArea
                                        rows={3}
                                        value={question.fact}
                                        onChange={(e) => {
                                            props.onChangeQuestion("fact", question_index, e.target.value)
                                        }}
                                    />
                                    {question.article.title == "" || question.article.cardpress_id == null ? (
                                        <div style={{marginTop: '10px'}}>
                                            <Text>
                                                <b>Source Name</b>
                                            </Text>
                                            <TextArea
                                                rows={1}
                                                value={question.article.source_name}
                                                onChange={(e) => {
                                                    props.onChangeQuestionArticle("source_name", question_index, e.target.value)
                                                }}
                                                style={{marginBottom: '10px'}}
                                            />
                                            <Text>
                                                <b>Category</b>
                                            </Text>
                                            <br />
                                            <Select
                                                value={question.article.category}
                                                onChange={(e) => {
                                                    props.onChangeQuestionArticle("category", question_index, e)
                                                }}
                                                style={{ width: "300px" }}
                                            >
                                                {props.category_options.map((category) => {
                                                    return (
                                                        <Option value={category}>{category}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </Col>
                                <Col span={5} style={{paddingLeft: '20px'}}>
                                    <Text style={{marginTop: '10px'}}>
                                        <b>Answers</b>
                                    </Text>
                                    <Radio.Group
                                        name={"answers-"+question_index}
                                        onChange={(e) => {
                                            question.answers.map((answer, answer_index) => {
                                                if (answer_index == e.target.value) {
                                                    props.onChangeAnswer("is_correct", question_index, answer_index, true)
                                                    props.onChangeQuestion("correct_idx", question_index, answer_index, e.target.value)
                                                } else {
                                                    props.onChangeAnswer("is_correct", question_index, answer_index, false)
                                                }
                                            })
                                        }}
                                        value={question.correct_idx}
                                        defaultValue={question.correct_idx}
                                    >
                                        {question.answers.map((answer, answer_index) => {
                                            return (
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <Radio
                                                                    name={"answers-"+question_index}
                                                                    value={answer_index}
                                                                >
                                                                    {answer_index+1}:
                                                                </Radio>
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    id={"input-"+question_index+"-"+answer_index}
                                                                    rows={1}
                                                                    value={answer.answer_text}
                                                                    onChange={(e) => {
                                                                        props.onChangeAnswer("answer_text", question_index, answer_index, e.target.value)
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )                                                    
                                        })}
                                    </Radio.Group>
                                </Col>
                                <Col span={5} style={{paddingLeft: '20px'}}>
                                    <Text style={{marginTop: '10px'}}>
                                        <b>Question Image</b>
                                    </Text>
                                    <br />
                                    <Button
                                        type="primary"
                                        style={{
                                            marginTop: '2px',
                                            marginBottom: '10px'
                                        }}
                                        onClick={() => {
                                            props.onReplaceImage(question_index, 'question')
                                            props.toggleImageSearchModal()
                                        }}
                                    >
                                        Select Image
                                    </Button>
                                    <br />
                                    <Button
                                        type="primary"
                                        style={{
                                            marginTop: '2px',
                                            marginBottom: '10px'
                                        }}
                                        onClick={() => {
                                            props.onCropImage(question_index, "question")
                                            props.toggleImageCroppingModal()
                                        }}
                                        disabled={question.question_image_url == ""}
                                    >
                                        Re-crop
                                    </Button>
                                    <Popconfirm
                                        title="Also use the unblurred version of this image for the answer?"
                                        onConfirm={() => {
                                            props.onBlurImage(question_index, true)
                                        }}
                                        onCancel={() => {
                                            props.onBlurImage(question_index, false)
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            type="primary"
                                            style={{
                                                marginLeft: '4px',
                                                marginTop: '2px',
                                                marginBottom: '10px'
                                            }}
                                            disabled={question.question_image_url == ""}
                                        >
                                            Blur
                                        </Button>
                                    </Popconfirm>
                                    <div>
                                        <img src={question.question_image_url} style={{width: '100%'}} />
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '4px',
                                            width: '100%',
                                            textAlign: 'right',
                                            fontStyle: 'italic',
                                            fontSize: '10px'
                                        }}
                                    >
                                        <Text>
                                            {question.question_image_attribution}
                                        </Text>
                                    </div>
                                </Col>
                                <Col span={5} style={{paddingLeft: '20px'}}>
                                    <Text style={{marginTop: '10px'}}>
                                        <b>Answer Image</b>
                                    </Text>
                                    <br />
                                    <Button
                                        type="primary"
                                        style={{
                                            marginTop: '2px',
                                            marginBottom: '10px'
                                        }}
                                        onClick={() => {
                                            props.onChangeQuestion("show_answer_image", question_index, true)
                                            props.onReplaceImage(question_index, "answer")
                                            props.toggleImageSearchModal()
                                        }}
                                    >
                                        Select Image
                                    </Button>
                                    <Button
                                        type="primary"
                                        style={{
                                            marginLeft: '4px',
                                            marginTop: '2px',
                                            marginBottom: '10px'
                                        }}
                                        onClick={() => {
                                            props.onChangeQuestion("show_answer_image", question_index, false)
                                        }}
                                    >
                                        No Image
                                    </Button>
                                    <br />
                                    <Button
                                        type="primary"
                                        style={{
                                            marginTop: '2px',
                                            marginBottom: '10px'
                                        }}
                                        onClick={() => {
                                            props.onCropImage(question_index, "answer")
                                            props.toggleImageCroppingModal()
                                        }}
                                        disabled={!question.show_answer_image}
                                    >
                                        Re-crop
                                    </Button>
                                    {question.show_answer_image ? (
                                        <div>
                                            <img src={question.answer_image_url} style={{width: '100%'}} />
                                            <div
                                                style={{
                                                    marginTop: '4px',
                                                    width: '100%',
                                                    textAlign: 'right',
                                                    fontStyle: 'italic',
                                                    fontSize: '10px'
                                                }}
                                            >
                                                <Text>
                                                    {question.answer_image_attribution}
                                                </Text>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ textAlign: 'center', fontStyle: 'italic' }}>
                                            No image selected
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <br />
                        </div>
                    )
                }) : null}
            </Content>
        </Modal>
    )
}