import React from "react";
import { Button, Col, Input, Layout, Row, Typography, Tooltip, Popconfirm, Modal } from "antd";
import { DeleteFilled } from '@ant-design/icons';

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;


export const AmpEditorModal = (props) => {
    const movePage = (direction, page_index) => {
        props.onMovePage(direction, page_index);
    };

    return (
        <Modal
            title="Edit AMP Story"
            centered
            open={props.isOpen}
            onOk={props.onOk}
            onCancel={props.onCancel}
            onDeletePage={props.onDeletePage}
            onChange={props.onChange}
            onReplaceImage={props.onReplaceImage}
            toggleImageSearchModal={props.toggleImageSearchModal}
            editing_pages={props.editing_pages}
            image_cdn_prefix={props.image_cdn_prefix}
            closable={false}
            width={1200}
            footer={[
                <Button key="done" type="primary" onClick={props.onOk}>
                    Done
                </Button>,
            ]}
        >
            <Content>
                {props.editing_pages ? (
                    props.editing_pages.map((page, page_index) => {
                        return (
                            <>
                            <Row style={{ width: '100%' }}>
                                <Col span={1}>
                                    <Popconfirm
                                        title="Do you really want to remove this page ?"
                                        onConfirm={(e) => {
                                            props.onDeletePage(page_index)
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            type="text"
                                        >
                                            <DeleteFilled />
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={5}>
                                    <Text>
                                        Heading
                                    </Text>
                                    <Tooltip trigger={['focus']} title={"Add heading"} placement="topLeft">
                                        <TextArea
                                            style={{
                                                fontSize: '13px',
                                                minHeight: '75px'
                                            }}
                                            value={page.heading}
                                            onChange={(e) => {
                                                props.onChange('heading', page_index, e)
                                            }}
                                        />

                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Text>
                                        Text
                                    </Text>
                                    <Tooltip trigger={['focus']} title={"Add text"} placement="topLeft">
                                        <TextArea
                                            style={{
                                                fontSize: '13px',
                                                minHeight: '75px'
                                            }}
                                            value={page.text}
                                            onChange={(e) => {
                                                props.onChange('text', page_index, e)
                                            }}
                                        />
                                    </Tooltip>
                                </Col>
                                <Col span={6}>
                                    <img
                                        style={{
                                            height: 140,
                                            padding: "5px",
                                        }}
                                        alt=""
                                        src={page.image_url}
                                        />

                                    {page.image_url.startsWith(props.image_cdn_prefix)
                                        ? (
                                            <>
                                            </>
                                        ) : (
                                            <>
                                            <br />
                                            Uploaded Image Source
                                            <br />
                                            <TextArea
                                                style={{
                                                    fontSize: '13px',
                                                    minHeight: '20px'
                                                }}
                                                value={page.image_source}
                                                onChange={(e) => {
                                                    props.onChange('image_source', page_index, e)
                                                }}
                                            />
                                        </>
                                    )
                                        }

                                    <Button
                                        onClick={() => {
                                            props.onReplaceImage(page_index)
                                            props.toggleImageSearchModal()
                                        }}
                                        style={{
                                            width: '100%',
                                            overflow: 'clip'
                                        }}
                                    >
                                        Replace Image
                                    </Button>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        disabled={page_index === 0}
                                        onClick={() => movePage('up', page_index)}>
                                        Move Up
                                    </Button>
                                    <Button
                                        disabled={page_index === props.editing_pages.length - 1}
                                        onClick={() => movePage('down', page_index)}>
                                        Move Down
                                    </Button>
                                </Col>
                            </Row>
                            </>
                        )})
                    ) : (
                        <>
                        None
                        </>
                    )
                }
            </Content>
        </Modal>
    );
};