import {
    Button, Col, Input, Layout, message, Row, Card, Select,Checkbox, Empty,Upload,Image,Space, Table
} from 'antd';
import React from 'react';
import UpCircleOutlined from '@ant-design/icons';
import NavBar from '../components/header';
import {TranscriptionAPI} from "../apis/vcs";
import {getTranscribeHistoricalData} from "../apis/vcs"; 
import {UploadButton} from '../components/uploadButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
import ReactPlayer from 'react-player';

const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

import { Typography } from 'antd';

const { Title } = Typography;


export default class Transcription extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            input_av_url: null,
            input_av_text: null,
            input_task_description: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
            transcribe: true,
            translate: false,
            models: ["transcribe", "transcribe and translate"],
            model: "transcribe",
            historicalData: [],
            transcription_api_running: false,
            transcription_api_response: null,
        };
        this.transcription = this.transcription.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.updateHistoricalData = this.updateHistoricalData.bind(this);
      }
      
      updateHistoricalData = async () => {
        this.props.refreshIdToken().then(async (token) => {
            console.log("Getting Historic data");
            const skip = 0; // replace with your actual skip value
            const limit = 100; // replace with your actual limit value
            const response = await getTranscribeHistoricalData(token, skip, limit);
            if(response.status){
                this.setState({
                    historicalData: response.message,
                });
            } else {
                console.error('Failed to fetch historical data:', response.message);
            }
            console.log("Hostoric data retrived");
        });
    };
    
    transcription = async () => {
        this.setState({
            transcription_api_running: true,
            transcription_api_response: null,
        })
        this.props.refreshIdToken().then((token) => {
            let url = this.state.input_av_url;
            let transcribe = false;
            let translate = false;
            if (!url) {
                console.log("input_av_url is not present. Using input_av_text instead.");
                url = this.state.input_av_text;
            } else {
                console.log("input_av_url is present.");
            }


            if (this.state.model === "transcribe") {
                transcribe = true;
            } else if (this.state.model === "transcribe and translate") {
                transcribe = true;
                translate = true;
            }
            
            this.setState({}, () => {
                const payload = {
                    user: this.props.username,
                    input_spec: { url: url,
                        transcribe: transcribe,
                        translate: translate,
                        task_description: this.state.input_task_description}
                   
                }
                console.log("Current model:", this.state.model);
                console.log("Payload "+payload.input_spec)
                var status_code = 200;
                try {
                    TranscriptionAPI(payload, token).then((response) => {
                        status_code = response.status_code
                        if(response.status==false){
                            message.destroy()
                            message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                        }else{
                            message.destroy()
                            //message.success(response.message)
                            message.success("Transcription Completed!")
                        }
                        this.setState({
                            transcription_api_running: false,
                            transcription_api_response: response,
                        })   
                    }).catch (error => {
                        console.log(error)
                        message.destroy()
                        if (status_code == 500){
                            message.error("Failed to generate Transcribe! Most likely due to high load. Try again :)")
                        }
                        this.setState({
                            transcription_api_running: false,
                        })
                    })
                } catch (error) {
                    console.log(error)
                    message.destroy()
                    if (status_code == 500){
                        message.error("Failed to generate Transcribe! Most likely due to high load. Try again :)")
                    }
                    this.setState({
                        transcription_api_running: false,
                    })
                }
            })        
        })
    }

    onClickHandler = async () =>  {
        console.log("Running transcribe...");
        this.transcription()
    
    }
    
    componentDidMount() {
        this.props.refreshIdToken().then((token) => {
            console.log("refreshIdToken token: ", token);
            this.updateHistoricalData(token);
        });
    }
    render() {
        const columns = [
            {
                title: 'User Name',
                dataIndex: 'user',
                key: 'user',
                render: (user) => user || "N/A", // Renders "N/A" if user is null
            },
            {
                title: 'Created At',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Input URL',
                key: 'input_url',
                render: (record) => (
                    <a href={record.input_spec.url} target="_blank" rel="noopener noreferrer">
                        Open Input
                    </a>
                ),
            },
            {
                title: 'Output URL',
                key: 'output_url',
                render: (record) => (
                    <a href={record.output_spec.url} target="_blank" rel="noopener noreferrer">
                        Open Output
                    </a>
                ),
            }
            ];
        const createForm = (
            <div>
                <Layout>
                    <Row>
                    <Col span={8}>
                    <Content>
                        <br />
                        <br />
                        <h3> Ask AI to Transcribe your input</h3>
                        <Row>
                            <Col span={9}>
                                <UploadButton
                                    signin_name={this.props.username}
                                    upload_loading={this.state.image_upload_loading}
                                    upload_endpoint={this.state.upload_endpoint}
                                    id_token={this.props.idToken}
                                    upload_button_label={"Upload Audio / Video"}
                                    onImUploadChange={(e) => {
                                        if (e.file.status == 'done') {
                                            message.success(' Uploaded!')
                                            var temp_video_upload_url = e.file.response.image_url
                                            this.setState({
                                                input_av_url: temp_video_upload_url
                                            })
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <br>
                        </br>
                        <Row>
                            <Col span={12} >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Title level={4} style={{ fontSize: '12px' }}>OR</Title>
                                </div>
                            </Col>
                        </Row>
                        <br>
                        </br>
                        <Row>
                            <Col span={15}>
                                <TextArea rows={3} style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px' }} placeholder="Video/Audio url..." onChange={(e) => {
                                    this.setState({
                                        input_av_text: e.target.value
                                    })
                                }} />
                            </Col>
                        </Row>
                        <br>
                        </br>
                        <Row>
                            <Col span={15}>
                                <TextArea rows={3} style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px' }} placeholder="Task Description (Optional)..." onChange={(e) => {
                                    this.setState({
                                        input_task_description: e.target.value
                                    })
                                }} />
                            </Col>
                        </Row>
                        <br>
                        </br>
                        <Row>
                            <Col span={12}>
                                <Select
                                    placeholder="model"
                                    style={{
                                        width: '100%',
                                        paddingLeft: '5px',
                                        paddingRight: '5px'
                                    }}
                                    value={this.state.model}
                                    allowClear={true}
                                    onChange={(e) => {
                                        this.setState({
                                            model: e
                                        })
                                    }}
                                >
                            
                                    {this.state.models.map((model) => {
                                        return (
                                            <Option key={model} value={model}>
                                                <Space align="center">
                                                    <span>{model}</span>
                                                </Space>
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                        <Col span={6} offset={2}>
                                <Button type="primary" style={{ width: '100%', marginLeft: '5px' }} block
                                    onClick={this.onClickHandler}
                                    loading={this.state.transcription_api_running}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <br />
                    </Content>
                    </Col>   
                    <br></br>
                    <br></br>
                <Col span={16}>
                <Content>
                <br />
                <br />
            </Content>
            </Col>
            {this.updateHistoricalData}
            <Table columns={columns} dataSource={this.state.historicalData} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} />
            <Col span={2} offset={10}>
                <Button type="primary" style={{ width: '100%', marginLeft: '5px' }} block
                    onClick={this.updateHistoricalData}
                >
                    Refresh
                </Button>
            </Col>
            </Row>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                    <NavBar
                        authHandler={async (instance) => {await this.authHandler(instance)}}
                        authRefresh={this.authRefresh}
                        signin_name={this.props.username}
                    />
                    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                            {createForm}
                        </div>
                        </Content>
                    <Footer style={{ textAlign: 'center' }}>
                    <img
                        src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                        alt="logo"
                        style={{
                            height:'20px',
                            display:'inline',
                            paddingRight:'10px'
                        }}/>
                        ©2021 Created by TAG AI Team
                    </Footer>
                </Layout>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuth />
            </UnauthenticatedTemplate>
            </>
        )
    }
}
