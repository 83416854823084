import { Button, Col, Input, Layout, message, Row, Typography } from 'antd';
import React from 'react';
import NavBar from '../components/header';
import { WikipediaGetCreationStatusAPI } from "../apis/wikiSHPs";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
import uuid4 from 'uuid4';

const { Content, Footer } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

let init = false;

export default class WikiCollectionsStatus extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            id_token: null,

            creation_status: null,
            creation_status_api_running: false,
        };

        this.getCurrentCreationStatus = this.getCurrentCreationStatus.bind(this);
    }

    getCurrentCreationStatus = async () => {
        message.loading("Getting latest Wiki SHP Collection creation status...", 0)
        this.setState({
            creation_status: null,
            creation_status_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            const payload = {
                unprocessed_only: true,
                get_text: false,
                skip: 0,
                limit: 1000000,
                lookback_hours: 24,
            }
            WikipediaGetCreationStatusAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("WikipediaGetCreationStatusAPI API failed!")
                    this.setState({
                        creation_status_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("WikipediaGetCreationStatusAPI API success!")
                    var creation_status = []
                    response.message.articles.forEach((item) => {
                        // check for url property in the record field
                        if (item.record && item.record.url)
                            creation_status.push({
                                collection_name: item.collection_name,
                                url: item.record.url,
                                title: item.title
                            })
                        else
                            creation_status.push({
                                collection_name: item.collection_name,
                                url: null,
                                title: item.title
                            })
                    })
                    this.setState({
                        creation_status: creation_status,
                        creation_status_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to get Wiki SHP Collection creation status!")
                this.setState({
                    creation_status_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to get Wiki SHP Collection creation status!", error)
            this.setState({
                creation_status_api_running: false
            })
        })
    }

    
    componentDidMount(){
        if (this.state.creation_status == null && !init) {
            init = true
            this.getCurrentCreationStatus()
        }
    }
    
    render() {
        const createForm = (
            <div>          
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Wiki SHP Collection Creation Status
                        </h3>
                        <br />
                        <Text>
                            The table below shows the current queue of Wiki SHP collections that are being created.
                        </Text>
                        <br />
                        <Row>
                            <Col span={24}>
                                <Button
                                    type="primary"
                                    onClick={this.getCurrentCreationStatus}
                                    loading={this.state.creation_status_api_running}
                                    style={{'margin-top':'10px'}}
                                >
                                    Refresh
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={24}>
                                <table style={{ border: '1px solid #ddd', borderSpacing: '7px', borderCollapse: 'separate' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'left'}}>Collection</th>
                                            <th style={{ textAlign: 'left'}}>Wikipedia Source Page</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.creation_status && this.state.creation_status.map((item, index) => {
                                            return (
                                                <tr key={uuid4()}>
                                                    <td>{item.collection_name}</td>
                                                    <td>{item.url ? <a href={item.url} target="_blank">{item.title}</a> : item.title}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.state.signin_name}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
