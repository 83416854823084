import React, { useState } from 'react';
import { FontSizeOutlined, FileImageOutlined, SketchOutlined } from '@ant-design/icons';
import {
    Button, Col, Divider, Input, Layout, message, Row,
    Tabs, Typography, Empty, Spin, InputNumber, Modal, Popconfirm
} from 'antd';
import 'antd/dist/antd.css';
import ReactPlayer from 'react-player';
import ScrollVideos from "../components/videoScroll"
import { UploadButton } from '../components/uploadButton';
import { FontDropDown } from "../components/fontDropDown"

const { Text } = Typography;
const { Content } = Layout;


export const VideoEditorModal = (props) => {
    const [open, setOpen] = useState(false);
    const showPopconfirm = () => {
        // Popconfirm is outside the modal to enable closing the confirmation popup correctly
        // Ref: https://stackoverflow.com/questions/75104333/using-a-popconfirm-from-antd-to-confirm-the-closing-of-a-modal
        if (!props.getGlobalProperty("stitching_required")) {
            props.onOk()
        } else {
            setOpen(true);
        }
    }

    return (
        <div>
            <Modal
                title="Edit Video"
                open={props.isOpen}
                onOk={props.onOk}
                onChangeVideoSetting={props.onChangeVideoSetting}
                onChangeFocusClipSetting={props.onChangeFocusClipSetting}
                getVideoSetting={props.onGetVideoSetting}
                getFocusClipSetting={props.onGetFocusClipSetting}
                getGlobalProperty={props.onGetGlobalProperty}
                toggleVideoEditorBackToGeneratorModal={props.toggleVideoEditorBackToGeneratorModal}
                addNewClip={props.addNewClip}
                togglePrefixEffectModal={props.togglePrefixEffectModal}
                addNewVideoClip={props.addNewVideoClip}
                toggleImageSearchModal={props.toggleImageSearchModal}
                toggleTextOverlayModal={props.toggleTextOverlayModal}
                toggleVideoEffectModal={props.toggleVideoEffectModal}
                toggleTransitionOverlayModal={props.toggleTransitionOverlayModal}
                clipGenerate={props.clipGenerate}
                removeClip={props.removeClip}
                videoStitch={props.videoStitch}
                closable={false}
                style={{top: '50px'}}
                width={'98%'}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        style={{float: "left"}}
                        onClick={props.toggleVideoEditorBackToGeneratorModal}
                        disabled={
                            props.getGlobalProperty("clip_generate_api_running")
                        }
                    >
                        &lt;&lt; Change Video Settings
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        style={{
                            marginRight: "400px"
                        }}
                        onClick={async () => {
                            await props.videoStitch()
                        }}
                        disabled={
                            props.getGlobalProperty("clip_generate_api_running")
                        }
                    >
                        Stitch and Preview
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={showPopconfirm}
                        disabled={
                            props.getGlobalProperty("clip_generate_api_running")
                        }
                    >
                        Finish Editing
                    </Button>
                ]}
            >
                <Content>
                    <Row style={{ width: '100%' }}>
                        <Col span={4}>
                            <Button
                                onClick={props.addNewClip}
                            >Add Blank Clip</Button>
                        </Col>
                        <Col span={4}>
                            <Button
                                onClick={props.togglePrefixEffectModal}
                            >Add Prefix Clip</Button>
                        </Col>
                        <Col span={4}>
                            <UploadButton
                                signin_name={props.username}
                                upload_loading={props.getGlobalProperty("image_upload_loading")}
                                upload_endpoint={props.getGlobalProperty("upload_endpoint")}
                                id_token={props.idToken}
                                onImUploadChange={(e) => {
                                    if (e.file.status == 'done') {
                                        message.success('Clip Uploaded!')
                                        var temp_video_upload_url = e.file.response.image_url
                                        props.addNewVideoClip(temp_video_upload_url)
                                    }
                                }}
                                style={{ marginTop: '-10px' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {props.getGlobalProperty("video_generate_api_response") == null ?
                            <Empty
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    paddingTop: '150px'
                                }}
                            /> :
                            <>
                                <Col span={6}>
                                    <Spin spinning={props.getGlobalProperty("clip_generate_api_running")}>
                                        <Tabs defaultActiveKey="Binge"
                                            onTabClick={(e) => {
                                                // console.log(e)
                                                if (e == "Images") {
                                                    props.toggleImageSearchModal()
                                                }
                                            }}
                                            style={{
                                                width: '340px',
                                                marginTop: '10px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                paddingBottom: '10px',
                                                backgroundColor: 'whitesmoke'
                                            }}
                                        >
                                            <Tabs.TabPane
                                                tab={
                                                    <span>
                                                        <FontSizeOutlined />
                                                        General
                                                    </span>
                                                }
                                                style={{
                                                    height: props.getGlobalProperty("player_height") - 10,
                                                    overflowY: 'scroll'
                                                }}
                                                key="General"
                                            >
                                                <Input
                                                    placeholder="Enter Binge Text"
                                                    value={props.getFocusClipSetting("text")}
                                                    onChange={(e) => {
                                                        props.onChangeFocusClipSetting("text", e.target.value)
                                                    }}
                                                />

                                                <Divider orientation="left"
                                                    orientationMargin={0}
                                                    style={{ margin: 0, paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
                                                >Select Image</Divider>
                                                <Button
                                                    onClick={props.toggleImageSearchModal}
                                                    style={{
                                                        width: '100%',
                                                        overflow: 'clip'
                                                    }}
                                                >
                                                    {props.getFocusClipSetting("background_image_url") == undefined ?
                                                        "Select Image" :
                                                        props.getFocusClipSetting("background_image_url")
                                                    }
                                                </Button>

                                                <Divider orientation="left"
                                                    orientationMargin={0}
                                                    style={{ margin: 0, paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
                                                >Override Image Source</Divider>

                                                <Input
                                                    placeholder="(Optional) Override Image Source"
                                                    value={props.getFocusClipSetting("override_image_source")}
                                                    onChange={(e) => {
                                                        props.onChangeFocusClipSetting("override_image_source", e.target.value)
                                                    }}
                                                />

                                                <Divider orientation="left"
                                                    orientationMargin={0}
                                                    style={{ margin: 0, paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
                                                >Text Overlay Effect</Divider>
                                                <Button
                                                    onClick={props.toggleTextOverlayModal}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    {props.getFocusClipSetting("text_overlay_template_id") == undefined ?
                                                        "Select Text Overlay Effect" :
                                                        props.getFocusClipSetting("text_overlay_template_id")
                                                    }
                                                </Button>


                                                <Divider orientation="left"
                                                    orientationMargin={0}
                                                    style={{ margin: 0, paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
                                                >Video Effect</Divider>
                                                <Button
                                                    onClick={props.toggleVideoEffectModal}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    {props.getFocusClipSetting("effect") == undefined ?
                                                        "Select Video Effect" :
                                                        props.getFocusClipSetting("effect")
                                                    }
                                                </Button>

                                                <Divider orientation="left"
                                                    orientationMargin={0}
                                                    style={{ margin: 0, paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
                                                >Language & Fonts</Divider>

                                                <div style={{ width: '100%' }}>
                                                    <FontDropDown
                                                        language={props.getFocusClipSetting("language")}
                                                        language_options={props.getGlobalProperty("lang_options")}
                                                        fonts={props.getGlobalProperty("language_to_fonts")}
                                                        second_font={(props.getFocusClipSetting("font") == undefined)
                                                            ? "default.ttf"
                                                            : props.getFocusClipSetting("font")
                                                        }
                                                        color_options={props.getGlobalProperty("color_options")}
                                                        color={(props.getFocusClipSetting("font_color") == undefined)
                                                            ? "white"
                                                            : props.getFocusClipSetting("font_color")
                                                        }
                                                        font_size_options={props.getGlobalProperty("font_size_options")}
                                                        font_size={(props.getFocusClipSetting("font_size") == undefined)
                                                            ? 50
                                                            : props.getFocusClipSetting("font_size")
                                                        }
                                                        fonts_to_images={props.getGlobalProperty("fonts_to_images")}
                                                        font_assets_cdn_prefix={props.getGlobalProperty("font_assets_cdn_prefix")}
                                                        onLanguageSelect={(e) => {
                                                            console.log("onLanguageSelect - " + e)
                                                            props.onChangeVideoSetting("language", e)
                                                            props.onChangeFocusClipSetting("language", e)
                                                            props.onChangeFocusClipSetting("font", "default.ttf")
                                                        }}
                                                        secondFontChange={(e) => {
                                                            console.log("secondFontChange - " + e)
                                                            props.onChangeVideoSetting("font", e)
                                                            props.onChangeFocusClipSetting("font", e)
                                                        }}
                                                        oncolorChange={(e) => {
                                                            console.log("oncolorChange - " + e)
                                                            props.onChangeFocusClipSetting("font_color", e)
                                                        }}
                                                        onfontsizeChange={(e) => {
                                                            console.log("onfontsizeChange - " + e)
                                                            props.onChangeFocusClipSetting("font_size", e)
                                                        }}
                                                    />
                                                </div>

                                                <Divider orientation="left"
                                                    orientationMargin={0}
                                                    style={{ margin: 0, paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
                                                >Duration</Divider>
                                                <InputNumber
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    min={1} max={15}
                                                    value={props.getFocusClipSetting("duration")}
                                                    onChange={(e) => {
                                                        props.onChangeFocusClipSetting("duration", e)
                                                    }}
                                                />

                                                <Divider orientation="left"
                                                    orientationMargin={0}
                                                    style={{ margin: 0, paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
                                                >Transition Overlay</Divider>
                                                <Button
                                                    onClick={props.toggleTransitionOverlayModal}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    {props.getFocusClipSetting("transition_overlay_template_id") == undefined ?
                                                        "Select Transition Overlay" :
                                                        props.getFocusClipSetting("transition_overlay_template_id")
                                                    }
                                                </Button>
                                                <br></br>
                                                <br></br>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane
                                                tab={
                                                    <span>
                                                        <FileImageOutlined />
                                                        Images
                                                    </span>
                                                }
                                                key="Images"
                                                style={{
                                                    height: props.getGlobalProperty("player_height") - 10
                                                }}
                                            >
                                                <p>
                                                    {props.getVideoSetting("video_generate_api_response") != null ?
                                                        props.getFocusClipSetting("selected_image_id") :
                                                        'NA'
                                                    }
                                                </p>
                                                <img
                                                    width={120}
                                                    src={props.getFocusClipSetting("background_image_url")}
                                                ></img>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane
                                                tab={
                                                    <span>
                                                        <SketchOutlined />
                                                        Advanced
                                                    </span>
                                                }
                                                key="Advanced"
                                                style={{
                                                    height: props.getGlobalProperty("player_height") - 10
                                                }}
                                            >
                                                Coming Soon!
                                            </Tabs.TabPane>
                                        </Tabs>
                                        <Button
                                            loading={props.getGlobalProperty("clip_generate_api_running") ? true : false}
                                            style={{ width: '100%' }}
                                            onClick={async () => {
                                                await props.clipGenerate()
                                            }}
                                        >Generate Clip</Button>
                                    </Spin>
                                </Col>
                                <Col span={18 - props.getGlobalProperty("result_col_span")}
                                    style={{
                                        marginTop: '10px',
                                        overflowX: 'scroll',
                                        borderLeft: '1px solid darkgray',
                                        borderRight: '1px solid darkgray'
                                    }}
                                >
                                    <Spin spinning={props.getGlobalProperty("clip_generate_api_running")}>
                                        <Row>
                                            <ScrollVideos
                                                video_generate_api_response={props.getVideoSetting("video_generate_api_response")}
                                                current_focus_clip_index={props.getVideoSetting("current_focus_clip_index")}
                                                player_height={props.getGlobalProperty("player_height")}
                                                player_width={props.getGlobalProperty("player_width")}
                                                scrollRadioChange={(e) => {
                                                    props.onChangeVideoSetting("current_focus_clip_index", e.target.value)
                                                }}
                                                deleteClip={props.removeClip}
                                            />
                                        </Row>
                                    </Spin>
                                </Col>
                                <Col span={props.getGlobalProperty("result_col_span")}
                                    style={{
                                        marginTop: '10px',
                                        backgroundColor: 'whitesmoke',
                                        hidden: props.getVideoSetting("video_stitch_response") == null ? true : false
                                    }}
                                >
                                    <Row>
                                        <Text
                                            style={{
                                                flexGrow: 1,
                                                textAlign: 'center',
                                                padding: '10px'
                                            }}
                                        >
                                            Video Preview
                                        </Text>
                                    </Row>
                                    <Row>
                                        <ReactPlayer
                                            style={{
                                                paddingTop: '10px',
                                                paddingLeft: '2px',
                                            }}
                                            controls
                                            // width="100%"
                                            width={300}
                                            height={props.getGlobalProperty("player_height")}
                                            url={props.getVideoSetting("video_stitch_response") == null ? 'none' : props.getVideoSetting("video_stitch_response").message.url} />
                                        </Row>
                                </Col>
                            </>
                        }
                    </Row>
                </Content>
            </Modal>
            <Popconfirm
                arrowPointAtCenter={true}
                title="Changes not yet included in the final video. Stitch and preview before finishing?"
                open={open}
                onConfirm={async () => {
                    await props.videoStitch()
                    setOpen(false)
                }}
                onCancel={() => {
                    props.onOk()
                    setOpen(false)
                }}
                okText="Yes"
                cancelText="No"
            >
                <div style={{ position: 'absolute', display: 'block', left: 0, right: 0, top: '500px', bottom: 0 }}></div>
            </Popconfirm>
        </div>
    )
}
