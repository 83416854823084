import { Layout } from 'antd';
import React from 'react';
const { Footer } = Layout;

export default class  Blank extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
        };
        // this.authHandler = this.authHandler.bind(this);
      }


    componentDidMount(){
    }

    render() {
        return (
            <Layout>
            <Footer style={{ textAlign: 'center', backgroundColor:'#ffffff', color:'white'}}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>
        )
    }
}
