import {
    Button, Col, Input, Layout, message, Row, Card, Select, Empty, Tooltip
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import {MemeCreateAPI} from '../apis/meme';
import { Upload } from 'antd';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import {UploadButton} from '../components/uploadButton';

import NoAuth from "../components/noAuth";

const { Search } = Input;
const { Header, Content, Footer } = Layout;

const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

export default class MemeCreate extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            recording: false,
            audioURL: "",
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            id_token: null,
            input_image_url: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
            description: "",
            modified_by : "glance.admin",
            versions:["v1","v2"],
            version: "v1",
            meta: "",
            
            meme_create_api_running: false,
            meme_createapi_response: null,
        };
        this.startRecording = this.startRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.onRecordComplete = this.onRecordComplete.bind(this);
        this.memeCreate - this.memeCreate.bind(this);
      }

      onRecordComplete = (blob, audioURL) => {
        console.log(blob, audioURL);
    
        // Create a new FormData instance
        var formData = new FormData();
    
        // Create a blob file...
        var file = new File([blob], "recording.mp3", {
            type: "audio/mp3",
        });
    
        // Add the file to formData object for the "file" field
        formData.append("file", file);
    
        // Asynchronous request to the upload endpoint
        fetch('https://lex-backend-staging.glanceai.inmobi.com/api/v1/helpers/upload', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
    
                // Assuming the response data contains the URL of the uploaded audio file
                // Replace 'image_url' with the actual key that the response uses to provide the file URL
                let uploadedAudioURL = data.image_url;
    
                // Update the state
                this.setState({ audioURL: uploadedAudioURL });
            })
            .catch((error) => console.error("Error:", error));
    };

      async startRecording() {
        this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(this.audioStream);
        this.chunks = [];
        this.mediaRecorder.ondataavailable = e => {
            if (e.data.size > 0) {
                this.chunks.push(e.data);
            }
        };
        this.mediaRecorder.onstop = e => {
            const blob = new Blob(this.chunks, { type: 'audio/mp3' });
            const audioURL = URL.createObjectURL(blob);
            this.chunks = [];
            this.onRecordComplete(blob, audioURL);  // Change is here
            this.setState({ audioURL });
        };
        this.mediaRecorder.start();
        this.setState({ recording: true });
    }

    stopRecording() {
        this.mediaRecorder.stop();
        this.audioStream.getTracks().forEach(track => track.stop());
        this.setState({ recording: false });
    }
    memeCreate = async () => {
        this.setState({
            meme_create_api_running: true,
            meme_createapi_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                audio_url:this.state.audioURL,
                description: this.state.description,
                version: this.state.version,
            }
            console.log("payload: ", payload)
            var status_code = 200;
            try {
                MemeCreateAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Meme Creation Completed!")
                    }
                    this.setState({
                        meme_create_api_running: false,
                        meme_createapi_response: response,
                    })
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to upload images! Most likely due to high load. Try again :)")
                }
                this.setState({
                    meme_create_api_running: false,
                })
            }
        })
    }
    
    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {

        
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <br/>
            <br/>
            <h3> Concept to create Meme</h3>
            <Row>
            <div>
                <Button onClick={this.startRecording} disabled={this.state.recording}>
                    Start Recording
                </Button>
                <Button onClick={this.stopRecording} disabled={!this.state.recording}>
                    Stop Recording
                </Button>
                <Button onClick={() => this.setState({audioURL: ""})} disabled={!this.state.audioURL}>
                    Clear Recording
                </Button>
                {this.state.audioURL && <audio src={this.state.audioURL} controls />}
            </div>
            <Col span={1}>
            <h4>OR</h4>
            </Col>
            <Col span={6}>
            <TextArea rows={1} style={{ width: '100%' }} placeholder="Enter the meme text" onChange={(e)=>{
                this.setState({
                    description: e.target.value
                })
            }} />
            </Col>
            <Col span={2}>
                <Select
                    placeholder="version"
                    style={{
                        width: '100%',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}
                    value={this.state.version}
                    allowClear={true}
                    onChange={(e) => {
                        this.setState({
                            version: e
                        })
                    }}
                >
                    {this.state.versions.map((e) => {
                        return <Option
                            value={e}>{e}</Option>
                    })}
                </Select>
            </Col>
            <Col span={3}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
                onClick={this.memeCreate} loading={this.state.meme_create_api_running}
            >
             create 
            </Button>
            </Col>
            </Row>
            <br>
            </br>
            </Content>
            <br></br>
            <br></br>
            <Content>
            <Row style={{overflow: "scroll"}}>
            {
                this.state.meme_createapi_response == null
                ? (
                    <Empty
                    style={{
                        paddingTop: "5px",
                        width: "100%",
                    }}
                    />
                )
                : (
                        
                        console.log("Output predicted:", this.state.meme_createapi_response),
                        this.state.meme_createapi_response.message.image_urls.map((imageUrl, idx) => {
                            let isSingleImage = this.state.meme_createapi_response.message.image_urls.length === 1;
                            let columnStyles = isSingleImage ? 
                                {display: "flex", justifyContent: "center"} : {};
                        
                            return (
                                <Col span={isSingleImage ? 24 : 6} style={columnStyles}>
                                    <Card
                                        hoverable
                                        style={{
                                        width: 240
                                        }}
                                        cover={
                                            <a
                                                href={imageUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img style={{width: 240}} alt="" src={imageUrl} />
                                            </a>
                                        }
                                    >
                                        <Meta title="Meme" description="Generated meme" />
                                        <br></br>
                                    </Card>
                                </Col>
                            )
                        }
                    )
                )
            }
            </Row>

        </Content>

          </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>

            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                {createForm}
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                alt="logo"
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>
          </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuth />
            </UnauthenticatedTemplate>
            </>
        )
    }
}
