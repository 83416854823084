import {
    Button, Col, Select, Input, Layout, message, Row, InputNumber, Typography, Tooltip, Checkbox, Popconfirm
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import { AmpStoryGenerateAPI, AmpStoryViewAPI, AmpStoryCreateAPI, AmpStoryUpdateAPI, AmpStoryPublishAPI, AmpStoryPostPublishAPI, AmpStoryVideoGenerateAPI } from "../apis/ampStory";
import { IMSV2ImageSelectionAPI} from "../apis/vcs";
import { TemplateModal } from "../components/templateModal"
import { LanguageDropDown } from "../components/languageDropDown"
import { AmpEditorModal } from "../components/ampEditorModal"
import { CategoryDropDown } from "../components/categoryDropDown"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { IMSImageSearchModal } from "../components/imsImageSearchModal";
import NoAuth from "../components/noAuth";

const { Content, Footer } = Layout;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

export default class AmpStoryCreate extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            
            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr",
                "ms",
                "vi",
                "th",
                "fil,"
            ],
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            template_options_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/amp-story-templates/",
            template_options: [
                "fade_in_v3", "orange_frame_content_v3", "white_fade_title_v3", "pink_heading_content_v3", "serif_v3",
            ],

            template_modal_open: false,

            image_search_modal_open: false,
            ims_selected_image_id: null,
            ims_selected_image_source: null,
            ims_banner_image_url: null,
            image_ids: [],
            image_id_to_source_map: {},

            amp_editor_modal_open: false,
            amp_editor_editing_pages: null,

            amp_story_publish_api_running: false,
            current_page_index: 0,
            template: "fade_in_v3",
            target_language: "en",
            amp_story_api_running: false,
            amp_story_api_response: null,
            amp_story_video_generate_api_running: false,
            amp_story_video_generate_api_response: null,
            amp_story_update_api_running: false,
            amp_story_update_response: null,
            source_url: null,
            num_points: 8,
            show_heading: true,
            stage: null,
            monetize: true,
            amp_story: null,
            genre: "serious",
            amp_story_html: "<p>AMP story preview will be shown here</p>",
            amp_story_html_url: null,
            amp_story_video_url: null,
            amp_story_publish_url: process.env.REACT_APP_TRENDS_BASE_URL + "/newz/amp",  // TODO: agree this with Engg

            content_category: "NEWS",
            category: "national_v_two",
            category_options: [
                'daily_digest',
                'cricket',
                'sports',
                'entertainment',
                'technology',
                'travel_and_lifestyle',
                'automotive',
                'food_and_health',
                'national_v_two',
                'international',
                'business',
                'fashion',
                'music',
                'fun_facts',
                'games',
                'nature',
                'talent',
                'comedy',
                'tamil_news',
                'telugu_news',
                'kannada_news',
                'marathi_news',
                'bengali_news',
                'home_n_living',
                'celebrity',
                'education',
                'live_game_streams',
                'religion',
                'content_for_apps',
                'ipl',
            ],
            genre_options: [
                "serious",
                "bright",
                "funny",
                "inspirational",
            ],
        };
        
        this.generateAmpStory = this.generateAmpStory.bind(this);
        this.generateAmpStoryRun = this.generateAmpStoryRun.bind(this);
        this.updateAmpStory = this.updateAmpStory.bind(this);
        this.publishAmpStory = this.publishAmpStory.bind(this);
        this.imageSelection = this.imageSelection.bind(this);
        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.toggleTemplateModal = this.toggleTemplateModal.bind(this);
        this.toggleAmpEditorModal = this.toggleAmpEditorModal.bind(this);
    }

    onMovePage = (direction, index) => {
        let pages = [...this.state.amp_editor_editing_pages];

        if (direction === "up" && index > 0) {
            [pages[index - 1], pages[index]] = [pages[index], pages[index - 1]];
        } else if (direction === "down" && index < pages.length - 1) {
            [pages[index], pages[index + 1]] = [pages[index + 1], pages[index]];
        }

        this.setState({ amp_editor_editing_pages: pages });
    };

    generateAmpStory = () => {
        this.setState({
            amp_story_html: "<p>AMP story preview will be shown here</p>"
        })
        this.generateAmpStoryRun()    
    }

    generateAmpStoryRun = async () => {
        this.setState({
            amp_story_api_running: true,
            amp_story_api_response: null,
            amp_story_html: "<p>AMP story preview will be shown here</p>"
        })
        message.loading("Generating AMP story...", 0)
        this.props.refreshIdToken().then((token) => {
            const payload = {
                source_url: this.state.source_url,
                target_language: this.state.target_language,
                template: this.state.template,
                show_heading: this.state.show_heading,
                stage: "generated",
                num_points: this.state.num_points,
                content_category: this.state.content_category,
                category: this.state.category,
                monetize: this.state.monetize,
            }
            console.log("payload: ", payload)
            AmpStoryGenerateAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("AMP story API failed!")
                    this.setState({
                        amp_story_api_running: false,
                    })
                    console.log("bhai yeh toh fail")
                } else {
                    message.destroy()
                    message.success("AMP story generated!")
                    var story = response.message.story;

                    // get the AMP story view
                    // TODO: set ads_config to something suitable
                    const payload_view = {
                        story: story,
                        ads_config: null
                    }
                    AmpStoryViewAPI(payload_view, token).then((response2) => {
                        if (response2.status==false) {
                            message.destroy()
                            message.error("AMP story view API failed!")
                            this.setState({
                                amp_story_api_running: false
                            })
                            console.log("bhai view yeh bhi fail")
                        } else {
                            message.destroy()
                            message.success("AMP story view generated!")
                            console.log("bhai view chalgaya")

                            // persist AMP story to DB
                            story.html_url = response2.message.amp_story_html_url
                            const payload_create = {
                                story: story
                            }
                            AmpStoryCreateAPI(payload_create, token).then((response3) => {
                                if (response3.status==false) {
                                    message.destroy()
                                    message.error("AMP story create API failed!")
                                    this.setState({
                                        amp_story_api_running: false
                                    })
                                } else {
                                    message.destroy()
                                    message.success("AMP story saved!")

                                    // update story state
                                    this.setState({
                                        source_url: story.source_url,
                                        language: story.language,
                                        title: story.title,
                                        pages: story.pages,
                                        template: story.template,
                                        show_heading: story.show_heading,
                                        stage: story.stage,
                                        amp_story: story,
                                        amp_story_html: response2.message.amp_story_html,
                                        amp_story_html_url: response2.message.amp_story_html_url,
                                        amp_story_api_response: response,
                                        amp_story_api_running: false,
                                    }, () => {
                                        console.log("AMP story: ", this.state.amp_story)
                                        console.log("bhai yeh toh chal jayega")
                                    })
                                }
                            }).catch((error) => {
                                message.destroy()
                                message.error("Failed to save AMP story!")
                                this.setState({
                                    amp_story_api_running: false
                                })
                            })
                        }
                    }).catch((error) => {
                        message.destroy()
                        message.error("Failed to generate AMP story view!")
                        this.setState({
                            amp_story_api_running: false
                        })
                        console.log("bhai failed hai hai")
                    })

                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to generate AMP story!")
                this.setState({
                    amp_story_api_running: false
                })
            })
            }).catch((error) => {
                message.destroy()
                message.error("Failed to generate AMP story!")
                this.setState({
                    amp_story_api_running: false
                })
            })
    }

    updateAmpStory = async () => {
        message.loading("Updating AMP story view...", 0)
        this.setState({
            amp_story_update_api_running: true,
            amp_story_api_response: null,
            amp_story_html: "<p>AMP story preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload_view = {
                story: this.state.amp_story,
                ads_config: null
            }
            AmpStoryViewAPI(payload_view, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("AMP story view API failed!")
                    this.setState({
                        amp_story_update_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("AMP story view updated!")
                    var story = this.state.amp_story
                    story.html_url = response.message.amp_story_html_url
                    this.setState({
                        amp_story_html: response.message.amp_story_html,
                        amp_story_html_url: response.message.amp_story_html_url,
                        amp_story: story
                    })

                    const payload_create = {
                        story: this.state.amp_story,
                    }
                    AmpStoryUpdateAPI(payload_create, token).then((response2) => {
                        if (response2.status==false) {
                            message.destroy()
                            message.error("AMP story database update API failed!")
                            this.setState({
                                amp_story_api_running: false,
                            })
                        } else {
                            message.destroy()
                            message.success("AMP story updated in database!")
                            this.setState({
                                amp_story_update_api_running: false,
                            })        
                        }
                    }).catch((error) => {
                        message.destroy()
                        message.error("Failed to update AMP story in database!")
                        this.setState({
                            amp_story_update_api_running: false,
                        })
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to update AMP story view!")
                this.setState({
                    amp_story_update_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to update AMP story!", error)
            this.setState({
                amp_story_update_api_running: false
            })
        })
    }

    publishAmpStory = async () => {
        message.loading("Publishing AMP story...", 0)
        this.setState({
            amp_story_publish_api_running: true,
        })

        this.props.refreshIdToken().then((token) => {
            AmpStoryPublishAPI(this.state.amp_story.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("AmpStoryPublishAPI API failed!")
                } else {
                    message.destroy()
                    message.success("AmpStoryPublishAPI API success!")
                    var story = this.state.amp_story
                    story.stage = "published"
                    this.setState({
                        amp_story: story,
                        amp_story_html: response.message.amp_story_html,
                        amp_story_html_url: response.message.amp_story_html_url,
                        amp_story_publish_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to publish AMP story!")
                this.setState({
                    amp_story_publish_api_running: false
                })
            })
        })
    }


    publishAmpStoryWithChaining = async (amp_chain=true) => {
        message.loading("Publishing AMP story...", 0)
        this.setState({
            amp_story_publish_api_running: true,
        })
        const payload = {
            story_id: this.state.amp_story.id,
            amp_chain: amp_chain
        }
        this.props.refreshIdToken().then((token) => {
            AmpStoryPostPublishAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("AmpStoryPublishAPI API failed!")
                } else {
                    message.destroy()
                    message.success("AmpStoryPublishAPI API success!")
                    var story = this.state.amp_story
                    story.stage = "published"
                    this.setState({
                        amp_story: story,
                        amp_story_html_url: response.message.story.html_url,
                        amp_story_publish_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to publish AMP story!")
                this.setState({
                    amp_story_publish_api_running: false
                })
            })
        })
    }


    generateVideoFromPages = async () => {
        message.loading("Generating video from AMP story...", 0)
        this.setState({
            amp_story_video_generate_api_running: true,
        })
        // change each page's advance_duration to 6 value
        var pages = this.state.amp_story.pages;
        pages = pages.map((page) => {
            page.advance_duration = 6
            return page
        })
        const payload = {
            pages: pages,
            genre: this.state.genre
        }
        this.props.refreshIdToken().then((token) => {
            AmpStoryVideoGenerateAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Video Generation API failed!")
                } else {
                    message.destroy()
                    message.success("Video Generation API success!")
                    this.setState({
                        amp_story_video_url: response.message.url,
                        amp_story_video_generate_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to generate Video AMP story!")
                this.setState({
                    amp_story_video_generate_api_running: false
                })
            })
        })
    }

    imageSelection = async (search_string) => {
        // TODO: Add image-selection API call
        // this.setState({
        //     clip_generate_api_running: true
        // })
        if (search_string == "") {
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": search_string,
                    "description": "",
                    "max_count": 30,
                    "duplicate_removal": true,
                    "blacklisted_removal": true,
                }
            }
            IMSV2ImageSelectionAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Image Selection API failed!")
                } else {
                    message.destroy()
                    message.success("Fetched Some Fresh Images!")
                }
                if (response.status == true) {
                    console.log("Image Selection API Response: ", response.message)
                    const image_ids = response.message.images.map(item => item.id);
                    const image_id_to_source_map = response.message.images.reduce((acc, item) => {
                        acc[item.id] = item.source_name;
                        return acc;
                    }, {});
                    this.setState({
                        image_ids: image_ids,
                        selected_image_id: image_ids[0],
                        image_id_to_source_map: image_id_to_source_map,
                    })
                    console.log("Image Ids: ", this.state.image_ids)
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch images!")
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to fetch images!")
        })
    }

    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleTemplateModal = () => {
        this.setState({
            template_modal_open: !this.state.template_modal_open
        })
    }

    toggleAmpEditorModal = () => {
        this.setState({
            amp_editor_modal_open: !this.state.amp_editor_modal_open
        })
    }

    render() {
        console.log("AMP story: ", this.state.amp_story)
        const createForm = (
            <div>
                <IMSImageSearchModal
                    isOpen={this.state.image_search_modal_open}
                    onOk={(e) => {
                        var pages = this.state.amp_editor_editing_pages;
                        pages[this.state.current_page_index].image_url = this.state.ims_banner_image_url;
                        pages[this.state.current_page_index].image_source = this.state.ims_selected_image_source;
                        this.setState({
                            amp_editor_editing_pages: pages
                        })
                        this.toggleImageSearchModal()
                    }}
                    imageSelection={this.imageSelection}
                    default_value={this.state.search_text}
                    image_list={this.state.image_ids}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                    imageOnSelect={(e)=>{
                        this.setState({
                            ims_selected_image_id: e.target.value,
                            ims_selected_image_source: this.state.image_id_to_source_map[e.target.value]?? "",
                            ims_banner_image_url: this.state.image_cdn_prefix + e.target.value + ".jpg",
                        })
                    }}
                    selectedImage={this.state.ims_banner_image_url}
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.props.idToken}
                    onImUploadChange={(e) => {
                        if (e.file.status == 'done') {
                            var pages = this.state.amp_editor_editing_pages;
                            pages[this.state.current_page_index].image_url = e.file.response.image_url;
                            pages[this.state.current_page_index].image_source = "";
                            this.setState({
                                amp_editor_editing_pages: pages,
                                image_search_modal_open: false
                            })
                            message.success('Image Uploaded!')
                        }
                    }}
                />

                <TemplateModal
                    isOpen={this.state.template_modal_open}
                    onOk={this.toggleTemplateModal}
                    template_options={this.state.template_options}
                    image_cdn_prefix={this.state.template_options_cdn_prefix}
                    imageOnSelect={(e) => {
                        console.log("Template changed to: ", e.target.value)
                        var story = this.state.amp_story
                        if (story) {
                            // Enables changing template after story is generated
                            story.template = e.target.value
                        }
                        this.setState({
                            template: e.target.value,
                            amp_story: story
                        })
                    }}
                    onClose={this.toggleTemplateModal}
                />

                <AmpEditorModal
                    onMovePage={this.onMovePage}
                    isOpen={this.state.amp_editor_modal_open}
                    centered
                    onOk={(e) => {
                        var pages = this.state.amp_editor_editing_pages;
                        var story = this.state.amp_story;
                        story.pages = pages;
                        this.setState({
                            amp_story: story
                        })
                        this.updateAmpStory()
                        this.toggleAmpEditorModal()
                    }}
                    onCancel={this.toggleAmpEditorModal}
                    onClose={this.toggleAmpEditorModal}
                    onDeletePage={(e) => {
                        var pages = this.state.amp_editor_editing_pages;
                        pages.splice(e, 1)
                        this.setState({
                            amp_editor_editing_pages: pages
                        })
                    }}
                    onChange={(field_name, page_index, e) => {
                        var pages = this.state.amp_editor_editing_pages;
                        pages[page_index][field_name] = e.target.value
                        this.setState({
                            amp_editor_editing_pages: pages
                        })
                    }}
                    onReplaceImage={(page_index) => {
                        var pages = this.state.amp_editor_editing_pages;
                        this.setState({
                            image_ids: pages[page_index].image_ids,
                            ims_banner_image_url: pages[page_index].image_url,
                            current_page_index: page_index
                        })
                    }}
                    toggleImageSearchModal={this.toggleImageSearchModal}
                    editing_pages={this.state.amp_editor_editing_pages}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                />
                
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>Generate an AMP Story</h3>
                        <Row style={{ width: '100%' }}>
                            <Col span={12}>
                                <Text>
                                    Prompt
                                </Text>
                                <Tooltip trigger={['focus']} title={"Submit a URL or an Article."} placement="topLeft">
                                    <TextArea
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '55px'
                                        }}
                                        placeholder="Submit a link to an article or just the article text :)"
                                        onChange={(e) => {
                                            this.setState({
                                                source_url: e.target.value
                                            })
                                        }}
                                    />

                                </Tooltip>
                            </Col>
                            <Col span={3} style={{ paddingLeft: '20px' }}>
                                <Text
                                    style={{
                                        paddingLeft: '2px'
                                    }}
                                >
                                    Pages#
                                </Text>
                                <br />
                                <Tooltip trigger={['focus']} title={"How many pages do you want?"} placement="topLeft">
                                    <InputNumber
                                        style={{
                                            width: '70px',
                                            paddingLeft: '2px'
                                        }}
                                        value={this.state.num_points}
                                        min={this.state.monetize ? 8 : 5} max={12} defaultValue={8} onChange={(e) => {
                                            this.setState({
                                                num_points: e
                                            })
                                        }} />
                                </Tooltip>
                                <br />
                                <br />
                                <Text
                                    style={{
                                        paddingLeft: '2px'
                                    }}
                                > Content Category </Text>
                                <br />
                                <Tooltip trigger={['focus']} title={"Content Category?"} placement="topLeft">
                                    <Input
                                        style={{
                                            width: '100%'
                                        }}
                                        value={this.state.content_category}
                                        onChange={(e) => {
                                            this.setState({
                                                content_category: e.target.value
                                            })
                                        }} />
                                </Tooltip>
                                <br />
                                <br />
                                <Text
                                     style={{
                                        paddingLeft: '2px'
                                    }}
                                >
                                    Article Category
                                </Text>
                                <br />
                                <CategoryDropDown
                                    style={{
                                        width: '100%',
                                    }}
                                    category={this.state.category}
                                    category_options={this.state.category_options}
                                    onCategorySelect={(e) => {
                                        console.log("onCategorySelect - " + e)
                                        this.setState({
                                            category: e
                                        })
                                    }}
                                />

                            </Col>
                            <Col span={5} style={{ paddingLeft: '20px' }}>
                                <Text
                                    style={{
                                        paddingLeft: '2px'
                                    }}
                                >
                                    Language
                                </Text>
                                <br />
                                <LanguageDropDown
                                    language={this.state.target_language}
                                    language_options={this.state.lang_options}
                                    onLanguageSelect={(e) => {
                                        console.log("onLanguageSelect - " + e)
                                        this.setState({
                                            target_language: e
                                        })
                                    }}
                                />
                                <br />
                                <br />
                                <Text
                                    style={{
                                        paddingLeft: '2px'
                                    }}
                                >
                                    Select Template
                                </Text>
                                <br />
                                <Tooltip trigger={['focus']} title={"Template?"} placement="topLeft">
                                    <Button
                                        onClick={this.toggleTemplateModal}
                                        style={{
                                            width: '80%',
                                            marginLeft: '2px'
                                        }}
                                    >
                                        {this.state.template ? this.state.template : this.state.template_options[0]}
                                    </Button>
                                </Tooltip>
                                <br />
                                <Checkbox
                                    style={{
                                        paddingLeft: '2px'
                                    }}
                                    onChange={(e) => {
                                        this.setState({
                                            show_heading: e.target.checked
                                        })
                                    }}
                                    checked={this.state.show_heading}
                                >
                                    Show content page headings
                                </Checkbox>
                            </Col>
                            <Col span={2}>
                                <Checkbox
                                    checked={this.state.monetize}
                                    style={{
                                        paddingTop: '32px',
                                        paddingLeft: '2px'
                                    }}
                                    onChange={(e)=>{
                                            var num_points = this.state.num_points
                                            if (num_points < 8) {
                                                num_points = 8
                                            }
                                            this.setState({
                                                monetize: e.target.checked,
                                                num_points: num_points
                                            })
                                        }}
                                >
                                    Monetize
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row style={{
                            marginTop: '10px'
                        }}>
                            <Col span={8}>
                                <Button type="primary" block
                                style={{
                                    marginTop: '15px'
                                }}
                                    onClick={this.generateAmpStory} 
                                    loading={this.state.amp_story_api_running}
                                    // disabled={this.state.amp_story !== null}  TODO: solve for almost-duplicate story creation
                                >
                                    Generate AMP story 
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <h3 style={{borderBottom: '1px solid #ddd'}}> Output </h3>
                        <Row>
                            <Col span={12}>
                                AMP Story Preview
                                <br />
                                <iframe
                                    style={{
                                        width: '540px',
                                        height: '640px'
                                    }}
                                    title="view"
                                    src={"data:text/html,"+encodeURIComponent(this.state.amp_story_html)}/>
                            </Col>
                            <Col span={7}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        var pages = this.state.amp_story.pages;
                                        this.setState({
                                            amp_editor_editing_pages: pages
                                        }, () => {
                                            this.toggleAmpEditorModal()
                                        })
                                    }}
                                    style={{
                                        width: '100%',
                                        overflow: 'clip',
                                        marginTop: '20px',
                                        display: 'block'
                                    }}
                                    block 
                                    loading={this.state.amp_story_update_api_running}
                                    disabled={
                                        this.state.amp_story === null
                                        || (this.state.amp_story && this.state.amp_story.stage == "published")
                                        || this.state.amp_story_api_running
                                        || this.state.amp_story_publish_api_running
                                    }
                                >
                                    Edit and Update AMP Story
                                </Button>
                                <br/>
                                <Row>
                                <Tooltip trigger={['focus']} title={"Type of voiceover for final video?"} placement="topLeft">
                            <Select
                                defaultValue="serious"
                                placeholder="Voice Over"
                                style={{
                                    width: '100%',
                                    paddingLeft: '5px',
                                    paddingRight: '5px'
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        genre: e
                                    })
                                }}
                            >
                                {this.state.genre_options.map((e) => {
                                    return <Option
                                        value={e}>{e}</Option>
                                })}
                            </Select>
                        </Tooltip>


                                <Button
                                    type="primary"
                                    onClick={() => {
                                        this.generateVideoFromPages()
                                    }}
                                    style={{
                                        width: '100%',
                                        overflow: 'clip',
                                        marginTop: '20px',
                                        display: 'block'
                                    }}
                                    block 
                                    loading={this.state.amp_story_video_generate_api_running}
                                    disabled={
                                        this.state.amp_story === null
                                    }
                                >
                                    Generate Video
                                </Button>
                                </Row>
                                <br/>
                                <br/>
                                <Popconfirm
                                    title="Do you really want to publish this AMP story? Editing will not be possible after publishing."
                                    onConfirm={(e) => {
                                        this.publishAmpStory()
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        type="primary"
                                        style={{
                                            width: '100%',
                                            marginTop: '20px',
                                            display: 'block'
                                        }}
                                        loading={this.state.amp_story_publish_api_running}
                                        disabled={
                                            this.state.amp_story === null
                                            || (this.state.amp_story && this.state.amp_story.stage == "published")
                                            || this.state.amp_story_api_running
                                            || this.state.amp_story_update_api_running
                                        }
                                    >
                                        Publish AMP Story WITHOUT Chaining
                                    </Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="Do you really want to publish this AMP story with Chaining? Editing will not be possible after publishing."
                                    onConfirm={(e) => {
                                        this.publishAmpStoryWithChaining()
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        type="primary"
                                        style={{
                                            width: '100%',
                                            marginTop: '20px',
                                            display: 'block'
                                        }}
                                        loading={this.state.amp_story_publish_api_running}
                                        disabled={
                                            this.state.amp_story === null
                                            || (this.state.amp_story && this.state.amp_story.stage == "published")
                                            || this.state.amp_story_api_running
                                            || this.state.amp_story_update_api_running
                                        }
                                    >
                                        Publish AMP Story With Chaining
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col span={5} style={{ paddingLeft: "20px"}}>
                                <Row>
                                    <Button
                                        style={{
                                            marginTop: '20px',
                                            display: 'block'
                                        }}
                                        onClick={() => navigator.clipboard.writeText(this.state.amp_story_html_url)}
                                        disabled={
                                            this.state.amp_story === null
                                            || this.state.amp_story_api_running
                                            || this.state.amp_story_update_api_running
                                        }
                                    >
                                        COPY AMP PREVIEW URL
                                    </Button>
                                </Row>
                                <Row>
                                    <Button
                                        style={{
                                            marginTop: '20px',
                                            display: 'block'
                                        }}
                                        onClick={() => navigator.clipboard.writeText(this.state.amp_story_video_url)}
                                        disabled={
                                            this.state.amp_story === null
                                            || this.state.amp_story_api_running
                                            || this.state.amp_story_update_api_running
                                        }
                                    >
                                        COPY AMP Video URL
                                    </Button>
                                </Row>

                                {/* {this.state.amp_story && this.state.amp_story.stage == "published" ? (
                                    <Row>
                                        <Button 
                                            style={{
                                                marginTop: '22px',
                                                display: 'block'
                                            }}
                                            onClick={() => navigator.clipboard.writeText(this.state.amp_story_publish_url + "/" + this.state.amp_story.id)}
                                            disabled={
                                                this.state.amp_story === null
                                                || this.state.amp_story_api_running
                                                || this.state.amp_story_update_api_running
                                                || this.state.amp_story_publish_api_running
                                            }
                                        >
                                            COPY AMP PUBLISHED URL
                                        </Button>
                                    </Row>
                                ) : (
                                    <></>
                                )} */}
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
