import {
    Button, Col, Input, Layout, message, Row, Card, Select, Empty, Tooltip,Pagination,Upload
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import {IMSBatchAddAPI} from '../apis/ims';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import {MultiUploadButton} from '../components/uploadButton';

import NoAuth from "../components/noAuth";

const { Search } = Input;
const { Header, Content, Footer } = Layout;

const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

export default class IMSAdd extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            id_token: null,
            input_image_url: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,


            typesArray: [],  // instead of 'type'
            preferredRegionsArray: [],  // instead of 'preferred_regions'
            search_texts: [],
            input_image_url: [],
            search_texts: [],
            random_meta: [],
            //type: null,
            types: ["LS", "raw"], // You probably initialize this somewhere
            preferred_regions: [],
            regions: ["in", "us", "jp", "id", "br", "mx", "co", "ph", "vn", "th", "my"], // You probably initialize this somewhere
            source_name: [],
            ims_add_api_running: false,
            modified_by : "glance.admin",
            meta: "",
            currentPage: 1,
            pageSize: 24,
            offset: 0,
            edited_images_map: {},
            ims_add_api_running: false,
            ims_add_api_response: null,
            uploadedImages: [],
            MAX_HEIGHT : '700px',
            fileList: []
            //const INITIAL_MAX_HEIGHT = '350px',
           // const [maxHeight, setMaxHeight] = useState(INITIAL_MAX_HEIGHT);
        };
        this.imageUpload = this.imageUpload.bind(this);

      }

      fillAll = () => {
        if (this.state.input_image_url.length > 0) {
            const {
                search_texts,
                random_meta,
                typesArray,
                preferredRegionsArray,
                source_name
            } = this.state;
    
            const firstValueSearchTexts = search_texts[0] || '';
            const firstValueRandomMeta = random_meta[0] || '';
            const firstValueTypesArray = typesArray[0];
            const firstValuePreferredRegions = preferredRegionsArray[0];
            const firstValueSourceName = source_name[0] || '';
    
            this.setState({
                search_texts: Array(this.state.input_image_url.length).fill(firstValueSearchTexts),
                random_meta: Array(this.state.input_image_url.length).fill(firstValueRandomMeta),
                typesArray: Array(this.state.input_image_url.length).fill(firstValueTypesArray),
                preferredRegionsArray: Array(this.state.input_image_url.length).fill(firstValuePreferredRegions),
                source_name: Array(this.state.input_image_url.length).fill(firstValueSourceName),
            });
        }
    };
    
      clearAll = async () => {
        this.setState({
            ims_add_api_running:  false,
            ims_add_api_response: null,
            MAX_HEIGHT : '700px',
            typesArray: [],  // instead of 'type'
            preferredRegionsArray: [],  // instead of 'preferred_regions'
            search_texts: [],
            input_image_url: [],
            search_texts: [],
            random_meta: [],
            source_name: [],
            modified_by : "glance.admin",
            fileList: [] ,
        })

    }
      imageUpload = async () => {
        this.setState({
            ims_add_api_running: true,
            ims_add_api_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            const images = this.state.input_image_url.map((url, index) => ({
                image_url: url,
                text: this.state.search_texts[index] || "", // Use the search_texts array based on index
                preferred_regions: this.state.preferredRegionsArray[index],
                type: this.state.typesArray[index],
                source_name: this.state.source_name[index] || "", // Use the source_name array based on index
                modified_by: this.state.modified_by,
                meta: {
                    extra: this.state.random_meta[index] || "", // Use the random_meta array based on index
                    preferred_regions: this.state.preferred_regions, // If preferred_regions is different for each image, adjust this
                }
            }));
    
            const hasEmptySourceName = images.some(img => !img.source_name || img.source_name === "");
            const payload = { images };
            console.log("payload: ", payload)
            //const payload = { images };
            //console.log("payload: ", payload)
            var status_code = 200;
            if (!hasEmptySourceName) { 
            try {
                IMSBatchAddAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Image Upload Completed!")
                        this.setState({MAX_HEIGHT : '350px'})
            
                    }
                    this.setState({
                        ims_add_api_running: false,
                        ims_add_api_response: response,
                    })
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to upload images! Most likely due to high load. Try again :)")
                }
                this.setState({
                    ims_add_api_running: false,
                })
            }
        }
        else{
            message.error("Failed to upload images! Please enter source name for all images:)")
            this.setState({
                ims_add_api_running: false,
            })
        }
        })
    }
    
    componentDidMount(){
        // DO NOTHING HERE
    }

    submitData = async () => {
        const payload = this.state.input_image_url.map((url, index) => ({
          image: url,
          text: this.state.search_texts[index] || ""
        }));
    
        try {
          const response = await axios.post('YOUR_BACKEND_API_ENDPOINT', payload);
          console.log('Data sent successfully:', response.data);
        } catch (error) {
          console.error('Error sending data:', error);
        }
      }
    
      handleTextInputChange = (event, index) => {
        const newSearchTexts = [...this.state.search_texts];
        newSearchTexts[index] = event.target.value;
        this.setState({ search_texts: newSearchTexts });
      }

    render() {
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <br/>
            <br/>
            <h3> Upload Images to the System</h3>
            <Row>
            <Col span={3}>
            <MultiUploadButton
               ref={ref => this.multiUploadButtonRef = ref}
                signin_name={this.props.username}
                upload_loading={this.state.image_upload_loading}
                upload_endpoint={this.state.upload_endpoint}
                id_token={this.props.idToken}
                fileList={this.state.fileList} 
                onImUploadChange={(info)=>{
                    const { fileList } = info;
                    const doneFiles = fileList.filter(file => file.status === 'done');
                    const fileUrls = doneFiles.map(file => {
                        return file.response.image_url;
                    });
                    this.setState({
                        fileList: info.fileList, 
                        input_image_url: fileUrls,
                        modified_by: this.props.username
                    },  ()=> {
                        console.log("input_image_url", this.state.input_image_url, fileUrls)
                    })
                }}
                />
            </Col>
            <col></col>
            <div style={{ maxHeight: this.state.MAX_HEIGHT, overflowY: 'auto', border: '1px solid #ddd', padding: '10px' }}>
            {this.state.input_image_url && this.state.input_image_url.map((url, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
            <Row gutter={12}>
            <Col span={3}>
                <img 
                        src={url} 
                        alt={`Uploaded Image ${index + 1}`}
                        style={{ width: '100px', height: 'auto', display: 'block', marginBottom: '10px' }} 
                    />
                </Col>
                <Col span={8}>
                    <TextArea 
                        rows={2} 
                        style={{ width: '100%', marginBottom: '10px' }} 
                        placeholder={`Enter text for Image ${index + 1}`} 
                        value={this.state.search_texts[index] || ''}
                        onChange={(e) => this.handleTextInputChange(e, index)} 
                    />
                </Col>
                <Col span={3}>
                    <TextArea 
                        rows={2} 
                        style={{ width: '100%' }} 
                        placeholder="(Optional) random metadata" 
                        value={this.state.random_meta[index] || ''}
                        onChange={(e) => {
                            let meta = [...this.state.random_meta];
                            meta[index] = e.target.value;
                            this.setState({ random_meta: meta });
                        }}
                    />
                </Col>
                <Col span={2}>
                    <Tooltip trigger={['focus']} title={"Use LS only if images are based on LS standards"} placement="topLeft">
                        <Select
                            allowClear
                            placeholder="Mode"
                            style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                            }}
                            value={this.state.typesArray[index]}
                            onChange={(e) => {
                                let typesArr = [...this.state.typesArray];
                                typesArr[index] = e;
                                this.setState({ typesArray: typesArr });
                            }}
                        >
                            {this.state.types.map((e) => <Option key={e} value={e}>{e}</Option>)}
                        </Select>
                    </Tooltip>
                </Col>
                <Col span={3}>
                    <Tooltip trigger={['focus']} title={"Any preference on country?"} placement="topLeft">
                        <Select
                            allowClear
                            mode="multiple"
                            placeholder="Preferred Regions"
                            style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                            }}
                            value={this.state.preferredRegionsArray[index]}
                            onChange={(e) => {
                                let preferredRegionsArr = [...this.state.preferredRegionsArray];
                                preferredRegionsArr[index] = e;
                                this.setState({ preferredRegionsArray: preferredRegionsArr });
                              }}
                        >
                            {this.state.regions.map((e) => <Option key={e} value={e}>{e}</Option>)}
                        </Select>
                    </Tooltip>
                </Col>
                <Col span={4}>
                    <TextArea 
                        rows={1} 
                        style={{ width: '100%' }} 
                        placeholder="Source Name" 
                        value={this.state.source_name[index] || ''}
                        onChange={(e) => {
                            let names = [...this.state.source_name];
                            names[index] = e.target.value;
                            this.setState({ source_name: names });
                        }}
                    />
                </Col>
            </Row>
        </div>
        ))}
       </div>   
       <Row>
        {this.state.input_image_url.length > 0 && (
            <Col>
            <Button onClick={this.imageUpload} loading={this.state.ims_add_api_running}>
                Submit
            </Button>
            </Col>
        )}
        
         {this.state.input_image_url.length > 0 && (
            <Col>
            <Button onClick={this.clearAll}>
                Clear All
            </Button>
            </Col>
        )}
        {this.state.input_image_url.length > 0 && (
            <Col>
            <Button onClick={this.fillAll}>
                Fill All
            </Button>
             </Col>
        )}
        </Row>
            </Row>
            <br>
            </br>
            </Content>
            <br></br>
            <br></br>

            <Content>
    <Row style={{ overflow: "scroll", justifyContent: 'center', width: '100%' }}>
        {
            this.state.ims_add_api_response === null ? (
                <Empty
                    style={{
                        paddingTop: "5px",
                        width: "100%",
                    }}
                />
            ) : (
                <>
                    <Row justify="center" style={{ marginBottom: '20px', width: '100%' }}>
                        <Col>
                            <Pagination
                                current={this.state.currentPage}
                                pageSize={this.state.pageSize}
                                total={this.state.ims_add_api_response.message.length}
                                onChange={(page, pageSize) => {
                                    var offset = (page - 1) * this.state.pageSize;
                                    this.setState({
                                        currentPage: page,
                                        offset: offset,
                                    });
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} justify="center" style={{ width: '100%' }}>
                        {
                            Array.isArray(this.state.ims_add_api_response.message) 
                            ? this.state.ims_add_api_response.message.map((item, idx) => (
                                <Col span={6} key={idx} style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                                    <a
                                        href={item.spec.image_urls[item.spec.image_urls.length - 1]}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Card
                                            hoverable
                                            style={{ width: 240 }}
                                            cover={<img alt="" src={item.spec.image_urls[item.spec.image_urls.length - 1]} />}
                                        >
                                            <Meta title="Search Text" description={item.text} />
                                            <Meta title="Type" description={item.type} />
                                            <Meta title="Source" description={item.source_name} />
                                        </Card>
                                    </a>
                                </Col>
                            ))
                            : null
                        }
                    </Row>
                </>
            )
        }
    </Row>
</Content>
          </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>

            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                {createForm}
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                alt="logo"
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>
          </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuth />
            </UnauthenticatedTemplate>
            </>
        )
    }
}
