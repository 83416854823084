import React from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Button, Modal, Row, Col, Layout, Input, Empty, Checkbox } from "antd";
import { MultiUploadButton } from "./uploadButton";
const { Content } = Layout;

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const DocubotProjectFilesModal = (props) => {
        /*
        [
  {
    "id": "string",
    "name": "string",
    "description": "string",
    "owner": "string",
    "documents": [],
    "indexed": false,
    "spec": {},
    "modified_at": "2023-04-03T10:37:31.156Z",
    "indexed_at": "2023-04-03T10:37:31.156Z",
    "created_at": "2023-04-03T10:37:31.156Z"
  }
]

        */

  return (
    <Modal
      title="Docubot Project Files"
      centered
      open={props.isOpen}
      onOk={props.onOk}
      onCancel={props.onOk}
      closable={false}
      width={1200}
      footer={[
        <Button key="done" type="primary" onClick={props.onOk}>
          Done
        </Button>,
      ]}
    >
      <Content>
        <Row>
          <Col span={20}>
            <Row>
            <Col span={6}>
                <MultiUploadButton
                  signin_name={props.signin_name}
                  upload_loading={props.upload_loading}
                  upload_endpoint={props.upload_endpoint}
                  upload_button_label="Upload PDF"
                  id_token={props.id_token}
                  onImUploadChange={props.onImUploadChange}
                />
              </Col>
              <Col span={8}>
                <Button type="primary"
                style={{
                  paddingLeft: "10px",
                }}
                    onClick={async () => {
                        console.log("Adding to index", props.new_project_document_urls)
                        await props.addToIndex()
                    }}
                    loading={props.add_to_index_running}
                    htmlType="add">
                    Add to Index
                </Button>
              </Col>
            </Row>
            <br></br>
            <Row
              style={{
                maxHeight: "70vh",
                overflow: "scroll",
              }}
            >
              <ul>
              {
              props.project == null
              ? (
                <Empty
                  style={{
                    paddingTop: "5px",
                    width: "100%",
                  }}
                />
              )
              : (
                props.project.documents.map((doc) => {
                    return (
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={ "file_url" in doc ? doc.file_url : "/blank"}>
                            {doc.file_name}
                          </a> {doc.indexed ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                        </li>
                    )
                })
              )}
              </ul>
            </Row>
          </Col>
        </Row>
      </Content>
    </Modal>
  );
};
