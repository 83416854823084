const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;

export async function GetArticlePublishers(publisher_id, token, skip=0, limit=30){
    console.log('About to get publisher articles for this publisher:', publisher_id)
    let request={
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    }

    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/publisher/feed?id="+publisher_id+"&skip="+skip+"&limit="+limit, request);
    if(rawResponse.ok){
        const content = await rawResponse.json();  
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    let content=null
    try {
      content = await rawResponse.json()
    } catch (error) {
      content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  };
  

export async function GetArticleAPI(token, articleId){
    let request={
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    }
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/article/get?id="+articleId, request);
    if(rawResponse.ok){
        const content = await rawResponse.json();  
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
      content = await rawResponse.json()
    } catch (error) {
      content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  };

  
export function GetRSS(publisher_id, custom_publisher){
    if (publisher_id == "create") {
      return API_BASE_URL + "/api/v1/publisher/rss?id="+custom_publisher
    }
    return API_BASE_URL + "/api/v1/publisher/rss?id="+publisher_id
  }

  export async function MultigenerateArticleAPI(payload,token=null){
    let request={
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify(payload)
    }
    if(token)
      request.headers['Authorization'] = `Bearer ${token}`;
      const rawResponse = await fetch(API_BASE_URL + "/api/v1/aipublisher/multigenerate",
      request
    );
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
      content = await rawResponse.json()
    } catch (error) {
      content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  }

  export async function TranslateArticleAPI(payload,token=null){
    let request={
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify(payload)
    }
    if(token)
      request.headers['Authorization'] = `Bearer ${token}`;
      const rawResponse = await fetch(API_BASE_URL + "/api/v1/article/translate",
      request
    );
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
      content = await rawResponse.json()
    } catch (error) {
      content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  }


  export async function UpdateArticleAPI(payload,token=null){
    let request={
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify(payload)
    }
    if(token)
      request.headers['Authorization'] = `Bearer ${token}`;
      const rawResponse = await fetch(API_BASE_URL + "/api/v1/article/update",
      request
    );
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
      content = await rawResponse.json()
    } catch (error) {
      content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  }


  export async function CreateArticleAPI(payload,token=null){
    let request={
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify(payload)
    }
    if(token)
      request.headers['Authorization'] = `Bearer ${token}`;
      const rawResponse = await fetch(API_BASE_URL + "/api/v1/article/create",
      request
    );
    if(rawResponse.ok){
        const content = await rawResponse.json();
        return {"status":true,
            "message":content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
            }
    }
    // TODO: Add the same error extract method to other api calls
    let content=null
    try {
      content = await rawResponse.json()
    } catch (error) {
      content = await rawResponse.text()
    }
    return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
  }


export async function SearchArticleAPI(payload,token=null){
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/article/search",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();
      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function MultipublishArticlesAPI(payload,token=null){
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/article/multipublish",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();
      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function MultiupdateArticlesAPI(payload,token=null){
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/article/multiupdate",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();
      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function DuplicateArticleAPI(payload,token=null){
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/article/duplicate",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();
      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}
