import { FontSizeOutlined, FileImageOutlined, SketchOutlined } from '@ant-design/icons';
import {
    Button, Col, Divider, Image, Input, Layout, message, Row,
    Select, Tabs, Typography, Upload, Empty, Spin, Result, InputNumber,
    Radio, Tooltip
} from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import NavBar from '../components/header';
import ReactPlayer from 'react-player';
import uuid4 from "uuid4";
import { VideoGenerateAPI, VideoUpdateAPI, ClipGenerateAPI, IMSV2ImageSelectionAPI, VideoStitchAPI } from "../apis/vcs";
import ScrollVideos from "../components/videoScroll"
import { ImageSearchModal } from "../components/imageSearchModal";
import { TransitionOverlayModal } from "../components/transitionOverlayModal"
import { VideoEffectModal } from "../components/videoEffectModal"
import { PrefixEffectModal } from "../components/prefixEffectModal"
import { TextOverlayModal } from "../components/textOverlayModal"
import TextArea from 'antd/lib/input/TextArea';
import { UploadButton } from '../components/uploadButton';
import { FontDropDown, FontDropDownLite } from "../components/fontDropDown"
import { AudioDropDown } from "../components/audioDropDown"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Title, Text } = Typography;
const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { Option } = Select;

export default class CreateLex extends React.Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,

            // create ui states
            voice_over_options: [
                "speech",
                "bright",
                "funny",
                "inspirational",
                "serious"
            ],
            voice_over_options_clip_gen: [
                "bright",
                "funny",
                "inspirational",
                "serious"
            ],
            voice_over_to_songs: {
                "bright": ["catch-it-117676.mp3", "electronic-future-beats-117997.mp3", "goldn-116392.mp3", "happy-day-113985.mp3", "inspiring-cinematic-ambient-116199.mp3", "lofi-study-112191.mp3", "minimal-inspiring-ambient-121005.mp3", "playing-in-color-120336.mp3", "price-of-freedom-33106.mp3"],
                "funny": ["catch-it-117676.mp3", "comedian-117016.mp3", "former-102685.mp3", "fun-times-together-112809.mp3", "love-in-vintage-film-117015.mp3"],
                "inspirational": ["cinematic-documentary-115669.mp3"],
                "serious": ["bright-upbeat-uplifting-corporate-120724.mp3", "cinematic-ambient-piano-118668.mp3", "cinematic-cello-115667.mp3", "cinematic-documentary-115669.mp3", "documentary-11052.mp3", "drone-9708.mp3", "euphoria-121294.mp3", "fake-news-117862mp3", "field-grass-115973.mp3", "price-of-freedom-33106.mp3", "sad-dramatic-time-119364.mp3", "sad-piano-atmosphere-22139.mp3", "sad-piano-calm-110613.mp3", "spirit-landscape-118015.mp3"]

            },
            soundtrack_assets_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/background-music/",
            // soundtrack_assets_cdn_prefix: "/Users/pradeep.m/Desktop/Workspace/Github.com/centralai-video-creation-service/vcs/operators/audio/soundtracks/",
            //genders
            genders: [
                "male",
                "female"
            ],
            lang_options: [
                "ba",
                "pt-BR",
                "es-MX",
                "es-CO",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr"
            ],
            effect_options_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/effect-gifs/",
            effect_options: [
                "zoomin",
                "zoomout",
                "righttilt_zoomin",
                "lefttilt_zoomin",
                "righttilt_zoomout",
                "lefttilt_zoomout"
            ],
            text_overlay_template_options_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/text-overlay-gifs/",
            text_overlay_template_options: [
                "random",
                'tint.png', 'samples_t1.png',
                'youtube_cc_white', 'youtube_cc_black', 'half_youtube_cc_white', 'half_youtube_cc_black',
                'cc_line_white', 'cc_line_black', 'border_lines_white', 'border_lines_black',
                'side_lines_white', 'side_lines_black', 'sliding_text_white', 'sliding_text_black',
                'golden_line_white', 'golden_line_black', 'sidewise_parallel_lines_white', 'sidewise_parallel_lines_black',
                'side_umbrella_white', 'side_umbrella_black',
                'top_lines_white', 'top_lines_black', 'parallel_lines_white', 'parallel_lines_black'
            ],


            // transition_overlay_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/transition-overlay/",
            transition_overlay_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/transition-overlay-gifs/",
            transition_overlay_options: [
                "none",
                "random",
            ],
            transition_overlay_id_to_name_mapping: {
                "4.webm": "Racing Blue",
                "black_14.webm": "Black White Brush 1",
                "black_13.webm": "Black White Brush 2",
                "21.webm": "Fair & Square Blue",
                "23.webm": "Fair & Square Rainbow",
                "9.webm": "Five Bars Walk Into The Bar",
                "1.webm": "Flash Before My Eyes.webm",
                "22.webm": "Green Arrows",
                "16.webm": "Heavy Rotation 1",
                "19.webm": "Heavy Rotation 2",
                "10.webm": "Life in Circle 1",
                "15.webm": "Life in Circle 2",
                "17.webm": "Three Blue Bars",
                "26.webm": "Simple Blue Cracks",
                "24.webm": "Colourful Swirl",
                "25.webm": "Double Pink Cracks"
            },

            // fonts
            language_to_fonts: {
                "mr": ["default.ttf"],
                "te": ["Ramabhadra-Regular.ttf", "default.ttf", "Timmana-Regular.ttf"],
                "ba": ["HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf",
                    "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf",
                    "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf", "Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf"],
                "kn": ["BalooTamma2-Bold.ttf", "default.ttf"],
                "bn": ["kalpurush.ttf", "default.ttf", "Siyamrupali.ttf", "HindSiliguri-Bold.ttf"],
                "hi": ["NotoSans-Regular.ttf", "default.ttf", "NotoSans-Bold.ttf", "Mukta-Bold.ttf", "Mukta-Medium.ttf"],
                "en": ["Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf", "HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf", "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf", "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf"],
                "ta": ["ArimaMadurai-Bold.ttf", "MuktaMalar-ExtraBold.ttf", "default.ttf", "MeeraInimai-Regular.ttf", "ArimaMadurai-Black.ttf", "ArimaMadurai-ExtraBold.ttf"],
                "pt-BR": ["Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf", "HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf", "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf", "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf"],
                "es-CO": ["Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf", "HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf", "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf", "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf"],
                "es-MX": ["Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf", "HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf", "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf", "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf"],
            },

            // fonts to font image mapping
            fonts_to_images: {
                "AlfaSlabOne-Regular.ttf": "alphaslabone.webp",
                "Comfortaa-Bold.ttf": "comfortaa.bold.webp",
                "Exo-ExtraBoldItalic.ttf": "exo.extra-bold-italic.webp",
                "Exo-ExtraBold.ttf": "exo.extra-bold.webp",
                "HoltwoodOneSC.ttf": "holtwood-one-sc.regular.webp",
                "Notable-Regular.ttf": "notable.webp",
                "Oswald-Bold.ttf": "oswald.bold.webp",
                "PermanentMarker-Regular.ttf": "permanent-marker.regular.webp",
                "Play-Bold.ttf": "play.bold.webp",
                "Righteous-Regular.ttf": "righteous.regular.webp",
                "Roboto-Bold.ttf": "roboto.bold.webp",
                "Roboto-Regular.ttf": "roboto.regular.webp",
                "Archivo-Regular.ttf": "Archivo-Regular.webp",
                "Barlow-Regular.ttf": "Barlow-Regular.webp",
                "Inter-Regular.ttf": "Inter-Regular.webp",
                "Lato-Regular.ttf": "Lato-Regular.webp",
                "Urbanist-Regular.ttf": "Urbanist-Regular.webp"
            },
            font_assets_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/fonts/",

            color_options: [
                "black",
                "white"
            ],

            // prefix effects
            prefix_effect_mp4_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/prefix-effect/",
            prefix_effect_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/prefix-effect-gifs/",
            prefix_effect_options: [
                "breaking_news_1.mp4",
                "breaking_news_2.mp4",
                "recipe_1.mp4",
                "recipe_2.mp4",
                "on_this_day_1.mp4",
                "travel_1.mp4",
                "travel_2.mp4"
            ],
            font_size_options: [
                2,
                4,
                6,
                8,
                10,
                12,
                14,
                16,
                18,
                20,
                22,
                24,
                26,
                28,
                30,
                32,
                34,
                36,
                38,
                40,
                42,
                44,
                46,
                48,
                50,
                52,
                54,
                56,
                58,
                60
            ],

            // videos options
            generated_clips: [
                "https://glance.glance-cdn.com/public/content/assets/videos/bf49af5aba_29072022_072250_000022.mp4",
                "https://glance.glance-cdn.com/public/content/assets/videos/c4587060c4_29072022_091224_000012.mp4",
                "https://glance.glance-cdn.com/public/content/assets/videos/bfa4965ea7_01082022_033248_000032.mp4",
                "https://glance.glance-cdn.com/public/content/assets/videos/34c6ef8595_01082022_092219_000022.mp4"
            ],
            output_video_link: "https://glance.glance-cdn.com/public/content/assets/videos/a734fa55e7_29072022_035858_000058.mp4",
            player_width: 300,
            player_height: 450,
            current_focus_clip_index: 0,
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",

            // api related states
            url: null,
            num_points: 3,
            voice_over: "speech",
            voice_over_song: "random",
            text_overlay: "youtube_cc_black",
            font: null,
            language: "en",
            gender: null,

            // default transition states
            video_generate_api_running: false,
            video_update_api_running: false,
            video_generate_api_response: null,
            clip_generate_api_running: false,
            video_stitch_response: null,
            result_col_span: 0,

            // Modals
            // Image Search Modal
            image_search_modal_open: false,
            transition_overlay_modal_open: false,
            video_effect_modal_open: false,
            text_overlay_modal_open: false,
            text_overlay_modal_open_parent: false,
            prefix_effect_modal_open: false,

        };
        for (var key in this.state.transition_overlay_id_to_name_mapping) {
            this.state.transition_overlay_options.push(key);
        }

        this.videoGenerate = this.videoGenerate.bind(this);
        this.clipGenerate = this.clipGenerate.bind(this);
        this.imageSelection = this.imageSelection.bind(this);
        this.videoStitch = this.videoStitch.bind(this);
        this.addNewClip = this.addNewClip.bind(this);
        this.addNewVideoClip = this.addNewVideoClip.bind(this);
        this.removeClip = this.removeClip.bind(this);

        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.toggleTransitionOverlayModal = this.toggleTransitionOverlayModal.bind(this);
        this.toggleVideoEffectModal = this.toggleVideoEffectModal.bind(this);
        this.toggleTextOverlayModal = this.toggleTextOverlayModal.bind(this);
        this.togglePrefixEffectModal = this.togglePrefixEffectModal.bind(this);
    }


    videoGenerate = async () => {
        this.setState({
            video_generate_api_running: true,
            video_generate_api_response: null,
            result_col_span: 0,
            video_stitch_response: null
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                url: this.state.url,
                language: this.state.language,
                voice_over: this.state.voice_over,
                voice_over_song: this.state.voice_over_song,
                num_points: this.state.num_points,
                gender: this.state.gender,
                text_overlay: this.state.text_overlay,
                font: this.state.font,
            }
            try {
                VideoGenerateAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Video Generation API failed!")
                    } else {
                        message.destroy()
                        message.success("Videos Generated!")
                    }
                    // console.log(response)
                    this.setState({
                        video_generate_api_running: false,
                        video_generate_api_response: response
                    })
                })
            } catch (error) {
                message.destroy()
                message.error("Failed to generate video!")
                this.setState({
                    video_generate_api_running: false,
                })
            }
        });
    }

    videoUpdate = async () => {
        this.setState({
            video_update_api_running: true,
            result_col_span: 0,
            video_stitch_response: null,
            clip_generate_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            console.log("Inside videoUpdate")
            const payload = this.state.video_generate_api_response.message
            console.log("Payload " + payload)
            try {
                VideoUpdateAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Video Updation API failed!")
                    } else {
                        message.destroy()
                        message.success("Videos Updated!")
                    }
                    this.setState({
                        video_update_api_running: false,
                        video_generate_api_response: response,
                        clip_generate_api_running: false,
                    })
                })
            } catch (error) {
                message.destroy()
                message.error("Failed to generate video!")
                this.setState({
                    video_update_api_running: false,
                    clip_generate_api_running: false,
                })
            }
        })
    }


    clipGenerate = async () => {
        this.setState({
            clip_generate_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            const payload = this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index]
            try {
                ClipGenerateAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Clip Generation API failed!")
                    } else {
                        message.destroy()
                        message.success("Clip Generated!")
                    }
                    var current_response = this.state.video_generate_api_response
                    if (response.status == true) {
                        current_response.message.clips[this.state.current_focus_clip_index] = response.message
                    }
                    this.setState({
                        clip_generate_api_running: false,
                        video_generate_api_response: current_response
                    })
                })
            } catch (error) {
                message.destroy()
                message.error("Clip generation failed!")
                this.setState({
                    clip_generate_api_running: false,
                })
            }
        });

    }

    videoStitch = async () => {
        this.setState({
            clip_generate_api_running: true,
            video_stitch_response: null,
            result_col_span: 0
        })
        this.props.refreshIdToken().then((token) => {
            const payload = this.state.video_generate_api_response.message
            try {
                VideoStitchAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Video's stitching API failed!")
                    } else {
                        message.destroy()
                        message.success("Videos Stitched!")
                    }
                    this.setState({
                        clip_generate_api_running: false,
                        video_stitch_response: response,
                        result_col_span: 5
                    })
                })
            } catch (error) {
                message.destroy()
                message.error("Failed to stich videos")
                this.setState({
                    clip_generate_api_running: false,
                })
            }
        });
    }

    addNewClip() {
        var id = uuid4();
        var new_clip = {
            "id": id,
            "url": "",
            "text": "",
            "image_ids": [
            ],
            "selected_image_id": "",
            "language": "en",
            "font": "Archivo-Regular.ttf",
            "duration": 7,
            "effect": "zoomin",
            "text_overlay_template_id": null,
            "background_audio_id": "serious",
            "background_song_id": null,
            "transition_overlay_template_id": "random",
            "spec": null
        }
        var current_response = this.state.video_generate_api_response
        var clips = current_response.message.clips
        var index = this.state.current_focus_clip_index + 1
        current_response.message.clips.splice(index, 0, new_clip)
        console.log(current_response)
        this.setState({
            video_generate_api_response: current_response,
            current_focus_clip_index: index
        })
    }

    addNewVideoClip(video_upload_url, type = "ad", force_index = -1) {
        var id = uuid4();
        var new_clip = {
            "id": id,
            "url": video_upload_url,
            "video_type": type,
            "text": "",
            "image_ids": [
            ],
            "selected_image_id": "",
            "language": "en",
            "font": "Archivo-Regular.ttf",
            "duration": 7,
            "effect": "zoomin",
            "text_overlay_template_id": null,
            "background_audio_id": "serious",
            "background_song_id": null,
            "transition_overlay_template_id": "random",
            "spec": null
        }
        var current_response = this.state.video_generate_api_response
        var clips = current_response.message.clips
        var index = this.state.current_focus_clip_index + 1
        if (force_index !== -1) {
            console.log("Using force index: ", force_index)
            index = force_index
        }
        else {
            console.log("No force index passed: ", force_index)
        }
        if (!Array.isArray(clips) || !clips.length) {
            // array does not exist, is not an array, or is empty
            // ⇒ do not attempt to process array
            // array does not exist or is empty
            clips = [new_clip]
        }
        else {
            clips.splice(index, 0, new_clip)
        }
        current_response.message.clips = clips
        console.log(current_response)
        this.setState({
            video_generate_api_response: current_response,
            current_focus_clip_index: index
        })
    }

    removeClip() {
        console.log("Deleting current focus clip: ", this.state.current_focus_clip_index)
        var current_response = this.state.video_generate_api_response
        current_response.message.clips.splice(this.state.current_focus_clip_index, 1)
        console.log("Updated clip packet: ", current_response)
        var current_focus_clip_index = Math.max(this.state.current_focus_clip_index - 1, 0)
        console.log("Updated current focus clip: ", current_focus_clip_index)
        this.setState({
            video_generate_api_response: current_response,
            current_focus_clip_index: current_focus_clip_index
        })
    }

    imageSelection = async (search_string) => {


        // TODO: Add image-selection API call
        // this.setState({
        //     clip_generate_api_running: true
        // })
        if (search_string == "") {
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        try {
            this.props.refreshIdToken().then((token) => {
                const payload = {
                    params : {
                    "title": search_string,
                    "max_count": 100
                    }
            }
                IMSV2ImageSelectionAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Image Selection API failed!")
                    } else {
                        message.destroy()
                        message.success("Fetched Some Fresh Images!")
                    }
                    response.message.image_ids = response.message.images.map(image => image.id);
                    var current_response = this.state.video_generate_api_response
                    if (response.status == true) {
                        current_response.message.clips[this.state.current_focus_clip_index].image_ids = response.message.image_ids
                        current_response.message.clips[this.state.current_focus_clip_index].selected_image_id = response.message.image_ids[0]

                    }
                    this.setState({
                        clip_generate_api_running: false,
                        video_generate_api_response: current_response
                    })
                })
            })
        } catch (error) {
            message.destroy()
            message.error("Failed to fetch images!")
            this.setState({
                clip_generate_api_running: false,
            })
        }
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleTransitionOverlayModal = () => {
        this.setState({
            transition_overlay_modal_open: !this.state.transition_overlay_modal_open
        })
    }

    toggleVideoEffectModal = () => {
        this.setState({
            video_effect_modal_open: !this.state.video_effect_modal_open
        })
    }

    toggleTextOverlayModal = () => {
        this.setState({
            text_overlay_modal_open: !this.state.text_overlay_modal_open
        })
    }

    toggleTextOverlayModalParent = () => {
        this.setState({
            text_overlay_modal_open_parent: !this.state.text_overlay_modal_open_parent
        })
    }

    togglePrefixEffectModal = () => {
        this.setState({
            prefix_effect_modal_open: !this.state.prefix_effect_modal_open
        })
    }

    componentDidMount() {
        // DONT NEED ANYTHING HERE
    }

    render() {
        const createForm = (
            <div>
                <ImageSearchModal
                    isOpen={this.state.image_search_modal_open}
                    onOk={this.toggleImageSearchModal}
                    imageSelection={this.imageSelection}
                    default_value={this.state.video_generate_api_response == null ?
                        "" :
                        this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].text}
                    image_list={this.state.video_generate_api_response == null ?
                        [] :
                        this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].image_ids}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                    imageOnSelect={(e) => {
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index].selected_image_id = e.target.value;
                        current_response.message.clips[this.state.current_focus_clip_index].background_image_url = this.state.image_cdn_prefix + e.target.value + ".jpg";
                        current_response.message.clips[this.state.current_focus_clip_index].override_image_source = null;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                    selectedImage={this.state.video_generate_api_response != null ?
                        this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].background_image_url : ""}

                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.state.id_token}
                    onImUploadChange={(e) => {
                        if (e.file.status == 'done') {
                            message.success('Image Uploaded!')
                            var current_response = this.state.video_generate_api_response
                            current_response.message.clips[this.state.current_focus_clip_index].selected_image_id = null;
                            current_response.message.clips[this.state.current_focus_clip_index].background_image_url = e.file.response.image_url;
                            this.setState({
                                video_generate_api_response: current_response
                            })
                        }
                    }}

                />
                <TransitionOverlayModal
                    isOpen={this.state.transition_overlay_modal_open}
                    onOk={this.toggleTransitionOverlayModal}
                    image_list={this.state.transition_overlay_options}
                    image_mapping={this.state.transition_overlay_id_to_name_mapping}
                    image_cdn_prefix={this.state.transition_overlay_prefix}
                    imageOnSelect={(e) => {
                        if (e.target.value == "none") {
                            e.target.value = null
                        }
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index].transition_overlay_template_id = e.target.value;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                />
                <VideoEffectModal
                    isOpen={this.state.video_effect_modal_open}
                    onOk={this.toggleVideoEffectModal}
                    image_list={this.state.effect_options}
                    image_cdn_prefix={this.state.effect_options_cdn_prefix}
                    imageOnSelect={(e) => {
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index].effect = e.target.value;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                />
                <TextOverlayModal
                    isOpen={this.state.text_overlay_modal_open}
                    onOk={this.toggleTextOverlayModal}
                    text_overlay_options={this.state.text_overlay_template_options}
                    image_cdn_prefix={this.state.text_overlay_template_options_cdn_prefix}
                    imageOnSelect={(e) => {
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index].text_overlay_template_id = e.target.value;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                    onClose={this.toggleTextOverlayModal}
                />

                <TextOverlayModal
                    isOpen={this.state.text_overlay_modal_open_parent}
                    onOk={this.toggleTextOverlayModalParent}
                    text_overlay_options={this.state.text_overlay_template_options}
                    image_cdn_prefix={this.state.text_overlay_template_options_cdn_prefix}
                    imageOnSelect={(e) => {
                        this.setState({
                            text_overlay: e.target.value
                        })
                    }}
                    onClose={this.toggleTextOverlayModalParent}
                />

                <PrefixEffectModal
                    isOpen={this.state.prefix_effect_modal_open}
                    onOk={this.togglePrefixEffectModal}
                    image_list={this.state.prefix_effect_options}
                    image_cdn_prefix={this.state.prefix_effect_prefix}
                    imageOnSelect={(e) => {
                        var video_url = this.state.prefix_effect_mp4_prefix + e.target.value
                        this.addNewVideoClip(video_url, "prefix", 0)
                    }}
                />
                <Divider orientation="left"
                    orientationMargin={0}
                    style={{
                        margin: '5px',
                        marginBottom: '15px',
                    }}
                >Speak to AI. Ask it to summarize an article, get you content, or simply submit a URL</Divider>

                <Row style={{ width: '100%' }}>

                    <Col span={15}>
                        <Text
                            style={{
                                marginLeft: '15px',
                                marginRight: '10px'
                            }}
                        > Prompt </Text>
                        <Tooltip trigger={['focus']} title={"Submit a URL or an Article. Speak to AI"} placement="topLeft">
                            <TextArea
                                style={{
                                    fontSize: '13px',
                                    minHeight: '75px'
                                }}
                                placeholder="Submit a link to an article or just the article text :) Speak to AI. "
                                onChange={(e) => {
                                    this.setState({
                                        url: e.target.value
                                    })
                                }}
                            />

                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Text
                            style={{
                                marginLeft: '15px',
                                marginRight: '5px',
                                fontSize: '13px'
                            }}
                        > Clip count </Text>
                        <Tooltip trigger={['focus']} title={"How many clips do you want?"} placement="topLeft">
                            <InputNumber
                                style={{
                                    width: '100%',
                                    marginLeft: '5px',
                                    marginRight: '5px'
                                }}
                                min={1} max={5} defaultValue={3} onChange={(e) => {
                                    this.setState({
                                        num_points: e
                                    })
                                }} />
                        </Tooltip>
                    </Col>

                    <Col span={4}>
                        <Row>
                            <Text
                                style={{
                                    marginLeft: '15px',
                                    marginRight: '25px'
                                }}
                            > Language & Font
                            </Text>
                        </Row>
                        <FontDropDownLite
                            language={this.state.language}
                            language_options={this.state.lang_options}
                            fonts={this.state.language_to_fonts}
                            second_font={(this.state.font == null)
                                ? "Archivo-Regular.ttf"
                                : this.state.font
                            }
                            fonts_to_images={this.state.fonts_to_images}
                            font_assets_cdn_prefix={this.state.font_assets_cdn_prefix}
                            onLanguageSelect={(e) => {
                                console.log("onLanguageSelect - " + e)
                                var temp_font = this.state.language_to_fonts[e][0];
                                this.setState({
                                    language: e,
                                    font: temp_font,
                                })
                            }}
                            secondFontChange={(e) => {
                                console.log("secondFontChange - " + e)
                                this.setState({
                                    font: e,
                                })
                            }}
                        />

                    </Col>


                    <Col span={3}>
                        <Text
                            style={{
                                marginLeft: '15px',
                                marginRight: '5px'
                            }}
                        > Select Theme </Text>
                        <Tooltip trigger={['focus']} title={"Overlay Theme?"} placement="topLeft">
                            <Button
                                onClick={this.toggleTextOverlayModalParent}
                                style={{
                                    width: '100%',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                }}
                            >
                                {this.state.text_overlay ? this.state.text_overlay : "Text Overlay"}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>

                <Divider orientation="left"
                    orientationMargin={0}
                    style={{
                        margin: '5px',
                        marginTop: '15px',
                        marginBottom: '15px',
                    }}
                >Select Audio Over Options</Divider>
                <Row>
                    <Col span={6}>
                    </Col>
                    <Col span={4}>
                        <Text
                            style={{
                                marginLeft: '15px',
                                marginRight: '5px'
                            }}
                        > Select Audio / Voiceover: </Text>

                        <Tooltip trigger={['focus']} title={"Type of voiceover for final video?"} placement="topLeft">
                            <Select
                                defaultValue="speech"
                                placeholder="Voice Over"
                                style={{
                                    width: '100%',
                                    paddingLeft: '5px',
                                    paddingRight: '5px'
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        voice_over: e
                                    })
                                }}
                            >
                                {this.state.voice_over_options.map((e) => {
                                    return <Option
                                        value={e}>{e}</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </Col>
                    <Col span={3}>
                        <Text
                            style={{
                                marginLeft: '15px',
                                marginRight: '5px'
                            }}
                        > Gender of the voice </Text>

                        <Tooltip trigger={['focus']} title={"Gender of voiceover for final video?"} placement="topLeft">
                            <Select
                                placeholder="Gender"
                                style={{
                                    width: '100%',
                                    paddingLeft: '5px',
                                    paddingRight: '5px'
                                }}
                                value={this.state.gender}
                                allowClear={true}
                                onChange={(e) => {
                                    this.setState({
                                        gender: e
                                    })
                                }}
                            >
                                {this.state.genders.map((e) => {
                                    return <Option
                                        value={e}>{e}</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </Col>
                    <Col span={3}>
                        <Text
                            style={{
                                marginLeft: '15px',
                                marginRight: '5px'
                            }}
                        > </Text>
                        <Button style={{ width: '100%', marginLeft: '5px' }}
                            type="primary"
                            loading={this.state.video_generate_api_running}
                            onClick={this.videoGenerate}
                        >Generate</Button>
                    </Col>
                </Row>
                <Divider orientation="left"
                    orientationMargin={0}
                    style={{
                        margin: '5px',
                        marginTop: '15px',
                        marginBottom: '15px',
                    }}
                >Change Voice Over Text (if needed) </Divider>

                <Row style={{
                    width: '100%',
                    height: '100%',
                    paddingTop: '15px'
                }}>
                    {this.state.video_generate_api_response == null ?
                        <Empty
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        /> :
                        <>
                            <Col span={22}>
                                <Tooltip trigger={['focus']} title={"Edit voice over text"} placement="topLeft">
                                    <TextArea
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '125px'
                                        }}
                                        placeholder="Edit voice over text"
                                        value={this.state.video_generate_api_response.message.spec["audio_text"]}
                                        onChange={(e) => {
                                            var current_response = this.state.video_generate_api_response
                                            current_response.message.spec["audio_text"] = e.target.value;
                                            this.setState({
                                                video_generate_api_response: current_response
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col span={2}>
                                <Button style={{ width: '100%', marginLeft: '5px' }}
                                    type="primary"
                                    loading={this.state.video_update_api_running}
                                    onClick={this.videoUpdate}
                                >Update</Button>
                            </Col>
                        </>
                    }
                </Row>

                <Divider orientation="left"
                    orientationMargin={0}
                    style={{
                        margin: '5px',
                        marginTop: '15px',
                        marginBottom: '15px',
                    }}
                >What did AI write back? </Divider>
                <Row style={{
                    width: '100%',
                    height: '100%',
                    paddingTop: '15px'
                }}>
                    {this.state.video_generate_api_response == null ?
                        <Empty
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        /> :
                        <>
                            <Col span={22}>
                                <Tooltip trigger={['focus']} title={"What points did the AI summarize?"} placement="topLeft">
                                    <TextArea
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '125px'
                                        }}
                                        placeholder="What points did the AI summarize?"
                                        value={this.state.video_generate_api_response.message.points}
                                        disabled={true}
                                    />
                                </Tooltip>
                            </Col>
                        </>
                    }
                </Row>
                <br></br>
                <br></br>
                <Row style={{ width: '100%' }}>
                    <Col span={4}>
                        <Button
                            onClick={this.addNewClip}
                        >Add Blank Clip</Button>
                    </Col>
                    <Col span={4}>
                        <Button
                            onClick={this.togglePrefixEffectModal}
                        >Add Prefix Clip</Button>
                    </Col>
                    <Col span={4}>
                        <UploadButton
                            signin_name={this.props.username}
                            upload_loading={this.state.image_upload_loading}
                            upload_endpoint={this.state.upload_endpoint}
                            id_token={this.props.idToken}
                            onImUploadChange={(e) => {
                                if (e.file.status == 'done') {
                                    message.success('Clip Uploaded!')
                                    var temp_video_upload_url = e.file.response.image_url
                                    this.addNewVideoClip(temp_video_upload_url)
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    {this.state.video_generate_api_response == null ?
                        <Empty
                            style={{
                                width: '100%',
                                height: '100%',
                                paddingTop: '150px'
                            }}
                        /> :
                        <>
                            <Col span={6}>
                                <Spin spinning={this.state.clip_generate_api_running}>
                                    <Tabs defaultActiveKey="Binge"
                                        onTabClick={(e) => {
                                            // console.log(e)
                                            if (e == "Images") {
                                                this.toggleImageSearchModal()
                                            }
                                        }}
                                        style={{ marginTop: '10px' }}>
                                        <Tabs.TabPane tab={
                                            <span>
                                                <FontSizeOutlined />
                                                General
                                            </span>
                                        }
                                            style={{
                                                maxHeight: '50vh',
                                                overflowY: 'scroll'
                                            }}
                                            key="Binge">
                                            <Input
                                                placeholder="Enter Binge Text"
                                                value={this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].text}
                                                onChange={(e) => {
                                                    var current_response = this.state.video_generate_api_response
                                                    current_response.message.clips[this.state.current_focus_clip_index].text = e.target.value;
                                                    this.setState({
                                                        video_generate_api_response: current_response
                                                    })
                                                }}
                                            />


                                            <Divider orientation="left"
                                                orientationMargin={0}
                                                style={{
                                                    margin: '5px'
                                                }}
                                            >Select Image</Divider>
                                            <Button
                                                onClick={this.toggleImageSearchModal}
                                                style={{
                                                    width: '100%',
                                                    overflow: 'clip'
                                                }}
                                            >
                                                {this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].background_image_url == undefined ?
                                                    "Select Image" :
                                                    this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].background_image_url
                                                }
                                            </Button>

                                            <Divider orientation="left"
                                                orientationMargin={0}
                                                style={{
                                                    margin: '5px'
                                                }}
                                            >Override Image Source</Divider>

                                            <Input
                                                placeholder="(Optional) Override Image Source"
                                                value={this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].override_image_source}
                                                onChange={(e) => {
                                                    var current_response = this.state.video_generate_api_response
                                                    current_response.message.clips[this.state.current_focus_clip_index].override_image_source = e.target.value;
                                                    this.setState({
                                                        video_generate_api_response: current_response
                                                    })
                                                }}
                                            />

                                            <Divider orientation="left"
                                                orientationMargin={0}
                                                style={{
                                                    margin: '5px'
                                                }}
                                            >Text Overlay Effect</Divider>
                                            <Button
                                                onClick={this.toggleTextOverlayModal}
                                                style={{
                                                    width: '100%'
                                                }}
                                            >
                                                {this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].text_overlay_template_id == undefined ?
                                                    "Select Text Overlay Effect" :
                                                    this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].text_overlay_template_id
                                                }
                                            </Button>


                                            <Divider orientation="left"
                                                orientationMargin={0}
                                                style={{
                                                    margin: '5px'
                                                }}
                                            >Video Effect</Divider>
                                            <Button
                                                onClick={this.toggleVideoEffectModal}
                                                style={{
                                                    width: '100%'
                                                }}
                                            >
                                                {this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].effect == undefined ?
                                                    "Select Video Effect" :
                                                    this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].effect
                                                }
                                            </Button>

                                            <Divider orientation="left" orientationMargin={0}
                                                style={{
                                                    margin: '5px'
                                                }}>Language & Fonts</Divider>

                                            <FontDropDown
                                                language={this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].language}
                                                language_options={this.state.lang_options}
                                                fonts={this.state.language_to_fonts}
                                                second_font={(this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].font == undefined)
                                                    ? "default.ttf"
                                                    : this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].font
                                                }
                                                color_options={this.state.color_options}
                                                color={(this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].font_color == undefined)
                                                    ? "white"
                                                    : this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].font_color
                                                }
                                                font_size_options={this.state.font_size_options}
                                                font_size={(this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].font_size == undefined)
                                                    ? 50
                                                    : this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].font_size
                                                }
                                                fonts_to_images={this.state.fonts_to_images}
                                                font_assets_cdn_prefix={this.state.font_assets_cdn_prefix}
                                                onLanguageSelect={(e) => {
                                                    console.log("onLanguageSelect - " + e)
                                                    var current_response = this.state.video_generate_api_response
                                                    current_response.message.clips[this.state.current_focus_clip_index].language = e;
                                                    current_response.message.clips[this.state.current_focus_clip_index].font = "default.ttf";
                                                    this.setState({
                                                        language: e,
                                                        video_generate_api_response: current_response
                                                    })
                                                }}
                                                secondFontChange={(e) => {
                                                    console.log("secondFontChange - " + e)
                                                    var current_response = this.state.video_generate_api_response
                                                    current_response.message.clips[this.state.current_focus_clip_index].font = e;
                                                    this.setState({
                                                        font: e,
                                                        video_generate_api_response: current_response
                                                    })
                                                }}
                                                oncolorChange={(e) => {
                                                    console.log("oncolorChange - " + e)
                                                    var current_response = this.state.video_generate_api_response
                                                    current_response.message.clips[this.state.current_focus_clip_index].font_color = e;
                                                    this.setState({
                                                        video_generate_api_response: current_response
                                                    })
                                                }}
                                                onfontsizeChange={(e) => {
                                                    console.log("onfontsizeChange - " + e)
                                                    var current_response = this.state.video_generate_api_response
                                                    current_response.message.clips[this.state.current_focus_clip_index].font_size = e;
                                                    this.setState({
                                                        video_generate_api_response: current_response
                                                    })
                                                }}
                                            />



                                            <Divider orientation="left"
                                                orientationMargin={0}
                                                style={{
                                                    margin: '5px'
                                                }}>Duration</Divider>
                                            <InputNumber
                                                style={{
                                                    width: '100%',
                                                    marginLeft: '1px'
                                                }}
                                                min={1} max={15}
                                                value={this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].duration}
                                                onChange={(e) => {
                                                    var current_response = this.state.video_generate_api_response
                                                    current_response.message.clips[this.state.current_focus_clip_index].duration = e;
                                                    this.setState({
                                                        video_generate_api_response: current_response
                                                    })
                                                }}
                                            />

                                            <Divider orientation="left"
                                                orientationMargin={0}
                                                style={{
                                                    margin: '5px'
                                                }}>Transition Overlay</Divider>
                                            <Button
                                                onClick={this.toggleTransitionOverlayModal}
                                                style={{
                                                    width: '100%'
                                                }}
                                            >
                                                {this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].transition_overlay_template_id == undefined ?
                                                    "Select Transition Overlay" :
                                                    this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].transition_overlay_template_id
                                                }
                                            </Button>
                                            <br></br>
                                            <br></br>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={
                                                <span>
                                                    <FileImageOutlined />
                                                    Images
                                                </span>
                                            } key="Images"
                                        >
                                            <p>
                                                {this.state.video_generate_api_response != null ?
                                                    this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].selected_image_id :
                                                    'NA'
                                                }
                                            </p>
                                            <img
                                                width={120}
                                                src={this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].background_image_url}
                                            ></img>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab={
                                            <span>
                                                <SketchOutlined />
                                                Advanced
                                            </span>
                                        } key="3">
                                            Coming Soon!
                                        </Tabs.TabPane>
                                    </Tabs>
                                    <br />
                                    <br />
                                    <Button
                                        loading={this.state.clip_generate_api_running ? true : false}
                                        style={{ width: '100%' }}
                                        onClick={async () => {
                                            await this.clipGenerate()
                                        }}
                                    >Generate Clip</Button>
                                </Spin>
                            </Col>
                            <Col span={18 - this.state.result_col_span}
                                style={{
                                    padding: '10px',
                                    width: '100%',
                                    overflow: 'scroll'
                                }}
                            >
                                <Spin spinning={this.state.clip_generate_api_running}>
                                    <Row>
                                        <ScrollVideos
                                            video_generate_api_response={this.state.video_generate_api_response}
                                            current_focus_clip_index={this.state.current_focus_clip_index}
                                            player_height={this.state.player_height}
                                            player_width={this.state.player_width}
                                            scrollRadioChange={(e) => {
                                                // console.log(this.state.video_generate_api_response)
                                                this.setState({
                                                    current_focus_clip_index: e.target.value,
                                                    video_generate_api_response: this.state.video_generate_api_response
                                                })
                                            }}
                                            deleteClip={this.removeClip}
                                        />
                                    </Row>
                                    <Row style={{
                                        padding: '10px',
                                        width: '100%'
                                    }}>
                                        <center style={{
                                            width: '100%'
                                        }}>
                                            <Button
                                                style={{
                                                    width: '50%'
                                                }}
                                                type='primary'
                                                onClick={async () => {
                                                    await this.videoStitch()
                                                }}
                                            >
                                                Stitch
                                            </Button>
                                        </center>
                                    </Row>
                                </Spin>
                            </Col>
                            <Col span={this.state.result_col_span}
                                style={{
                                    hidden: this.state.video_stitch_response == null ? true : false
                                }}
                            >
                                <ReactPlayer
                                    style={{
                                        paddingTop: '10px',
                                        paddingLeft: '20px',
                                    }}
                                    controls
                                    // width="100%"
                                    width={300}
                                    height={this.state.player_height}
                                    url={this.state.video_stitch_response == null ? 'none' : this.state.video_stitch_response.message.url} />
                            </Col>
                        </>
                    }
                </Row>

            </div>
        )
        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{ 'min-height': '100vh' }}>
                        <NavBar
                            authHandler={async (instance) => { await this.authHandler(instance) }}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 95 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>


                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center', fontSize: '17px' }}>
                            <img
                                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                                style={{
                                    height: '20px',
                                    display: 'inline',
                                    paddingRight: '10px'
                                }} />
                            ©2021 Powered by <b>AI</b></Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
