import {
    Button, Col, Layout, message, Row, Typography, Select, DatePicker, Space, Popconfirm
} from 'antd';
import React from 'react';
import moment from "moment";
import NavBar from '../components/header';
import { VideoGenerateAPI, VideoUpdateAPI, VideoUpdatedbAPI, VideoDeleteAPI, VideoPublishAPI, VideoSearchAPI, ClipGenerateAPI, IMSV2ImageSelectionAPI, VideoStitchAPI, VideoCreateAPI } from "../apis/vcs";
import uuid4 from "uuid4";
import { LanguageDropDown } from "../components/languageDropDown"
import { VideoGeneratorModal } from '../components/videoGeneratorModal';
import { VideoEditorModal } from '../components/videoEditorModal';
import { ImageSearchModal } from "../components/imageSearchModal";
import { TransitionOverlayModal } from "../components/transitionOverlayModal"
import { VideoEffectModal } from "../components/videoEffectModal"
import { PrefixEffectModal } from "../components/prefixEffectModal"
import { TextOverlayModal } from "../components/textOverlayModal"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Content, Footer } = Layout;
const { Text } = Typography;


export default class VideoPortal extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,

            // create ui states
            voice_over_options: [
                "speech",
                "bright",
                "funny",
                "inspirational",
                "serious"
            ],
            voice_over_options_clip_gen: [
                "bright",
                "funny",
                "inspirational",
                "serious"
            ],
            voice_over_to_songs: {
                "bright": ["catch-it-117676.mp3", "electronic-future-beats-117997.mp3", "goldn-116392.mp3", "happy-day-113985.mp3", "inspiring-cinematic-ambient-116199.mp3", "lofi-study-112191.mp3", "minimal-inspiring-ambient-121005.mp3", "playing-in-color-120336.mp3", "price-of-freedom-33106.mp3"],
                "funny": ["catch-it-117676.mp3", "comedian-117016.mp3", "former-102685.mp3", "fun-times-together-112809.mp3", "love-in-vintage-film-117015.mp3"],
                "inspirational": ["cinematic-documentary-115669.mp3"],
                "serious": ["bright-upbeat-uplifting-corporate-120724.mp3", "cinematic-ambient-piano-118668.mp3", "cinematic-cello-115667.mp3", "cinematic-documentary-115669.mp3", "documentary-11052.mp3", "drone-9708.mp3", "euphoria-121294.mp3", "fake-news-117862mp3", "field-grass-115973.mp3", "price-of-freedom-33106.mp3", "sad-dramatic-time-119364.mp3", "sad-piano-atmosphere-22139.mp3", "sad-piano-calm-110613.mp3", "spirit-landscape-118015.mp3"]

            },
            soundtrack_assets_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/background-music/",
            // soundtrack_assets_cdn_prefix: "/Users/pradeep.m/Desktop/Workspace/Github.com/centralai-video-creation-service/vcs/operators/audio/soundtracks/",
            //genders
            genders: [
                "male",
                "female"
            ],
            lang_options: [
                "ba",
                "pt-BR",
                "es-MX",
                "es-CO",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr"
            ],
            effect_options_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/effect-gifs/",
            effect_options: [
                "zoomin",
                "zoomout",
                "righttilt_zoomin",
                "lefttilt_zoomin",
                "righttilt_zoomout",
                "lefttilt_zoomout"
            ],
            text_overlay_template_options_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/text-overlay-gifs/",
            text_overlay_template_options: [
                "random",
                'tint.png', 'samples_t1.png',
                'youtube_cc_white', 'youtube_cc_black', 'half_youtube_cc_white', 'half_youtube_cc_black',
                'cc_line_white', 'cc_line_black', 'border_lines_white', 'border_lines_black',
                'side_lines_white', 'side_lines_black', 'sliding_text_white', 'sliding_text_black',
                'golden_line_white', 'golden_line_black', 'sidewise_parallel_lines_white', 'sidewise_parallel_lines_black',
                'side_umbrella_white', 'side_umbrella_black',
                'top_lines_white', 'top_lines_black', 'parallel_lines_white', 'parallel_lines_black'
            ],


            // transition_overlay_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/transition-overlay/",
            transition_overlay_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/transition-overlay-gifs/",
            transition_overlay_options: [
                "none",
                "random",
            ],
            transition_overlay_id_to_name_mapping: {
                "4.webm": "Racing Blue",
                "black_14.webm": "Black White Brush 1",
                "black_13.webm": "Black White Brush 2",
                "21.webm": "Fair & Square Blue",
                "23.webm": "Fair & Square Rainbow",
                "9.webm": "Five Bars Walk Into The Bar",
                "1.webm": "Flash Before My Eyes.webm",
                "22.webm": "Green Arrows",
                "16.webm": "Heavy Rotation 1",
                "19.webm": "Heavy Rotation 2",
                "10.webm": "Life in Circle 1",
                "15.webm": "Life in Circle 2",
                "17.webm": "Three Blue Bars",
                "26.webm": "Simple Blue Cracks",
                "24.webm": "Colourful Swirl",
                "25.webm": "Double Pink Cracks"
            },

            // fonts
            language_to_fonts: {
                "mr": ["default.ttf"],
                "te": ["Ramabhadra-Regular.ttf", "default.ttf", "Timmana-Regular.ttf"],
                "ba": ["HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf",
                    "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf",
                    "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf", "Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf"],
                "kn": ["BalooTamma2-Bold.ttf", "default.ttf"],
                "bn": ["kalpurush.ttf", "default.ttf", "Siyamrupali.ttf", "HindSiliguri-Bold.ttf"],
                "hi": ["NotoSans-Regular.ttf", "default.ttf", "NotoSans-Bold.ttf", "Mukta-Bold.ttf", "Mukta-Medium.ttf"],
                "en": ["Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf", "HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf", "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf", "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf"],
                "ta": ["ArimaMadurai-Bold.ttf", "MuktaMalar-ExtraBold.ttf", "default.ttf", "MeeraInimai-Regular.ttf", "ArimaMadurai-Black.ttf", "ArimaMadurai-ExtraBold.ttf"],
                "pt-BR": ["Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf", "HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf", "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf", "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf"],
                "es-CO": ["Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf", "HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf", "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf", "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf"],
                "es-MX": ["Archivo-Regular.ttf", "Barlow-Regular.ttf", "Inter-Regular.ttf", "Lato-Regular.ttf", "Urbanist-Regular.ttf", "HoltwoodOneSC.ttf", "Oswald-Bold.ttf", "Righteous-Regular.ttf", "default.ttf", "Roboto-Regular.ttf", "non-default.ttf", "AlfaSlabOne-Regular.ttf", "PermanentMarker-Regular.ttf", "Exo-ExtraBoldItalic.ttf", "Play-Bold.ttf", "Notable-Regular.ttf", "Roboto-Bold.ttf", "Exo-ExtraBold.ttf", "Comfortaa-Bold.ttf"],
            },

            // fonts to font image mapping
            fonts_to_images: {
                "AlfaSlabOne-Regular.ttf": "alphaslabone.webp",
                "Comfortaa-Bold.ttf": "comfortaa.bold.webp",
                "Exo-ExtraBoldItalic.ttf": "exo.extra-bold-italic.webp",
                "Exo-ExtraBold.ttf": "exo.extra-bold.webp",
                "HoltwoodOneSC.ttf": "holtwood-one-sc.regular.webp",
                "Notable-Regular.ttf": "notable.webp",
                "Oswald-Bold.ttf": "oswald.bold.webp",
                "PermanentMarker-Regular.ttf": "permanent-marker.regular.webp",
                "Play-Bold.ttf": "play.bold.webp",
                "Righteous-Regular.ttf": "righteous.regular.webp",
                "Roboto-Bold.ttf": "roboto.bold.webp",
                "Roboto-Regular.ttf": "roboto.regular.webp",
                "Archivo-Regular.ttf": "Archivo-Regular.webp",
                "Barlow-Regular.ttf": "Barlow-Regular.webp",
                "Inter-Regular.ttf": "Inter-Regular.webp",
                "Lato-Regular.ttf": "Lato-Regular.webp",
                "Urbanist-Regular.ttf": "Urbanist-Regular.webp"
            },
            font_assets_cdn_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/fonts/",

            color_options: [
                "black",
                "white"
            ],

            // prefix effects
            prefix_effect_mp4_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/prefix-effect/",
            prefix_effect_prefix: "https://aicontentsa.azureedge.net/prod/aicontent/lex/assets/prefix-effect-gifs/",
            prefix_effect_options: [
                "breaking_news_1.mp4",
                "breaking_news_2.mp4",
                "recipe_1.mp4",
                "recipe_2.mp4",
                "on_this_day_1.mp4",
                "travel_1.mp4",
                "travel_2.mp4"
            ],
            font_size_options: [
                2,
                4,
                6,
                8,
                10,
                12,
                14,
                16,
                18,
                20,
                22,
                24,
                26,
                28,
                30,
                32,
                34,
                36,
                38,
                40,
                42,
                44,
                46,
                48,
                50,
                52,
                54,
                56,
                58,
                60
            ],

            // videos options
            generated_clips: [
                "https://glance.glance-cdn.com/public/content/assets/videos/bf49af5aba_29072022_072250_000022.mp4",
                "https://glance.glance-cdn.com/public/content/assets/videos/c4587060c4_29072022_091224_000012.mp4",
                "https://glance.glance-cdn.com/public/content/assets/videos/bfa4965ea7_01082022_033248_000032.mp4",
                "https://glance.glance-cdn.com/public/content/assets/videos/34c6ef8595_01082022_092219_000022.mp4"
            ],
            output_video_link: "https://glance.glance-cdn.com/public/content/assets/videos/a734fa55e7_29072022_035858_000058.mp4",
            player_width: 270,
            player_height: 405,
            current_focus_clip_index: 0,
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",

            // api related states
            id: null,
            url: null,
            language: "en",
            gender: null,
            voice_over: "speech",
            voice_over_song: "random",
            text_overlay: "youtube_cc_black",
            font: null,
            num_points: 3,
            video_response: null,
            stitch_url: null,
            stage: null,

            stitching_required: true,

            // default transition states
            video_generate_api_running: false,
            video_update_api_running: false,
            video_generate_api_response: null,
            video: null,
            clip_generate_api_running: false,
            video_stitch_response: null,
            result_col_span: 0,

            // video actions and overview state
            search_language: "en",
            search_modified_date: new Date(),
            search_skip: 0,
            search_limit: 100,

            video_search_api_running: false,
            videos_search_response: null,
            video_titles_search_response: null,
            selected_video_index: null,

            video_publish_api_running: false,
            video_publish_url: "",   // TODO: SET CORRECTLY process.env.REACT_APP_TRENDS_BASE_URL + "/newz/nic",

            video_delete_api_running: false,
            video_delete_response: null,

            video_update_api_running: false,
            video_update_response: null,
            stage: null,

            video_title: null,
            video_preview_html: "<p>Video preview will be shown here</p>",
            video_preview_html_url: null,
            
            // Modals
            // Image Search Modal
            video_generator_modal_open: false,
            video_editor_modal_open: false,
            image_search_modal_open: false,
            transition_overlay_modal_open: false,
            video_effect_modal_open: false,
            text_overlay_modal_open: false,
            text_overlay_modal_open_parent: false,
            prefix_effect_modal_open: false,
        };
        
        this.videoGenerate = this.videoGenerate.bind(this);
        this.videoUpdate = this.videoUpdate.bind(this);

        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.toggleImageCroppingModal = this.toggleImageCroppingModal.bind(this);
    }

    getCurrentVideoState = () => {
        var current_video_state = {
            id: this.state.id,
            url: this.state.url,
            language: this.state.language,
            gender: this.state.gender,
            voice_over: this.state.voice_over,
            voice_over_song: this.state.voice_over_song,
            text_overlay: this.state.text_overlay,
            font: this.state.font,
            num_points: this.state.num_points,
            video_response: this.state.video_generate_api_response,
            stitch_url: this.state.stitch_url,
            stage: this.state.stage,
        }
        return current_video_state
    }

    setCurrentVideoState = (video_state) => {
        this.setState({
            id: video_state.id,
            url: video_state.url,
            language: video_state.language,
            gender: video_state.gender,
            voice_over: video_state.voice_over,
            voice_over_song: video_state.voice_over_song,
            text_overlay: video_state.text_overlay,
            font: video_state.font,
            num_points: video_state.num_points,
            video_generate_api_response: video_state.video_response,
            stitch_url: video_state.stitch_url,
            stage: video_state.stage,
        })
    }

    setDefaultVideoState = () => {
        this.setState({
            id: null,
            url: null,
            language: "en",
            gender: null,
            voice_over: "speech",
            voice_over_song: "random",
            text_overlay: "youtube_cc_black",
            font: null,
            num_points: 3,
            video_generate_api_response: null,
            stitch_url: null,
            stage: null,
        })
    }

    newVideo = () => {
        this.setState({
            video_generate_api_response: null,
            selected_video_index: null,
        }, () => {
            this.setDefaultVideoState()
            this.toggleVideoGeneratorModal()
        })
    }

    getVideoTitle(video_response) {
        var clip_titles = ""
        for (var j = 0; j < video_response.message.clips.length; j++) {
            if (j > 0) {
                clip_titles += " | "
            }
            clip_titles += video_response.message.clips[j].text
        }
        return clip_titles
    }


    searchVideos = async () => {
        if (this.state.search_modified_date == null) {
            message.destroy()
            message.info("Select a modified date!")
            return
        }

        message.loading("Searching videos...", 0)
        this.setState({
            video_search_api_running: true,
            video_search_response: null,
            video_preview_html: "<p>Video preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                language: this.state.search_language,
                modified_date: this.state.search_modified_date,
                skip: this.state.search_skip,
                limit: this.state.search_limit
            }
            VideoSearchAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Video search API failed!")
                    this.setState({
                        video_search_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("Video search API success!")
                    var video_titles = []
                    for (var i = 0; i < response.message.videos.length; i++) {
                        var title = this.getVideoTitle(response.message.videos[i].video_response)
                        video_titles.push(title)
                    }
                    this.setState({
                        video_search_api_running: false,
                        videos_search_response: response.message.videos,
                        video_titles_search_response: video_titles,
                        video_generate_api_response: null,
                        selected_video_index: null,
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to search videos!")
                this.setState({
                    video_search_api_running: false,
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to search videos!", error)
            this.setState({
                video_search_api_running: false,
            })
        })
    }

    videoGenerate = async () => {
        this.setState({
            video_generate_api_running: true,
            video_generate_api_response: null,
            result_col_span: 0,
            video_stitch_response: null
        })

        this.props.refreshIdToken().then((token) => {
            var payload = this.getCurrentVideoState()
            payload.id = uuid4()
            console.log(payload)
            VideoGenerateAPI(payload, token).then((response) => {
                if (response.status == false) {
                    message.destroy()
                    message.error("Video Generation API failed!")
                } else {
                    message.destroy()
                    message.success("Video Generated!")

                    var payload_create = payload
                    payload_create.id = response.message.id
                    payload_create.video_response = response
                    payload_create.stage = "generated"
                    VideoCreateAPI(payload_create, token).then((response_create) => {
                        if (response_create.status == false) {
                            message.destroy()
                            message.error("Failed to save video in DB!")
                            this.setState({
                                video_generate_api_running: false
                            })
                        } else {
                            message.destroy()
                            message.success("Video saved to DB!")

                            // add new video to the start of the search results and select it
                            var videos_search_response = this.state.videos_search_response
                            var video_titles_search_response = this.state.video_titles_search_response
                            videos_search_response.unshift(response_create.message)
                            var title = this.getVideoTitle(response_create.message.video_response)
                            video_titles_search_response.unshift(title)

                            this.setState({
                                video_generate_api_running: false,
                                stitching_required: true,
                                videos_search_response: videos_search_response,
                                video_titles_search_response: video_titles_search_response,
                                video_title: video_titles_search_response[0],
                                selected_video_index: 0,
                            }, () => {
                                this.setCurrentVideoState(response_create.message)
                            })
                        }
                    }).catch((error) => {
                        message.destroy()
                        message.error("Failed to save video in DB!")
                        this.setState({
                            video_generate_api_running: false
                        })
                    })
                }
                // console.log(response)
            }).catch((error) => {
                message.destroy()
                message.error("Failed to generate video!")
                this.setState({
                    video_generate_api_running: false,
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to generate video!")
            this.setState({
                video_generate_api_running: false,
            })
        })
    }

    videoUpdate = async () => {
        this.setState({
            video_update_api_running: true,
            result_col_span: 0,
            video_stitch_response: null,
            clip_generate_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            console.log("Inside videoUpdate")
            const payload = this.state.video_generate_api_response.message
            console.log("Payload " + payload)
            VideoUpdateAPI(payload, token).then((response) => {
                if (response.status == false) {
                    message.destroy()
                    message.error("Video Updation API failed!")
                } else {
                    message.destroy()
                    message.success("Videos Updated!")

                    var payload_updatedb = this.getCurrentVideoState()
                    payload_updatedb.video_response = response
                    payload_updatedb.stage = "generated"
                    VideoUpdatedbAPI(payload_updatedb, token).then((response_updatedb) => {
                        if (response_updatedb.status == false) {
                            message.destroy()
                            message.error("Failed to update video in DB!")
                            this.setState({
                                video_generate_api_running: false,
                                clip_generate_api_running: false,
                            })
                        } else {
                            message.destroy()
                            message.success("Video updated in DB!")
                            var videos_search_response = this.state.videos_search_response
                            videos_search_response[this.state.selected_video_index] = response_updatedb.message
                            var video_titles_search_response = this.state.video_titles_search_response
                            video_titles_search_response[this.state.selected_video_index] = this.getVideoTitle(response_updatedb.message.video_response)

                            this.setState({
                                video_update_api_running: false,
                                clip_generate_api_running: false,
                                videos_search_response: videos_search_response,
                                video_titles_search_response: video_titles_search_response,
                                stitching_required: true,
                            }, () => {
                                this.setCurrentVideoState(response_updatedb.message)
                            })
                        }
                    }).catch((error) => {
                        message.destroy()
                        message.error("Failed to update video in DB!")
                        this.setState({
                            video_generate_api_running: false,
                            clip_generate_api_running: false,
                        })
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to update video!")
                this.setState({
                    video_update_api_running: false,
                    clip_generate_api_running: false,
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to update video!")
            this.setState({
                video_update_api_running: false,
                clip_generate_api_running: false,
            })
        })
    }

    clipGenerate = async () => {
        this.setState({
            clip_generate_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            const payload = this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index]
            try {
                ClipGenerateAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Clip Generation API failed!")
                    } else {
                        message.destroy()
                        message.success("Clip Generated!")
                    }
                    var current_response = this.state.video_generate_api_response
                    if (response.status == true) {
                        current_response.message.clips[this.state.current_focus_clip_index] = response.message
                    }
                    this.setState({
                        clip_generate_api_running: false,
                        video_generate_api_response: current_response,
                        stitching_required: true,
                    })
                })
            } catch (error) {
                message.destroy()
                message.error("Clip generation failed!")
                this.setState({
                    clip_generate_api_running: false,
                })
            }
        });

    }

    videoStitch = async () => {
        this.setState({
            clip_generate_api_running: true,
            video_stitch_response: null,
            result_col_span: 0
        })
        this.props.refreshIdToken().then((token) => {
            const payload = this.state.video_generate_api_response.message
            VideoStitchAPI(payload, token).then((response) => {
                if (response.status == false) {
                    message.destroy()
                    message.error("Video's stitching API failed!")
                } else {
                    message.destroy()
                    message.success("Videos Stitched!")

                    var payload_updatedb = this.getCurrentVideoState()
                    payload_updatedb.stitch_url = response.message.url
                    payload_updatedb.video_response.message = response.message.video_response
                    payload_updatedb.stage = "stitched"
                    VideoUpdatedbAPI(payload_updatedb, token).then((response_updatedb) => {
                        if (response_updatedb.status == false) {
                            message.destroy()
                            message.error("Failed to update video in DB!")
                            this.setState({
                                clip_generate_api_running: false,
                            })
                        } else {
                            message.destroy()
                            message.success("Video updated in DB!")
                            var videos_search_response = this.state.videos_search_response
                            videos_search_response[this.state.selected_video_index] = response_updatedb.message
                            var video_titles_search_response = this.state.video_titles_search_response
                            video_titles_search_response[this.state.selected_video_index] = this.getVideoTitle(response_updatedb.message.video_response)

                            this.setState({
                                clip_generate_api_running: false,
                                video_stitch_response: response,
                                videos_search_response: videos_search_response,
                                video_titles_search_response: video_titles_search_response,
                                result_col_span: 5,
                                stitching_required: false,
                            }, () => {
                                this.setCurrentVideoState(response_updatedb.message)
                            })
                        }
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to stitch videos")
                this.setState({
                    clip_generate_api_running: false,
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to stitch videos")
            this.setState({
                clip_generate_api_running: false,
            })
        })
    }

    addNewClip = async () => {
        var id = uuid4();
        var new_clip = {
            "id": id,
            "url": "",
            "text": "",
            "image_ids": [
            ],
            "selected_image_id": "",
            "language": "en",
            "font": "Archivo-Regular.ttf",
            "duration": 7,
            "effect": "zoomin",
            "text_overlay_template_id": null,
            "background_audio_id": "serious",
            "background_song_id": null,
            "transition_overlay_template_id": "random",
            "spec": null
        }
        var current_response = this.state.video_generate_api_response
        var clips = current_response.message.clips
        var index = this.state.current_focus_clip_index + 1
        current_response.message.clips.splice(index, 0, new_clip)
        console.log(current_response)
        this.setState({
            video_generate_api_response: current_response,
            current_focus_clip_index: index,
            stitching_required: true
        })
    }

    addNewVideoClip = async (video_upload_url, type = "ad", force_index = -1) => {
        var id = uuid4();
        var new_clip = {
            "id": id,
            "url": video_upload_url,
            "video_type": type,
            "text": "",
            "image_ids": [
            ],
            "selected_image_id": "",
            "language": "en",
            "font": "Archivo-Regular.ttf",
            "duration": 7,
            "effect": "zoomin",
            "text_overlay_template_id": null,
            "background_audio_id": "serious",
            "background_song_id": null,
            "transition_overlay_template_id": "random",
            "spec": null
        }
        var current_response = this.state.video_generate_api_response
        var clips = current_response.message.clips
        var index = this.state.current_focus_clip_index + 1
        if (force_index !== -1) {
            console.log("Using force index: ", force_index)
            index = force_index
        } else {
            console.log("No force index passed: ", force_index)
        }
        if (!Array.isArray(clips) || !clips.length) {
            // array does not exist, is not an array, or is empty
            // ⇒ do not attempt to process array
            // array does not exist or is empty
            clips = [new_clip]
        } else {
            clips.splice(index, 0, new_clip)
        }
        current_response.message.clips = clips
        console.log(current_response)
        this.setState({
            video_generate_api_response: current_response,
            current_focus_clip_index: index,
            stitching_required: true
        })
    }

    removeClip = async () => {
        console.log("Deleting current focus clip: ", this.state.current_focus_clip_index)
        var current_response = this.state.video_generate_api_response
        current_response.message.clips.splice(this.state.current_focus_clip_index, 1)
        console.log("Updated clip packet: ", current_response)
        var current_focus_clip_index = Math.max(this.state.current_focus_clip_index - 1, 0)
        console.log("Updated current focus clip: ", current_focus_clip_index)
        this.setState({
            video_generate_api_response: current_response,
            current_focus_clip_index: current_focus_clip_index,
            stitching_required: true
        })
    }

    imageSelection = async (search_string) => {
        // TODO: Add image-selection API call
        // this.setState({
        //     clip_generate_api_running: true
        // })
        if (search_string == "") {
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        try {
            this.props.refreshIdToken().then((token) => {
                const payload = {
                    params : {
                    "title": search_string,
                    "max_count": 100
                    }
            }
                IMSV2ImageSelectionAPI(payload, token).then((response) => {
                    if (response.status == false) {
                        message.destroy()
                        message.error("Image Selection API failed!")
                    } else {
                        message.destroy()
                        message.success("Fetched Some Fresh Images!")
                    }
                    response.message.image_ids = response.message.images.map(image => image.id);
                    var current_response = this.state.video_generate_api_response
                    if (response.status == true) {
                        current_response.message.clips[this.state.current_focus_clip_index].image_ids = response.message.image_ids
                        current_response.message.clips[this.state.current_focus_clip_index].selected_image_id = response.message.image_ids[0]

                    }
                    this.setState({
                        clip_generate_api_running: false,
                        video_generate_api_response: current_response
                    })
                })
            })
        } catch (error) {
            message.destroy()
            message.error("Failed to fetch images!")
            this.setState({
                clip_generate_api_running: false,
            })
        }
    }

    publishVideo = async () => {
        message.loading("Publishing video...", 0)
        this.setState({
            video_publish_api_running: true,
        })

        this.props.refreshIdToken().then((token) => {
            VideoPublishAPI(this.state.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("VideoPublishAPI API failed!")
                } else {
                    message.destroy()
                    message.success("VideoPublishAPI API success!")
                    var videos_search_response = this.state.videos_search_response
                    videos_search_response[this.state.selected_video_index] = response.message
                    var video_titles_search_response = this.state.video_titles_search_response
                    video_titles_search_response[this.state.selected_video_index] = this.getVideoTitle(response.message.video_response)

                    this.setState({
                        videos_search_response: videos_search_response,
                        video_titles_search_response: video_titles_search_response,
                        video_publish_api_running: false
                    }, () => {
                        this.setCurrentVideoState(response.message)
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to publish video!")
                this.setState({
                    video_publish_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to publish video!")
            this.setState({
                video_publish_api_running: false
            })
        })
    }

    deleteVideo = async () => {
        message.loading("Deleting video...", 0)
        this.setState({
            video_delete_api_running: true,
            video_delete_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            // delete video and its clips from the DB
            VideoDeleteAPI(this.state.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Video delete API failed!")
                    this.setState({
                        video_delete_api_running: false,
                        video_delete_response: response.message
                    })
                } else {
                    message.destroy()
                    message.success("Video deleted!")

                    // remove the deleted video and its questions from the search results
                    var videos = this.state.videos_search_response
                    var video_titles = this.state.video_titles_search_response
                    var index = this.state.selected_video_index
                    if (index > -1) {
                        videos.splice(index, 1)
                        video_titles.splice(index, 1)
                    }

                    this.setState({
                        video_delete_api_running: false,
                        video_delete_response: response.message,
                        videos_search_response: videos,
                        video_titles_search_response: video_titles,
                        selected_video_index: null,
                    }, () => {
                        this.setDefaultVideoState()
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to delete video!")
                this.setState({
                    video_delete_api_running: false
                })
            })

        }).catch((error) => {
            message.destroy()
            message.error("Failed to delete video!", error)
            this.setState({
                video_delete_api_running: false
            })
        })
    }

    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleImageCroppingModal = () => {
        this.setState({
            image_cropping_modal_open: !this.state.image_cropping_modal_open
        })
    }

    toggleVideoGeneratorModal = () => {
        if (this.state.video_generator_modal_open == false) {
            this.setState({
                video_generator_modal_open: true
            })
        } else {
            this.setState({
                video_generator_modal_open: false,
                video_editor_modal_open: true
            })
        }
    }

    toggleVideoEditorBackToGeneratorModal = () => {
        this.setState({
            video_editor_modal_open: false,
            video_generator_modal_open: true
        })
    }

    toggleVideoEditorModal = () => {
        this.setState({
            video_editor_modal_open: !this.state.video_editor_modal_open
        })
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleTransitionOverlayModal = () => {
        this.setState({
            transition_overlay_modal_open: !this.state.transition_overlay_modal_open
        })
    }

    toggleVideoEffectModal = () => {
        this.setState({
            video_effect_modal_open: !this.state.video_effect_modal_open
        })
    }

    toggleTextOverlayModal = () => {
        this.setState({
            text_overlay_modal_open: !this.state.text_overlay_modal_open
        })
    }

    toggleTextOverlayModalParent = () => {
        this.setState({
            text_overlay_modal_open_parent: !this.state.text_overlay_modal_open_parent
        })
    }

    togglePrefixEffectModal = () => {
        this.setState({
            prefix_effect_modal_open: !this.state.prefix_effect_modal_open
        })
    }

    render() {
        console.log("video_generate_api_response: ", this.state.video_generate_api_response)
        const createForm = (
            <div>
                <VideoGeneratorModal
                    isOpen={this.state.video_generator_modal_open}
                    onOk={(e) => {
                        this.toggleVideoGeneratorModal()
                    }}
                    onChangeVideoSetting={(property, value) => {
                        this.setState({
                            [property]: value
                        })
                    }}
                    getVideoSetting={(property) => {
                        return this.state[property]
                    }}
                    getGlobalProperty={(property) => {
                        return this.state[property]
                    }}
                    toggleTextOverlayModalParent={this.toggleTextOverlayModal}
                    videoGenerate={this.videoGenerate}
                    updateVideoResponse={(e) => {
                        var current_response = this.state.video_generate_api_response
                        current_response.message.spec["audio_text"] = e;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                    videoUpdate={this.videoUpdate}
                />

                <VideoEditorModal
                    isOpen={this.state.video_editor_modal_open}
                    onOk={(e) => {
                        this.toggleVideoEditorModal()
                    }}
                    onChangeVideoSetting={(property, value) => {
                        this.setState({
                            [property]: value
                        })
                    }}
                    onChangeFocusClipSetting={(property, value) => {
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index][property] = value
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                    getVideoSetting={(property) => {
                        return this.state[property]
                    }}
                    getFocusClipSetting={(property) => {
                        return this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index][property]
                    }}
                    getGlobalProperty={(property) => {
                        return this.state[property]
                    }}
                    addNewClip={this.addNewClip}
                    togglePrefixEffectModal={this.togglePrefixEffectModal}
                    addNewVideoClip={this.addNewVideoClip}
                    toggleImageSearchModal={this.toggleImageSearchModal}
                    toggleTextOverlayModal={this.toggleTextOverlayModal}
                    toggleVideoEffectModal={this.toggleVideoEffectModal}
                    toggleTransitionOverlayModal={this.toggleTransitionOverlayModal}
                    clipGenerate={this.clipGenerate}
                    removeClip={this.removeClip}
                    videoStitch={this.videoStitch}
                    toggleVideoEditorBackToGeneratorModal={this.toggleVideoEditorBackToGeneratorModal}
                />

                <ImageSearchModal
                    isOpen={this.state.image_search_modal_open}
                    onOk={this.toggleImageSearchModal}
                    imageSelection={this.imageSelection}
                    default_value={this.state.video_generate_api_response == null ?
                        "" :
                        this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].text}
                    image_list={this.state.video_generate_api_response == null ?
                        [] :
                        this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].image_ids}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                    imageOnSelect={(e) => {
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index].selected_image_id = e.target.value;
                        current_response.message.clips[this.state.current_focus_clip_index].background_image_url = this.state.image_cdn_prefix + e.target.value + ".jpg";
                        current_response.message.clips[this.state.current_focus_clip_index].override_image_source = null;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                    selectedImage={this.state.video_generate_api_response != null ?
                        this.state.video_generate_api_response.message.clips[this.state.current_focus_clip_index].background_image_url : ""}

                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.state.id_token}
                    onImUploadChange={(e) => {
                        if (e.file.status == 'done') {
                            message.success('Image Uploaded!')
                            var current_response = this.state.video_generate_api_response
                            current_response.message.clips[this.state.current_focus_clip_index].selected_image_id = null;
                            current_response.message.clips[this.state.current_focus_clip_index].background_image_url = e.file.response.image_url;
                            this.setState({
                                video_generate_api_response: current_response
                            })
                        }
                    }}

                />

                <TransitionOverlayModal
                    isOpen={this.state.transition_overlay_modal_open}
                    onOk={this.toggleTransitionOverlayModal}
                    image_list={this.state.transition_overlay_options}
                    image_mapping={this.state.transition_overlay_id_to_name_mapping}
                    image_cdn_prefix={this.state.transition_overlay_prefix}
                    imageOnSelect={(e) => {
                        if (e.target.value == "none") {
                            e.target.value = null
                        }
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index].transition_overlay_template_id = e.target.value;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                />
                <VideoEffectModal
                    isOpen={this.state.video_effect_modal_open}
                    onOk={this.toggleVideoEffectModal}
                    image_list={this.state.effect_options}
                    image_cdn_prefix={this.state.effect_options_cdn_prefix}
                    imageOnSelect={(e) => {
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index].effect = e.target.value;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                />
                <TextOverlayModal
                    isOpen={this.state.text_overlay_modal_open}
                    onOk={this.toggleTextOverlayModal}
                    text_overlay_options={this.state.text_overlay_template_options}
                    image_cdn_prefix={this.state.text_overlay_template_options_cdn_prefix}
                    imageOnSelect={(e) => {
                        var current_response = this.state.video_generate_api_response
                        current_response.message.clips[this.state.current_focus_clip_index].text_overlay_template_id = e.target.value;
                        this.setState({
                            video_generate_api_response: current_response
                        })
                    }}
                    onClose={this.toggleTextOverlayModal}
                />

                <TextOverlayModal
                    isOpen={this.state.text_overlay_modal_open_parent}
                    onOk={this.toggleTextOverlayModalParent}
                    text_overlay_options={this.state.text_overlay_template_options}
                    image_cdn_prefix={this.state.text_overlay_template_options_cdn_prefix}
                    imageOnSelect={(e) => {
                        this.setState({
                            text_overlay: e.target.value
                        })
                    }}
                    onClose={this.toggleTextOverlayModalParent}
                />

                <PrefixEffectModal
                    isOpen={this.state.prefix_effect_modal_open}
                    onOk={this.togglePrefixEffectModal}
                    image_list={this.state.prefix_effect_options}
                    image_cdn_prefix={this.state.prefix_effect_prefix}
                    imageOnSelect={(e) => {
                        var video_url = this.state.prefix_effect_mp4_prefix + e.target.value
                        this.addNewVideoClip(video_url, "prefix", 0)
                    }}
                />
                
                <Layout>
                    <Content>
                        <Row>
                            <Col span={20}>
                                <br/>
                                <br/>
                                <h3>Search for Videos</h3>
                            </Col>
                            <Col span={4}>
                                <br/>
                                <br/>
                                <h3>Create Videos</h3>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Col span={2}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Language
                                </Text>
                                <br />
                                <LanguageDropDown
                                    language={this.state.search_language}
                                    language_options={this.state.lang_options}
                                    onLanguageSelect={(e) => {
                                        console.log("onLanguageSelect - " + e)
                                        this.setState({
                                            search_language: e
                                        })
                                    }}
                                    disabled={true}
                                />
                            </Col>
                            <Col span={4} style={{ paddingLeft: '20px'}}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Modified Date
                                </Text>
                                <br />
                                <Space direction="vertical">
                                    <DatePicker
                                        defaultValue={moment(this.state.search_modified_date)}
                                        onChange={(e) => {
                                            this.setState({
                                                search_modified_date: e
                                            })
                                        }}
                                    />
                                </Space>

                            </Col>
                            <Col span={3} style={{ paddingLeft: '20px'}}>
                                <Button type="primary" block
                                    style={{
                                        marginTop: '21px'
                                    }}
                                    onClick={this.searchVideos} loading={this.state.video_search_api_running}
                                >
                                    Search 
                                </Button>
                            </Col>
                            <Col span={11}></Col>
                            <Col span={3}>
                                <Button type="primary" block
                                    style={{
                                        marginTop: '21px'
                                    }}
                                    onClick={this.newVideo}
                                >
                                    New Video
                                </Button>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Search Results
                                </Text>
                                <br />
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder={this.state.videos_search_response == null ? "Search for videos" : "Select a video:"}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.setState({
                                            video_title: this.state.video_titles_search_response[e],
                                            selected_video_index: e,
                                            result_col_span: this.state.videos_search_response[e].stitch_url == null ? 0 : 5,
                                            stitching_required: this.state.videos_search_response[e].stitch_url == null ? true : false
                                        }, () => {
                                            this.setCurrentVideoState(this.state.videos_search_response[e])
                                        })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={this.state.selected_video_index}
                                >
                                    {this.state.videos_search_response ? (
                                        this.state.videos_search_response.map((video, index) => {
                                            return (
                                                <Select.Option value={index}>{"[" + video.stage + "] " + this.state.video_titles_search_response[index]}</Select.Option>
                                            )
                                        })
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </Select>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row style={{ visibility: this.state.video_generate_api_response == null ? 'hidden' : 'visible'}}>
                            <Col span={8}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Video Preview
                                </Text>
                                <br />
                                <iframe
                                    style={{
                                        width: '100%',
                                        height: '640px'
                                    }}
                                    title="view"
                                    src={this.state.stitch_url ? this.state.stitch_url : ""}
                                />
                            </Col>
                            <Col span={16} style={{ paddingLeft: '40px'}}>
                                <Row>
                                    <Col span={24}>
                                        <Text style={{ fontWeight: 'bold' }}>
                                        Video Details
                                        </Text>
                                        <br />
                                        <table style={{ lineHeight: '2.5em'}}>
                                            <tr>
                                                <td><b>Language:</b></td>
                                                <td>{this.state.language ? this.state.language : ""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Clips:</b></td>
                                                <td>{this.state.video_generate_api_response ? this.state.video_generate_api_response.message.clips.length : ""}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}><b>Title:</b></td>
                                                <td style={{ lineHeight: '1.6em', paddingTop: '7px' }}>{this.state.video_title ? this.state.video_title : ""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>State:</b></td>
                                                <td>{this.state.stage ? this.state.stage : ""}</td>
                                            </tr>
                                        </table>
                                        <br />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Text style={{ fontWeight: 'bold' }}>
                                            Video Actions
                                        </Text>
                                        <br />
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                this.toggleVideoGeneratorModal()
                                            }}
                                            style={{
                                                width: 320,
                                                overflow: 'clip'
                                            }}
                                            block
                                            loading={this.state.video_update_api_running}
                                            disabled={
                                                this.state.video_generate_api_response === null
                                                || (this.state.video_generate_api_response && this.state.stage == "published")
                                                || this.state.video_publish_api_running
                                            }
                                        >
                                            Change Video Settings
                                        </Button>
                                        <br />
                                        <br />
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                this.toggleVideoEditorModal()
                                            }}
                                            style={{
                                                width: 320,
                                                overflow: 'clip'
                                            }}
                                            block
                                            loading={this.state.video_update_api_running}
                                            disabled={
                                                this.state.video_generate_api_response === null
                                                || (this.state.video_generate_api_response && this.state.stage == "published")
                                                || this.state.video_publish_api_running
                                            }
                                        >
                                            Edit and Update Video
                                        </Button>
                                        <br />
                                        <br />
                                        <Popconfirm
                                            title="Do you really want to publish this video? Editing will not be possible after publishing."
                                            onConfirm={(e) => {
                                                this.publishVideo()
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: 320,
                                                    overflow: 'clip'
                                                }}
                                                loading={this.state.video_publish_api_running}
                                                disabled={
                                                    this.state.video_generate_api_response === null
                                                    || (this.state.video_generate_api_response && (this.state.stage == "generated" || this.state.stage == "published"))
                                                    || this.state.video_update_api_running
                                                }
                                            >
                                                Publish Video
                                            </Button>
                                        </Popconfirm>
                                        <br />
                                        <br />
                                        <Popconfirm
                                            title="Delete this video ?"
                                            onConfirm={(e) => {
                                                this.deleteVideo()
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: 320,
                                                    overflow: 'clip'
                                                }}
                                                block
                                                loading={this.state.video_delete_api_running}
                                            >
                                                Delete Video
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: "20px"}}>
                                        <Button
                                            style={{
                                                marginTop: '20px',
                                                display: 'block'
                                            }}
                                            onClick={() => navigator.clipboard.writeText(this.state.video_generate_api_response.html_url)}
                                            disabled={
                                                this.state.video_generate_api_response === null
                                                || this.state.video_update_api_running
                                            }
                                        >
                                            COPY VIDEO PREVIEW URL (internal use only)
                                        </Button>
                                        <br />
                                        <Button 
                                            style={{
                                                display: 'block'
                                            }}
                                            onClick={() => navigator.clipboard.writeText(this.state.video_publish_url + "/" + this.state.video_generate_api_response.id)}
                                            disabled={
                                                this.state.video_generate_api_response === null
                                                || (this.state.video_generate_api_response && this.state.video_generate_api_response.stage != "published")
                                            }
                                        >
                                            COPY VIDEO PUBLISHED URL (for sharing)
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.state.signin_name}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
