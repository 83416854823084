const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;


export async function NewsStoryGenerateAPI(payload, token=null) {
    return await postRequestNewsStoryAPI("generate", payload, token);
}


export async function NewsStoryViewAPI(payload, token=null) {
    return await postRequestNewsStoryAPI("view", payload, token);
}


export async function NewsStoryCreateAPI(payload, token=null) {
    return await postRequestNewsStoryAPI("create", payload, token);
}


export async function NewsStoryUpdateAPI(payload, token=null) {
    return await postRequestNewsStoryAPI("update", payload, token);
}

export async function NewsStorySearchAPI(payload, token=null) {
    return await postRequestNewsStoryAPI("search", payload, token);
}


export async function NewsStoryPublishAPI(news_story_id, token=null) {
    return await getRequestNewsStoryAPI("publish", news_story_id, token);
}


export async function NewsStoryDeleteAPI(payload, token=null) {
    var id = payload.story.id;
    return await getRequestNewsStoryAPI("delete", id, token);
}


export async function NewsStoryPostPublishAPI(payload, token=null) {
    return await postRequestNewsStoryAPI("publishstory", payload, token);
}


export async function postRequestNewsStoryAPI(api_function, payload, token=null, method="POST"){
    let request={
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    console.log(payload)
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/newsstory/" + api_function, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}


export async function getRequestNewsStoryAPI(api_function, id, token=null){
    let request={
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
    }
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/newsstory/" + api_function + "/" + id, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}
