import { ScrollMenu } from "react-horizontal-scrolling-menu";
import React from "react";
import { Button, Radio} from "antd";
import ReactPlayer from "react-player";
import { DeleteFilled } from '@ant-design/icons';
export default class ScrollVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
    };
  }

  refreshPage = () => {
    this.setState({ reload: true }, () => this.setState({ reload: false }));
  };

  render() {
    return (
      <ScrollMenu
        style={{
          padding: "5px",
          overflow: "scroll",
        }}
        options={{
          ratio: 0.9,
          rootMargin: "5px",
          threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
        }}
      >
        <Radio.Group
          style={{
            display: "flex",
          }}
          // optionType="button"
          onChange={this.props.scrollRadioChange}
          defaultValue={0}
          value={this.props.current_focus_clip_index}
        >
          {this.props.video_generate_api_response.message.clips.map(
            (e, idx) => (
              <div
                style={{
                  background:
                    this.props.current_focus_clip_index == idx
                      ? "gray"
                      : "white",
                }}
              >
                <Radio
                  value={idx}
                  style={{
                    height: this.props.player_height + 50,
                  }}
                >
                  <Button
                      style={{
                        float: "right"
                      }}
                      type="text"
                      onClick={this.props.deleteClip}
                      disabled={
                        this.props.current_focus_clip_index == idx
                        ? false
                        : true
                      }
                  >
                    <DeleteFilled />
                  </Button>
                  <video
                    style={{
                      marginTop: "3px",
                      marginBottom: "10px",
                    }}
                    controls
                    width={
                      this.props.current_focus_clip_index == idx
                        ? this.props.player_width + 20
                        : this.props.player_width
                    }
                    height={
                      this.props.current_focus_clip_index == idx
                        ? this.props.player_height - 20
                        : this.props.player_height - 50
                    }
                    src={e.url}
                  />
                </Radio>
              </div>
            )
          )}
        </Radio.Group>
      </ScrollMenu>
    );
  }
}
