import React from "react";
import { Button, Modal, Row, Col, Layout, Input, Empty, Radio } from "antd";
import { UploadButton } from "./uploadButton";
const { Content } = Layout;

const { Search } = Input;
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const IMSImageSearchModal = (props) => {

  return (
    <Modal
      title="Select Images"
      centered
      open={props.isOpen}
      onOk={props.onOk}
      onCancel={props.onOk}
      closable={false}
      width={1200}
      footer={[
        <Button key="done" type="primary" onClick={props.onOk}>
          Done
        </Button>,
      ]}
    >
      <Content>
        <Row>
          <Col span={4}>
            <img
                alt=""
                style={{
                    width: "100%",
                    padding: "5px",
                }}
                src={props.selectedImage}
            />
          </Col>
          <Col span={20}>
            <Row>
              <Col span={20}>
                <Search
                  placeholder="Search Images from IMS!"
                  allowClear
                  enterButton="Search"
                  defaultValue={props.default_value}
                  onSearch={async (e) => {
                    await props.imageSelection(e);
                  }}
                />
              </Col>
              <Col span={4}>
                <UploadButton
                  upload_button_label={"Upload Image"}
                  signin_name={props.signin_name}
                  upload_loading={props.upload_loading}
                  upload_endpoint={props.upload_endpoint}
                  id_token={props.id_token}
                  onImUploadChange={props.onImUploadChange}
                />
              </Col>
            </Row>
            <Row
              style={{
                maxHeight: "70vh",
                overflow: "scroll",
              }}
            >
              {typeof(props.image_list) ==='undefined' || props.image_list.length == 0 ? (
                <Empty
                  style={{
                    paddingTop: "5px",
                    width: "100%",
                  }}
                />
              ) : (
                <Radio.Group
                  onChange={props.imageOnSelect}
                  style={{
                    paddingTop: "5px",
                  }}
                  optionType="button"
                >
                  {props.image_list.map((image) => {
                    return (
                      <Radio
                        value={image}
                        style={{
                          height: "150px",
                        }}
                      >
                        <img
                            alt=""
                            style={{
                                height: "150px",
                                // padding: '5px'
                            }}
                            src={props.image_cdn_prefix + image + ".jpg"}
                        />
                      </Radio>
                    );
                  })}
                </Radio.Group>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
    </Modal>
  );
};
