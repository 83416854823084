import { Button, Col, Input, Layout, message, Row, Typography, Tooltip, Select, Radio, Popconfirm } from 'antd';
import React from 'react';
import { EyeFilled } from '@ant-design/icons';
import NavBar from '../components/header';
import { NicQuizArticlesAPI, NicQuizImageCropAPI, NicQuizGenerateAPI, NicQuizQuestionGenerateAPI, NicQuizViewAPI, NicQuizCreateAPI, NicQuizUpdateAPI, NicQuizPublishAPI, NicQuizGetImageSourceAPI } from "../apis/nicQuiz";
import { IMSV2ImageSelectionAPI, ImageBlurAPI } from "../apis/vcs";
import { IMSImageSearchModal } from "../components/imsImageSearchModal";
import { ArticleSelectionModal } from "../components/articleSelectionModal"
import { ImageCroppingModal } from "../components/imageCroppingModal"
import { NicQuizEditorModal } from '../components/nicQuizEditorModal';
import { MultiSelectDropDown } from "../components/multiSelectDropDown"

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Content, Footer } = Layout;
const { Option } = Select;
const { Text } = Typography;

export default class NicQuizCreate extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            id_token: null,

            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr"
            ],
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,

            crop: { x: 0, y: 28.24 },
            zoom: 1,
            aspect: 7 / 4,
            cropping_image_url: null,
            cropping_question_index: null,
            cropped_area_pixels: null,
            image_cropping_modal_open: false,

            article_selection_modal_open: false,
            article_selection_fetch_count: 150,
            selected_categories: [],
            selected_publishers: [],

            image_search_modal_open: false,
            ims_selected_image_id: null,
            ims_selected_image_source: null,
            ims_editing_question_index:0,
            ims_banner_image_url: null,
            search_text: "",
            target_language: "en",
            nic_quiz_manual_articles_api_running: false,
            nic_quiz_automatic_articles_api_running: false,
            nic_quiz_articles_api_response: null,
            nic_quiz_generate_api_running: false,
            nic_quiz_generate_api_response: null,
            nic_quiz_update_api_running: false,
            nic_quiz_update_api_response: null,
            fetched_articles: [],
            articles: null,
            nic_editor_modal_open: false,

            num_questions: 0,
            max_num_questions: 20,
            random_order: true,
            image_ids: [],
            image_id_to_source_map: {},

            nic_quiz: null,
            nic_questions: null,
            nic_quiz_html: "<p>Quiz preview will be shown here</p>",
            nic_quiz_html_url: null,

            nic_quiz_publish_api_running: false,
            nic_quiz_publish_url: process.env.REACT_APP_TRENDS_BASE_URL + "/newz/nic",

            category_options: [
                "all-categories",
                'daily_digest',
                'cricket',
                'sports',
                'entertainment',
                'technology',
                'travel_and_lifestyle',
                'automotive',
                'food_and_health',
                'national_v_two',
                'international',
                'business',
                'fashion',
                'music',
                'fun_facts',
                'games',
                'nature',
                'talent',
                'comedy',
                'tamil_news',
                'telugu_news',
                'kannada_news',
                'marathi_news',
                'bengali_news',
                'home_n_living',
                'celebrity',
                'education',
                'live_game_streams',
                'religion',
                'content_for_apps',
                'ipl',
            ],

            publisher_options: [
                "Free Press Journal",
                "FeedPost",
                "AFK Gaming",
                "CricTracker",
                "Sports Tiger",
                "The Newz Thing",
                "SportsTak",
                "Cric Tamil",
                "CricTracker Hindi",
                "CricTracker Bengali",
                "CricTracker বাংলা",
                "Maha Sports",
                "Vikatan",
                "Vistara News",
                "ET Now",
                "Times Now",
                "Zoom",
                "Gadgets 360",
                "PTI",
                "CNBC TV18",
                "NewsBytes"
            ]
        };

        this.generateNicQuiz = this.generateNicQuiz.bind(this);
        this.generateNicQuizRun = this.generateNicQuizRun.bind(this);
        this.updateNicQuiz = this.updateNicQuiz.bind(this);
        this.toggleArticleSelectionModal = this.toggleArticleSelectionModal.bind(this);
        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.toggleImageCroppingModal = this.toggleImageCroppingModal.bind(this);
        this.toggleNicQuizEditorModal = this.toggleNicQuizEditorModal.bind(this);
        this.publishNicQuiz = this.publishNicQuiz.bind(this);
        this.articleSelection = this.articleSelection.bind(this);
        this.imageSelection = this.imageSelection.bind(this);
        this.getCroppedImageUrl = this.getCroppedImageUrl.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.saveCroppedImage = this.saveCroppedImage.bind(this);
        this.onAddBlankQuestion = this.onAddBlankQuestion.bind(this);
        this.onGenerateQuestion = this.onGenerateQuestion.bind(this);
        this.onMoveQuestion = this.onMoveQuestion.bind(this);
    }


    onCropChange = (crop) => {
        this.setState({ crop });
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom });
    }

    onCropComplete = (cropped_area, cropped_area_pixels) => {
        console.log('crop complete')
        this.setState({
            cropped_area_pixels: cropped_area_pixels,
        })
    }

    saveCroppedImage = () => {
        this.getCroppedImageUrl(
            this.state.cropping_image_url,
            this.state.cropped_area_pixels,
            this.state.cropping_question_index
        )
        this.toggleImageCroppingModal()
    }

    generateNicQuiz = () => {
        this.setState({
            nic_quiz_html: "<p>Quiz preview will be shown here</p>"
        })
        this.generateNicQuizRun()
    }

    generateNicQuizRun = async () => {
        message.loading("Generating Quiz...", 0)
        this.setState({
            nic_quiz_generate_api_running: true,
            nic_quiz_generate_api_response: null,
            nic_quiz_html: "<p>Quiz preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                articles: this.state.articles,
                target_language: this.state.target_language,
                num_questions: this.state.num_questions,
                random_order: this.state.random_order,
                stage: "generated"
            }
            console.log("payload: ", payload)
            NicQuizGenerateAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NicQuizGenerate API failed!")
                } else {
                    message.destroy()
                    message.success("NicQuizGenerate API success!")
                    var nic_quiz = response.message.quiz
                    var nic_questions = response.message.questions

                    // get the NicQuiz view
                    const payload_view = {
                        quiz: nic_quiz,
                        questions: nic_questions
                    }
                    NicQuizViewAPI(payload_view, token).then((response2) => {
                        if (response2.status==false) {
                            message.destroy()
                            message.error("NicQuizView API failed!")
                        } else {
                            message.destroy()
                            message.success("NicQuizView API success!")
                            this.setState({
                                nic_quiz_html: response2.message.quiz_html,
                                nic_quiz_html_url: response2.message.quiz_html_url,
                            })
                        }

                        // persist NicQuiz to DB
                        nic_quiz.html_url = response2.message.quiz_html_url
                        const payload_create = {
                            quiz: nic_quiz,
                            questions: nic_questions
                        }
                        NicQuizCreateAPI(payload_create, token).then((response3) => {
                            if (response3.status==false) {
                                message.destroy()
                                message.error("NicQuizCreate API failed!")
                                this.setState({
                                    nic_quiz_generate_api_running: false
                                })
                            } else {
                                message.destroy()
                                message.success("NicQuizCreate API success!")
                                this.setState({
                                    nic_quiz_generate_api_running: false,
                                    nic_quiz_generate_api_response: response
                                })
                            }
                        }).catch((error) => {
                            message.destroy()
                            message.error("Failed to save NicQuiz!")
                            this.setState({
                                nic_quiz_generate_api_running: false
                            })
                        })

                        // update quiz and questions state
                        this.setState({
                            nic_quiz: nic_quiz,
                            nic_questions: nic_questions
                        }, () => {
                            console.log("nic_quiz: ", this.state.nic_quiz)
                            console.log("nic_questions: ", this.state.nic_questions)
                        })
                    }).catch((error) => {
                        console.log(error)
                        message.destroy()
                        message.error("Failed to generate Quiz view!")
                        this.setState({
                            nic_quiz_generate_api_running: false,
                        })
                    })

                }
            }).catch((error) => {
                console.log(error)
                message.destroy()
                message.error("Failed to generate Quiz!")
                this.setState({
                    nic_quiz_generate_api_running: false,
                })
            })
        })
    }

    updateNicQuiz = async () => {
        message.loading("Updating Quiz view...", 0)
        this.setState({
            nic_quiz_update_api_running: true,
            nic_quiz_generate_api_response: null,
            nic_quiz_html: "<p>Quiz preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload_view = {
                quiz: this.state.nic_quiz,
                questions: this.state.nic_questions
            }
            NicQuizViewAPI(payload_view, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NicQuiz view API failed!")
                } else {
                    message.destroy()
                    message.success("NicQuiz view API updated!")
                    var quiz = this.state.nic_quiz
                    quiz.html_url = response.message.quiz_html_url
                    this.setState({
                        nic_quiz_html: response.message.quiz_html,
                        nic_quiz_html_url: response.message.quiz_html_url,
                        nic_quiz: quiz
                    }, () => {
                        // update NicQuiz in DB
                        const payload_update = {
                            quiz: this.state.nic_quiz,
                            questions: this.state.nic_questions
                        }
                        NicQuizUpdateAPI(payload_update, token).then((response2) => {
                            if (response2.status==false) {
                                message.destroy()
                                message.error("NicQuiz database update API failed!")
                                this.setState({
                                    nic_quiz_update_api_running: false
                                })
                            } else {
                                message.destroy()
                                message.success("NicQuiz updated in database!")
                                this.setState({
                                    nic_quiz_update_api_running: false,
                                })
                            }
                        }).catch((error) => {
                            message.destroy()
                            message.error("Failed to update NicQuiz in database!")
                            this.setState({
                                nic_quiz_update_api_running: false
                            })
                        })
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to update Quiz view!")
                this.setState({
                    nic_quiz_update_api_running: false
                })
            })
        })
    }

    publishNicQuiz = async () => {
        message.loading("Publishing Quiz...", 0)
        this.setState({
            nic_quiz_publish_api_running: true,
        })

        this.props.refreshIdToken().then((token) => {
            NicQuizPublishAPI(this.state.nic_quiz.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NicQuizPublish API failed!")
                } else {
                    message.destroy()
                    message.success("NicQuizPublish API success!")
                    this.setState({
                        nic_quiz: response.message.quiz,
                        nic_questions: response.message.questions,
                        nic_quiz_publish_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to publish Quiz!")
                this.setState({
                    nic_quiz_publish_api_running: false
                })
            })
        })
    }    

    articleSelection = async (method) => {
        if (this.state.selected_categories.length == 0) {
            message.destroy()
            message.info("Please select some categories!")
            return
        }
        if (method == "manual") {
            this.setState({
                nic_quiz_manual_articles_api_running: true,
                articles: [],
                selected_articles: []
            })
        } else if (method == "automatic") {
            this.setState({
                nic_quiz_automatic_articles_api_running: true,
                articles: [],
                selected_articles: []
            })
        }
        this.props.refreshIdToken().then((token) => {
            const payload = {
                language: this.state.target_language,
                categories: this.state.selected_categories,
                source_names: this.state.selected_publishers,
                max_results: this.state.article_selection_fetch_count
            }
            NicQuizArticlesAPI(payload, token).then((response) => {
                if (response.status == false) {
                    message.destroy()
                    message.error("NicQuizArticles API failed!")
                } else {
                    message.destroy()
                    message.success("Fetched some fresh articles!")
                }
                if (response.status == true) {
                    this.setState({
                        nic_quiz_articles_api_running: false,
                        fetched_articles: response.message.articles
                    }, () => {
                        console.log("fetched_articles: ", this.state.fetched_articles)
                        if (method == "manual") {
                            // Open the modal for manual article selection after fetching articles
                            this.toggleArticleSelectionModal()
                        } else if (method == "automatic") {
                            // Select a random subset of articles
                            var articles = response.message.articles
                            var selected_articles = []
                            for (var i = 0; i < this.state.max_num_questions; i++) {
                                var random_index = Math.floor(Math.random() * articles.length)
                                selected_articles.push(articles[random_index])
                                articles.splice(random_index, 1)
                                if (articles.length == 0) {
                                    break
                                }
                            }
                            this.setState({
                                articles: selected_articles,
                                num_questions: selected_articles.length,
                                nic_quiz_automatic_articles_api_running: false
                            }, () => {
                                console.log("articles: ", this.state.articles)
                            })
                        }
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch articles!")
                this.setState({
                    nic_quiz_manual_articles_api_running: false,
                    nic_quiz_automatic_articles_api_running: false
                })
            })
        })
    }

    imageSelection = async (search_string) => {
        // TODO: Add image-selection API call
        // this.setState({
        //     clip_generate_api_running: true
        // })
        if (search_string == "") {
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": search_string,
                    "description": "",
                    "max_count": 30,
                    "duplicate_removal": true,
                    "blacklisted_removal": true,
                }
            }
            IMSV2ImageSelectionAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Image Selection API failed!")
                } else {
                    message.destroy()
                    message.success("Fetched Some Fresh Images!")
                }
                if (response.status == true) {
                    console.log("Image Selection API Response: ", response.message)
                    const image_ids = response.message.images.map(item => item.id);
                    const image_id_to_source_map = response.message.images.reduce((acc, item) => {
                        acc[item.id] = item.source_name;
                        return acc;
                    }, {});
                    this.setState({
                        image_ids: image_ids,
                        selected_image_id: image_ids[0],
                        image_id_to_source_map: image_id_to_source_map,
                    })
                    console.log("Image Ids: ", this.state.image_ids)
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch images!")
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to fetch images!")
        })
    }

    blurImage = async (image_url) => {
        try {
            const token = await this.props.refreshIdToken();
            const payload = { "url": image_url };
            const response = await ImageBlurAPI(payload, token);
            if (response.status === false) {
                message.destroy();
                message.error("Blur API failed!");
                throw new Error("Blur API failed");
            }
            message.destroy();
            message.success("Blurred Images!");

            if (response.status === true) {
                console.log('blurred: ', response.message.image_url);
                return response.message.image_url;
            }
        } catch (error) {
            message.destroy();
            message.error("Failed to blur image!");
            throw error;  // re-throw the error to be caught in the calling function
        }
        return image_url;
    }

    getCroppedImageUrl = async (image_url, cropped_area_pixels, question_idx) => {
        this.props.refreshIdToken().then((token) => {
            const payload = {
                image_url: image_url,
                cropped_area_pixels: cropped_area_pixels
            }
            NicQuizImageCropAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Quiz Image Cropping API failed!")
                } else {
                    message.destroy()
                    message.success("Cropped Image!")
                }
                if (response.status == true) {
                    console.log("Quiz Image Cropping API Response: ", response.message)
                    var nic_questions = this.state.nic_quiz_editor_editing_questions
                    if (this.state.nic_quiz_editor_image_type == "question") {
                        nic_questions[question_idx].question_image_url = response.message.image_cropped_url
                    } else if (this.state.nic_quiz_editor_image_type == "answer") {
                        nic_questions[question_idx].answer_image_url = response.message.image_cropped_url
                    }
                    this.setState({
                        nic_quiz_editor_editing_questions: nic_questions
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to crop image!"+error)
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to crop image!"+error)
        })
    }

    storeIMSSettings = async () => {
        console.log("ims_selected_image_id: ", this.state.ims_selected_image_id)
        var image_cropped_area_pixels
        var nic_questions = this.state.nic_quiz_editor_editing_questions
        if (this.state.nic_quiz_editor_image_type == "question") {
            nic_questions[this.state.ims_editing_question_index].question_image_id_selected = this.state.ims_selected_image_id
            image_cropped_area_pixels = this.state.nic_quiz_editor_editing_questions[this.state.ims_editing_question_index].question_image_cropped_area_pixels
        } else if (this.state.nic_quiz_editor_image_type == "answer") {
            nic_questions[this.state.ims_editing_question_index].answer_image_id_selected = this.state.ims_selected_image_id
            image_cropped_area_pixels = this.state.nic_quiz_editor_editing_questions[this.state.ims_editing_question_index].answer_image_cropped_area_pixels
        }
        this.setState({
            nic_quiz_editor_editing_questions: nic_questions
        }, () => {
            this.getImageSource()
        })
        this.getCroppedImageUrl(
            this.state.ims_banner_image_url,
            image_cropped_area_pixels,
            this.state.ims_editing_question_index
        )
        this.toggleImageSearchModal()
    }

    getImageSource = async () => {
        var nic_questions = this.state.nic_quiz_editor_editing_questions
        if (this.state.ims_selected_image_source != "") {
            if (this.state.nic_quiz_editor_image_type == "question") {
                nic_questions[this.state.ims_editing_question_index].question_image_attribution = this.state.ims_selected_image_source
            } else if (this.state.nic_quiz_editor_image_type == "answer") {
                nic_questions[this.state.ims_editing_question_index].answer_image_attribution = this.state.ims_selected_image_source
            }
            this.setState({
                nic_quiz_editor_editing_questions: nic_questions
            })
        } else {
            // get the source of the image selected in IMS if lookup map is not available
            await this.props.refreshIdToken().then((token) => {
                NicQuizGetImageSourceAPI(this.state.ims_selected_image_id, token).then((response) => {
                    if (response.status==false) {
                        message.destroy()
                        message.error("NicQuizGetImageSource API failed!")
                        return
                    } else {
                        if (this.state.nic_quiz_editor_image_type == "question") {
                            nic_questions[this.state.ims_editing_question_index].question_image_attribution = response.message.source_name
                        } else if (this.state.nic_quiz_editor_image_type == "answer") {
                            nic_questions[this.state.ims_editing_question_index].answer_image_attribution = response.message.source_name
                        }
                        this.setState({
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                        return
                    }
                }).catch((error) => {
                    message.destroy()
                    message.error("Failed to get image source!")
                })
            }).catch((error) => {
                message.destroy()
                message.error("Failed to get image source!")
            })
        }
    }

    onAddBlankQuestion = async () => {
        var nic_quiz = this.state.nic_quiz_editor_editing_quiz
        var nic_questions = this.state.nic_quiz_editor_editing_questions
        var question = {
            quiz_id: null,
            language: nic_quiz.language,
            article: {
                url: "",
                title: "",
                source_name: "Glance",
                category: null,
                glance_id: null,
            },
            title: "",
            fact: "",
            question_text: "",
            answers: [
                {
                    is_correct: true,
                    answer_text: "",
                },
                {
                    is_correct: false,
                    answer_text: "",
                },
                {
                    is_correct: false,
                    answer_text: "",
                },
                {
                    is_correct: false,
                    answer_text: "",
                },
            ],
            correct_idx: 0,
            question_image_ids: [],
            question_image_id_selected: null,
            question_image_cropped_area_pixels: null,
            question_image_url: "",
            question_image_attribution: "",
            answer_image_url: "",
            show_answer_image: false,
            spec: {},
            stage: "added-by-user",

        }
        nic_questions.push(question)
        this.setState({
            nic_quiz_editor_editing_quiz: nic_quiz,
            nic_quiz_editor_editing_questions: nic_questions
        })
    }

    onGenerateQuestion = async (index) => {
        message.loading("Generating question...", 0)
        this.setState({
            nic_quiz_generate_question_api_running: true
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                articles: [
                    {
                        "url": this.state.nic_quiz_editor_editing_questions[index].article.url,
                    }
                ],
                target_language: this.state.nic_quiz_editor_editing_quiz.language,
                num_questions: 1
            }
            NicQuizQuestionGenerateAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NicQuizGenerateFromArticle API failed!")
                    this.setState({
                        nic_quiz_generate_question_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("NicQuizGenerateFromArticle API success!")
                    var nic_questions = this.state.nic_quiz_editor_editing_questions
                    nic_questions[index] = response.message.questions[0]
                    this.setState({
                        nic_quiz_editor_editing_questions: nic_questions,
                        nic_quiz_generate_question_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to generate question!")
                this.setState({
                    nic_quiz_generate_question_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to generate question!")
            this.setState({
                nic_quiz_generate_question_api_running: false
            })
        })
    }

    onMoveQuestion = async (direction, index) => {
        let questions = [...this.state.nic_quiz_editor_editing_questions];

        if (direction === "up" && index > 0) {
            [questions[index - 1], questions[index]] = [questions[index], questions[index - 1]];
        } else if (direction === "down" && index < questions.length - 1) {
            [questions[index], questions[index + 1]] = [questions[index + 1], questions[index]];
        }

        this.setState({ nic_quiz_editor_editing_questions: questions });
    }
    
    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }

    toggleArticleSelectionModal = () => {
        this.setState({
            nic_quiz_manual_articles_api_running: !this.state.article_selection_modal_open,
            article_selection_modal_open: !this.state.article_selection_modal_open
        })
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleImageCroppingModal = () => {
        this.setState({
            image_cropping_modal_open: !this.state.image_cropping_modal_open
        })
    }

    toggleNicQuizEditorModal = () => {
        this.setState({
            nic_editor_modal_open: !this.state.nic_editor_modal_open
        })
    }
    
    render() {
        console.log("nic_quiz: ", this.state.nic_quiz)
        const createForm = (
            <div>
                <ArticleSelectionModal
                    isOpen={this.state.article_selection_modal_open}
                    onOk={this.toggleArticleSelectionModal}
                    onViewArticle={(article) => {
                        window.open(article.url, "_blank")
                    }}
                    fetchedArticles={this.state.fetched_articles}
                    articlesOnSelect={(e) => {
                        this.setState({
                            articles: e,
                            num_questions: e.length
                        })
                    }}
                    selectedArticles={this.state.articles}
                    api_running={this.state.nic_quiz_articles_api_running}
                    maxSelectionCount={this.state.max_num_questions}
                    onClose={this.toggleArticleSelectionModal}
                />

                <IMSImageSearchModal
                    isOpen={this.state.image_search_modal_open}
                    onOk={this.storeIMSSettings}
                    imageSelection={this.imageSelection}
                    default_value={this.state.search_text}
                    image_list={this.state.image_ids}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                    imageOnSelect={(e)=>{
                        this.setState({
                            ims_selected_image_id: e.target.value,
                            ims_selected_image_source: this.state.image_id_to_source_map[e.target.value]?? "",
                            ims_banner_image_url: this.state.image_cdn_prefix + e.target.value + ".jpg",
                        })
                    }}
                    selectedImage={this.state.ims_banner_image_url}
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.props.idToken}
                    onImUploadChange={(e)=>{
                        if(e.file.status == 'done'){
                            this.setState({
                                ims_selected_image_id: null,
                                ims_selected_image_source: "",
                                ims_banner_image_url: e.file.response.image_url,
                                image_search_modal_open: false
                            })
                            message.success('Image Uploaded!')
                        }
                    }}
                />

                <ImageCroppingModal
                    isOpen={this.state.image_cropping_modal_open}
                    image_url={this.state.cropping_image_url}
                    onClose={this.saveCroppedImage}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    onCropChange={this.onCropChange}
                    onZoomChange={this.onZoomChange}
                    onCropComplete={this.onCropComplete}
                />

                <NicQuizEditorModal
                    isOpen={this.state.nic_editor_modal_open}
                    centered
                    onOk={(e) => {
                        var nic_quiz = this.state.nic_quiz_editor_editing_quiz
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        this.setState({
                            nic_quiz: nic_quiz,
                            nic_questions: nic_questions
                        })
                        this.updateNicQuiz()
                        this.toggleNicQuizEditorModal()
                    }}
                    onCancel={this.toggleNicQuizEditorModal}
                    onClose={this.toggleNicQuizEditorModal}
                    onDeleteQuestion={(e) => {
                        var nic_quiz = this.state.nic_quiz_editor_editing_quiz
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_quiz.question_ids.splice(e, 1)
                        nic_questions.splice(e, 1)
                        this.setState({
                            nic_quiz_editor_editing_quiz: nic_quiz,
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                    }}
                    onChangeQuestion={(field_name, question_index, e) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_questions[question_index][field_name] = e
                        this.setState({
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                    }}
                    onChangeQuestionArticle={(field_name, question_index, e) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_questions[question_index]['article'][field_name] = e
                        this.setState({
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                    }}
                    onChangeAnswer={(field_name, question_index, answer_index, e) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_questions[question_index].answers[answer_index][field_name] = e
                        this.setState({
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                    }}
                    onReplaceImage={(question_index, image_type) => {
                        var nic_questions = this.state.nic_questions
                        console.log("nic_questions: ", nic_questions)
                        if (image_type == "question") {
                            console.log("replace image for question: ", question_index)
                            this.setState({
                                image_ids: nic_questions[question_index].question_image_ids,
                                ims_banner_image_url: nic_questions[question_index].question_image_url,
                                ims_editing_question_index: question_index,
                                nic_quiz_editor_image_type: "question"
                            })
                        } else if (image_type == "answer") {
                            console.log("replace image for answer: ", question_index)
                            var image_ids
                            if (nic_questions[question_index].answer_image_ids == null) {
                                image_ids = []
                            } else {
                                image_ids = nic_questions[question_index].answer_image_ids
                            }
                            this.setState({
                                image_ids: image_ids,
                                ims_banner_image_url: nic_questions[question_index].answer_image_url,
                                ims_editing_question_index: question_index,
                                nic_quiz_editor_image_type: "answer"
                            })
                        }
                    }}
                    toggleImageSearchModal={this.toggleImageSearchModal}
                    onCropImage={(question_index, image_type) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        if (image_type == "question") {
                            this.setState({
                                cropping_image_url: this.state.image_cdn_prefix+nic_questions[question_index].question_image_id_selected+".jpg",
                                cropping_cropped_area_pixels: nic_questions[question_index].question_image_cropped_area_pixels,
                                cropping_question_index: question_index,
                                nic_quiz_editor_image_type: "question"
                            })
                        } else if (image_type == "answer") {
                            var cropping_image_url
                            if (nic_questions[question_index].answer_image_id_selected == null) {
                                cropping_image_url = nic_questions[question_index].answer_image_url
                            } else {
                                cropping_image_url = this.state.image_cdn_prefix+nic_questions[question_index].answer_image_id_selected+".jpg"
                            }
                            this.setState({
                                cropping_image_url: cropping_image_url,
                                cropping_cropped_area_pixels: nic_questions[question_index].answer_image_cropped_area_pixels,
                                cropping_question_index: question_index,
                                nic_quiz_editor_image_type: "answer"
                            })
                        }
                        this.toggleImageCroppingModal()
                    }}
                    onBlurImage={(question_index, unblur_answer) => {
                        const updatedQuestions = [...this.state.nic_quiz_editor_editing_questions];
                        if (unblur_answer) {
                            // show the original unblurred image with the answer
                            updatedQuestions[question_index].show_answer_image = true;
                            updatedQuestions[question_index].answer_image_url = updatedQuestions[question_index].question_image_url;
                            updatedQuestions[question_index].answer_image_attribution = updatedQuestions[question_index].question_image_attribution;
                            updatedQuestions[question_index].answer_image_ids = updatedQuestions[question_index].question_image_ids;
                            updatedQuestions[question_index].answer_image_id_selected = updatedQuestions[question_index].question_image_id_selected;
                        }
                        this.blurImage(updatedQuestions[question_index].question_image_url)
                        .then((blurredImageUrl) => {
                            updatedQuestions[question_index].question_image_url = blurredImageUrl;
                            this.setState({ nic_quiz_editor_editing_questions: updatedQuestions }, () => {
                                console.log("Updated State:", updatedQuestions[question_index].question_image_url);
                            });
                        })
                        .catch((e) => {
                            console.log("Error blurring image: ", e);
                        })
                    }}
                    toggleImageCroppingModal={this.toggleImageCroppingModal}
                    onAddBlankQuestion={this.onAddBlankQuestion}
                    onGenerateQuestion={this.onGenerateQuestion}
                    onMoveQuestion={this.onMoveQuestion}
                    category_options={this.state.category_options}
                    editing_quiz={this.state.nic_quiz_editor_editing_quiz}
                    editing_questions={this.state.nic_quiz_editor_editing_questions}
                    nic_quiz_generate_question_api_running={this.state.nic_quiz_generate_question_api_running}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                />
          
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Select Articles for News in Clues Quiz
                        </h3>
                        <br />
                        <Row>
                            <Col span={3}>
                                <Text style={{
                                    fontWeight: "bold",
                                }}>
                                    # Questions:
                                </Text>
                                <br />
                                <Select
                                    defaultValue={this.state.max_num_questions}
                                    onChange={(value) => {
                                        this.setState({
                                            max_num_questions: value
                                        })
                                    }}
                                >
                                    <Option value={5}>{5}</Option>
                                    <Option value={15}>{15}</Option>
                                    <Option value={20}>{20}</Option>
                                    <Option value={25}>{25}</Option>
                                    <Option value={30}>{30}</Option>
                                </Select>
                            </Col>
                            <Col span={2}>
                                <Text style={{
                                    fontWeight: "bold",
                                    marginLeft: '-20px'
                                }}>
                                    Language:
                                </Text>
                                <br />
                                <Select
                                    style={{
                                        marginLeft: '-20px'
                                    }}
                                    defaultValue={this.state.target_language}
                                    onChange={(value) => {
                                        this.setState({
                                            target_language: value
                                        })
                                    }}
                                >
                                    {this.state.lang_options.map((lang) => {
                                        return (
                                            <Option value={lang}>{lang}</Option>
                                        )
                                    })}
                                </Select>
                            </Col>
                            <Col span={11}>
                                <Text style={{
                                    fontWeight: "bold"
                                }}>
                                    Article Categories to Fetch:
                                </Text>
                                <br />
                                <MultiSelectDropDown
                                    options={this.state.category_options}
                                    onChange={(e) => {
                                        this.setState({
                                            selected_categories: e
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={1}></Col>
                            <Col span={7}>
                                <Text style={{
                                    fontWeight: "bold"
                                }}>
                                    Publishers to Include:
                                </Text>
                                <br />
                                <MultiSelectDropDown
                                    options={this.state.publisher_options}
                                    onChange={(e) => {
                                        this.setState({
                                            selected_publishers: e
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={5}>
                                <Tooltip title="Search for and manually select articles to base quiz questions upon">
                                    <Button
                                        type="primary"
                                        onClick={this.articleSelection.bind(this, "manual")}
                                        style={{
                                            marginTop: '15px',
                                            display: 'block'
                                        }}
                                        disabled={
                                            this.state.nic_quiz !== null
                                            || this.state.nic_quiz_generate_api_running
                                            || this.state.nic_quiz_automatic_articles_api_running
                                        }
                                        loading={this.state.nic_quiz_manual_articles_api_running}
                                    >
                                        Select Articles Manually
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col span={6}>
                                <Tooltip title={'Base quiz questions upon a random selection of ' + this.state.max_num_questions + ' recent articles from all categories and publishers'}>
                                    <Button
                                        type="primary"
                                        onClick={this.articleSelection.bind(this, "automatic")}
                                        style={{
                                            marginTop: '15px',
                                            display: 'block'
                                        }}
                                        disabled={
                                            this.state.nic_quiz !== null
                                            || this.state.nic_quiz_generate_api_running
                                            || this.state.nic_quiz_manual_articles_api_running
                                        }
                                        loading={this.state.nic_quiz_automatic_articles_api_running}
                                    >
                                        Select Articles Automatically
                                    </Button>
                                </Tooltip>
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Text style={{ paddingBottom: '10px' }}>
                                    <span style={{ fontWeight: 'bold' }}>Selected articles to generate quiz questions for</span> ({this.state.num_questions} selected out of maximum of {this.state.max_num_questions}):
                                </Text>
                                <br />
                                <Text>
                                    <br />
                                    {
                                        this.state.articles ? this.state.articles.map((article) => {
                                            return (
                                                <div style={{
                                                    borderBottom: "1px solid #ddd"
                                                }}>
                                                    <div style={{width: "80%", display: "inline-block"}}>
                                                        <Text
                                                            style={{
                                                                paddingBottom: "4px",
                                                            }}
                                                        >
                                                            {article.title}
                                                        </Text>
                                                    </div>
                                                    <div style={{width: "17%", display: "inline-block"}}>
                                                        <Text style={{
                                                            fontStyle: "italic"
                                                        }}>
                                                            {article.source_name}
                                                        </Text>
                                                    </div>
                                                    <div style={{width: "3%", display: "inline-block"}}>
                                                        <Button
                                                            type="text"
                                                            onClick={() => {
                                                                window.open(article.url, "_blank")
                                                            }}
                                                        >
                                                            <EyeFilled />
                                                        </Button>
                                                    </div>
                                                </div>
                                            )

                                        }) : <Text style={{ fontStyle: 'italic' }}>No articles selected</Text>
                                    }
                                </Text>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Quiz Generation
                        </h3>
                        <Row>
                            <Col span={8}>
                                <Radio.Group
                                    onChange={(e) => {
                                        this.setState({
                                            random_order: e.target.value
                                        })
                                    }}
                                    value={this.state.random_order}
                                    style={{
                                        marginTop: '15px'
                                    }}
                                >
                                    <Radio value={true}>Randomize question ordering</Radio>
                                    <br />
                                    <Radio value={false}>Group questions by category</Radio>
                                </Radio.Group>
                                <br />
                                <br />
                                <Tooltip title="Generate Quiz">
                                    <Button
                                        type="primary"
                                        onClick={this.generateNicQuiz}
                                        loading={this.state.nic_quiz_generate_api_running}
                                        style={{
                                            width: '100%',
                                            marginTop: '15px',
                                            display: 'block'
                                        }}
                                        disabled={
                                            this.state.articles === null
                                            || this.state.articles.length == 0
                                            || (this.state.nic_quiz !== null && !this.state.nic_quiz_generate_api_running)
                                        }
                                    >
                                        Generate Quiz
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}></Col>

                        </Row>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Output
                        </h3>
                        <Row>
                            <Col span={8}>
                                Quiz Preview
                                <br />
                                <iframe
                                    style={{
                                        width: '360px',
                                        height: '640px'
                                    }}
                                    title="view"
                                    src={"data:text/html,"+encodeURIComponent(this.state.nic_quiz_html)}
                                />
                            </Col>
                            <Col span={8} style={{ paddingLeft: "20px"}}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        var nic_quiz = this.state.nic_quiz
                                        var nic_questions = this.state.nic_questions
                                        this.setState({
                                            nic_quiz_editor_editing_quiz: nic_quiz,
                                            nic_quiz_editor_editing_questions: nic_questions
                                        }, () => {
                                            this.toggleNicQuizEditorModal()
                                        })
                                    }}
                                    style={{
                                        width: '100%',
                                        marginTop: '20px',
                                        display: 'block'
                                    }}
                                    loading={this.state.nic_quiz_update_api_running}
                                    disabled={
                                        this.state.nic_quiz === null
                                        || (this.state.nic_quiz && this.state.nic_quiz.stage == "published")
                                        || this.state.nic_quiz_generate_api_running
                                        || this.state.nic_quiz_publish_api_running
                                    }
                                >
                                    Edit and Update Quiz
                                </Button>
                                <br/>
                                <br/>
                                <Popconfirm
                                    title="Do you really want to publish this quiz? Editing will not be possible after publishing."
                                    onConfirm={(e) => {
                                        this.publishNicQuiz()
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        type="primary"
                                        style={{
                                            width: '100%',
                                            marginTop: '20px',
                                            display: 'block'
                                        }}
                                        loading={this.state.nic_quiz_publish_api_running}
                                        disabled={
                                            this.state.nic_quiz === null
                                            || (this.state.nic_quiz && this.state.nic_quiz.stage == "published")
                                            || this.state.nic_quiz_generate_api_running
                                            || this.state.nic_quiz_update_api_running
                                            || (this.state.nic_questions && this.state.nic_questions.length == 0)
                                        }
                                    >
                                        Publish Quiz
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col span={8} style={{ paddingLeft: "20px"}}>
                                <Button
                                    style={{
                                        marginTop: '20px',
                                        display: 'block'
                                    }}
                                    onClick={() => navigator.clipboard.writeText(this.state.nic_quiz_html_url)}
                                    disabled={
                                        this.state.nic_quiz === null
                                        || this.state.nic_quiz_generate_api_running
                                        || this.state.nic_quiz_update_api_running
                                    }
                                >
                                    COPY QUIZ PREVIEW URL (internal use only)
                                </Button>
                                <Button 
                                    style={{
                                        marginTop: '64px',
                                        display: 'block'
                                    }}
                                    onClick={() => navigator.clipboard.writeText(this.state.nic_quiz_publish_url + "/" + this.state.nic_quiz.id)}
                                    disabled={
                                        this.state.nic_quiz === null
                                        || (this.state.nic_quiz && this.state.nic_quiz.stage != "published")
                                    }
                                >
                                    COPY QUIZ PUBLISHED URL (for sharing)
                                </Button>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.state.signin_name}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
