import {
    Button, Col, Layout, message, Row, Typography, Select, DatePicker, Space, Popconfirm
} from 'antd';
import React, { useEffect } from 'react';
import moment from "moment";
import NavBar from '../components/header';
import { ZappFactoryContentSearchAPI, ZappFactoryZappSearchAPI, ZappFactoryContentDeleteAPI, ZappFactoryContentPublishAPI, ZappFactoryContentRejectAPI } from "../apis/zappFactory";
import { LanguageDropDown } from "../components/languageDropDown"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Content, Footer } = Layout;
const { Text } = Typography;


export default class ZappFactory extends React.Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            id_token: null,
            
            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr",
                "ms",
                "vi",
                "th",
                "fil,"
            ],
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            zapp_options: [],

            search_zapp_id: null,
            search_language: "en",
            search_modified_date: new Date(),
            search_skip: 0,
            search_limit: 100,

            zapp_content_search_api_running: false,
            zapp_content_search_response: null,
            zapp_content_delete_api_running: false,
            zapp_content_delete_response: null,
            zapp_content_publish_api_running: false,
            zapp_content_publish_response: null,
            zapp_content_reject_api_running: false,
            zapp_content_reject_response: null,
            zapp_content_target_index: null,
        };
        
        this.getZappOptions = this.getZappOptions.bind(this);
        this.searchZappContent = this.searchZappContent.bind(this);
    }

    getZappOptions = async () => {
        this.props.refreshIdToken().then((token) => {
            const payload = {
                skip: 0,
                limit: 10000
            }
            ZappFactoryZappSearchAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("ZappFactoryZappSearchAPI API failed!")
                } else {
                    message.destroy()
                    //message.success("ZappFactoryZappSearchAPI API success!")
                    this.setState({
                        zapp_options: response.message.zapps
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to get Zapp options!")
                alert(error)
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to get Zapp options!", error)
            alert(error)
        })
    }

    searchZappContent = async () => {
        if (this.state.search_zapp_id == null || this.state.search_modified_date == null) {
            message.destroy()
            message.info("Select a Zapp type and modified date!")
            return
        }

        message.loading("Searching Zapp content...", 0)
        this.setState({
            zapp_content_search_api_running: true,
            zapp_content_search_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                zapp_id: this.state.search_zapp_id,
                language: this.state.search_language,
                modified_date: this.state.search_modified_date,
                skip: this.state.search_skip,
                limit: this.state.search_limit
            }
            ZappFactoryContentSearchAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Zapp content search API failed!")
                    this.setState({
                        zapp_content_search_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("Zapp content search API success!")
                    this.setState({
                        zapp_content_search_api_running: false,
                        zapp_content_search_response: response.message.zapp_contents,
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to search Zapp content!")
                this.setState({
                    zapp_content_search_api_running: false,
                })
            })

        }).catch((error) => {
            message.destroy()
            message.error("Failed to search Zapp content!", error)
            this.setState({
                zapp_content_search_api_running: false,
            })
        })
    }

    publishZappContent = async (index, status) => {
        message.loading("Publishing Zapp Factory content...", 0)
        this.setState({
            zapp_content_publish_api_running: true,
            zapp_content_target_index: index,
        })

        this.props.refreshIdToken().then((token) => {
            const id = this.state.zapp_content_search_response[index].id
            ZappFactoryContentPublishAPI(id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("ZappFactoryContentPublishAPI API failed!")
                } else {
                    message.destroy()
                    message.success("ZappFactoryContentPublishAPI API success!")
                    var zapp_contents = this.state.zapp_content_search_response
                    zapp_contents[index] = response.message.zapp_content
                    this.setState({
                        zapp_content_publish_api_running: false,
                        zapp_content_target_index: null,
                        zapp_content_publish_response: response.message,
                        zapp_content_search_response: zapp_contents,
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to publish Zapp Factory content!")
                this.setState({
                    zapp_content_publish_api_running: false,
                    zapp_content_target_index: null,
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to publish Zapp Factory content!", error)
            this.setState({
                zapp_content_publish_api_running: false,
                zapp_content_target_index: null,
            })
        })
    }

    rejectZappContent = async (index) => {
        message.loading("Rejecting Zapp Factory content...", 0)
        this.setState({
            zapp_content_reject_api_running: true,
            zapp_content_target_index: index,
        })

        this.props.refreshIdToken().then((token) => {
            const id = this.state.zapp_content_search_response[index].id
            ZappFactoryContentRejectAPI(id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("ZappFactoryContentRejectAPI API failed!")
                } else {
                    message.destroy()
                    message.success("ZappFactoryContentRejectAPI API success!")
                    var zapp_contents = this.state.zapp_content_search_response
                    zapp_contents[index] = response.message.zapp_content
                    this.setState({
                        zapp_content_reject_api_running: false,
                        zapp_content_target_index: null,
                        zapp_content_reject_response: response.message,
                        zapp_content_search_response: zapp_contents,
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to reject Zapp Factory content!")
                this.setState({
                    zapp_content_reject_api_running: false,
                    zapp_content_target_index: null,
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to reject Zapp Factory content!", error)
            this.setState({
                zapp_content_reject_api_running: false,
                zapp_content_target_index: null,
            })
        })
    }

    deleteZappContent = async (index) => {
        message.loading("Deleting Zapp content...", 0)
        this.setState({
            zapp_content_delete_api_running: true,
            zapp_content_delete_response: null,
            zapp_content_target_index: index,
        })

        this.props.refreshIdToken().then((token) => {
            // delete Zapp content from the DB
            const id = this.state.zapp_content_search_response[index].id
            ZappFactoryContentDeleteAPI(id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Zapp Factory content delete API failed!")
                    this.setState({
                        zapp_content_delete_api_running: false,
                        zapp_content_delete_response: response.message,
                        zapp_content_target_index: null,
                    })
                } else {
                    message.destroy()
                    message.success("Zapp Factory content deleted!")

                    // remove the deleted Zapp content from the search results
                    var zapp_contents = this.state.zapp_content_search_response;
                    zapp_contents.splice(index, 1)

                    this.setState({
                        zapp_content_delete_api_running: false,
                        zapp_content_delete_response: response.message,
                        zapp_content_search_response: zapp_contents,
                        zapp_content_target_index: null,
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to delete Zapp Factory content!")
                this.setState({
                    zapp_content_delete_api_running: false,
                    zapp_content_target_index: null,
                })
            })

        }).catch((error) => {
            message.destroy()
            message.error("Failed to delete Zapp Factory content!", error)
            this.setState({
                zapp_content_delete_api_running: false,
                zapp_content_target_index: null,
            })
        })
    }

    componentDidMount(){
        if (this.state.zapp_options.length == 0) {
            this.getZappOptions()
        }
    }

    render() {
        const createForm = (
            <div>                
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3>Search for Zapp content</h3>
                        <Row style={{ width: '100%' }}>
                            <Col span={5}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Zapp Type
                                </Text>
                                <br />
                                <Select
                                    placeholder={ this.state.zapp_options.length == 0 ? "Loading Zapp types..." : "Select a Zapp type" }
                                    value={this.state.search_zapp_id}
                                    onChange={(e) => {
                                        this.setState({
                                            search_zapp_id: e
                                        })
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {this.state.zapp_options.map((zapp) => (
                                        <Option key={zapp.id}>{zapp.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={2}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Language
                                </Text>
                                <br />
                                <LanguageDropDown
                                    language={this.state.search_language}
                                    language_options={this.state.lang_options}
                                    onLanguageSelect={(e) => {
                                        console.log("onLanguageSelect - " + e)
                                        this.setState({
                                            search_language: e
                                        })
                                    }}
                                    disabled={true}
                                />
                            </Col>
                            <Col span={4} style={{ paddingLeft: '20px'}}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Modified Date
                                </Text>
                                <br />
                                <Space direction="vertical">
                                    <DatePicker
                                        defaultValue={moment(this.state.search_modified_date)}
                                        onChange={(e) => {
                                            this.setState({
                                                search_modified_date: e
                                            })
                                        }}
                                    />
                                </Space>

                            </Col>
                            <Col span={3} style={{ paddingLeft: '20px'}}>
                                <Button type="primary" block
                                    style={{
                                        marginTop: '21px'
                                    }}
                                    onClick={this.searchZappContent} loading={this.state.zapp_content_search_api_running}
                                >
                                    Search 
                                </Button>
                            </Col>
                            <Col span={10}></Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Search Results
                                </Text>
                                <br />
                                <Row>
                                    {this.state.zapp_content_search_response ? (
                                        this.state.zapp_content_search_response.map((zapp_content, index) => {
                                            return (
                                                <Col span={6}>
                                                    <Row>
                                                        {
                                                            <iframe
                                                                style={{
                                                                    width: '100%',
                                                                    height: '480px'
                                                                }}
                                                                title="view"
                                                                src={zapp_content.view_url}
                                                            />
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <Col span={8}>
                                                            <Button type="primary" block
                                                                style={
                                                                    zapp_content.status == "converted" 
                                                                    && !(this.state.zapp_content_publish_api_running
                                                                        || this.state.zapp_content_reject_api_running
                                                                        || this.state.zapp_content_delete_api_running
                                                                        || this.state.zapp_content_search_api_running)
                                                                    ? {
                                                                        backgroundColor: '#52c41a',
                                                                        borderColor: '#52c41a',
                                                                    } : {}
                                                                }
                                                                onClick={() => {
                                                                    this.publishZappContent(index)
                                                                }}
                                                                loading={this.state.zapp_content_publish_api_running && this.state.zapp_content_target_index == index}
                                                                disabled={
                                                                    this.state.zapp_content_publish_api_running
                                                                    || this.state.zapp_content_reject_api_running
                                                                    || this.state.zapp_content_delete_api_running
                                                                    || this.state.zapp_content_search_api_running
                                                                    || zapp_content.status != "converted"
                                                                }
                                                            >
                                                                {zapp_content.status == "published" ? "Published" : (zapp_content.status == "rejected" ? "" : "Publish")}
                                                            </Button>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Button type="primary" block
                                                                style={
                                                                    zapp_content.status == "converted" 
                                                                    && !(this.state.zapp_content_publish_api_running
                                                                        || this.state.zapp_content_reject_api_running
                                                                        || this.state.zapp_content_delete_api_running
                                                                        || this.state.zapp_content_search_api_running)
                                                                    ? {
                                                                        backgroundColor: '#f5222d',
                                                                        bordercolor: '#f5222d',
                                                                    } : {}
                                                                }
                                                                onClick={() => {
                                                                    this.rejectZappContent(index)
                                                                }}
                                                                loading={this.state.zapp_content_reject_api_running && this.state.zapp_content_target_index == index}
                                                                disabled={
                                                                    this.state.zapp_content_publish_api_running
                                                                    || this.state.zapp_content_reject_api_running
                                                                    || this.state.zapp_content_delete_api_running
                                                                    || this.state.zapp_content_search_api_running
                                                                    || zapp_content.status != "converted"
                                                                }
                                                            >
                                                                {zapp_content.status == "rejected" ? "Rejected" : (zapp_content.status == "published" ? "" : "Reject")}
                                                            </Button>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Button type="primary" block
                                                                onClick={() => {
                                                                    this.deleteZappContent(index)
                                                                }}
                                                                loading={this.state.zapp_content_delete_api_running && this.state.zapp_content_target_index == index}
                                                                disabled={
                                                                    this.state.zapp_content_publish_api_running
                                                                    || this.state.zapp_content_reject_api_running
                                                                    || this.state.zapp_content_delete_api_running
                                                                    || this.state.zapp_content_search_api_running
                                                                }
                                                            >
                                                                Delete 
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
