import React from "react";
import { Select } from "antd";
const { Option } = Select;

export const AIPublisherIdDropDown = (props) => {
  return (
    <>
    <Select
        mode="multiple"
        placeholder="Specific Publisher ?"
        style={{
            width: '100%',
            paddingLeft: '5px',
            paddingRight: '5px'
        }}
        value={props.publisher_ids}
        allowClear={false}
        onChange={props.onPublisherSelect}
        >
        {props.available_publishers.map((e) => {
            return <Option
            value={e}>{props.formatPublisherId(e)}</Option>
        })}
    </Select>
    </>
  );
};

