export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AUTH_CLIENT_ID,
        authority: process.env.REACT_APP_AZURE_AUTH_AUTHORITY,
        redirectUri: window.location.protocol + "//" + window.location.host + "/blank"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["user.read"]
   };
   
// Add the endpoints here for Microsoft Graph API services you'd like to use.
// export const graphConfig = {
//     graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
// };