import React from "react";
import { Button, Modal, Row, Layout, Empty, Radio } from "antd";
const { Content } = Layout;

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const PrefixEffectModal = (props) => {
  return (
    <Modal
      title="Select Prefix Effect"
      centered
      open={props.isOpen}
      onOk={props.onOk}
      onCancel={props.onOk}
      closable={false}
      width={1200}
      footer={[
        <Button key="done" type="primary" onClick={props.onOk}>
          Done
        </Button>,
      ]}
    >
      <Content>
        <Row>
          {props.image_list.length == 0 ? (
            <Empty
              style={{
                paddingTop: "5px",
                width: "100%",
              }}
            />
          ) : (
            <Radio.Group
              onChange={props.imageOnSelect}
              style={{
                paddingTop: "5px",
              }}
              optionType="button"
            >
              {props.image_list.map((image) => {
                return (
                  <Radio
                    value={image}
                    style={{
                      height: "200px",
                    }}
                  >
                    <img
                      style={{
                        height: "200px",
                        // padding: '5px'
                      }}
                      src={props.image_cdn_prefix + image.replace(".mp4", ".gif")}
                    />
                    <br></br>
                    {image}
                    <br></br>
                  </Radio>
                );
              })}
            </Radio.Group>
          )}
        </Row>
      </Content>
    </Modal>
  );
};
