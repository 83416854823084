import React, { useState } from "react";
import { Button, Modal, Row, Col, Layout, Typography, Empty, Checkbox } from "antd";
import { EyeFilled } from '@ant-design/icons';
const { Content } = Layout;
const { Text } = Typography;


export const ArticleSelectionModal = (props) => {
    const [sel_count, setSelCount] = useState(0);
    
    return (
        <Modal
            title="Select Articles"
            centered
            open={props.isOpen}
            onOk={props.onOk}
            onCancel={props.onOk}
            onViewArticle={props.onViewArticle}
            fetchedArticles={props.fetchedArticles}
            articlesOnSelect={props.articlesOnSelect}
            selectedArticles={props.selectedArticles}
            maxSelectionCount={props.maxSelectionCount}
            api_running={props.api_running}
            closable={false}
            width={1200}
            footer={[
                <Button key="done" type="primary" onClick={props.onOk}>
                    Done
                </Button>,
            ]}
        >
            <Content>

                <Row>
                    <Col span={24}>
                        {props.fetchedArticles.length == 0 ? (
                            <Empty
                                style={{
                                    paddingTop: "5px",
                                    width: "100%",
                                }}
                            />
                        ) : (
                            <div style={{
                                marginTop: "20px"
                            }}>
                                <Text style={{
                                    fontWeight: "bold"
                                }}>
                                    Select Articles ({props.fetchedArticles.length} available,&nbsp;
                                    <span style={{ color: sel_count >= props.maxSelectionCount ? 'red' : 'black'}}>{sel_count} selected out of maximum of {props.maxSelectionCount}</span>):
                                </Text>
                                <br />
                                <div
                                    style={{
                                        height: "380px",
                                        overflow: "auto"
                                    }}
                                >
                                    <Checkbox.Group
                                        onChange={(e) => {
                                            setSelCount(e.length)
                                            props.articlesOnSelect(e)
                                        }}
                                        style={{
                                            paddingTop: "5px",
                                        }}
                                        optionType="button"
                                    >
                                        {props.fetchedArticles.map((article) => {
                                            return (
                                                <div style={{
                                                    borderBottom: "1px solid #eee"
                                                }}>
                                                    <div style={{width: "80%", display: "inline-block"}}>
                                                        <Checkbox
                                                            value={article}
                                                            style={{
                                                                paddingBottom: "4px",
                                                            }}
                                                            // disable this checkbox if it has been checked already
                                                            disabled={props.selectedArticles && !props.selectedArticles.includes(article) && sel_count >= props.maxSelectionCount}
                                                        >
                                                            {article.title}
                                                        </Checkbox>
                                                    </div>
                                                    <div style={{width: "17%", display: "inline-block"}}>
                                                        <Text style={{
                                                            fontStyle: "italic"
                                                        }}>
                                                            {article.source_name}
                                                        </Text>
                                                    </div>
                                                    <div style={{width: "3%", display: "inline-block"}}>
                                                        <Button
                                                            type="text"
                                                            onClick={() => {
                                                                props.onViewArticle(article);
                                                            }}
                                                        >
                                                            <EyeFilled />
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Checkbox.Group>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Content>
        </Modal>
    );
};
