import React from "react";
import { Select } from "antd";
const { Option } = Select;

export const CategoryDropDown = (props) => {
  return (
    <>
      <Select
        defaultValue={props.category}
        value={props.category}
        onChange={props.onCategorySelect}
        style={{
          width: '100%',
          paddingLeft: '5px',
          paddingRight: '5px'
        }}
      >
        {props.category_options.map((cat) => (
          <Option key={cat}>{cat}</Option>
        ))}
      </Select>
    </>
  );
};
