import {
    Button, Col, Input, Layout, message, Row, Card, Select, Empty,Modal,Tooltip,Popconfirm,Image, Tag,Checkbox,Pagination,Typography
} from 'antd';
import React from 'react';
import {CheckCircleTwoTone} from '@ant-design/icons';
import NavBar from '../components/header';
import {GPSCardsSearchAPI, MarkCardsAPI} from '../apis/1clickPreview';
import {UploadButton} from '../components/uploadButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
const { Title, Text } = Typography;

const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

export default class ContentReview extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            input_image_url: null,
            aws_cdn_prefix: process.env.REACT_APP_AWS_PREFIX,
            gps_search_api_running: false,
            gps_search_api_response: null,
            mark_api_running: false,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            title: null,
            gps_cards: null,
            duplicate_removal_v2: true,
            statuses_options: ["fastlane_published", "others"],
            status: "fastlane_published",
            submitStatus: "fastlane_published",
            source_name: "feedpost",
            country: "IN",
            category: "food_and_health",
            category_options: [
                "entertainment",
                "international",
                "business",
                "technology",
                "travel_and_lifestyle",
                "national_v_two",
                "sports",
                "live_game_streams",
                "food_and_health"
            ],
            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr",
                "ms",
                "vi",
                "th",
                "fil"
            ],
            language: "en",
            countries: ["IN", "ID", "US", "BR", "CO", "MX", "VN", "TH", "JP", "PH", "MY"],
             // pagination related
            currentPage: 1,
            pageSize: 24,
            order_by_field: "created_at",
            order_by_fields: ["created_at", "launch_time"],
            ready_for_autopublish: false,
            autopublished: false,
            genai_image: false,
            strict_image_search: true,
            offset: 0,
            limit: 200
        };

        this.cardManagementSearch = this.cardManagementSearch.bind(this);
      }

      handleSubmit = () => {
        // Update the state based on form input, for example:
        this.setState({ submitStatus: this.state.status });
        this.setState({ gps_search_api_response:null});
      };

       cardManagementSearch = async () => {
        message.loading("Fetching Cards...", 0)
        this.props.refreshIdToken().then((token) => {
            const payload = 
                {
                    "title": this.state.title
                }
            
            if (this.state.country) {
                payload["country"] = this.state.country
            }
            if (this.state.language) {
                payload["language"] = this.state.language
            }
            if (this.state.source_name) {
                payload["source_name"] = this.state.source_name
            }
            if (this.state.category) {
                payload["category"] = this.state.category
            }
            payload["order_by_field"] = this.state.order_by_field 
            payload["ready_for_autopublish"] = this.state.ready_for_autopublish
            payload["autopublished"] = this.state.autopublished
            if (this.state.genai_image) {
                payload["genai_image"] = this.state.genai_image
            }
            if (this.state.status) {
                payload["status"] = this.state.status
            }
            payload["offset"] = this.state.offset
            payload["limit"] = this.state.limit
            //const response = GPSCardsSearchAPI(payload, token);
            GPSCardsSearchAPI(payload, token).then((response) => {
                if(response.status===false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                }else{
                    message.destroy()
                    message.success("Cards Search Complete!")
                }
                this.setState({
                    gps_search_api_running: false,
                    gps_search_api_response: response,
                    gps_cards: response.message,
                    currentPage: 1,
                });
               
            }).catch ((error) => {
                message.destroy()
                message.error("Failed to fetch cards. Try again?")
                this.setState({
                    gps_search_api_running: false,
                })
            })
        })
    }


    markCard = async (card_id, publishing_status="publish", remark=null) => {
        message.loading("Marking card ..", 0)
        this.props.refreshIdToken().then((token) => {
            const payload = {
                "ids": [card_id],
                "notes": {}
            }
            // if status == "unpublish" then write in notes, "mark_for_unpublish"
            if (publishing_status == "unpublish") {
                payload["notes"]["mark_for_unpublish"] = true
            }
            else {
                payload["notes"]["mark_for_publish"] = true
            }
            if (remark) {
                payload["notes"]["remark"] = remark
            }
            //console.log("Payload: ", payload)
            MarkCardsAPI(payload, token).then((response) => {
                if(response.status===false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                }else{
                    message.destroy()
                    message.success("Cards Search Complete!")
                }
                this.setState({
                    mark_api_running: false,
                    //currentPage: 1,
                });
            }).catch ((error) => {
                message.destroy()
                message.error("Failed to mark card. Try again?")
                this.setState({
                    mark_api_running: false,
                })
            })
        })
    }

    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <Row>
                <h2>1  Click Preview</h2>
            </Row>

            <Row>
            <Col span={8}>
            <h3>Title</h3>
            <Input placeholder='Search...' onChange={(e)=>{
                this.setState({
                    title: e.target.value
                })
            }}
            onPressEnter={
                !this.state.gps_search_api_running ? this.cardManagementSearch : undefined
              }
            />
            </Col>
            {/*

            <Col span={3}>
                <Checkbox
                style={{
                    marginTop: '30px',
                    marginLeft: '30px'
                }}
                checked={this.state.duplicate_removal_v2} onChange={(e)=>{
                    this.setState({
                        duplicate_removal_v2: e.target.checked
                    })
                }}>Stricter Duplicates</Checkbox>
            </Col>
            */}
            <Col span={2}>
            <h3>Language</h3>  
                <Tooltip trigger={['focus']} title={"Any specific Language"} placement="topLeft">
                    <Select
                        placeholder="Specific Language?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.language}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                language: e
                                })
                            }}
                        >
                        {this.state.lang_options.map(key => {
                            return <Option value={key}>{key}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={2}>
            <h3> Country</h3>  
                <Tooltip trigger={['focus']} title={"Any specific region?"} placement="topLeft">
                    <Select
                        placeholder="Specific region?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.country}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                country: e
                                })
                            }}
                        >
                        {this.state.countries.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Category</h3>  
                <Tooltip trigger={['focus']} title={"Any specific category?"} placement="topLeft">
                    <Select
                        placeholder="Specific category?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.category}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                category: e
                                })
                            }}
                        >
                        {this.state.category_options.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Source Name</h3>  
                <Input 
                    value={this.state.source_name}
                    placeholder='feedpost' onChange={(e)=>{
                    this.setState({
                        source_name: e.target.value
                    })
                }}
                onPressEnter={
                    !this.state.gps_search_api_running ? this.cardManagementSearch : undefined
                }
            />
            </Col>
            <Col span={3}>
            <h3> Status</h3>  
                <Tooltip trigger={['focus']} title={"Any specific status"} placement="topLeft">
                    <Select
                        placeholder="Status"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.status}
                        allowClear={true}
                        onChange={(e)=>{
                                this.setState({
                                    status: e
                                    })
                                }}
                        >
                        {this.state.statuses_options.map((e) => {
                            return <Option
                                value={e}>{e}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Sort by</h3>  
                <Tooltip trigger={['focus']} title={"Any specific date?"} placement="topLeft">
                    <Select
                        placeholder="Specific date?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.order_by_field}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                order_by_field: e
                                })
                            }}
                        >
                        {this.state.order_by_fields.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            </Row>
            <Row><br></br></Row>
            
            <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col span={2}>
                <h3> AI Image Generated</h3> 
                <Checkbox onChange={(e) => {
                    this.setState({
                        genai_image: e.target.checked
                    })
                }}></Checkbox>
                
            </Col>
            <Col span={2}>
            <h3> Ready for Autopublish</h3> 
                
                <Checkbox 
                 defaultChecked={true} 
                onChange={(e) => {
                    this.setState({
                        ready_for_autopublish: e.target.checked
                    })
                }}></Checkbox>
            </Col>
            <Col span={2}>
                <h3> AutoPublished Cards</h3> 
                <Checkbox onChange={(e) => {
                    this.setState({
                        autopublished: e.target.checked
                    })
                }}></Checkbox>
                
            </Col>
            </Row>
            <br/>
            <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col span={5}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
                onClick={async () => {
                    await this.cardManagementSearch();
                    this.handleSubmit();
                }} loading={this.state.gps_search_api_running} 
            >
             Search Cards
            </Button>
            </Col>
            </Row>
            <br>
            </br>
            </Content>
            <br></br>
            <br></br>
            <Content>
                        
                <Row style={{overflow: "scroll",justifyContent: 'center',
                                                alignItems: 'center',}}>
                {
                    this.state.gps_search_api_response == null
                    ? (
                        <Empty
                        style={{
                            paddingTop: "5px",
                            width: "100%",
                        }}
                        />
                    )
                    :
                    (   
                        <>
                        <Row>
                        <Pagination
                            current={this.state.currentPage}
                            pageSize={this.state.pageSize}
                            total={this.state.gps_cards.length}
                            onChange={(page, pageSize) => {
                                    var offset = (page - 1) * this.state.pageSize;
                                    this.setState({
                                        currentPage: page,
                                        offset: offset,
                                    });
                                }
                            }
                        />
                        <br></br>
                        <br></br>
                        </Row>
                        <Row>
                        {this.state.gps_cards.slice((this.state.currentPage - 1) * this.state.pageSize, this.state.currentPage * this.state.pageSize).map((item, item_index) => {
                            return (
                                <Col span={24} key={item.id}>
                                    <Card hoverable style={{ width: '100%' }}>
                                    <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                                        <Col span={6}>
                                        <Title level={4}>Title</Title>
                                        <Text>{item.title}</Text>
                                        <br></br>
                                        <br></br>
                                        <Title level={4}>Vernacular Title</Title>
                                        <Text>{item.vernacular_title}</Text>
                                        <br></br>
                                        <br></br>
                                        <Title level={5}>FastCard Summary</Title>
                                        <Text>{item.fastcard_summary}</Text>
                                        <br></br>
                                        <br></br>
                                        <Title level={5}>Created At</Title>
                                        <Text>{item.created_at}</Text>
                                        </Col>
                                        <Col span={3}>
                                        <Title level={5}>FastCard Image</Title>
                                        <img src={item.fastcard_image_selected_url} alt="FastCard" style={{ width: '100%' }} />
                                        </Col>
                                        <Col span={3}>
                                        <Title level={5}>FastCard Banner Image</Title>
                                        <img src={item.fastcard_image_banner_image_url} alt="FastCard" style={{ width: '100%' }} />
                                        </Col>
                                        <Col span={3}>
                                        <Title level={5}>Image Generated</Title>
                                        <img src={item.autopublish_genai_imagen_outpainted_selected_url} alt="GenAI" style={{ width: '100%' }} />
                                        </Col>
                                        <Col span={3}>
                                        <Title level={5}>Image Searched</Title>
                                        <img src={item.autopublish_image_selected_url} alt="AutoPublish" style={{ width: '100%' }} />
                                        <Text>{"Score: "+item.autopublish_image_selected_score}</Text>
                                        </Col>
                                        {this.state.submitStatus === 'fastlane_published' && (
                                            <Col span={3}>
                                                <Title level={5}>Image Live</Title>
                                                <img src={item.image_selected_url} alt="Live" style={{ width: '100%' }} />
                                            </Col>
                                        )}
                                        <Col span={3}>
                                        <Title level={5}>Article Operations</Title>
                                        <a href={item.url_selected} target="_blank" rel="noopener noreferrer">Open SHP Article</a>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <a href={"https://publisher.glance.inmobi.com/?id="+item.id} target="_blank" rel="noopener noreferrer">View on GPS</a>
                                        <br></br>
                                        </Col>
                                    </Row>
                                    </Card>
                                </Col>
                            )
                        })}
                        </Row>
                        </> 
                    )
                }
                </Row>
            </Content>
          </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                {createForm}
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                alt=""
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>

        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <NoAuth />
        </UnauthenticatedTemplate>
        </>
        )
    }
}
