import {
    Button, Col, Input, Layout, message, Select, Divider, Card, Breadcrumb, List, Menu, Row, Form, Tooltip
} from 'antd';
import React from 'react';
import NavBar from '../components/header';

import {GetArticleAPI} from "../apis/publisher"
import { ArticleHTML} from "../apis/articlePreview";
import { withRouter } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
import {ArticlePreviewModal} from "../components/articlePreviewModal";
const { Search } = Input;
const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

class AIArticleView extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            article: null,
            temp_article_html: null,
            get_article_api_running: false,
            article_preview_modal_open: true,
        };

        this.toggleArticlePreviewModal = this.toggleArticlePreviewModal.bind(this);
        this.getArticle = this.getArticle.bind(this);
    }

    toggleArticlePreviewModal = () => {
        this.setState({
            article_preview_modal_open: !this.state.article_preview_modal_open
        })
    }

    getArticle = (articleId) => {
        message.loading("Fetching Article", 0)
        this.props.refreshIdToken().then((token) => {
            GetArticleAPI(token, articleId).then((response) => {
                if(response.status==false){
                    message.destroy()
                    message.error("Fetched Article")
                }else{
                    message.destroy()
                    message.success("Article can't be fetched. Check URL again?!")
                }
                if(response.status == true){
                    this.setState({
                        article: response.message,
                        temp_article_html: ArticleHTML(response.message),
                    })
                }
                this.setState({
                    get_article_api_running: false,
                })
            }).catch((e) => {
                message.destroy()
                message.error("Error while fetching Article")
                this.setState({
                    get_article_api_running: false,
                })
            })
        }).catch((e) => {
            message.destroy()
            message.error("Error while fetching Article")
            this.setState({
                get_article_api_running: false,
            })
        })
    }

    async componentDidMount() {
        const { articleId } = this.props.match.params;
        console.log('Article ID:', articleId);
        this.getArticle(articleId).then((response) => {
            console.log("Fetched Article")
        });
    }

    componentDidUpdate(prevProps, prevState) {}

    
    render() {
        const createForm = (
            <div>
                <ArticlePreviewModal
                    isOpen={this.state.article_preview_modal_open}
                    onOk={this.toggleArticlePreviewModal}
                    article_html={this.state.temp_article_html}
                />
            </div>
        )        
        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 0px', marginTop: 50 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth/>
                </UnauthenticatedTemplate>
            </>
        )
    }
}

export default withRouter(AIArticleView);