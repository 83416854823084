import {
    DatePicker, Button, Col, Switch, Input, Layout, message, Row, Card, Select, Empty,Modal,Tooltip,Checkbox,Pagination, Tag
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import {GPSCardsSearchAPI, UnpublishCardsAPI} from '../apis/1clickPreview';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
const { Content, Footer } = Layout;
const { Option } = Select;

export default class HLModeration extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            input_image_url: null,
            aws_cdn_prefix: process.env.REACT_APP_AWS_PREFIX,
            gps_search_api_running: false,
            gps_search_api_response: null,
            mark_api_running: false,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            title: null,
            gps_cards: null,
            duplicate_removal_v2: true,
            //marked_for_unpublish: [],
            incorrect_headlines: [],
            incorrect_images: [],
            incorrect_landing_pages: [],
            marked_for_unpublish: [],
            reviewed_card_ids: [],
            statuses_options: ["fastlane_published", "others"],
            status: "fastlane_published",
            submitStatus: "fastlane_published",
            source_names: ["feedpost","wiki"],
            source_name: "feedpost",
            country: "IN",
            category: "food_and_health",
            packets: [],
            category_options: [
                "entertainment",
                "international",
                "business",
                "technology",
                "travel_and_lifestyle",
                "national_v_two",
                "sports",
                "live_game_streams",
                "food_and_health"
            ],
            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr",
                "ms",
                "vi",
                "th",
                "fil"
            ],
            language: "en",
            countries: ["IN", "ID", "US", "BR", "CO", "MX", "VN", "TH", "JP", "PH", "MY"],
            last_used_at_in_days: null,
            last_used_at_in_days_options: [2, 10, 15, 30, 90, 120, 180],
            isBatchUnpublishModalVisible: false,
            max_count : 100,
            image_url : "",
             // pagination related
            currentPage: 1,
            pageSize: 24,
            order_by_field: "launch_time",
            order_by_fields: ["launch_time", "created_at"],
            launch_date: null,
            include_reviewed: false,
            mark_as_reviewed: false,
            autopublished: false,
            offset: 0,
            limit: 500,
            page_offset: 0,
            page_limit: 500,
        };

        this.cardManagementSearch = this.cardManagementSearch.bind(this);
        this.toggleBatchUnpublishModal = this.toggleBatchUnpublishModal.bind(this);
        this.handleBatchUnpublish = this.handleBatchUnpublish.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }

    onDateChange = (date, dateString) => {
        this.setState({
            launch_date: dateString // Update the state with the formatted date string
        });
      }

      generateUnpublishPackets() {
        const offset = (this.state.currentPage - 1) * this.state.pageSize;
        const { incorrect_headlines, incorrect_images, incorrect_landing_pages, marked_for_unpublish, gps_cards } = this.state;
        return marked_for_unpublish.map(index => {
            const reasons = [];
            if (incorrect_headlines[index]) {
                reasons.push('incorrect_headline');
            }
            if (incorrect_images[index]) {
                reasons.push('incorrect_image');
            }
            if (incorrect_landing_pages[index]) {
                reasons.push('incorrect_landing_page');
            }
            return reasons.length > 0 ? {
                id: gps_cards[index+offset].id,
                moderator_unpublished_reasons: reasons,
            } : null;
        }).filter(packet => packet !== null);
    }
    
    generateReviewedCardIds(unpublish_card_packets) {
        // need to return this.state.reviewed_card_ids with ids of unpublish_card_packets
        const reviewed_card_ids = this.state.reviewed_card_ids || [];
        const reviewed_ids = [];
        unpublish_card_packets.forEach(packet => {
            reviewed_ids.push(packet.id);
        });
        return reviewed_card_ids.concat(reviewed_ids);
    }

    getGPSLink(id, country) {
        if (country == "IN") {
            return "https://publisher.glance.inmobi.com/?id=" + id;
        }
        else {
            // return country in lower case
            return "https://"+country.toLowerCase()+"-publisher.glance.inmobi.com/?id=" + id;
        }
    }

    formatDate(dateString) {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    }
    
      handleSubmit = () => {
        // Update the state based on form input, for example:
        this.setState({ submitStatus: this.state.status });
        this.setState({ gps_search_api_response:null});
      };
    toggleBatchUnpublishModal = () => {
        this.setState(prevState => ({
            isBatchUnpublishModalVisible: !prevState.isBatchUnpublishModalVisible
        }));
    }
    renderBatchUnpublishModal() {
        const { gps_cards, marked_for_unpublish } = this.state;
        const offset = (this.state.currentPage - 1) * this.state.pageSize;

        const cardsToUnpublish = marked_for_unpublish.map(index => gps_cards[index+offset]);
    
        return (
            <Modal
                title="Batch Unpublish"
                visible={this.state.isBatchUnpublishModalVisible}
                onOk={this.handleBatchUnpublish}
                onCancel={this.toggleBatchUnpublishModal}
                footer={[
                    <Button key="back" onClick={this.toggleBatchUnpublishModal}>
                        Back
                    </Button>,
                    <Button key="unpublish" type="danger" onClick={(e) => {
                        this.handleBatchUnpublish(cardsToUnpublish)
                    }}>
                        Unpublish
                    </Button>,
                ]}
            >
                <Row gutter={[16, 16]}>
                    {cardsToUnpublish.map((card, index) => (
                        <Col key={index} span={8}> {/* span=8 will create 3 columns per row */}
                            <Card
                                hoverable
                                style={{ width: '100%' }} // Adjust the width as necessary
                                cover={<img alt="Image Live" src={card.image_selected_url} style={{ width: '100%' }} />}
                            >
                                <div style={{ padding: '10px' }}>
                                    {card.modified_headline}
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Modal>
        );
    }
    
    handleBatchUnpublish(cardsToUnpublish) {
        // Add logic to handle batch unpublishing
        console.log('Unpublishing cards at index:', this.state.marked_for_unpublish);
        console.log("Unpublishing cards:", cardsToUnpublish)
        const card_packets = this.generateUnpublishPackets();
        const reviewed_card_ids = this.generateReviewedCardIds(card_packets);
        console.log("Generated Unpublish Packets: ", card_packets);
        console.log("Reviewed Card Ids: ", reviewed_card_ids);
        this.props.refreshIdToken().then((token) => {
            const payload = {
                card_packets: card_packets,
                reviewed_card_ids: reviewed_card_ids,
                meta: {
                    country: this.state.country
                }
            }
            UnpublishCardsAPI(payload, token).then((response) => {
                if(response.status===false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                }else{
                    message.destroy()
                    message.success("Cards Unpublished!")
                }
                this.setState({
                    unpublish_api_running: false,
                    unpublish_api_running: response,
                });
                console.log("response:", response)
            }).catch ((error) => {
                message.destroy()
                message.error("Failed to unpublish cards. Try again?")
                this.setState({
                    unpublish_api_running: false,
                })
            })
        }).catch ((error) => {
            message.destroy()
            message.error("Failed to unpublish cards. Try again?")
            this.setState({
                unpublish_api_running: false,
            })
        })
        this.toggleBatchUnpublishModal(); // Close the modal after operation
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    onPageChange = async (page, pageSize) => {
        const offset = (page - 1) * pageSize;
        console.log("Offset: ", offset, "Page: ", page, "PageSize: ", pageSize);
        await this.setStateAsync({
            currentPage: page,
            offset: offset,
            incorrect_headlines: [],
            incorrect_images: [],
            incorrect_landing_pages: [],
            marked_for_unpublish: [],
        });

        console.log('State has been updated after page change:', this.state);
        // Now, proceed with loading the next page or any other actions
        this.loadNextPage(); // Example function to load the next page
    };


    loadNextPage = () => {
        // Logic to load the next page
        console.log('Next page is being loaded with state:', this.state);
    };

       cardManagementSearch = async () => {
        message.loading("Fetching Cards...", 0)
        this.props.refreshIdToken().then((token) => {
            const payload = 
                {
                    "title": this.state.title
                }
            
            if (this.state.country) {
                payload["country"] = this.state.country
            }
            if (this.state.language) {
                payload["language"] = this.state.language
            }
            if (this.state.source_name) {
                payload["source_name"] = this.state.source_name
            }
            if (this.state.category) {
                payload["category"] = this.state.category
            }
            if (this.state.launch_date) {
                payload["launch_date"] = this.state.launch_date
            }
            payload["order_by_field"] = this.state.order_by_field
            payload["include_reviewed"] = this.state.include_reviewed
            if (this.state.autopublished) {
                payload["autopublished"] = this.state.autopublished
            }
            if (this.state.status) {
                payload["status"] = this.state.status
            }
            payload["offset"] = this.state.page_offset
            payload["limit"] = this.state.page_limit
            //const response = GPSCardsSearchAPI(payload, token);
            GPSCardsSearchAPI(payload, token).then((response) => {
                if(response.status===false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                }else{
                    message.destroy()
                    message.success("Cards Search Complete!")
                }
                this.setState({
                    gps_search_api_running: false,
                    gps_search_api_response: response,
                    gps_cards: response.message,
                    currentPage: 1,
                });
               
            }).catch ((error) => {
                message.destroy()
                message.error("Failed to fetch cards. Try again?")
                this.setState({
                    gps_search_api_running: false,
                })
            })
        })
    }


    componentDidMount(){
        // DO NOTHING HERE
    }

    handleReviewModeChange(card_id, isChecked) {
        this.setState(prevState => {
            let reviewed_card_ids = prevState.reviewed_card_ids || [];
            if (isChecked) {
                reviewed_card_ids.push(card_id);
            } else {
                reviewed_card_ids = reviewed_card_ids.filter(id => id !== card_id);
            }
            return {
                reviewed_card_ids
            };
        });
    }

    handleChange(item_index, type, isChecked) {
        this.setState(prevState => {
            // Initialize arrays if they are undefined
            const incorrect_headlines = prevState.incorrect_headlines || [];
            const incorrect_images = prevState.incorrect_images || [];
            const incorrect_landing_pages = prevState.incorrect_landing_pages || [];
            let marked_for_unpublish = prevState.marked_for_unpublish || [];
    
            console.log("Working on item_index: "+item_index)
            // Update the specific condition based on the checkbox type
            if (type === 'incorrect_headline') {
                incorrect_headlines[item_index] = isChecked;
            } else if (type === 'incorrect_image') {
                incorrect_images[item_index] = isChecked;
            } else if (type === 'incorrect_landing_page') {
                incorrect_landing_pages[item_index] = isChecked;
            }
    
            // Check conditions and update the marked_for_unpublish array
            if (incorrect_headlines[item_index] || incorrect_images[item_index] || incorrect_landing_pages[item_index]) {
                if (!marked_for_unpublish.includes(item_index)) {
                    marked_for_unpublish.push(item_index);
                }
            } else {
                marked_for_unpublish = marked_for_unpublish.filter(index => index !== item_index);
            }
            // Return the new state
            return {
                incorrect_headlines,
                incorrect_images,
                incorrect_landing_pages,
                marked_for_unpublish
            };
        });
        console.log("marked_for_unpublish", this.state.marked_for_unpublish)
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            console.log('Component did update with new state:', this.state);
        }
    }
    render() {
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <Row>
                <h2>🏎️ HL Moderation</h2>
            </Row>

            <Row>
            <Col span={8}>
            <h3>Title</h3>
            <Input placeholder='Search...' onChange={(e)=>{
                this.setState({
                    title: e.target.value
                })
            }}
            onPressEnter={
                !this.state.gps_search_api_running ? this.cardManagementSearch : undefined
              }
            />
            </Col>
            {/*

            <Col span={3}>
                <Checkbox
                style={{
                    marginTop: '30px',
                    marginLeft: '30px'
                }}
                checked={this.state.duplicate_removal_v2} onChange={(e)=>{
                    this.setState({
                        duplicate_removal_v2: e.target.checked
                    })
                }}>Stricter Duplicates</Checkbox>
            </Col>
            */}
            <Col span={2}>
            <h3>Language</h3>  
                <Tooltip trigger={['focus']} title={"Any specific Language"} placement="topLeft">
                    <Select
                        placeholder="Specific Language?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.language}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                language: e
                                })
                            }}
                        >
                        {this.state.lang_options.map(key => {
                            return <Option value={key}>{key}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={2}>
            <h3> Country</h3>  
                <Tooltip trigger={['focus']} title={"Any specific region?"} placement="topLeft">
                    <Select
                        placeholder="Specific region?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.country}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                country: e
                                })
                            }}
                        >
                        {this.state.countries.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Category</h3>  
                <Tooltip trigger={['focus']} title={"Any specific category?"} placement="topLeft">
                    <Select
                        placeholder="Specific category?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.category}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                category: e
                                })
                            }}
                        >
                        {this.state.category_options.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Source Name</h3>  
                <Input placeholder='feedpost' onChange={(e)=>{
                    this.setState({
                        source_name: e.target.value
                    })
                }}
                onPressEnter={
                    !this.state.gps_search_api_running ? this.cardManagementSearch : undefined
                }
            />
            </Col>
            <Col span={3}>
            <h3> Status</h3>  
                <Tooltip trigger={['focus']} title={"Any specific status"} placement="topLeft">
                    <Select
                        placeholder="Status"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.status}
                        allowClear={true}
                        onChange={(e)=>{
                                this.setState({
                                    status: e
                                    })
                                }}
                        >
                        {this.state.statuses_options.map((e) => {
                            return <Option
                                value={e}>{e}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Sort by</h3>  
                <Tooltip trigger={['focus']} title={"Any specific date?"} placement="topLeft">
                    <Select
                        placeholder="Specific date?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.order_by_field}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                order_by_field: e
                                })
                            }}
                        >
                        {this.state.order_by_fields.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            </Row>
            <Row><br></br></Row>
            
            <Row justify="center" align="middle" style={{ height: '100%' }}>

            <Col span={2} style={{ paddingBottom: '20px' }}>
                <h3>Review Mode</h3>
                <Switch 
                    checkedChildren="ON" 
                    unCheckedChildren="OFF" 
                    checked={this.state.mark_as_reviewed} 
                    onChange={(checked) => {
                        this.setState({
                            mark_as_reviewed: checked
                        })
                    }}
                />
            </Col>

            <Col span={3}>
                <h4>Launch Date</h4>
                <DatePicker
                    onChange={this.onDateChange}
                    format="YYYY-MM-DD" // Specify the format you want
                    allowClear={true} // Allow the user to clear the date
                />
            </Col>

            <Col span={2}>
                <h3> Already Reviewed Cards</h3>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={this.state.include_reviewed}
                    onChange={(checked) => {
                        this.setState({
                            include_reviewed: checked
                        })
                    }}
                />
            </Col>

            <Col span={2}>
                <h3> AutoPublished Cards</h3> 
                <Checkbox checked={this.state.autopublished} onChange={(e) => {
                    this.setState({
                        autopublished: e.target.checked
                    })
                }}></Checkbox>
                
            </Col>
            </Row>
            <br/>
            <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col span={5}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
                onClick={async () => {
                    await this.cardManagementSearch();
                    this.handleSubmit();
                }} loading={this.state.gps_search_api_running} 
            >
             Search Cards
            </Button>
            </Col>
            </Row>
            <br>
            </br>
            </Content>
            <br></br>
            <br></br>
            <Content>
                        
                <Row style={{overflow: "scroll",justifyContent: 'center',
                                                alignItems: 'center',}}>
                {
                    this.state.gps_search_api_response == null
                    ? (
                        <Empty
                        style={{
                            paddingTop: "5px",
                            width: "100%",
                        }}
                        />
                    )
                    :
                    (   
                        <>
                        <Row>
                        <Pagination
                            current={this.state.currentPage}
                            pageSize={this.state.pageSize}
                            total={this.state.gps_cards.length}
                            onChange={this.onPageChange}
                        />
                        <br></br>
                        <br></br>
                        </Row>
                        <Row>
                        {this.state.gps_cards.slice((this.state.currentPage - 1) * this.state.pageSize, this.state.currentPage * this.state.pageSize).map((item, item_index) => {
                            return (

                                <Col span={4}>

                                    <Card
                                        hoverable
                                        style={{
                                            width: 240,
                                            // if marked_for_unpublish contains the item_index then one condition else green
                                            boxShadow: this.state.marked_for_unpublish.includes(item_index) ? '0 4px 8px 0 rgba(255, 0, 0, 0.5)' : '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
                                            transition: 'box-shadow 0.3s' // Smooth transition for the shadow effect
                                        }}
                                        cover={
                                            <>
                                                <img 
                                                    alt={"Image Live"} // Using the item's title as the alt text for accessibility
                                                    src={item.image_selected_url} 
                                                    style={{ width: '100%' }} // Ensuring the image covers the card width
                                                />
                                            </>
                                        }
                                        
                                    >
                                        <div>
                                            <Tag color="purple"> {item.source_name} </Tag>
                                        {
                                            (item["moderator_reviewed"])
                                            ? <Tag color="green">{item.moderator_reviewed_by}</Tag>
                                            : <Tag color={"grey"}>Not Reviewed</Tag>
                                        }
                                        {
                                            (item["tags"] && item["tags"].length > 0)
                                            ? <Tag color="blue">{item["tags"]}</Tag>
                                            : <Tag color={"grey"}>No Tags</Tag>
                                        }
                                        </div>
                                        
                                        <div style={{ padding: '5px'}} title="Item Title">
                                        {item.title}
                                        </div>
                                        <div style={{ padding: '5px'}}>
                                         {item.modified_headline} 
                                        </div>
                                        <div style={{ padding: '5px'}}>
                                        <a href={item.url_selected} target="_blank" rel="noopener noreferrer">Open SHP Article</a>
                                        </div>
                                        <div style={{ padding: '10px' }}>
                                        <a href={this.getGPSLink(item.id, this.state.country)} target="_blank" rel="noopener noreferrer">Open GPS</a>
                                        </div>
                                        <div style={{ padding: '5px'}}>
                                        <span style={{ fontWeight: 'bold' }}>Created At:</span> {this.formatDate(item.created_at)}
                                        </div>
                                        <div style={{ padding: '5px'}}>
                                        <span style={{ fontWeight: 'bold' }}>Published At:</span> {this.formatDate(item.live_at)}
                                        </div>
                                        <Col>
                                            <Row>
                                            <label style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                <input
                                                    
                                                    type="checkbox"
                                                    checked={this.state.incorrect_headlines[item_index] || false}
                                                    onChange={(e) => this.handleChange(item_index, 'incorrect_headline', e.target.checked)}
                                                />
                                                Incorrect Headline
                                            </label>

                                            <label style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.incorrect_images[item_index] || false}
                                                    onChange={(e) => this.handleChange(item_index, 'incorrect_image', e.target.checked)}
                                                />
                                                Incorrect Image
                                            </label>

                                            <label style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.incorrect_landing_pages[item_index] || false}
                                                    onChange={(e) => this.handleChange(item_index, 'incorrect_landing_page', e.target.checked)}
                                                />
                                                Incorrect LP
                                            </label>

                                            <label style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.reviewed_card_ids.includes(item.id)}
                                                    onChange={(e) => this.handleReviewModeChange(item.id, e.target.checked)}
                                                />
                                                Mark as reviewed
                                            </label>
                                            </Row>
                                        </Col>
                                    </Card>
                                </Col>
                            )
                        })}
                        </Row>
                        <Col span={5}>
                            <Button type="primary" style={{ width: '100%', marginLeft:'5px' }} block
                               onClick={(e) => {
                                if (this.state.mark_as_reviewed != true) {
                                    message.error("Review mode off! Enable it to unpublish cards")
                                }
                                else {
                                    this.toggleBatchUnpublishModal()
                                }
                               }} 
                               loading={this.state.mark_api_running}
                            >
                                Batch Unpublish
                            </Button>
                        </Col>
                        {this.renderBatchUnpublishModal()}
                        </> 
                        
                    )
                }
                </Row>
            </Content>
          </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                {createForm}
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                alt=""
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>

        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <NoAuth />
        </UnauthenticatedTemplate>
        </>
        )
    }
}

