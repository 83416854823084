import React from "react";
import { Select } from "antd";
const { Option } = Select;

export const LanguageDropDown = (props) => {
  return (
    <>
      <Select
        defaultValue={"ba"}
        value={props.language}
        onChange={props.onLanguageSelect}
      >
        {props.language_options.map((lang) => (
          <Option key={lang}>{lang}</Option>
        ))}
      </Select>
    </>
  );
};
