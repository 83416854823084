const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;

export async function NicQuizArticlesAPI(payload, token=null){
    return await postRequestNicQuizAPI("articles", payload, token);
}


export async function NicQuizImageCropAPI(payload, token=null){
    return await postRequestNicQuizAPI("crop", payload, token);
}


export async function NicQuizGenerateAPI(payload, token=null){
    return await postRequestNicQuizAPI("generate", payload, token);
}


export async function NicQuizGenerateFromQuestionsAPI(payload, token=null){
    return await postRequestNicQuizAPI("generatefq", payload, token);
}


export async function NicQuizViewAPI(payload, token=null){
    return await postRequestNicQuizAPI("view", payload, token);
}


export async function NicQuizCreateAPI(payload, token=null){
    return await postRequestNicQuizAPI("quiz/create", payload, token);
}


export async function NicQuizCreateFromQuestionsAPI(payload, token=null){
    return await postRequestNicQuizAPI("quiz/createfq", payload, token);
}


export async function NicQuizUpdateAPI(payload, token=null){
    return await postRequestNicQuizAPI("quiz/update", payload, token);
}


export async function NicQuizPublishAPI(nic_quiz_id, token=null){
    return await getRequestNicQuizAPI("quiz/publish", nic_quiz_id, token);
}


export async function NicQuizSearchAPI(payload, token=null){
    return await postRequestNicQuizAPI("quiz/search", payload, token);
}


export async function NicQuizDeleteAPI(nic_quiz_id, token=null){
    return await getRequestNicQuizAPI("quiz/delete", nic_quiz_id, token);
}


export async function NicQuizQuestionSearchAPI(payload, token=null){
    return await postRequestNicQuizAPI("question/search", payload, token);
}


export async function NicQuizQuestionGenerateAPI(payload, token=null){
    return await postRequestNicQuizAPI("question/generate", payload, token);
}

export async function NicQuizQuestionRejectAPI(nic_question_id, token=null){
    return await getRequestNicQuizAPI("question/reject", nic_question_id, token);
}

export async function NicQuizGetImageSourceAPI(image_id, token=null){
    return await getRequestNicQuizAPI("question/imagesource", image_id, token);
}


export async function postRequestNicQuizAPI(api_function, payload, token=null, method="POST"){
    let request={
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    console.log(payload)
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/nicquiz/" + api_function, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}

export async function getRequestNicQuizAPI(api_function, id, token=null){
    let request={
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
    }
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/nicquiz/" + api_function + "/" + id, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}
