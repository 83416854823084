import React from "react";
import { Select } from "antd";
const { Option } = Select;
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const AudioDropDown = (props) => {
  return (
    <>
      <Select
       placeholder="Select Background Audio"
       style={{
           width: '40%',
       }}
        value={props.background_soundtrack}
        onChange={props.onBackgroundSelect}
      >
        {props.background_soundtrack_options.map((genre) => (
          <Option key={genre}>{genre}</Option>
        ))}
      </Select>
      <Select
      placeholder="Select song"
      style={{width: "60%",
    }}
    value={props.song}
    onChange={props.onBackgroundSongSelect}
    >
    {props.background_soundtrack_all_songs[props.background_soundtrack].map((song) => (<Option key={song}>{song}</Option> ))}
    
    </Select>
    <audio src={props.soundtrack_assets_cdn_prefix+props.background_soundtrack+"/"+props.background_soundtrack_song} type="audio/mpeg" controls autoplay muted>
      <source  src={props.soundtrack_assets_cdn_prefix+props.background_soundtrack+"/"+props.background_soundtrack_song} type="audio/mpeg" />
    </audio>
    </>
  );
};
