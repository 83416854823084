const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;

export async function ZappFactoryZappSearchAPI(payload, token=null) {
    return await postRequestZappFactoryAPI("zapp/search", payload, token);
}

export async function ZappFactoryContentCreateAPI(payload, token=null) {
    return await postRequestZappFactoryAPI("content/create", payload, token);
}


export async function ZappFactoryContentCreateFromAPI(payload, token=null) {
    return await postRequestZappFactoryAPI("content/createfrom", payload, token);
}


export async function ZappFactoryContentViewAPI(payload, token=null) {
    return await postRequestZappFactoryAPI("content/view", payload, token);
}


export async function ZappFactoryContentUpdateAPI(payload, token=null) {
    return await postRequestZappFactoryAPI("content/update", payload, token);
}


export async function ZappFactoryContentGetAPI(id, token=null) {
    return await getRequestZappFactoryAPI("content/get", id, token);
}


export async function ZappFactoryContentDeleteAPI(id, token=null) {
    return await getRequestZappFactoryAPI("content/delete", id, token);
}


export async function ZappFactoryContentSearchAPI(payload, token=null) {
    return await postRequestZappFactoryAPI("content/search", payload, token);
}


export async function ZappFactoryContentPublishAPI(id, token=null) {
    return await getRequestZappFactoryAPI("content/publish", id, token);
}


export async function ZappFactoryContentRejectAPI(id, token=null) {
    return await getRequestZappFactoryAPI("content/reject", id, token);
}


export async function postRequestZappFactoryAPI(api_function, payload, token=null, method="POST") {
    let request={
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    console.log(payload)
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/zappfactory/" + api_function, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}

export async function getRequestZappFactoryAPI(api_function, id, token=null) {
    let request={
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
    }
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/zappfactory/" + api_function + "/" + id, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}
