import React, { useState } from "react";
import { Select } from "antd";

export const MultiSelectDropDown = (props) => {
    const selAll = '-- Select All --'

    const options = [selAll].concat(props.options);
    const [selected_items, setSelectedItems] = useState([]);
    const filtered_options = options.filter((o) => !selected_items.includes(o));

    return (
        <>
            <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                value={selected_items}
                onChange={(value, selected_options) => {
                    var last_selected = selected_options.pop()
                    if (last_selected && (last_selected.value == selAll)) {
                        setSelectedItems(options.slice(1));
                        props.onChange(options.slice(1));
                    } else {
                        setSelectedItems(value);
                        props.onChange(value);
                    }
                }}
                options={filtered_options.map((item) => ({
                    value: item,
                    label: item,
                }))}
            />
        </>
    );
};
