import { Button, Col, Input, Layout, message, Row, Typography } from 'antd';
import React from 'react';
import NavBar from '../components/header';
import { WikipediaListAPI, WikipediaSearchAPI, WikipediaScheduleCollectionAPI } from "../apis/wikiSHPs";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
import uuid4 from 'uuid4';

const { Content, Footer } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

export default class WikiCollections extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            id_token: null,

            list_page_url: "",
            depth: "1",
            output_urls: "",
            output_urls_count: 0,

            list_page_url: "",
            wikipedia_list_api_running: false,

            search_query: "",
            search_method: "",
            minimum_score: 0.85,
            search_results_max_count: 20,
            wikipedia_search_api_running: false,

            schedule_collection_api_running: false,
            block_rescheduling: false,
        };

        this.getWikipediaListURLs = this.getWikipediaListURLs.bind(this);
        this.getWikipediaSearchResults = this.getWikipediaSearchResults.bind(this);
    }

    getWikipediaListURLs = async () => {
        message.loading("Getting Wikipedia list URLs...", 0)
        this.setState({
            output_urls: "",
            output_urls_count: 0,
            wikipedia_list_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            const payload = {
                list_page_url: this.state.list_page_url,
                depth: this.state.depth,
            }
            WikipediaListAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("WikipediaListAPI API failed!")
                    this.setState({
                        wikipedia_list_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("WikipediaListAPI API success!")
                    this.setState({
                        output_urls: response.message.urls.join("\n"),
                        output_urls_count: response.message.urls.length,
                        wikipedia_list_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to get Wikipedia list!")
                this.setState({
                    wikipedia_list_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to get Wikipedia list!", error)
            this.setState({
                wikipedia_list_api_running: false
            })
        })
    }


    getWikipediaSearchResults = async (search_method) => {
        message.loading("Getting Wikipedia search results...", 0)
        this.setState({
            output_urls: "",
            output_urls_count: 0,
            search_method: search_method,
            wikipedia_search_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            const payload = {
                query: this.state.search_query,
                search_method: search_method,
                minimum_score: this.state.minimum_score,
                limit: this.state.search_results_max_count,
            }
            WikipediaSearchAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("WikipediaSearchAPI API failed!")
                    this.setState({
                        wikipedia_search_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("WikipediaSearchAPI API success!")
                    let search_results_summary = ""
                    if (response.message.results.length > 0) {
                        for (let i = 0; i < response.message.results.length; i++) {
                            search_results_summary += response.message.results[i].score.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ": " + response.message.results[i].name + "\n"
                        }
                    }
                    this.setState({
                        output_urls: search_results_summary,
                        output_urls_count: response.message.results.length,
                        wikipedia_search_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to get Wikipedia search results!")
                this.setState({
                    wikipedia_search_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to get Wikipedia search results!", error)
            this.setState({
                wikipedia_search_api_running: false
            })
        })
    }


    scheduleCollectionCreation = async () => {
        // schedule the collection creation
        message.loading("Scheduling collection creation...", 0)
        this.setState({
            schedule_collection_api_running: true
        })
        this.props.refreshIdToken().then((token) => {
            const payload = {
                source: "lex",
                external_id: uuid4(),  // any source requesting collection creation may provide their own unique id for tracking on their side
                query: this.state.search_query,
                search_method: "theme",
                minimum_score: this.state.minimum_score,
                publishers: ["us_en_wiki"],
                shp_count: this.state.search_results_max_count,
                // settings below are left to their defaults currently, could add to the UI later
                // is_dynamic: bool = False
                // refresh_interval: int = 0
                // refresh_next: datetime = None
            }
            WikipediaScheduleCollectionAPI(payload, token).then((response) => {
                if (response.status==false || response.message.scheduled==false) {
                    message.destroy()
                    message.error("WikipediaScheduleCollection API failed!")
                    this.setState({
                        schedule_collection_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("WikipediaScheduleCollection API success, collection has been scheduled!")
                    this.setState({
                        schedule_collection_api_running: false,
                        block_rescheduling: true
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to schedule collection creation!")
                this.setState({
                    schedule_collection_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to schedule collection creation!", error)
            this.setState({
                schedule_collection_api_running: false
            })
        })
    }

    
    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }
    
    render() {
        console.log("nic_quiz: ", this.state.nic_quiz)
        const createForm = (
            <div>          
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Wiki SHP Collection Creation
                        </h3>
                        <br />
                        <Row>
                            <Col span={18}>
                                <Text>
                                    Enter a theme search query that defines the collection of Wiki SHP articles you want to create,
                                    plus the maximum number of SHPs to be created. The minimum score can be set between 0 and 1,
                                    with a default of 0.85. Increasing the minimum score will increase the relevance of the SHPs
                                    to the theme, but may also reduce the number of SHPs created.
                                </Text> 
                                <br />                               
                                <Input
                                    placeholder="Enter Theme Search Query"
                                    value={this.state.search_query}
                                    onChange={(e) => {
                                        this.setState({
                                            search_query: e.target.value,
                                            block_rescheduling: false
                                        })
                                    }}
                                    disabled={this.state.wikipedia_search_api_running}
                                />
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={5}>
                                Maximum Collection Size:<br />
                                <Input
                                    placeholder="Collection Size"
                                    value={this.state.search_results_max_count}
                                    onChange={(e) => {
                                        this.setState({
                                            search_results_max_count: Math.min(1000, e.target.value)
                                        })
                                    }}
                                    disabled={this.state.wikipedia_search_api_running}
                                    style={{width: '100px'}}
                                />
                            </Col>
                            <Col span={5}>
                                Minimum score:<br />
                                <Input
                                    placeholder="Minimum Score"
                                    value={this.state.minimum_score}
                                    onChange={(e) => {
                                        this.setState({
                                            minimum_score: e.target.value
                                        })
                                    }}
                                    disabled={this.state.wikipedia_search_api_running}
                                    style={{width: '100px'}}
                                />
                            </Col> 
                        </Row>
                        <br/>
                        <Row>
                            <Col span={3}>
                                <Button
                                    type="primary"
                                    onClick={
                                        (e) => {
                                            this.getWikipediaSearchResults("theme")
                                        }
                                    }
                                    loading={this.state.wikipedia_search_api_running && this.state.search_method == "theme"}
                                    disabled={
                                        (this.state.search_query=="") ||
                                        (this.state.schedule_collection_api_running)
                                    }
                                >
                                    Generate Sample Query Results
                                </Button>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Sample Query Results (Total {this.state.output_urls_count} results found)
                        </h3>
                        <br />
                        <Row>
                            <Text>
                                Note that the Wikipedia pages listed below are only indicative of the SHPs that will be created.
                            </Text>
                            <br />
                            <Col span={16}>
                                <TextArea 
                                    rows={15}
                                    style={{ width: 1200 }}
                                    placeholder="Fill in the form above and click the button"
                                    value={this.state.output_urls}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>
                            Schedule Collection Creation
                        </h3>
                        <br />
                        <Row>
                            <Text>
                                If the sample query results are acceptable, click the button below to schedule the creation of the SHPs.
                            </Text>
                            <Col span={24}>
                                <Button
                                    type="primary"
                                    onClick={
                                        (e) => {
                                            this.scheduleCollectionCreation()
                                        }
                                    }
                                    loading={this.state.schedule_collection_api_running}
                                    disabled={
                                        (this.state.search_query=="") ||
                                        (this.state.wikipedia_search_api_running) ||
                                        (this.state.block_rescheduling)
                                    }
                                >
                                    Schedule Collection Creation
                                </Button>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.state.signin_name}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
