import { Input, Layout, message, Select, Steps, Typography, Upload } from 'antd';
import React from 'react';
import NavBar from '../components/header';
// require('dotenv').config()
const { Dragger } = Upload;
const { Header, Content, Footer } = Layout;
const { Search } = Input;
const { Step } = Steps;
const { Option } = Select;
const { Paragraph } = Typography;
const FOMM_BASE_URL = "http://fomm.centralai.glanceai.inmobi.com:8080"


export default class  HelpPage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token: null,
            id_token_claims: null,
            
            // project_table configs
            content_style: {
                height: '80vh',
                color: '#fff',
                lineHeight: '160px',
                textAlign: 'center',
                background: '#364d79',
              }
        };
      }

    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {
        return (
            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 0px', 
            marginTop: 60,
            backgroundColor:'#ffffff',
            }}>
                <center>
                    <Typography.Title
                        style={{
                            fontSize: '15px',
                            height:'10vh',
                            marginTop:'20%',
                            color:'black',
                            fontFamily: "Arial"
                        }}
                    >Reach out to engineers (Ritesh Pallod / Pradeep M) on <a style={{fontSize: '18px', color: 'red'}}>Lex Helpline</a> for any doubts / suggestion for improvements / KT via Teams <a style={{fontSize: '20px', color: 'red'}}>24/7</a> </Typography.Title>
                </center>
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor:'#ffffff', color:'black', fontSize: '17px'}}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Powered by <b>AI</b></Footer>
          </Layout>
        )
    }
}
