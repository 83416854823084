import React, { useState } from "react";
import { Button, Modal, Row, Col, Layout, Typography, Empty, Select, Checkbox } from "antd";
import Cropper from 'react-easy-crop';
const { Content } = Layout;
const { Text } = Typography;


export const ImageCroppingModal = (props) => {
    return (
        <Modal
            title="Crop Image"
            centered
            open={props.isOpen}
            onOk={props.onOk}
            onCancel={props.onOk}
            closable={false}
            width={800}
            footer={[
                <Button key="done" type="primary" onClick={props.onClose}>
                    Crop
                </Button>,
            ]}
        >
            <Content>
                <Row>
                    <Col span={24}>
                        <Text>
                            Move the image to crop it:
                        </Text>
                        <br />
                        <div
                            style={{
                                width: '100%',
                                height: '400px',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                            >
                            <Cropper
                                image={props.image_url}
                                crop={props.crop}
                                zoom={props.zoom}
                                aspect={props.aspect}
                                onCropChange={props.onCropChange}
                                onZoomChange={props.onZoomChange}
                                onCropComplete={props.onCropComplete}
                            />
                        </div>
                    </Col>
                </Row>
            </Content>
        </Modal>
    );
};
