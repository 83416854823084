import React from "react";
import { Button, Modal, Row, Col, Layout, Input, Empty, Radio } from "antd";
const { Content } = Layout;

const { Search } = Input;
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const TemplateModal = (props) => {
  return (
    <Modal
      title="Select Template"
      centered
      open={props.isOpen}
      onOk={props.onOk}
      onCancel={props.onOk}
      closable={false}
      width={1200}
      footer={[
        <Button key="done" type="primary" onClick={props.onOk}>
          Done
        </Button>,
      ]}
    >
      <Content>
        <Row>
          {props.template_options.length == 0 ? (
            <Empty
              style={{
                paddingTop: "5px",
                width: "100%",
              }}
            />
          ) : (
            <Radio.Group
              onChange={props.imageOnSelect}
              style={{
                paddingTop: "5px",
              }}
              optionType="button"
            >
              {props.template_options.map((image) => {
                return (
                  <Radio
                    value={image}
                    style={{
                      height: "200px",
                    }}
                  >
                    <img
                      style={{
                        height: "200px",
                        // padding: '5px'
                      }}
                      src={
                        image == "random"
                        ? "https://g.glance-cdn.com/public/dynamic/1440x2560/0aa1d8c9fb86c2276a617755f29caaa94fc1322d.jpg"
                        : props.image_cdn_prefix + image + ".gif"}
                    />
                    <br></br>
                    {image}
                    <br></br>
                    <br></br>
                  </Radio>
                );
              })}
            </Radio.Group>
          )}
        </Row>
      </Content>
    </Modal>
  );
};
