import { UpCircleOutlined } from '@ant-design/icons';
import {
    Button, Col, Input, Layout, message, Select, Divider, Card, Breadcrumb, List, Menu, Row
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import { withRouter } from 'react-router-dom';
import {PersonabotChatAPI} from "../apis/personabot";
import {PersonabotChatGPT4API} from "../apis/personabot";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";


const { Search } = Input;
const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

class Personabot extends React.Component{

    constructor(props) {
        super(props);
        this.lastMessageRef = React.createRef();
        // Don't call this.setState() here!
        const bots= [
            {
                "id": 1,
                "name": "Custom Bot",
                "system_message": "",
            },
            {
                "id": 2,
                "name": "Sports Bot",
                "system_message": "You are a Chatbot that caters to sports enthusiasts. You  provide sports tips, news, and updates, as well as connect users to sports communities. Try not to answer anything away from this topic.",
            },
            {
                "id": 3,
                "name": "Humorous Bot",
                "system_message": "You are an humours chat bot. You have a good sense of humor with a witty and funny personality. You are friendly, incorporate jokes, memes and playful banter. You are friendly and concise and do not provide answers that might be NFSW or might hurt others and answer in a funny way.",
            },
            {
                "id": 4,
                "name": "Pop Culture Bot",
                "system_message": "You are pop culture savy chatbots. You have information on the latest trends in music, movies, TV shows, and internet culture are likely to appeal to this age group of 18 to 24. You can recommend content, discuss current events, and even participate in fan discussions. You are friendly and concise and answer based on facts, while keeping the mood light. Try not to answer anything away from this topic.",
            },
            {
                "id": 5,
                "name": "Gaming Bot",
                "system_message": "You are a Chatbot that cater to gamers and eSports enthusiasts. You  provide gaming tips, news, and updates, as well as connect users to gaming communities. Try not to answer anything away from this topic.",
            },
            {
                "id": 6,
                "name": "Influencer Mimicing Bot",
                "system_message": "You are an Influencer and Celebrity Chatbots.You are designed to emulate the personalities of popular influencers or celebrities can be a hit among young adults. You offer insights into their favorite celebrities' lives or provide exclusive content.Try not to answer anything away from this topic.",
            },
            {
                "id": 7,
                "name": "Personal Growth Bot",
                "system_message": "You are a Personal Growth and Wellness Chatbots. You guide Many young adults are who interested in self-improvement, mental health, and physical fitness. You are friendly and concise and only provide factual answers to queries. Try not to answer anything away from this topic.",
            },
            {
                "id": 8,
                "name": "Educational & Learning Bot",
                "system_message": "You are an Educational and Career, Learning Chatbots. You guide young adults navigate college, internships, and early career choices, how to study something, you are friendly and concise and only provide factual answers to queries. You help people or any individual plan any of the learning experiences. Try not to answer anything away from this topic.",
            },
            {
                "id": 9,
                "name": "Companion Bot",
                "system_message": "You are an Social Chatbot. You can simulate the experience of talking to a friend, offering companionship and empathy, can be popular among young adults. You are a friend, a buddy and a confidante. You can engage in casual conversations and provide emotional support.You are friendly and concise and do not provide answers that might be NFSW or might hurt others and answer in a funny way.",
            }
        ]
        const botMapping = bots.reduce((acc, bot) => {
            acc[bot.id] = bot;
            return acc;
          }, {});

        this.state = {
            bot_id: 1,
            bot_name: null,
            loading: false,
            system_message: null,
            bots: bots,
            botMapping: botMapping,
            messages: [],
            bot_options: ["turbo3.5", "gpt4"],
            bot_option:"turbo3.5",
            inputValue: '',
            loadingText: 'Thinking',
            question: null,
            answer: null,
            history: [],
            customSystemMessage: "",
            isCustomSystemMessageFrozen: false,
        };

        this.myMessageBubbleStyle = {
            position: 'relative',
            borderRadius: '12px',
            backgroundColor: '#ccd8ff',
            padding: '8px',
            marginBottom: '8px',
            maxWidth: '80%',
        };
            
        this.theirMessageBubbleStyle = {
            position: 'relative',
            borderRadius: '12px',
            backgroundColor: '#d6f5d6',
            padding: '8px',
            marginBottom: '8px',
            maxWidth: '80%',
          };
         
          this.myMessageContentStyle = {
            display: 'inline-block',
            color: 'black',
            whiteSpace: 'pre-wrap',
            textAlign: 'right',
            fontFamily: 'sans-serif', // Add this
          };
          
        this.theirMessageContentStyle = {
            display: 'inline-block',
            color: 'black',
            whiteSpace: 'pre-wrap',
            textAlign: 'left',
            fontFamily: 'sans-serif', // Add this
          };

        this.preElementStyle = {
            margin: 0,
            whiteSpace: 'pre-wrap',
            fontFamily: 'inherit',
            fontSize: 'inherit',
        };

        this.updateLoadingText = this.updateLoadingText.bind(this);
    }

    scrollToBottom() {
        if (this.lastMessageRef.current) {
          this.lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    updateLoadingText = async () => {
        const { loadingText } = this.state;
        const nextLoadingText = loadingText.length < 11 ? loadingText + '.' : 'Thinking';
        this.setState({ loadingText: nextLoadingText });
      };
      startLoadingAnimation = async () => {
        this.loadingAnimationInterval = setInterval(this.updateLoadingText, 500);
      };
      
      stopLoadingAnimation = async () => {
        clearInterval(this.loadingAnimationInterval);
      };

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
      };
    
      handleSendClick = async () => {
        try {
            this.props.refreshIdToken().then((token) => {
            var question = this.state.inputValue;
            this.setState((prevState) => ({
                messages: [
                    ...prevState.messages,
                    { text: prevState.inputValue, isMine: true },
                    ],
                inputValue: '',
            }));
            this.setState({loading: true})
            console.log("loading via .. loading ", this.state.loading)

            this.startLoadingAnimation();

                const payload = {
                    system_message: this.state.system_message,
                    question: question,
                    history: this.state.history,
                    answer: this.state.answer,
                }
                console.log(payload)
                try {
                    console.log("Bot option selected:",this.state.bot_option)
                    const apiFunction = this.state.bot_option == 'turbo3.5' ? PersonabotChatAPI : PersonabotChatGPT4API;
                    apiFunction(payload, token).then((response) => {
                        // Append the answer to the messages array
                        if (response && response.status) {
                            this.setState((prevState) => ({
                            messages: [
                                ...prevState.messages,
                                { text: response.message.answer, isMine: false },
                            ],
                            }));
                            // TODO: MAKE SURE THIS ALWAYS HAPPENS
                            this.setState({
                                question: question,
                                answer: response.message.answer,
                                history: response.message.history,
                                loading: false,
                            })
                        } else {
                            // Handle the case when the response doesn't have the expected format
                            console.error('Invalid response format:', response);
                        } 
                    }) 
                } catch (error) {
            // Handle the error while making the API call
            console.error('Error while sending the message:', error);
                }
            })
            this.setState({loading: false})
            console.log("loading via .. loading ", this.state.loading)
            this.stopLoadingAnimation();
        }
        catch (error) {
            // Handle the error while making the API call
            console.error('Error while sending the message:', error);  
            this.setState({loading: false})
            console.log("loading via .. loading ", this.state.loading)
            this.stopLoadingAnimation();

        }
        this.scrollToBottom();
    }; 

    async componentDidMount() {
        const { botId } = this.props.match.params;
        console.log('Bot ID:', botId);
        const bot = this.state.botMapping[botId];
        this.setState({
            system_message: bot["system_message"],
            bot_id: bot["id"],
            bot_name: bot["name"],
        })
      }

      componentDidUpdate(prevProps, prevState) {
        if (prevState.messages.length !== this.state.messages.length) {
          this.scrollToBottom();
        }
      }

    render() {
        const { messages, inputValue } = this.state;
        const createForm = (
            <div>
                <Layout style={{
                    minHeight: '90vh',
                }}>
                    {
                        this.state.bots == null
                        ? (
                            <h3>You should login perhaps</h3>
                        )
                        : 
                        <>
                            <Sider
                                width={400}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                }}
                                >
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    selectedKeys={[this.state.bot_id.toString()]}
                                    style={{
                                        height: '100%',
                                        borderRight: 0,
                                        borderTop: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                    }}
                                >

                                    <Divider orientation="left"
                                        orientationMargin={0}
                                        style={{
                                            margin: '5px'
                                        }}
                                    />
                                    {
                                        this.state.bots.map((bot) => {
                                            return (
                                                <>
                                                    <Menu.Item key={bot.id}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        width: '100%',
                                                        height: 'auto',
                                                    }}
                                                    onClick={(e)=> {
                                                        this.setState({
                                                            system_message: bot["system_message"],
                                                            bot_id: bot["id"],
                                                            bot_name: bot["name"],
                                                            history: [],
                                                            question: null,
                                                            answer: null,
                                                            messages: [],
                                                            loading: false,
                                                            loadingText: 'Thinking',
                                                        })
                                                        this.props.history.push(`/personabot/${bot.id}`);
                                                    }}>
                                                            <h3>{bot["name"]}</h3>
                                                    </Menu.Item>
                                                    <Divider orientation="left"
                                                        orientationMargin={0}
                                                        style={{
                                                            margin: '1px'
                                                        }}
                                                    />
                                                </>
                                            )       
                                        })
                                    }
                                </Menu>
                            </Sider>
                            {
                                    (this.state.bot_id == null)
                                    ? (
                                        <>
                                        <div
                                            style={{
                                                padding: '0 24px 24px',
                                                paddingTop: 5,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                                }}
                                        >
                                        <h2> Please select bot</h2>
                                        </div>
                                        </>
                                    )
                                    :
                                    <>
                            <Layout
                                style={{
                                    padding: '0 24px 24px',
                                    paddingTop: 5,
                                }}
                            >
                                <Breadcrumb
                                    style={{
                                    margin: '16px 0'
                                    }}
                                >
                                    <Breadcrumb.Item>{this.state.bot_name}</Breadcrumb.Item>
                                </Breadcrumb>
                                <Content
                                    style={{
                                        height: '70vh', // 50% of the viewport height
                                        overflowY: 'auto', // Enable vertical scrolling when the content overflows
                                    }}
                                >
                                    {
                                    this.state.bot_id === 1 && (
                                        <div
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 10,
                                                padding: '0 8px',
                                            }}
                                        >
                                        <Row align="middle" >
                                            <Col span={20}>
                                                <TextArea rows={2}
                                                    placeholder="Instruct your AI companion to follow a specific behaviour: Example - be a bot that can only speak the truth in a very precise manner"
                                                    value={this.state.customSystemMessage}
                                                    onChange={(e) =>
                                                        this.setState({ customSystemMessage: e.target.value })
                                                    }
                                                    disabled={this.state.isCustomSystemMessageFrozen}
                                                />
                                            </Col>
                                            {!this.state.isCustomSystemMessageFrozen && (                                
                                                <Col span={4}>
                                                    
                                                        <Select
                                                            placeholder="position"
                                                            style={{
                                                                width: '100%',
                                                                paddingLeft: '5px',
                                                                paddingRight: '5px'
                                                            }}
                                                            value={this.state.bot_option}
                                                            allowClear={true}
                                                            onChange={(e)=>{
                                                                this.setState({
                                                                    bot_option: e
                                                                })
                                                            }}
                                                            >
                                                            {this.state.bot_options.map((e) => {
                                                                return <Option
                                                                value={e}>{e}</Option>
                                                            })}
                                                            </Select>
                                                
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                system_message: this.state.customSystemMessage,
                                                                isCustomSystemMessageFrozen: true,
                                                            });
                                                        }}
                                                    >
                                                        Freeze
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                        </div>
                                    )
                                }
                                    <List
                                        dataSource={messages}
                                        renderItem={(message) => 
                                            <List.Item>
                                                <div
                                                    style={{
                                                    display: 'flex',
                                                    justifyContent: message.isMine ? 'flex-end' : 'flex-start',
                                                    width: '100%',
                                                    padding: '4px 0',
                                                    }}
                                                >
                                                <div 
                                                    style={
                                                        message.isMine ? this.myMessageBubbleStyle : this.theirMessageBubbleStyle
                                                    }
                                                >
                                                <pre
                                                style={
                                                    message.isMine
                                                    ? { ...this.myMessageContentStyle, ...this.preElementStyle }
                                                    : { ...this.theirMessageContentStyle, ...this.preElementStyle }
                                                }
                                                >{message.text}</pre>
                                                </div>
                                                </div>                                                
                                            </List.Item>
                                            }
                                    />
                                            <List.Item ref={this.lastMessageRef}>
                                            {(this.state.loading)?(
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: '4px 0' }}>
                                                <div style={this.theirMessageBubbleStyle}>
                                                <span style={this.theirMessageContentStyle}><i>{this.state.loadingText}</i></span>
                                                </div>
                                            </div>
                                            ):null}
                                            </List.Item>
                                </Content>
                                <Footer>
                                    <Row>
                                    <Col span={22}>
                                    <Input
                                        value={this.state.inputValue}
                                        onChange={this.handleInputChange}
                                        onPressEnter={
                                            !this.state.loading ? this.handleSendClick : undefined
                                          }
                                    />
                                    </Col>
                                    <Col span={2}>
                                    <Button type="primary" disabled={this.state.loading} onClick={this.handleSendClick}>
                                        Send
                                    </Button>
                                    </Col>
                                    </Row>
                                </Footer>

                            </Layout>
                            </>}
                        </>
                    }
                </Layout>
            </div>
        )

        
        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 0px', marginTop: 50 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth/>
                </UnauthenticatedTemplate>
            </>
        )
    }
}

export default withRouter(Personabot);