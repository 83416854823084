import {
    Button, Col, Input, Layout, message, Row, Card, Select, Typography
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import { LogoPlacerAPI} from "../apis/helpers";
import { getBeautifullsLogosAPI} from "../apis/beautifulls";
import {UploadButton} from '../components/uploadButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
import Draggable from 'react-draggable';
import {IMSImageSearchModal} from "../components/imsImageSearchModal";
import {LogoManagementModal} from '../components/logoCampaignManagementModal';
import { IMSV2ImageSelectionAPI} from "../apis/vcs";
const { Title, Text } = Typography;
const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;


export default class LogoPlacer extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            id_token: null,
            token: null,
            input_image: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
            logo_placer_api_running: false,
            logo_placer_api_response: null,
            bgImage: null,
            bgImageURL: "https://g.glance-cdn.com/public/dynamic/1440x2560/081f555f856dc33581c9a8b115b90d4d7f507e05.jpg",
            bgImageUrlInput: null,

            // logo placer
            logo: null,
            x_norm: 36,
            y_norm: 135,
            logoWidth: 200,
            logoPosition: {x: 0, y: 0},
            logo_scale_factor: 0.45,
            logo_upload_loading: false,
            heightMultiplier: 1.77777778,
            bgFixedWidth: 300,
            usual_bg_image_width: 1440,
            boundStartX: 40,
            boundStartY: 127,
            boundWidth: 218,
            boundHeight: 249,
            logoURL: 'https://aicontentsa.blob.core.windows.net/dev/output-onlinepngtools.png',
            finalUrl: null,

            // image search
            image_search_modal_open: false,
            image_ids: [],
            image_cdn_prefix: process.env.REACT_APP_AWS_PREFIX,
            image_id_to_source_map: {},
            image_source: null,
            search_text: "",

            // logo campaign modal
            logo_campaign_modal_open: false,
            campaigns: [],
        };
        this.logoPlacer = this.logoPlacer.bind(this);
        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.imageSelection = this.imageSelection.bind(this);
        this.toggleLogoCampaignModal = this.toggleLogoCampaignModal.bind(this);
      }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }
    toggleLogoCampaignModal = () => {
        // if campaigns is empty, fetch campaigns
        this.props.refreshIdToken().then((token) => {
            getBeautifullsLogosAPI(token).then((response) => {
                if(response.status==false){
                    message.error("Ok. Failed to fetch campaigns!")
                }else{
                    message.success("Fetched campaigns!")
                    this.setState({
                        campaigns: response.message.campaigns,
                        token: token,
                    })    
                }
            }).catch((error) => {
                message.error("Failed to fetch campaigns!")
            })
        }).catch((error) => {
            message.error("Login Issue!")
        })
        this.setState({
            logo_campaign_modal_open: !this.state.logo_campaign_modal_open
        })
    }
    handleKeyDown = (e) => {
        let delta = 5;  // Set this to the number of percentage points you want to move the logo with each keypress.
        let { x_norm, y_norm, bgFixedWidth, heightMultiplier } = this.state;
        switch (e.code) {
          case "ArrowUp":
            y_norm = Math.max(this.state.boundStartY, y_norm - delta); // limit the movement to not go beyond the parent div
            break;
          case "ArrowDown":
            y_norm = Math.min(this.state.boundStartY+this.state.boundHeight, y_norm + delta); // limit the movement to not go beyond the parent div
            break;
          case "ArrowLeft":
            x_norm = Math.max(this.state.boundStartX, x_norm - delta); // limit the movement to not go beyond the parent div
            break;
          case "ArrowRight":
            x_norm = Math.min(this.state.boundStartX+this.state.boundWidth, x_norm + delta); // limit the movement to not go beyond the parent div
            break;
          default:
            return;
        }

        const x = (x_norm / bgFixedWidth) * 100;
        const y = (y_norm / (bgFixedWidth*heightMultiplier)) * 100;
        this.setState({
            x_norm: x_norm,
            y_norm: y_norm,
            logoPosition: {
                ...this.state.logoPosition,
                x: x,
                y: y,
            }
        });
      }
    imageSelection = async (search_string) => {
        if(search_string == ""){
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": search_string,
                    "max_count": 30,
                }
            }
            IMSV2ImageSelectionAPI(payload, token).then((response) => {
                message.destroy()
                if(response.status==false){
                    message.error("Image Selection API failed!")
                }else{
                    message.destroy()
                    message.success("Fetched Some Fresh Images!")
                }
                if(response.status == true){
                    console.log("Image Selection API Response: ", response.message)
                    const image_ids = response.message.images.map(item => item.id);
                    const image_id_to_source_map = response.message.images.reduce((acc, item) => {
                        acc[item.id] = item.source_name;
                        return acc;
                    }, {});
                    this.setState({
                        image_ids: image_ids,
                        image_id_to_source_map: image_id_to_source_map,
                    })
                    console.log("Image Ids: ", this.state.image_ids)
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch images!")
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to fetch images!")
        })
    }

    logoPlacer = async () => {
        this.setState({
            logo_placer_api_response: null,
            logo_placer_api_running: true,
        })
        const img = new Image();
        img.src = this.state.logoURL; // Set the src to the URL of the image you want to load
        img.onload = () => {
            const bg_image_scale_factor = this.state.bgFixedWidth / this.state.usual_bg_image_width;
            const equivalent_logo_width_in_original_image = this.state.logoWidth / bg_image_scale_factor;
            const backend_scale_factor = equivalent_logo_width_in_original_image / img.width;
            const normalized_scale_factor = this.state.logo_scale_factor * backend_scale_factor;
            // Do something with normalized_scale_factor
            const payload = {
                image_url: this.state.bgImageURL,
                logo_url: this.state.logoURL,
                logo_position: {
                    x: this.state.logoPosition.x/100,
                    y: this.state.logoPosition.y/100,
                },
                logo_scale_factor: normalized_scale_factor,
            }
            console.log("payload", payload)
            message.loading("Pasting logo...", 0)
            this.props.refreshIdToken().then((token) => {
                var status_code = 200;
                try {
                    LogoPlacerAPI(payload, token).then((response) => {
                        status_code = response.status_code
                        if(response.status==false){
                            message.destroy()
                            message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                        }else{
                            message.destroy()
                            message.success("Logo Pasted!")
                        }
                        this.setState({
                            logo_placer_api_running: false,
                            logo_placer_api_response: response,
                            finalUrl: response.message.image_url,
                        })
                        window.open(response.message.image_url)
                    })
                } catch (error) {
                    console.log(error)
                    message.destroy()
                    if (status_code == 500){
                        message.error("Failed to paste logo. Try again :)")
                    }
                    else {
                        message.error("Failed to paste logo!")
                    }
                    this.setState({
                        logo_placer_api_running: false,
                    })
                }
            })
        };
    }
    handleDrag = (e, data) => {
        const { x, y } = data;
        const { bgFixedWidth } = this.state;
        const xPercent = (x / bgFixedWidth) * 100;
        const yPercent = (y / (bgFixedWidth*this.state.heightMultiplier)) * 100;
        this.setState({ logoPosition: { x: xPercent, y: yPercent } });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
      }
      componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
      }
      
    render() {
        const createForm = (
            <div>
                <Layout>
                <IMSImageSearchModal
                    isOpen={this.state.image_search_modal_open}
                    onOk={this.toggleImageSearchModal}
                    imageSelection={this.imageSelection}
                    default_value={this.state.search_text}
                    image_list={this.state.image_ids}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                    imageOnSelect={(e)=>{
                        this.setState({
                            image_source: this.state.image_id_to_source_map[e.target.value],
                            bgImageURL: this.state.image_cdn_prefix + e.target.value + ".jpg",
                        }, () => {console.log("image_searched_on_index")}
                        )
                    }}
                    selectedImage={this.state.bgImageURL}
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.props.idToken}
                    onImUploadChange={(e)=>{
                        if(e.file.status == 'done'){
                            message.success('Image Uploaded!')
                            this.setState({
                                bgImageURL: e.file.response.image_url,
                            })
                        }
                    }}
                />
                <LogoManagementModal
                    isOpen={this.state.logo_campaign_modal_open}
                    onOk={this.toggleLogoCampaignModal}
                    campaigns={this.state.campaigns}
                    onLogoSelect={(e)=>{
                        this.setState({
                            logoURL: e,
                        }, () => {console.log("image_searched_on_index")}
                        )
                    }}
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    idToken={this.props.idToken}
                />

                <Row style={{width:'100%'}}>
                    <Content>
                        <h3> Ask AI to Generate LS Images </h3>
                        <Row>
                            <Col span={4}>
                            <Row>
                            <Text>
                                Select Base LS Image
                            </Text>
                            </Row>
                            <Row>
                            <Button
                                onClick={this.toggleImageSearchModal}
                                style={{
                                    width:'100%',
                                    overflow:'clip'
                                }}
                            >
                                {this.state.banner_image_url==undefined?
                                    "Select Image":
                                    this.state.banner_image_url
                                }
                            </Button>
                            </Row>
                            <Row>
                                <Input
                                    placeholder="Enter image URL"
                                    value={this.state.bgImageURL}
                                    onChange={(e) => this.setState({ bgImageURL: e.target.value })}
                                />
                            </Row>
                            <Row>
                            <div>
                                <Text>
                                    Image Source
                                </Text>
                                    <Input
                                        placeholder="Enter image URL"
                                        value={this.state.image_source}
                                        onChange={(e) => this.setState({ image_source: e.target.value })}
                                    />
                            </div>
                            </Row>

                                </Col>
                                <Col span={4}>
                                    <Row>
                                <Text>
                                    Logo Management
                                </Text>
                                <Button
                                    onClick={this.toggleLogoCampaignModal}
                                    style={{
                                        width:'100%',
                                        overflow:'clip'
                                    }}
                            >
                                Logo Management
                            </Button>
                                </Row>
                                <Row>
                                </Row>
                                </Col>
                                <Col span={8}>
                                    <Row>
                                <Text>
                                    Upload custom logo
                                </Text>
                                </Row>
                                <Row>
                                <UploadButton
                                    signin_name={this.props.username}
                                    upload_loading={this.state.logo_upload_loading}
                                    upload_endpoint={this.state.upload_endpoint}
                                    id_token={this.props.idToken}
                                    onImUploadChange={(e) => {
                                        if (e.file.status === 'done') {
                                            console.log("file: ", e)
                                            message.success('Logo Uploaded!');
                                            let temp_logo_url = e.file.response.image_url;
                                            this.setState({
                                                logo: temp_logo_url,
                                                logoURL: temp_logo_url
                                            });
                                        }
                                    }}
                                />
                                </Row>

                            </Col>
                            </Row>

                            <br></br>
                            <Row>
    <Col span={2}>
        X Coordinate
        <Input
            placeholder="X Coordinate"
            value={this.state.logoPosition.x}
            onChange={(e) => {
                const { bgFixedWidth } = this.state;
                const x = (e.target.value * bgFixedWidth) / 100;        
                this.setState({ 
                    x_norm: x,
                    logoPosition: {
                        ...this.state.logoPosition,
                        x: e.target.value,
                },
            })}
        }
        />
        </Col>
        <Col span={2}>
        Y Coordinate
        <Input
            placeholder="Y Coordinate"
            value={this.state.logoPosition.y}
            onChange={(e) =>{
                const { bgFixedWidth, heightMultiplier } = this.state;
                const y = (e.target.value * (bgFixedWidth*heightMultiplier)) / 100;
                this.setState({ 
                    y_norm: y,
                    logoPosition: {
                        ...this.state.logoPosition,
                        y: e.target.value,
                    },
                })}
            }
        />
    </Col>
    <Col span={2}>
    Logo Scale Factor
    <Input
        value={this.state.logo_scale_factor}
        onChange={(e) => {
            this.setState({ logo_scale_factor: e.target.value });
        }}
    />
    </Col>
    </Row>
    <br></br>
    <Row>
        <Col>
        {this.state.bgImageURL && (
            <div 
            style={{
                width: this.state.bgFixedWidth+"px",
                height: this.state.bgFixedWidth*this.state.heightMultiplier+"px",
                backgroundImage: `url(${this.state.bgImageURL})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                position: 'relative'
            }}
            >
    {/* Add this div for the greyed-out bounding box */}
    <div 
        style={{
            position: 'absolute',
            left: this.state.boundStartX+"px",
            top: this.state.boundStartY+"px",
            width: this.state.boundWidth+"px",
            height: this.state.boundHeight+"px",
            backgroundColor: 'red',
            opacity: 0.3,
            //zIndex: -1 // To make sure it's behind the logo
        }}
    ></div>
                {this.state.logoURL && (
                <Draggable
                    bounds={{
                        left: this.state.boundStartX,
                        top: this.state.boundStartY,
                        right: this.state.boundWidth+this.state.boundStartX,
                        bottom: this.state.boundHeight+this.state.boundStartY,
                      }}
                    position={{
                        x: this.state.x_norm,
                        y: this.state.y_norm,
                    }}
                    onDrag={this.handleDrag}
                >
                    <img
                        src={this.state.logoURL}
                        alt="Logo"
                        style={{
                            width: this.state.logo_scale_factor*this.state.logoWidth+'px',
                            position: 'absolute',
                        }}
                    />
                </Draggable>
                )}
            </div>
        )}

        <Button
            style={{
                marginTop: '10px',
            }}
            type="primary" onClick={() => {
                this.logoPlacer()
            }}>Generate Image!</Button>
    </Col>
    <Col span={8}></Col>
    <Col>
    {this.state.finalUrl && (
        <img
            src={this.state.finalUrl}
            alt="Final"
            style={{
                width: this.state.bgFixedWidth+"px",
                height: this.state.bgFixedWidth*this.state.heightMultiplier+"px",
                position: 'absolute',
            }}/>)}
    </Col>
</Row>

                    </Content>
            </Row>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                    <NavBar
                        authHandler={async (instance) => {await this.authHandler(instance)}}
                        authRefresh={this.authRefresh}
                        signin_name={this.props.username}
                    />
                    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                            {createForm}
                        </div>
                        </Content>
                    <Footer style={{ textAlign: 'center' }}>
                    <img
                        src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                        alt="logo"
                        style={{
                            height:'20px',
                            display:'inline',
                            paddingRight:'10px'
                        }}/>
                        ©2021 Created by TAG AI Team
                    </Footer>
                </Layout>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuth />
            </UnauthenticatedTemplate>
            </>
        )
    }
}
