import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConf";
import { Button, Upload, List, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const UploadButton = (props) => {
  const { instance } = useMsal();

  return (
    <Upload
      showUploadList={true}
      action={props.upload_endpoint}
      headers={{ Authorization: `Bearer ${props.id_token}` }}
      onChange={props.onImUploadChange}
      maxCount={1}
    >
      <Button
        icon={<UploadOutlined />}
        // disabled={props.signin_name == null ? true : false}
        style={{
          width: "100%",
          marginLeft: "5px",
        }}
        loading={props.upload_loading}
      >
        {
          (props.upload_button_label)
          ? props.upload_button_label
          : "Upload Media / Ad"
        }
      </Button>
    </Upload>
  );
};

export const MultiUploadButton = (props) => {
  const { instance } = useMsal();

  return (
    <Upload
      multiple={true}
      action={props.upload_endpoint}
      headers={{ Authorization: `Bearer ${props.id_token}` }}
      onChange={props.onImUploadChange}
      fileList={props.fileList} // <-- Use the fileList prop here
      maxCount={200}
    >
      <Button
        icon={<UploadOutlined />}
        // disabled={props.signin_name == null ? true : false}
        style={{
          width: "100%",
          marginLeft: "5px",
        }}
        loading={props.upload_loading}
      >
        {
          (props.upload_button_label)
          ? props.upload_button_label
          : "Upload Media / Ad"
        }
      </Button>
    </Upload>
  );
};
