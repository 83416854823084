const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;

export async function getRequestBeautifullsAPI(api_function, token){
    let request={
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
    }
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/beautifulls/" + api_function, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }
}


export async function postRequestBeautifullsAPI(api_function, payload, token=null, method="POST"){
    let request={
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    console.log(payload)
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/beautifulls/" + api_function, request);
    if (rawResponse.ok) {
        const content = await rawResponse.json();
        return {
            "status": true,
            "message": content,
            "status_code": rawResponse.status,
            "status_text": rawResponse.statusText
        }
    }
    let content=null
    try {
        content = await rawResponse.json()
    } catch (error) {
        content = await rawResponse.text()
    }
    return {
        "status": false,
        "message": content,
        "status_code": rawResponse.status,
        "status_text": rawResponse.statusText
    }

}


export async function getBeautifullsLogosAPI(token) {
    return await getRequestBeautifullsAPI("list", token);
}

export async function createBeautifullsCampaignAPI(payload, token) {
    return await postRequestBeautifullsAPI("create", payload, token);
}

export async function updateBeautifullsCampaignAPI(payload, token) {
    return await postRequestBeautifullsAPI("update", payload, token);
}

export async function deleteBeautifullsCampaignAPI(payload, token) {
    return await postRequestBeautifullsAPI("delete", payload, token);
}
