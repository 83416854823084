import * as React from 'react';
import { SignInButtonMSFT } from './signInButtonMSFT';
import { Layout, Row, Col, Typography } from 'antd';
const { Header, Content, Footer } = Layout;

async function authHandler(instance) {
    var loginRequest = {
        scopes: ["user.read"] // optional Array<string>
    };

    try {
        const loginResponse = await instance.loginRedirect(loginRequest);
    } catch (err) {
        // handle error
        console.log(err)
    }
}

export default function NoAuth() {
    return (
        <Layout style={{ 'min-height': '100vh' }}>
            <Content className="site-layout" style={{}}>
                <Row style={{
                    marginTop: '15%'
                }}>
                    <Col span={9}></Col>
                    <Col span={6}>
                        <div className="logo" style={{}}>
                            <center>
                                <Typography.Title
                                    level={1}
                                    style={{
                                        color: "white",
                                        paddingTop: "12px",
                                        color: "#FC054D",
                                    }}
                                >
                                    <img
                                        src={process.env.PUBLIC_URL + "/lex.png"}
                                        style={{
                                            height: "50px",
                                        }}
                                    ></img>{" "}
                                </Typography.Title>
                            </center>
                        </div>
                        <SignInButtonMSFT

                            signInFunc={authHandler}
                        />
                        <center>
                            <Typography.Title
                                level={3}
                                style={{
                                    height: '10vh',
                                    marginTop: '5%',
                                    color: 'black',
                                    fontFamily: "Arial"
                                }}
                            >Helping you create. Fast.</Typography.Title>
                        </center>
                    </Col>
                    <Col span={9}></Col>

                </Row>
            </Content>
            <Footer style={{ textAlign: 'center', fontSize: '17px' }}>
                <img
                    src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                    style={{
                        height: '20px',
                        display: 'inline',
                        paddingRight: '10px'
                    }} />
                ©2021 Powered by <b>AI</b>
            </Footer>
        </Layout>

    )
}