import {
    Button, Col, Input, Layout, message, Row, Card, Select,Checkbox, Empty,Upload,Image,Tooltip
} from 'antd';
import React from 'react';
import UpCircleOutlined from '@ant-design/icons';
import NavBar from '../components/header';
import {LSTransitionAPI, ImageSuperResolutionAPI,ImageAutoCorrectionAPI} from "../apis/vcs";
import {UploadButton} from '../components/uploadButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;


export default class GlideEditor extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            input_image: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
            default_prompt: "Fill in the Empty pixels with clean background and no texts so that it can be used as a wallpaper 4k",
            prompt: null,
            positions: ["center", "bottom"],
            models: ["dalle","imagen"],
            model: "dalle",
            outpaint: false,
            smart_crop: false,
            super_res:false,
            position: "bottom",
            color_correction: false,
            super_res: false,
            ls_transition_api_running: false,
            ls_transition_api_response: null,
            image_super_res_api_running: false,
        };
        this.lsTransition = this.lsTransition.bind(this);
        this.imageAutoCorrect = this.imageAutoCorrect.bind(this);
        this.imageSuperRes = this.imageSuperRes.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.imageAutoCorrectAndSuperRes = this.imageAutoCorrectAndSuperRes.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);

      }

      imageAutoCorrect = async (image_url) => {
        message.loading("Create High Quality Images...", 0)
        this.props.refreshIdToken().then((token) => {
            var status_code = 200;
            const payload = {
                image_url: image_url,
                parameters: {}
            }    
            try {
                ImageAutoCorrectionAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Image Auto Correction Completed!")
                    }
                    this.setState({
                        image_auto_correct_api_running: false,
                        image_auto_correct_api_response: response,
                    })
                    window.open(response.message.image_url)
                })
                
            }
            catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to Auto Correct images! Most likely due to high load. Please do not use webp. Try again :)")
                }
                else {
                    message.error("Failed to Auto Correct images!")
                }
                this.setState({
                    image_auto_correct_api_running: false,
                })
            }
                
        })
    }

    lsTransition = async () => {
        this.setState({
            ls_transition_api_running: true,
            ls_transition_api_response: null,
        })
        this.props.refreshIdToken().then((token) => {
            var prompt = this.state.prompt
            if ((prompt==null) || (prompt === "")) {
                console.log("this.state.prompt is ", this.state.prompt, " so using default prompt: ", this.state.default_prompt)
                prompt = this.state.default_prompt
            }
            const payload = {
                image_url:this.state.input_image,
                prompt: prompt,
                outpaint: this.state.outpaint,
                smart_crop: this.state.smart_crop,
                position: this.state.position,
                model_name: this.state.model,
            }
            var status_code = 200;
            try {
                LSTransitionAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("LS Transition Completed!")
                    }
                    this.setState({
                        ls_transition_api_running: false,
                        ls_transition_api_response: response,
                    })   
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to convert images! Most likely due to high load. Try again :)")
                }
                this.setState({
                    ls_transition_api_running: false,
                })
            }
        })        
    }
    imageSuperRes = async (image_url) => {
        const payload = {
            image_url: image_url,
            parameters: {}
        }
        message.loading("Create High Res Images...", 0)
        this.props.refreshIdToken().then((token) => {
            var status_code = 200;
            try {
                ImageSuperResolutionAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Image Super Res Completed!")
                    }
                    this.setState({
                        image_super_res_api_running: false,
                        image_super_res_api_response: response,
                    })
                    window.open(response.message.image_url)
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to res images! Most likely due to high load. Please do not use webp. Try again :)")
                }
                else {
                    message.error("Failed to res images!")
                }
                this.setState({
                    image_super_res_api_running: false,
                })
            }
        })
    }

    imageAutoCorrectAndSuperRes = async (image_url) => {
        const payload = {
            image_url: image_url,
            parameters: {}
        };
    
    
        this.props.refreshIdToken().then(async (token) => {
            var status_code = 200;
    
            try {
                // Call ImageAutoCorrectionAPI
                message.loading("Creating High-Quality Image...", 0);
                const autoCorrectResponse = await ImageAutoCorrectionAPI(payload, token);
                status_code = autoCorrectResponse.status_code;
    
                if (autoCorrectResponse.status == false) {
                    message.destroy();
                    message.error(autoCorrectResponse.message["detail"] + ". " + autoCorrectResponse.status_text + ". Login again or new tab :)");
                } else {
                    message.destroy();
                    message.success("Image Auto Correction Completed!");
    
                    // Set state with auto-corrected image API response
                    this.setState({
                        image_auto_correct_api_running: false,
                        image_auto_correct_api_response: autoCorrectResponse,
                    });
    
                    // Call ImageSuperResolutionAPI with the output of the ImageAutoCorrectionAPI
                    const superResPayload = {
                        image_url: autoCorrectResponse.message.image_url,
                        parameters: {}
                    };
                    console.log("Auto color corrected image:",autoCorrectResponse.message.image_url,)
                    message.loading("Creating High-Res Image...", 0);
                    const superResResponse = await ImageSuperResolutionAPI(superResPayload, token);
                    status_code = superResResponse.status_code;
    
                    if (superResResponse.status == false) {
                        message.destroy();
                        message.error(superResResponse.message["detail"] + ". " + superResResponse.status_text + ". Login again or new tab :)");
                    } else {
                        message.destroy();
                        message.success("Image Super Res Completed!");
    
                        // Set state with super-resolved image API response
                        this.setState({
                            image_super_res_api_running: false,
                            image_super_res_api_response: superResResponse,
                        });
    
                        window.open(superResResponse.message.image_url);
                    }
                }
            } catch (error) {
                console.log(error);
                message.destroy();
                if (status_code == 500) {
                    message.error("Failed to process images! Most likely due to high load. Please do not use webp. Try again :)");
                } else {
                    message.error("Failed to process images!");
                }
                this.setState({
                    image_auto_correct_api_running: false,
                    image_super_res_api_running: false,
                });
            }
        });
    }

    handleButtonClick = async (imageUrl) => {
        const { color_correction, super_res } = this.state;
    
        if (!color_correction && !super_res) {
            message.error('Please select either Color Correction or Super Res option before submitting.');
        } else {
            if (color_correction && super_res) {
                console.log("Running color correction and super res on image..", imageUrl);
                this.imageAutoCorrectAndSuperRes(imageUrl);
            } else {
                if (color_correction) {
                    console.log("Running color correction on image..", imageUrl);
                    this.imageAutoCorrect(imageUrl);
                }
                if (super_res) {
                    console.log("Running super resolution on image..", imageUrl);
                    this.imageSuperRes(imageUrl);
                }
            }
        }
    }
    onClickHandler = async () =>  {
        if(this.state.smart_crop==false && this.state.outpaint==false && this.state.super_res==false){
            message.error("Please select at least one option!")
        } 
        else {
            if (this.state.super_res==true)
            {
                if (this.state.smart_crop==true ||  this.state.outpaint==true)
                {
                    message.error("Please select either one of them!") 
                }
                else
                {
                    console.log("Running super resolution on image..", this.state.input_image);
                    this.imageSuperRes(this.state.input_image);
                }
            }
            else{
                console.log("Running LS Transition on image..", this.state.input_image);
                this.lsTransition()
            }
            
        }
    
    }

    
    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {
        const createForm = (
            <div>
                <Layout>
                    <Row>
                    <Col span={8}>
                    <Content>
                        <br />
                        <br />
                        <h3> Ask AI to Generate LS Images </h3>
                        <Row>
                            <Col span={8}>
                                <UploadButton
                                    signin_name={this.props.username}
                                    upload_loading={this.state.image_upload_loading}
                                    upload_endpoint={this.state.upload_endpoint}
                                    id_token={this.props.idToken}
                                    onImUploadChange={(e) => {
                                        if (e.file.status == 'done') {
                                            message.success(' Uploaded!')
                                            var temp_video_upload_url = e.file.response.image_url
                                            this.setState({
                                                input_image: temp_video_upload_url
                                            })
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <br>
                        </br>
                        <Row>
                            <Col span={12}>
                                <TextArea rows={3} style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px' }} placeholder="Override default promt..." onChange={(e) => {
                                    this.setState({
                                        prompt: e.target.value
                                    })
                                }} />
                            </Col>
                        </Row>
                        <br>
                        </br>
                        <Col>
                        <Row>
                            <Col span={6}>
                                <Checkbox onChange={(e) => {
                                    this.setState({
                                        smart_crop: e.target.checked
                                    })
                                }}>Smart Crop</Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox onChange={(e) => {
                                    this.setState({
                                        outpaint: e.target.checked
                                    })
                                }}>Outpaint</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                        <h5>or</h5>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <Checkbox onChange={(e) => {
                                    this.setState({
                                        super_res: e.target.checked
                                    })
                                }}>Super Res</Checkbox>
                            </Col>
                        </Row>
                        </Col>
                        <br></br>
                        <Row>
                        <Col span={6}>
                        <Tooltip trigger={['focus']} title={"stable-diffusion-2 use for default."} placement="topLeft">
                        <Select
                            placeholder="dalle"
                            style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                            }}
                            value={this.state.model}
                            defaultValue={this.state.model}
                            onChange={(e)=>{
                                this.setState({
                                    model: e
                                })
                            }}
                            >
                            {this.state.models.map((e) => {
                                return <Option
                                value={e}>{e}</Option>
                            })}
                            </Select>
                            </Tooltip>
                            </Col>
                            <Col span={6}>
                                <Select
                                    placeholder="position"
                                    style={{
                                        width: '100%',
                                        paddingLeft: '5px',
                                        paddingRight: '5px'
                                    }}
                                    value={this.state.position}
                                    allowClear={true}
                                    onChange={(e) => {
                                        this.setState({
                                            position: e
                                        })
                                    }}
                                >
                                    {this.state.positions.map((e) => {
                                        return <Option
                                            value={e}>{e}</Option>
                                    })}
                                </Select>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col span={6}>
                                <Button type="primary" style={{ width: '100%', marginLeft: '5px' }} block
                                    onClick={this.onClickHandler}
                                    loading={this.state.ls_transition_api_running}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                        <Col span={6}>
                        {
                            this.state.ls_transition_api_response == null
                            ? (
                                <Empty
                                    style={{
                                        paddingTop: "5px",
                                        width: "100%",
                                    }}
                                />
                            )
                            : (
                                        <Col span={6}>
                                            <Card
                                                hoverable
                                                style={{ width: 240 }}
                                                cover={
                                                    <a
                                                        href={this.state.input_image}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img style={{ width: 240 }} alt="" src={this.state.input_image} />
                                                    </a>
                                                }
                                            >
                                                <Meta title={"Input Image"} />
                                            </Card>
                                        </Col>
                                
                            )
                        }
                    </Col>
                    </Row>
                    </Content>
                    </Col>
                    <br></br>
                    <br></br>
                <Col span={16}>
                <Content>
                <br />
                <br />
                <h3> Glide Editor </h3>
                <Col span={24}>
                <Row style={{overflow: "scroll",justifyContent: 'center',
                                                alignItems: 'center',}}>
                {
                    this.state.ls_transition_api_response == null
                    ? (
                        <Empty
                        style={{
                            paddingTop: "5px",
                            width: "100%",
                        }}
                        />
                    )
                    : 
                    (   
                        
                        //console.log(this.state.ls_transition_api_response.message.images),
                        this.state.ls_transition_api_response.message.images.map((image) => {
                            return (
                                        <Col span={12}>
                                            <Card
                                                hoverable
                                                style={{
                                                width: 240
                                                }}
                                                cover={
                                                    <a
                                                        href={image["image_url"]}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img style={{width: 240}} alt="" src={image["image_url"]} />
                                                    </a>
                                                }
                                            >
                                                <Meta title={image["image_title"]} />
                                                <br></br>
                                                <Col span={24}>
                                                        <Checkbox onChange={(e) => {
                                                            this.setState({
                                                                color_correction: e.target.checked
                                                            })
                                                        }}>Color Correction</Checkbox>
                                                    </Col>
                                                <Col span={24}>
                                                        <Checkbox onChange={(e) => {
                                                            this.setState({
                                                                super_res: e.target.checked
                                                            })
                                                        }}>Super Res</Checkbox>
                                                </Col>
                                                <br></br>
                                                <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block
                                                    onClick={() => {
                                                            console.log("Running edits on image...", image["image_url"])
                                                            this.handleButtonClick(image["image_url"])
                                                        }
                                                    }
                                                    loading={this.state.image_super_res_api_running}
                                                    icon={<UpCircleOutlined />}
                                                >
                                                    Submit!
                                                </Button>
                                            </Card>
                                        </Col>
                                       
                                    
                            )
                                })
                            )
                        }
                </Row>
                </Col>
            </Content>
            </Col>
            </Row>
                </Layout>
            </div>
        )
        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                    <NavBar
                        authHandler={async (instance) => {await this.authHandler(instance)}}
                        authRefresh={this.authRefresh}
                        signin_name={this.props.username}
                    />
                    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                            {createForm}
                        </div>
                        </Content>
                    <Footer style={{ textAlign: 'center' }}>
                    <img
                        src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                        alt="logo"
                        style={{
                            height:'20px',
                            display:'inline',
                            paddingRight:'10px'
                        }}/>
                        ©2021 Created by TAG AI Team
                    </Footer>
                </Layout>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuth />
            </UnauthenticatedTemplate>
            </>
        )
    }
}
