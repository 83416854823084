import { Modal, Row, Col, Input, Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
const { Search, TextArea } = Input;

export class GpsCardModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          title: props.title,
          description: props.title,
          questions: props.questions,
          selectedImage: "20f6e3db-518b-4715-9f82-9ea4381a7820",
        };
      }


  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleImageChange = (value) => {
    this.setState({
      selectedImage: value,
    });
  };

  handleSubmit = () => {
    // Your API call logic goes here
    const card = {
      "title": this.state.title?this.state.title:this.props.title,
      "description": this.state.description?this.state.description:this.props.title,
      "url": this.props.url,
      "image_selected": this.state.selectedImage,
    }
    this.props.pushCardToGPS(card);
    // Close the modal after the submission
    this.props.onOk();
  };

  render() {
    return (
      <div>
        <Modal
          open={this.props.isOpen}
          title="Add Card"
          onCancel={this.props.onOk}
          onOpen={this.props.onOk}
          closable={true}
          width={500}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Submit
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item label="Title">
              <TextArea
                name="title"
                value={this.state.title?this.state.title:this.props.title}
                onChange={this.handleInputChange}
              />
            </Form.Item>
            <Button
                  type="primary"
                  style={{
                      marginLeft: '4px',
                      marginBottom: '10px'
                  }}
                  onClick={() => {
                    this.props.rephrase(this.state.title?this.state.title:this.props.title, 40)
                    .then((re_title) => {
                      console.log("re_title", re_title)
                      this.setState({
                        title: re_title
                      })
                    })
                  }}
              >
                  rephrase
              </Button>
            <Form.Item label="Description">
              <TextArea
                name="description"
                value={this.state.description?this.state.description:this.props.title}
                onChange={this.handleInputChange}
              />
            </Form.Item>
            <Button
                  type="primary"
                  style={{
                      marginLeft: '4px',
                      marginTop: '2px',
                      marginBottom: '10px'
                  }}
                  onClick={() => {
                    this.props.rephrase(this.state.title?this.state.title:this.props.title, 120)
                    .then((re_description) => {
                      console.log("re_description", re_description)
                      this.setState({
                        description: re_description
                      })
                    })
                  }}
              >
                  rephrase
              </Button>
            <Form.Item label="URL">
              <Input
                name="url"
                value={this.props.url}
              />
            </Form.Item>
            <Row>
                {this.props.image_id_list.map((img_id) => (
                  <Col key={img_id} span={3}>
                    <img
                        style={{ 
                          width: '70px',
                          cursor: 'pointer',
                          backgroundColor: 'grey'  // Add this line
                        }} 
                      src={this.props.image_cdn_prefix+img_id+".jpg"}
                      onClick={() => {
                        console.log("image id", img_id)
                        this.handleImageChange(img_id)
                      }} 
                    />
                  </Col>
                ))}
              </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}
