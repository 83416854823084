import React from 'react';
import moment from 'moment';
import {
    Typography, Button, Col, Input, Layout, message, Row, InputNumber, Switch,Select,Form, Empty,Modal,Tooltip,Table, Tag,Pagination,DatePicker, Space
} from 'antd';
import {EditOutlined, GoldOutlined} from '@ant-design/icons';
import NavBar from '../components/header';
import { AIPublisherIdDropDown } from '../components/aipublisherIdDropdown';
import {CreateHeadlinesAPI, ScheduleArticlesAPI,UpdateContentFactoryAPI, SearchContentFactoryAPI, CreateDummyArticleAPI} from "../apis/contentFactory";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
const { Content, Footer,Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

export default class ContentFactory extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            currentView: 'new',
            currentItem: null,
            isEditing: false,
            editPublisherId: null,
            contentFactories: [],
            currentPage: 1,
            pageSize: 30, // items per page
            totalArticles: 0,
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            input_image_url: null,
            topics_str: '',
            context: '',
            count:1,
            custom_prompt: null,
            article_prompt: null,
            view: "pipeline",
            aws_cdn_prefix: process.env.REACT_APP_AWS_PREFIX,
            content_factory_api_running: false,
            content_factory_api_response: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            name: null,
            start_at: null,
            end_at: null,
            send_to_gps: false,
            autopublish_on_gps: false,
            submitStatus: "fastlane_published",
            headlines: [], // to store API response for headlines
            selected_headline: null,
            articles:'',
            showScheduleButton: false, // to control display of the schedule button
            article_prompt: null,
            no_of_articles: Array.from({ length: 100 }, (_, i) => i + 1),
            story_types: ["news article","short stories"],
            story_type: "news article",
            count: 1,
            sample_headline_count: 7,
            country: "IN",
            category: null,
            status: "active",
            category_options: ['vietnam-specific', 'thailand-specific', 'generic', 'brazil-specific', 'malaysia-specific', 'philippines-specific', 'indonesia-specific', 'india-specific', 'short-stories', 'health', 'travel', 'fashion', 'beauty', 'sports', 'space', 'science', 'animals', 'food', 'zodiac', 'lifestyle', 'business'],
            publisher_ids: ["en_misc"],
            headline_prefix: "",
            editFormData: {
                name: '',
                category: '',
                custom_prompt: '',
                article_prompt: '',
                article_count: 1,
                context: '',
                topics_str: '',
                story_type: '',
                publisher_ids: [],
                start_at: null,
                end_at: null,
                last_processed_at: null,
                send_to_gps: false,
                autopublish_on_gps: false,
                publisher_ids: [],
                spec: {},
                trigger_now: false,
            },
            temp_factories: [],
            country_to_publisher_id_map: {
                "in": ["en_newsflash", "en_misc", "en_now", "en_specials", "bn_misc", "hi_misc", "ta_misc", "te_misc", "mr_misc", "kn_misc", "en_pti", "en_wiki"],
                "id": ["id_ba_goodtimes", "id_ba_misc", "id_en_misc", "ba_travel"],
                "mx": ["mx_es_misc", "mx_en_misc"],
                "co": ["co_es_misc", "co_en_misc"],
                "br": ["br_pt_misc", "br_en_misc", "br_pt_wiki"],
                "us": ["us_en_misc", "us_es_misc", "us_en_wiki", "us_en_trial"],
                "th": ["th_th_misc", "th_en_misc"],
                "vn": ["vn_vi_misc", "vn_en_misc"],
                "jp": ["jp_ja_misc", "jp_ja_wod", "jp_en_misc"],
                "ph": ["ph_fil_misc", "ph_en_misc"],
                "my": ["my_ms_misc", "my_en_misc"],
            },
            language_to_display_name_map: {
                "en": "English",
                "es": "Spanish",
                "pt": "Portuguese",
                "bn": "Bengali",
                "kn": "Kannada",
                "ta": "Tamil",
                "te": "Telugu",
                "hi": "Hindi",
                "ba": "Bahasa",
                "mr": "Marathi",
                "fil": "Filipino",
                "ja": "Japanese",
                "th": "Thai",
                "vi": "Vietnamese",
                "ms": "Malay",
            },
            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr",
                "ms",
                "vi",
                "th",
                "fil"
            ],
            language: "en",
            countries: ["in", "id", "us", "br", "co", "mx", "vn", "th", "jp", "ph", "my"],
            country_to_display_name_map: {
                "id": "🇮🇩 Indonesia",
                "in": "🇮🇳 India",
                "mx": "🇲🇽 Mexico",
                "co": "🇨🇴 Colombia",
                "us": "🇺🇸 US",
                "br": "🇧🇷 Brazil",
                "th": "🇹🇭 Thailand",
                "vn": "🇻🇳 Vietnam",
                "jp": "🇯🇵 Japan",
                "ph": "🇵🇭 Philippines",
                "my": "🇲🇾 Malaysia",
            },

            prefix_article_prompt: "Write an engaging and informative article titled '{TITLE}'",
            dummy_article_text: null,
            showSampleArticles: false,
            available_publishers: [],
            headline_creation_api_running: false,
            article_creation_api_running: false,

            edit_publisher_ids: [],
            edit_factory_type: "pipeline",
            edit_factory_type_options: ["pipeline", "on-demand"],
            edit_status: "active",
            edit_status_options: ["active", "disabled"],
        };

        for (const publishers of Object.values(this.state.country_to_publisher_id_map)) {
            for (const publisher of publishers) {
                this.state.available_publishers.push(publisher);
            }
        }

        this.headlineCreation = this.headlineCreation.bind(this);
        this.articleCreation = this.articleCreation.bind(this);
        this.handleSchedule = this.handleSchedule.bind(this); // Bind new method
        this.formatPublisherId = this.formatPublisherId.bind(this);

      }

      showEditModal = (item) => {
        this.setState({
            isEditing: true,
            currentItem: item,
            editFormData: {
                name: item.name,
                category: item.category,
                custom_prompt: item.custom_prompt,
                article_prompt: item.article_prompt,
                topics_str: item.topics_str,
                article_count: item.article_count,
                context: item.context,
                // Include other necessary fields and format them appropriately
                start_at: item.start_at ? moment(item.start_at) : null,
                end_at: item.end_at ? moment(item.end_at) : null,
                last_processed_at: item.last_processed_at ? moment(item.last_processed_at) : null,
                status: item.status,
                send_to_gps: item.send_to_gps,
                autopublish_on_gps: item.autopublish_on_gps,
                publisher_ids: item.publisher_ids,
                spec: item.spec,
            }
        });
    }
    
    // Close edit modal
    closeEditModal = () => {
        // the item we were operating on, now we need to move all editFormData back to item from state.temp_factories
        const { currentItem, editFormData } = this.state;
        const updatedItem = { ...currentItem, ...editFormData };
        const updatedFactories = this.state.temp_factories.map(item => {
            if (item.id === currentItem.id) {
                return updatedItem;
            }
            return item;
        });
        this.setState({
            isEditing: false,
            currentItem: null,
            temp_factories: updatedFactories,
        });
    }

    // Handle input changes in modal form
    handleEditChange = (field, value) => {
        if (field == "spec_headline_prefix") {
            let spec = this.state.editFormData.spec;
            // if headline_prefix is changed, update the spec object
            // if spec is null, create a new spec object and update the headline_prefix
            if (spec == null) {
                spec = {};
            }
            spec.headline_prefix = value;
            this.setState(prevState => ({
                editFormData: { ...prevState.editFormData, spec: spec }
            }));
        }
        else {
            this.setState(prevState => ({
                editFormData: { ...prevState.editFormData, [field]: value }
            }));
        }
    }

    // Submit updated data
    updateContentFactory = async () => {
        const { currentItem, editFormData } = this.state;
        // Initialize payload with the currentItem to ensure all data is carried over
        let payload = {...currentItem};
    
        // Update payload with edited fields from editFormData
        Object.keys(editFormData).forEach(key => {
            if (key === 'start_at' || key === 'end_at') {
                // Format dates properly or maintain the original if not modified
                payload[key] = editFormData[key] ? editFormData[key].format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : currentItem[key];
            } else if (key === 'article_count') {
                // Ensure article_count is an integer
                payload[key] = parseInt(editFormData[key]);
            } else {
                // Directly assign other changed fields
                payload[key] = editFormData[key];
            }
        });

        // Handle trigger_now functionality
        if (editFormData.trigger_now) {
            const twentyFiveHoursAgo = new Date(Date.now() - 25 * 60 * 60 * 1000);
            payload.last_processed_at = twentyFiveHoursAgo.toISOString();
        }

        // Remove trigger_now from payload as it's not needed in the API
        delete payload.trigger_now;

        this.props.refreshIdToken().then(async (token) => {
            try {
                const response = await UpdateContentFactoryAPI(payload, token);
                if (response.status) { // Adjust based on your actual API's success response handling
                    message.success('Content Factory updated successfully!');
                    this.closeEditModal();
                    // Optionally refresh the list or re-fetch data if needed
                } else {
                    throw new Error(response.message["detail"] || 'Server responded with an error');
                }
            } catch (error) {
                message.error(`Failed to update Content Factory: ${error.message}`);
            }
        }).catch(error => {
            message.error(`Authentication error: ${error.message}`);
        });
    }

    createSpecForCF = async () => {
        // if this.state.headline_prefix exists then use it
        if (this.state.headline_prefix) {
            const spec = {
                headline_prefix: this.state.headline_prefix,
            };
            return spec;
        }
        return {};
    }

    articleCreation = async () => {
        message.loading("Fetching Article...", 0)
        this.setState({
            content_factory_api_running: false,
            article_creation_api_running: true,
            content_factory_api_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            // create variable temp_prompt
            let temp_prompt = "";
            if (this.state.article_prompt !== null) {
                temp_prompt = this.getFullArticlePrompt()
            }
            const payload = {
                title: this.state.selected_headline,
                prompt: temp_prompt,
                word_count: 1200,
                publisher_id: this.state.publisher_ids[0],
            }
            if (this.state.context) {
                payload["context"] = this.state.context;
            }
            console.log("Payload for dummy article", payload)
            try {
                CreateDummyArticleAPI(payload, token).then((response) => {
                    if(response.status==false){
                        message.destroy()
                        message.error("Article Creation API failed!")
                    }
                    else {
                        message.destroy()
                        message.success("Article created!")
                        this.setState({
                            dummy_article_text: response.message.text,
                            showSampleArticles: true,
                            content_factory_api_running: false,
                            article_creation_api_running: false,
                        })
                        
                        if(this.state.language!='en')
                        {
                            this.setState({
                                dummy_article_text: response.message.translated_text,
                                showSampleArticles: true,
                                content_factory_api_running: false,
                                article_creation_api_running: false,
                            })
                        }
                    }
                    this.setState({
                        content_factory_api_running: false,
                        content_factory_api_response: response,
                        article_creation_api_running:false
                    })
                })
            } catch (error) {
                message.destroy()
                message.error("Failed to complete texts!")
                this.setState({
                    content_factory_api_running: false,
                    article_creation_api_running: false,
                })
            }
        })
    }


    getContentFactories = async (skip = 0, limit = 100) => {
        this.props.refreshIdToken().then((token) => {
            message.loading("Fetching Content Factories..")
            var status_code = 200;
            const payload = {
                skip: skip,
                limit: limit,
                factory_type: this.state.edit_factory_type,
                status: this.state.edit_status,
                publisher_ids: this.state.edit_publisher_ids,
            }
            SearchContentFactoryAPI(payload, token).then((response) => {
                status_code = response.status_code;
                if (status_code === 500) {
                    message.error("Failed to fetch CF! Most likely due to high load. Try again");
                } else if (status_code === 401) {
                    message.error("You are not logged in. We'll fix this, just refresh the page or open in new tab 😅");
                } else {
                    message.destroy();
                    message.success("Fetched CF!");
                    console.log("Response",response.message);
                    const filtered_factories = response.message;
                    this.setState({
                        temp_factories: filtered_factories,
                    });
                    console.log("Response after",filtered_factories);
                }
            }).catch((error) => {
                if (status_code === 500) {
                    message.error("Failed to fetch articles! Most likely due to high load. Try again");
                } else if (status_code === 401) {
                    message.error("You are not logged in. We'll fix this, just refresh the page or open in new tab 😅");
                } else {
                    message.error("Failed to get articles!");
                }
            });
        });
    }
    
    fetchContentFactory = (page) => {
        const skip = (page - 1) * this.state.pageSize;
        const limit = this.state.pageSize;
        this.getContentFactories(skip, limit);
    };

    handleSubmit = () => {
        // Update the state based on form input, for example:
        //this.setState({ submitStatus: this.state.status });
        this.setState({ content_factory_api_response:null});
      };

    handleTextChange = (event) => {
        const headlines = event.target.value.split('\n');
        this.setState({ headlines });
    };

    toggleView = () => {
        this.setState((prevState) => ({
          view: prevState.view === 'pipeline' ? 'on-demand' : 'pipeline',
        }));
    };

    handlePromptChange = (event) => {
        this.setState({ custom_prompt: event.target.value });
    };

    handleHeadlineSubmit = () => {
        this.setState({ content_factory_api_response:null});
        this.setState({ headlines: []});
        this.setState({ showScheduleButton: false});
        this.setState({ showSampleArticles: null});
    }

    handleArticleSubmit = () => {
        this.setState({ content_factory_api_response:null});
        this.setState({ dummy_article_text: null});
        this.setState({ showSampleArticles: null});
      }

    formatPublisherId = (publisherId) => {
        const components = publisherId.split("_");
        let countryCode, languageCode, languageName, countryName, misc;
        if (components.length === 2) {
          [languageCode, misc] = components;
          languageName = this.state.language_to_display_name_map[languageCode] || languageCode;
          //this.setState({ language: languageCode });
          return `${languageName} ${misc}`;
        } else if (components.length === 3) {
          [countryCode, languageCode, misc] = components;
          languageName = this.state.language_to_display_name_map[languageCode] || languageCode;
          countryName = this.state.country_to_display_name_map[countryCode] || countryCode;
          //this.setState({ language: languageCode });
          return `${countryName}-${languageName} ${misc}`;
        } else {
          return publisherId; // Fallback to displaying the raw publisher ID if format is not recognized
        }
      };

    headlineCreation = async () => {
        message.loading("Fetching Headline...", 0)
            this.setState({ headline_creation_api_running:true})
            this.props.refreshIdToken().then((token) => {
                const payload = 
                    {
                        "count": this.state.sample_headline_count,
                        "custom_prompt": this.state.custom_prompt,
                        "category": this.state.category,
                        "topics_str": this.state.topics_str,
                        "target_language": this.state.language,
                        "headline_prefix": this.state.headline_prefix,
                    }
                
                CreateHeadlinesAPI(payload, token).then((response) => {
                    if(response.status===false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Headline Search Complete!")
                    }
                    this.setState({
                        content_factory_api_running: false,
                        headline_creation_api_running: false,
                        content_factory_api_response: response,
                        dummy_article_text: '',
                        article_prompt: null,
                        headlines: response.message,
                        // set selected_headline to the first value of headlines
                        // handle for errors
                        // if the response is empty, set selected_headline to null
                        selected_headline: response.message.length > 0 ? response.message[0] : null,
                        showScheduleButton: true,
                    });
                
                }).catch ((error) => {
                    message.destroy()
                    message.error("Failed to fetch Headlines. Try again?")
                    this.setState({
                        content_factory_api_running: false,
                        headline_creation_api_running: false,
                        article_prompt: null,
                        dummy_article_text:''
                    })
                })
    
        })
    }

    getFullArticlePrompt = () => {
        return this.state.prefix_article_prompt.replace("{TITLE}", this.state.selected_headline) + this.state.article_prompt;
    }

    createContentFactory = async () => {
        message.loading("Scheduling Articles...", 0)
         this.setState({ content_factory_api_running:true})
         this.props.refreshIdToken().then((token) => {
             const payload = 
                 {
                    "name": this.state.name,
                    "article_count": this.state.count,
                    "context": this.state.context,
                    "category": this.state.category,
                    "topics_str": this.state.topics_str,
                    "sample_headlines": this.state.headlines.join('\n '),
                    "factory_type": this.state.view,
                    "publisher_ids": this.state.publisher_ids,
                    "start_at": this.state.start_at,
                    "end_at" : this.state.end_at,
                    "story_type": this.state.story_type,
                    "author": this.props.username,
                 }
                 if (this.state.custom_prompt) {
                    payload["custom_prompt"] = this.state.custom_prompt
                }
                if (this.state.article_prompt) {
                    payload["article_prompt"] = this.state.article_prompt
                }
                if (this.state.send_to_gps) {
                    payload["send_to_gps"] = this.state.send_to_gps
                }
                if (this.state.autopublish_on_gps) {
                    payload["autopublish_on_gps"] = this.state.autopublish_on_gps
                }
                if (this.state.headline_prefix) {
                    if (payload["spec"] == null) {
                        payload["spec"] = {};
                    }
                    payload["spec"]["headline_prefix"] = this.state.headline_prefix;
                }
                console.log("Payload for scheduling articles", payload)
             ScheduleArticlesAPI(payload, token).then((response) => {
                 if(response.status===false){
                     message.destroy()
                     message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                 }else{
                     message.destroy()
                     message.success("Articles Scheduled Successfully!")
                 }
                 this.setState({
                     content_factory_api_running: false,
                     headline_creation_api_running: false,
                     content_factory_api_response: response,
                     showScheduleButton: true,
                 });
                
             }).catch ((error) => {
                 message.destroy()
                 message.error("Failed to schedule articles. Try again?")
                 this.setState({
                     content_factory_api_running: false,
                     headline_creation_api_running: false,
                     dummy_article_text:''
                 })
             })
     
         })
     }

    updateLanguageFromPublisherId = (publisherId) => {
        console.log("Publisher ID:", publisherId); // Check what is being passed
        if (!publisherId || publisherId.length === 0) {
            return;
        }
        const components = publisherId[0].split("_");
        let languageCode;
        if (components.length === 2) {
            [languageCode] = components;
        } else if (components.length === 3) {
            [, languageCode] = components;
        }
        const languageName = this.state.language_to_display_name_map[languageCode] || languageCode;
        this.setState({ language: languageCode });
    };

    handleSchedule = () => {
        // Implement scheduling logic here
        console.log("Scheduling Articles...");
        message.success("Articles Scheduled!");
    };

    handleRadioChange = (event) => {
        this.setState({ selected_headline: event.target.value });
    };

    componentDidMount(){
        // DO NOTHING HERE
    }
    setView = (view) => {
        this.setState({ currentView: view });
    };

    renderContentFactoryTable = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
            },
            // render category as above
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
                sorter: (a, b) => a.category.localeCompare(b.category),
            },
            // render publisher_ids as tags, blue ones
            {
                title: 'Publisher IDs',
                dataIndex: 'publisher_ids',
                key: 'publisher_ids',
                render: (publisher_ids) => {
                    return publisher_ids.map((publisher_id, index) => (
                        <Tag color="blue" key={index}>
                            {this.formatPublisherId(publisher_id)}
                        </Tag>
                    ));
                },
            },
            // render topics_str as tags, but break it into comma separated values and show only 3
            {
                title: 'Topics',
                dataIndex: 'topics_str',
                key: 'topics',
                render: (topics) => {
                  const topicList = topics.split(',').map(topic => topic.trim());
                  return topicList.slice(0, 3).map((topic, index) => {
                    const displayTopic = topic.length > 15 ? topic.substring(0, 15) + '...' : topic;
                    return (
                      <Tag color="green" key={index} title={topic}>
                        {displayTopic}
                      </Tag>
                    );
                  });
                },
              },
            // render status if active as green if disabled as red
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status) => {
                    return status === 'active' ? (
                        <Tag color="green">{status}</Tag>
                    ) : (
                        <Tag color="red">{status}</Tag>
                    );
                },
            },
            {
                title: 'Articles / Day',
                dataIndex: 'article_count',
                key: 'article_count',
                sorter: (a, b) => a.article_count - b.article_count,
            },
            // format start_at & end_at as date ONLY (from 2024-04-17T06:32:37.202000)
            {
                title: 'Start At',
                dataIndex: 'start_at',
                key: 'start_at',
                render: (start_at) => {
                    return moment(start_at).format('YYYY-MM-DD');
                },
            },
            {
                title: 'End At',
                dataIndex: 'end_at',
                key: 'end_at',
                render: (end_at) => {
                    return moment(end_at).format('YYYY-MM-DD');
                },
            },
            {
                title: 'Last Scraped At',
                dataIndex: 'last_processed_at',
                key: 'last_processed_at',
                render: (last_processed_at) => {
                    return moment(last_processed_at).format('YYYY-MM-DD');
                },
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (_, record) => (
                    <Tooltip title="Edit ContentFactory">
                    <Button 
                        icon={<EditOutlined />} 
                        onClick={() => this.showEditModal(record)}
                    >
                        Edit
                    </Button>
                    </Tooltip>
                ),
            },
        ];

        if (!this.state.temp_factories || this.state.temp_factories.length === 0) {
            return <Empty description="No Content Factories found" />;
        }
        return (
          <Table 
            columns={columns} 
            dataSource={this.state.temp_factories}
            rowKey="id"
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        );
    }
    
    render() {
        const { isEditing, editFormData } = this.state;
        const { view, custom_prompt, headlines } = this.state;
        const createForm = this.state.currentView === 'new' ? (
            <div>
                <Layout>
            <br></br>
            <Content>
            <Row gutter={[16, 16]}>
                <Col span={4}>
                    <h3>Factory Identifier</h3>
                    <Input
                        placeholder='What do you wanna refer this project by?'
                        onChange={(e) => this.setState({ name: e.target.value })}
                    />
                </Col>
                <Col span={6}>
                    <h3>Topic</h3>  
                    <Input 
                    placeholder='Topic name...' 
                    onChange={(e) => this.setState({ category: e.target.value })}
                    onPressEnter={!this.state.content_factory_api_running ? this.headlineCreation : undefined}
                    />
                </Col>
                <Col span={3}>
                    <h3>Articles / Day</h3>  
                    <Select
                    placeholder="Count in integer?"
                    style={{ width: '100%' }}
                    value={this.state.count}
                    allowClear={true}
                    onChange={(e) => this.setState({ count: e })}
                    >
                    {this.state.no_of_articles.map(k => <Option key={k} value={k}>{k}</Option>)}
                    </Select>
                </Col>
                <Col span={4}>
                    <h3>Story Type</h3>  
                    <Select
                    placeholder="Story type?"
                    style={{ width: '100%' }}
                    value={this.state.story_type}
                    allowClear={true}
                    onChange={(e) => this.setState({ story_type: e })}
                    >
                    {this.state.story_types.map(k => <Option key={k} value={k}>{k}</Option>)}
                    </Select>
                </Col>
                <Col span={3}>
                    <h3>Direct2GPS</h3>
                    <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    onChange={(checked) => this.setState({ send_to_gps: checked })}
                    />
                </Col>
                <Col span={4}>
                    <h3>Auto Publish on GPS</h3>
                    <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    onChange={(checked) => this.setState({ autopublish_on_gps: checked })}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center" style={{ marginTop: 16 }}>
                <Col span={4}>
                    <h3>Headline Prefix (Optional)</h3>
                    <Input
                        placeholder='Any prefix for headline?'
                        onChange={(e) => this.setState({ headline_prefix: e.target.value })}
                    />
                </Col>
                <Col span={6}>
                    <h3>Publishers</h3>
                    <AIPublisherIdDropDown
                    style={{ width: '100%' }}
                    publisher_ids={this.state.publisher_ids}
                    onPublisherSelect={(e) => {
                        this.setState({ publisher_ids: e });
                        this.updateLanguageFromPublisherId(e);
                    }}
                    formatPublisherId={this.formatPublisherId}
                    available_publishers={this.state.available_publishers}
                    />
                </Col>
                <Col span={4}>
                    <h3>Start At</h3>
                    <DatePicker
                    style={{ width: '100%' }}
                    value={this.state.start_at}
                    onChange={(date) => this.setState({ start_at: date })}
                    disabledDate={(current) => current && current < moment().startOf('day')}
                    placeholder="Select start date"
                    />
                </Col>
                <Col span={4}>
                    <h3>End At</h3>
                    <DatePicker
                    style={{ width: '100%' }}
                    value={this.state.end_at}
                    onChange={(date) => this.setState({ end_at: date })}
                    disabled={!this.state.start_at}
                    disabledDate={(current) => {
                        if (!this.state.start_at) return true;
                        const dayAfterStartAt = moment(this.state.start_at).add(1, 'days').startOf('day');
                        return current.isBefore(dayAfterStartAt, 'day');
                    }}
                    placeholder="Select end date"
                    />
                </Col>
            </Row>

            <br></br>
            <Row span={3}>
            <Col span={24}>
            <h3>Sub-Topics</h3>
            <TextArea rows={2} placeholder='Topics' onChange={(e)=>{
                this.setState({
                    topics_str: e.target.value
                })
            }}
            />
            </Col>
            </Row>
            <br></br>
            <Row span={3}>
                <Col span={24}>
                    <h3>Context (Optional)</h3>
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 10 }}
                        placeholder='Additional context you wish to base your content around'
                        onChange={(e)=>{
                            this.setState({
                                context: e.target.value
                            })
                        }}
                    />
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col span={24}>
                <Button onClick={this.toggleView}>
                {view === 'pipeline' ? 'Switch to Direct Headlines' : 'Switch to Custom Prompt'}
                <br></br>
                </Button>
                {view === 'pipeline' ? (
                <div>
                    <h3>Custom Headline Prompt</h3>
                    <TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    placeholder='Optional prompt which we could use to manipulate the default article headline generation...'
                    value={custom_prompt}
                    onChange={this.handlePromptChange}
                    />
                </div>
                ) : (
                <div>
                    <h3>Direct Headlines</h3>
                    <TextArea
                    autoSize={{ minRows: 4, maxRows: 10 }}
                    placeholder='Write each headline on a new line...'
                    onChange={this.handleTextChange}
                    />
                </div>
                )}
            </Col>

            </Row>
        <br></br>
            <Row justify="center">
            <Col span={4}></Col>
            </Row>
            <Row align="middle" style={{ height: '100%' }}>
            <Col span={20}>
            <h3>Sample Headlines</h3>
                {this.state.headlines.map((headline, index) => (
                    <div key={index}>
                        <label>
                        <input
                            type="radio"
                            name="headline"
                            value={headline}
                            checked={this.state.selected_headline === headline}
                            onChange={this.handleRadioChange}
                        />
                        {headline}
                        </label>
                    </div>
                ))}
            </Col>
            <Col span={4}>
                <Button 
                    type="primary" 
                    style={{ width: '100%', marginLeft: '5px' }} 
                    block
                    onClick={async () => {
                        await this.headlineCreation();
                        this.handleHeadlineSubmit();
                    }} 
                    loading={this.state.headline_creation_api_running}
                    disabled={!this.state.category || this.state.category.trim() === ''}
                    >
                    Generate Headlines
                </Button>
            </Col>
             </Row>
             <Row align="middle" style={{ height: '100%' }}>
            <Col span={20}>
            {this.state.showScheduleButton && (<h3> Article Generation Prompt (Optional)</h3>)}

            {this.state.showScheduleButton && (
                <>
                    <div style={{ 
                        fontSize: '12px', 
                        color: 'rgba(0, 0, 0, 0.45)', 
                        marginBottom: '4px' 
                    }}>
                    {this.state.prefix_article_prompt.replace("{TITLE}", this.state.selected_headline)}
                    </div>
                    <TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    placeholder="Additional Prompt to generate sample article..."
                    onChange={(e) => {
                        this.setState({
                        article_prompt: e.target.value
                        })
                    }}
                    />
                </>
                )}

            </Col>
            <Col span={4}>
            {this.state.showScheduleButton && (
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
            onClick={async () => {
                await this.articleCreation();
                this.handleArticleSubmit();
            }} loading={this.state.article_creation_api_running} >
             Generate Article
            </Button>
             )}
            </Col>
             </Row>    
             <Row>
             {this.state.showSampleArticles && (
                    <h3>Sample Article</h3>) }
             </Row>
             <Row  style={{ height: '100%', marginTop: '20px' }}>
                
                <Col span={20}>
                {this.state.showSampleArticles &&(
                        <TextArea
                            rows={20}
                            value={this.state.dummy_article_text}
                            readOnly // make it read-only if you only want to display data
                        />
                )}
                </Col>
            <br></br>
            <Row></Row>
            </Row>
                <Row justify="center" align="middle" style={{ height: '100%' }}>
                    <Row>
                    {this.state.showScheduleButton && (
                        <Button 
                            type="primary" 
                            onClick={async () => {
                                await this.createContentFactory();
                                this.handleSubmit();
                            }} 
                            loading={this.state.content_factory_api_running}
                            disabled={
                                !this.state.name || 
                                !this.state.category || 
                                !this.state.start_at ||
                                !this.state.end_at ||
                                this.state.name.trim() === '' ||
                                this.state.category.trim() === ''
                            }
                            >
                            Schedule Articles
                        </Button>
                    )}
                    </Row>
                </Row>          
            <br></br>
            </Content>
            <br></br>
            <br></br>
          </Layout>
            </div>
        ): (
            <div>
            <Row>
            <Col span={3}>
                <h3 >Factory Type</h3>  
                <Tooltip trigger={['focus']} title={"Factory type"} placement="topLeft">
                    <Select
                        placeholder="story type?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.edit_factory_type}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                edit_factory_type: e
                                })
                            }}
                        >
                        {this.state.edit_factory_type_options.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>

            <Col span={3}>
                <h3 >Status</h3>  
                <Tooltip trigger={['focus']} title={"Status"} placement="topLeft">
                    <Select
                        placeholder="status?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.edit_status}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                edit_status: e
                                })
                            }}
                        >
                        {this.state.edit_status_options.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>

                                    
                            <Form.Item
                                label = ""
                                name = "Publishers:"
                                required
                            >
                                    </Form.Item>

                                 <Col span={4}>
                                    <Tooltip trigger={['focus']} title={"Any specific publisher?"} placement="topLeft">
                                    <h3>
                                    Publishers
                                </h3>
                                    <AIPublisherIdDropDown
                                        style={{
                                            width: '100%',
                                            paddingLeft: '5px',
                                            paddingRight: '5px'
                                        }}
                                        publisher_ids={this.state.edit_publisher_ids}
                                        onPublisherSelect={(e)=>{
                                            this.setState({
                                                edit_publisher_ids: e
                                            })
                                            this.updateLanguageFromPublisherId(e);
                                        }}
                                        formatPublisherId={this.formatPublisherId}
                                        available_publishers={this.state.available_publishers}
                                    />
                                    </Tooltip>
                                    </Col>
                                    <br></br>
                                    <br></br>
                                    <Col span={4}>
                                    <Button style={{
                                        // move button a bit below
                                        marginTop: "25px",
                                        }}
                                    type="primary" onClick={() => this.getContentFactories()}>Load ContentFactories</Button>
                                    </Col>
                                    
                                    </Row>

                                    <Content>
                        
                        <Row style={{overflow: "scroll",justifyContent: 'center',
                                                        alignItems: 'center',}}>
                        {
                            this.state.temp_factories == null
                            ? (
                                <Empty
                                style={{
                                    paddingTop: "5px",
                                    width: "100%",
                                }}
                                />
                            )
                            :
                            (   
                                <>
                                <br></br>
                                <Row>
                                    <div>
                                        {this.renderContentFactoryTable()}
                                    </div>
                                </Row>
                                </> 
                            )
                        }
                        </Row>
                    </Content>
            <center>
                <Pagination
                    current={this.state.currentPage}
                    total={this.state.totalArticles}
                    pageSize={this.state.pageSize}
                    onChange={(page) => this.fetchArticles(this.state.publisher_ids, page)}
                />
            </center>
        </div>
    );

        return (
            
            <>
                <AuthenticatedTemplate>
                    <Layout style={{ minHeight: '100vh' }}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                          {this.state.isEditing && (
                            <Modal
                                title="Edit Content Factory"
                                visible={isEditing}
                                onOk={this.updateContentFactory}
                                onCancel={this.closeEditModal}
                                footer={[
                                    <Button key="back" onClick={this.closeEditModal}>Cancel</Button>,
                                    <Button
                                        key="trigger"
                                        onClick={() => {
                                            this.handleEditChange('trigger_now', true);
                                            this.updateContentFactory();
                                        }}
                                    >
                                        Update & Trigger Now
                                    </Button>,
                                    <Button key="submit" type="primary" onClick={this.updateContentFactory}>
                                        Update
                                    </Button>,
                                ]}
                                >
                                <Form layout="vertical">
                                    <Form.Item label="Factory Identifier">
                                    <Input value={editFormData.name} onChange={e => this.handleEditChange('name', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Category">
                                    <Input value={editFormData.category} onChange={e => this.handleEditChange('category', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Topics">
                                    <Input value={editFormData.topics_str} onChange={e => this.handleEditChange('topics_str', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Headline Prefix">
                                    <Input value={editFormData?.spec?.headline_prefix} onChange={e => this.handleEditChange('spec_headline_prefix', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Context">
                                    <Input value={editFormData.context} onChange={e => this.handleEditChange('context', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Custom Prompt">
                                    <TextArea autoSize value={editFormData.custom_prompt} onChange={e => this.handleEditChange('custom_prompt', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Article Prompt">
                                    <TextArea autoSize value={editFormData.article_prompt} onChange={e => this.handleEditChange('article_prompt', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Sample Headlines">
                                    <TextArea autoSize value={editFormData.sample_headlines} onChange={e => this.handleEditChange('sample_headlines', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Publisher IDs">
                                        <AIPublisherIdDropDown
                                            style={{
                                                width: '100%',
                                                paddingLeft: '5px',
                                                paddingRight: '5px'
                                            }}
                                            publisher_ids={editFormData.publisher_ids}
                                            onPublisherSelect={(e)=>{
                                                this.handleEditChange('publisher_ids', e)
                                            }}
                                            formatPublisherId={this.formatPublisherId}
                                            available_publishers={this.state.available_publishers}
                                        />
                                    </Form.Item>
                                    <Row gutter={16}>
                                    <Col span={4}>
                                        <Form.Item label="Articles/D">
                                        <InputNumber
                                            min={1}
                                            value={editFormData.article_count}
                                            onChange={value => this.handleEditChange('article_count', value)}
                                            style={{ width: '100%' }}
                                        />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Start At">
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            value={editFormData.start_at}
                                            onChange={(date) => this.handleEditChange('start_at', date)}
                                        />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="End At">
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            value={editFormData.end_at}
                                            onChange={(date) => this.handleEditChange('end_at', date)}
                                        />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Status">
                                            <Switch
                                                checked={editFormData.status === 'active'}
                                                onChange={(checked) => this.handleEditChange('status', checked ? 'active' : 'disabled')}
                                                checkedChildren="Active"
                                                unCheckedChildren="Disabled"
                                            />
                                        </Form.Item>
                                    </Col>
                                    </Row>

                                    <Row gutter={16} justify="center" align="middle" style={{ marginBottom: '24px' }}>
                                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Space align="center">
                                            <span>Direct2GPS</span>
                                            <Switch
                                                checked={editFormData.send_to_gps === true}
                                                onChange={(checked) => this.handleEditChange('send_to_gps', checked ? true : false)}
                                                checkedChildren="Yes"
                                                unCheckedChildren="NOO!"
                                            />
                                            </Space>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Space align="center">
                                            <span>Auto Publish on GPS</span>
                                            <Switch
                                                checked={editFormData.autopublish_on_gps === true}
                                                onChange={(checked) => this.handleEditChange('autopublish_on_gps', checked ? true : false)}
                                                checkedChildren="Yes"
                                                unCheckedChildren="NOO!"
                                            />
                                            </Space>
                                        </Col>
                                        </Row>
                                    </Form>
                                </Modal>
                )}
                        <Sider width={200} className="site-layout-background" style={{ padding: '10px' }}>
                        <div 
                            style={{ 
                                color: 'white', 
                                padding: '15px',
                                paddingTop: '60px',
                                textAlign: 'center', 
                                fontSize: '18px',
                                fontWeight: 'bold',
                                zIndex: 1 // To ensure it's above other elements
                            }}
                            >
                                <GoldOutlined />  Content Factory
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Button
                                    type={this.state.currentView === 'new' ? 'primary' : 'default'}
                                    style={{ marginBottom: '16px', height: '40px' }}
                                    onClick={() => this.setView('new')}
                                    block
                                >
                                    New
                                </Button>
                                <Button
                                    type={this.state.currentView === 'edit' ? 'primary' : 'default'}
                                    style={{ height: '40px' }}
                                    onClick={() => this.setView('edit')}
                                    block
                                >
                                    Edit
                                </Button>
                            </div>
                        </Sider>
                        <Layout style={{ padding: '0 24px 24px' }}>
                            <Content style={{ padding: '0 50px', marginTop: 64, overflow: 'initial' }}>
                                <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                    {createForm}
                                </div>
                            </Content>
                            <Footer style={{ textAlign: 'center' }}>
                                <img
                                    src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                                    alt=""
                                    style={{
                                        height: '20px',
                                        display: 'inline',
                                        paddingRight: '10px'
                                    }}
                                />
                                ©2021 Created by TAG AI Team
                            </Footer>
                        </Layout>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        );
    }
}
